import { Injectable, OnInit } from '@angular/core';

// import { DropdownQuestion } from './question-dropdown';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './textbox';
import { Observable, of } from 'rxjs';
import { CheckboxQuestion } from './checkbox';
import { RadioQuestion } from './radio';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
import { map } from 'rxjs/operators';
// import { QuestionApiService } from './question-api.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private appraisalService: AppraisalService,
    // private quesApiService: QuestionApiService
  ) {

  }

  filteredData = [];
  dataCheck: any;
  data = {
    "success": true,
    "data": [
      {
        "id": 1,
        "title": "What are person's accomplishments in last 12 month?",
        "description": "(Please review Achievements of the past year for the employee and mark your comments)",
        "hint": "Achievements",
        "type": "textarea",
        "validations": {
          "max": 1000,
          "min": 100
        },
        "order": 1,
        "is_disabled": false,
        "meta": {
          "json": null
        }
      },
      {
        "id": 2,
        "title": "Please review Achievements of the past year for the employee and mark your comments",
        "description": "What are the skills you want to learn this year?",
        "hint": "select any 3",
        "type": "checkbox",
        "validations": {
          "max": 3,
          "min": 1
        },
        "order": 2,
        "is_disabled": false,
        "meta": {
          "id": 1,
          "json": {
            "1": "module training",
            "2": "project reporting",
            "3": "language training"
          }
        }
      },
      {
        "id": 3,
        "title": "How motivated are you for working in Amplus?",
        "description": "(Please review Achievements of the past year for the employee and mark your comments)",
        "hint": "select 1",
        "type": "radio",
        "validations": {
          "max": 1,
          "min": 1
        },
        "order": 3,
        "is_disabled": false,
        "meta": {
          "id": 2,
          "json": {
            "1": "occasionaly",
            "2": "always",
            "3": "never"
          }
        }
      }
    ]
  }

  // filterData() {
  //   this.appraisalService.getSelfAppraisalTemplate().subscribe((res: any) => {
  //     this.dataCheck = res;
  //     console.log("data check===================>", this.dataCheck)
  //   })
  //   let filterData = this.data.data;
  //   for (let i = 0; i < filterData.length; i++) {
  //     let arrmeta = [];
  //     let arrvalid = [];
  //     arrvalid.push(filterData[i].validations);
  //     filterData[i]['validation'] = arrvalid;
  //     if (filterData[i].meta.json != null) {
  //       Object.keys(filterData[i].meta.json).forEach(function (key) {
  //         console.log('Key : ' + key + ', Value : ' + filterData[i].meta.json[key])
  //         let obj = {};
  //         obj['id'] = key;
  //         obj['name'] = filterData[i].meta.json[key];
  //         arrmeta.push(obj);
  //       })
  //       filterData[i].meta.json = arrmeta;
  //       filterData[i]['newMeta'] = arrmeta;
  //     }
  //   }
  //   this.filteredData = filterData;
  //   console.log(this.filteredData, '============================')
   
  //   console.log(this.filteredData, '============================')
  // }

  // getQuestions() {
  //   const questions: QuestionBase<string>[] = [];
  //   this.appraisalService.getSelfAppraisalTemplate().subscribe((res: any) => {
  //     this.dataCheck = res.data;
  //     console.log("data check===================>", this.dataCheck)
   
  //   let filterData = this.dataCheck;
  //   for (let i = 0; i < filterData.length; i++) {
  //     let arrmeta = [];
  //     let arrvalid = [];
  //     arrvalid.push(filterData[i].validations);
  //     filterData[i]['validation'] = arrvalid;
  //     if (filterData[i].meta.json != null) {
  //       Object.keys(filterData[i].meta.json).forEach(function (key) {
  //         console.log('Key : ' + key + ', Value : ' + filterData[i].meta.json[key])
  //         let obj = {};
  //         obj['id'] = key;
  //         obj['name'] = filterData[i].meta.json[key];
  //         arrmeta.push(obj);
  //       })
  //       filterData[i].meta.json = arrmeta;
  //       filterData[i]['newMeta'] = arrmeta;
  //     }
  //   }
  //   // this.filteredData = filterData;
  //   // this.filterData();
  //   let ques = filterData;
  //   console.log(ques, "=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")

  //   for (var i = 0; i < ques.length; i++) {
  //     if (ques[i].type == "textarea") {
  //       questions.push(
  //         new TextboxQuestion(ques[i])
  //       )
  //     }
  //     else if (ques[i].type == "checkbox") {
  //       questions.push(
  //         new CheckboxQuestion(ques[i])
  //       )
  //     }
  //     else if (ques[i].type == "radio") {
  //       questions.push(
  //         new RadioQuestion(ques[i])
  //       )
  //     }
  //   }
  //   console.log(questions.sort((a, b) => a.order - b.order), "[][][][][][][][][][][][][][][][][][][]");
  //   return of(questions.sort((a, b) => a.order - b.order));
  // })
  // }

}




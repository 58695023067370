export class QuestionBase<T> {
    // value: T|undefined;
    title: string;
    description: string;
    hint: string;
    order: number;
    type: string;
    validation: { min: number, max: number}[];
    group: {id: number, name: string}[];
    newMeta: {id: number, name: string} [];
    id: number;
    question: any;
  
    constructor(options: {
        // value?: T;
        title?: string;
        description?: string;
        hint?: string;
        order?: number;
        type?: string;
        id?: number;
        group?: {id: number, name: string}[];
        validation?: { min: number, max: number}[];
        newMeta?: {id: number, name: string} [];
      } = {}) 
      
      {
      this.title = options.title || '';
      this.description = options.description || '';
      this.hint = options.hint || '';
      this.validation = options.validation || [];
      this.order = options.order === undefined ? 1 : options.order;
      this.type = options.type || '';
      this.group = options.group;
      this.id = options.id;
      this.newMeta = options.newMeta || [];
    }
  }
  
  
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'action-popup',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionPopupComponent implements OnInit {

    // @Input() data: any;
    @Output() option: EventEmitter<any> = new EventEmitter();
    form: any;
    title: any;
    modality: any;
    location: any;
    date: any;
    startTime: any;
    endTime: any;
    message: any;
    dataPresent = false;
    desc: any;
    buttontext: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ActionPopupComponent>,
        private matDialog: MatDialog,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        console.log("inside conformation popup", this.data);
        this.message = this.data.message;
        if (this.data.data != null) {
            this.desc = this.data.desc;
            this.buttontext = this.data.buttontext;
            this.dataPresent = true;
            this.title = this.data.data.name;
            this.modality = this.data.data.modality;
            this.location = this.data.data.location;
            this.date = formatDate(this.data.data.start_time, "MMM dd, yyyy", 'en-IN');
            this.startTime = formatDate(this.data.data.start_time, "HH:mm aa", 'en-IN');
            this.endTime = formatDate(this.data.data.end_time, "HH:mm aa", 'en-IN');
        }
        else{
            this.desc = this.data.desc;
            this.buttontext = this.data.buttontext;
        }
    }

    cancel() {
        this.dialogRef.close();
    }
    
    option_yes() {
        this.option.emit('success');
        this.dialogRef.close();
      }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LoadChildren, Router } from '@angular/router';
import { formatDate, DatePipe } from '@angular/common';
import { LearnerService } from 'src/app/core/services/learner.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { ActionPopupComponent } from '../action/action.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import{AllLinksComponent} from 'src/app/core/all-links/all-links.component'

@Component({
    selector: 'portal-page',
    templateUrl: './portal-page.component.html',
    styleUrls: ['./portal-page.component.css']
})
export class PortalPageComponent implements OnInit {

    form: any;
    data: any;
    isAdmin: boolean = false;
    user: any;
    name: any;
    user_type: any;
    lndUserType: boolean = false;
    AllLinksComponent: any;
    showcard:any;
    showhr:boolean;
    showPOA:any;
    showAmdoc:any;

    constructor(
        public allLink : AllLinksComponent,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private msalService: LoginService,
        private router: Router,
        private learnerService: LearnerService,
        private loginService: LoginService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside portal page");
        this.learnerService.getUserType().subscribe(res => {
            this.user = res['lnd_user_type'];
            this.lndUserType = true;
            localStorage.setItem('user_type', res['lnd_user_type']);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        // this.user_type = localStorage.getItem('user_type');
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        // this.user = localStorage.getItem('user_type');
        this.name = localStorage.getItem('name');

    }

    logout() {
        let dialogRef = this.dialog.open(ActionPopupComponent, {
            width: "300px",
            data: {
                buttontext: "Log Out",
                desc: "Do you want to Log Out?",
            },
        });
        const sub = dialogRef.componentInstance.option.subscribe((data) => {
            if (data) {
                sub.unsubscribe();
                if (data == "success") {
                    this.msalService.logout();
                }
            }
        });

    }


    openSessionModal() {
        this.loaderService.setLoading(true);
        const now = formatDate(new Date(), "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        const oneHour = new Date();
        oneHour.setHours(oneHour.getHours() + 1);
        let dialogRef = this.dialog.open(SessionModalComponent, {
            data: {
                startTime: formatDate(new Date(), "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2'),
                endTime: formatDate(new Date().setHours(new Date().getHours() + 1), "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2'),
                create: true,
                view: false,
                edit: false
            },
        });
    }
    home() {
        this.router.navigate(['home']);
    }

    expensecardshow(showclaimdata) {
        this.showcard = showclaimdata;
      }

      hrmanagercardshow(showhrmanagerdata){
        this.showhr=showhrmanagerdata
        
     }
      poashow(showpoa){
        this.showPOA=showpoa;
      }
    amdocshow(showamdoc) {
        this.showAmdoc = showamdoc;
      }
}
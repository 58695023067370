<span>
    <app-header-global (notify)="expensecardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
  </span>
  
  <div class="row allcontent">
    <div class="col-sm-2">
      <app-side-overlay-menu [showClaim]="showcard" [showpoa]="showPOA" [showamdoc]="showAmdoc" (notify)="getDocument($event)"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10" id="container">



      <form #notifyform="ngForm">
         
        <div class="head">
          <h4>Notification Setter</h4>

        </div>
        <!-- <h4>{{item_id}}</h4> -->

        <div class="modal-body">

                <div class="mb-3">
                  <label for="expirationDate" class="form-label">Expiration Date</label>
                  <input type="date" class="form-control custom-input" id="expirationDate" name="expirationDate" (click)="hideValidation('invalid_expirationDate')" [(ngModel)]="expirationDate" readonly>
                  <!-- <input type="date" class="form-control custom-input" id="expirationDate"  [(ngModel)]="expirationDate" > -->

                  <span class="text-danger" id='invalid_expirationDate' style='display:none'>Please enter a valid  date and time.</span>

                </div>

              <!-- <div class="mb-3">
                  <label for="notificationDate" class="form-label">Notification Date </label>
                  <input type="date" class="form-control custom-input " id="notificationDate" name="notificationDate" (click)="hideValidation('invalid_notificationDate')" [(ngModel)]="notificationDate" required>
                  <span class="text-danger" id='invalid_notificationDate' style='display:none'>Please enter a valid  date and time.</span>
              </div> -->
              <!-- To field for entering multiple email addresses -->

              <div class="mb-3">
                <label for="toEmails" class="form-label">Send Email To</label>
                <div class="form-group d-flex">
                  <input type="email" class="form-control emailToInput" id="emailToInput" placeholder="Enter email" name="getEmailTo" (click)="hideValidation('invalid_emailsTo')" [(ngModel)]="getEmailTo">
                  <button type="button" class="btn btn-outline-success" (click)="addEmailTo('invalid_emailsTo')" *ngIf="showRemoveButton">Add</button> 
                </div>
                <span class="text-danger" id='invalid_emailsTo' style='display:none'>Please add an email</span>
              
                <ul class="list-group mt-2">
                  <li *ngFor="let email of emailsTo; let i = index" class="list-group-item d-flex justify-content-between align-items-center remove-outline">
                    {{ email }}
                    <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeEmailTo(i)" *ngIf="showRemoveButton">Remove</button>
                  </li>
                </ul>
              </div>
        
              <!-- CC field for entering multiple email addresses -->

              <div class="mb-3">
                <label for="CcEmails" class="form-label">Send Email as Cc</label>
                <div class="form-group d-flex">
                  <input type="email" class="form-control emailToInput" id="emailCcInput" placeholder="Enter email" name="getEmailCc" (click)="hideValidation('invalid_emailsCc')" [(ngModel)]="getEmailCc">
                  <button type="button" class="btn btn-outline-success" (click)="addEmailCc('invalid_emailsCc')" *ngIf="showRemoveButton">Add</button>
                </div>
                <span class="text-danger" id='invalid_emailsCc' style='display:none'>Please add an email</span>
              
                <ul class="list-group mt-2">
                  <li *ngFor="let email of emailsCc; let i = index" class="list-group-item d-flex justify-content-between align-items-center remove-outline ">
                    {{ email }}
                    <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeEmailCc(i)" *ngIf="showRemoveButton">Remove</button>
                  </li>
                </ul>
              </div>

              <!--Email Subject-->
              <div class="mb-3">
                <label for="subject" class="form-label">Subject</label>
                <textarea class="form-control" id="subject" rows="2" name="subject"  placeholder="Enter subject" (click)="hideValidation('invalid_subject')"  [(ngModel)]="subject" required ></textarea>
                <span class="text-danger" id='invalid_subject' style='display:none'>Please enter the subject</span>

              </div>

              <!-- Document upload section -->
              <div class="mb-3">
                <label for="ccEmails" class="form-label">Upload Documents</label>
                <div class="container">
                  <button type="button" class="btn btn-sm btn-notify" (click)="triggerFileInput()" *ngIf="showRemoveButton">Upload</button>
                    <div class="custom-file" style="display: none;">
                        <input type="file" class="custom-file-input" id="customFile" multiple (change)="onFileSelected($event)">
                        <label class="custom-file-label" for="customFile"></label>
                    </div>

                    <div class='mt-2'>
                      <div *ngIf="files && files.length > 0">
                        <div *ngFor="let file of files" class="alert alert-light d-flex justify-content-between" role="alert">
                          <span class='fileSpan'>{{ file.name }}</span>
                          <button type="button" class="btn btn-sm btn-outline-danger" *ngIf="showRemoveButton" (click)="removeFile(file)">Remove</button>
                        </div>
                      </div>
                      <div>

                <div *ngFor="let savedFile of uploadedFileUrl" class="alert alert-light d-flex justify-content-between" role="alert">
                  <span class='fileSpan'>{{ savedFile }}</span>
                  <button type="button" class="btn btn-sm btn-outline-danger" (click)="removeSavedFile(savedFile)" *ngIf="showRemoveButton">Remove</button>
                </div>

                </div>
                  </div>
                    
                </div>
            </div>
            
       </div>
    
        <div class="modal-footer actionBtns">
            <!-- <button type="button" class="btn btn-secondary " data-bs-dismiss="modal">Close</button> -->
            <!-- <button type="submit" class="btn btn-notify " form="modalForm" (click)='saveChanges(Id)'>Save</button> -->
            <div *ngIf="saveBtn">
              <button type="submit" class="btn btn-notify" (click)='saveChanges(0)'>Save</button>
            </div>

            <div *ngIf="editBtn">
              <button type="submit" class="btn btn-notify" (click)='saveChanges(1)'>Edit</button>
            </div>



        </div>

        <!-- <div class="modal-footer" *ngIf="deleteFooter">
          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-danger btn-sm" form="modalForm" (click)='deleteDocument()'>Delete</button>
        </div> -->
      </form>


    </div>
  </div>
  


    <!-- Button trigger modal -->
        <!-- <div class='addDocument'>
          <button type="button" class="btn btn-notify" (click)="openModal(null,0)">
            Add Notification
         </button>

        </div> -->
   
      <!--Card-->    
      <!-- <div *ngFor="let item of responseData ;let i = index" >
        <div class="card" (click)="handleCardClick($event, item)"  >
          <div class="card-body">
            <div class='card-head'>
              <p class="card-text"><span class='card_title'>SrNo  : </span>{{i+1}}</p>
      
            </div>
            
            <div class='seperateDiv'>
              <div class='subjectDiv'>
                <p class="card-text"><span class='card_title'>Subject : </span>{{item.subject}}</p>
              </div>
  
              <div class='buttonsDiv'>

                <button type="button" class="btn  space"  (click)="handleEditButtonClick($event, item)">
                  <i class="fa fa-pencil-square-o" aria-hidden="true" ></i> 
                </button>

                <button type="button" class="btn space" (click)="handleDeleteButtonClick($event, item)" >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              
              </div>
            </div>
            <div class ='seperate_cont'>
              <div class='text-cont'>
                <p class="card-text"><span class='card_title'>Expired Date : </span>{{ item.expired_date | date: 'yyyy-MM-dd' }}</p>      
              </div>
            </div>
      
          </div>
        </div>
      </div> -->
  
    
          <!-- Modal -->
      <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Notification Module</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

           

            </div>
        </div>
      </div> -->

<ng-container *ngIf="!lndUserType && !loaderService.getLoading()">
    <div class="row">
        <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
            Oops! It seems you are logged out. <br>
            Please Login again to continue :)
        </div>
    </div>
    <div class="row">
        <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
            <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
        </div>
    </div>
</ng-container>
<span *ngIf="lndUserType">
    <app-header-global (notify)="expensecardshow($event)"
        (notifyhrmanager)="hrmanagercardshow($event)"></app-header-global>
</span>
<div class="row allcontent" *ngIf="lndUserType">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
        <div class="row">
            <div class="col header">
                Amplus Team Aprraisal and Increment Process<br>
                <font size="1.8vh">Performance Evaluation Process for Appraisal Cycle: <strong>{{cycle_name}}</strong> |
                    For Employee:
                    <strong>{{emp_name}} ({{emp_id}}) </strong>
                </font>
            </div>
        </div>

        <div *ngIf="midCycle" [formGroup]="midForm">
            <div class="row">
                <div class="col  mT5 questions p-mid">
                    1. Hypothetically, assuming the business prospects are not looking very good for the next 12 months and
                    we are under pressure to reduce salary costs by half, even in such adverse conditions, I would like
                    this person to be on my team:<br>
                    <font size="1.8vh" color="#8c8c8c">(Please note: Answer this question considering the value add and
                        criticality of the team member and not optimized team size)</font>
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q1" type="radio"
                            name="q1" id="q11" value="yes" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q1"
                            type="radio" name="q1" id="q11" value="yes">
                        <label class="form-check-label" for="q11">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q1" type="radio"
                            name="q1" id="q12" value="no" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q1"
                            type="radio" name="q1" id="q12" value="no">
                        <label class="form-check-label" for="q12">No</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions p-mid">
                   2. If it were my money, I would award this person the highest possible compensation increase and bonus:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q2" type="radio"
                            name="q2" id="q21" value="strongly agree" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q2"
                            type="radio" name="q2" id="q21" value="strongly agree">
                        <label class="form-check-label" for="q21">Strongly Agree</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q2" type="radio"
                            name="q2" id="q22" value="agree" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q2"
                            type="radio" name="q2" id="q22" value="agree">
                        <label class="form-check-label" for="q22">Agree</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q2" type="radio"
                            name="q2" id="q23" value="nuetral" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q2"
                            type="radio" name="q2" id="q23" value="nuetral">
                        <label class="form-check-label" for="q23">Neutral</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q2" type="radio"
                            name="q2" id="q24" value="disagree" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q2"
                            type="radio" name="q2" id="q24" value="disagree">
                        <label class="form-check-label" for="q24">Disagree</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q2" type="radio"
                            name="q2" id="q25" value="strongly disagree" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q2"
                            type="radio" name="q2" id="q25" value="strongly disagree">
                        <label class="form-check-label" for="q25">Strongly Disagree</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions p-mid">
                   3. I believe, in the last six months, this person stretched himself/ herself to seek new and better
                    ways of doing things:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q31" value="always" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q31" value="always">
                        <label class="form-check-label" for="q31">Always</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q32" value="mostly" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q32" value="mostly">
                        <label class="form-check-label" for="q32">Mostly</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q33" value="occassionally" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q33" value="occassionally">
                        <label class="form-check-label" for="q33">Occasionally</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q34" value="rarely" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q34" value="rarely">
                        <label class="form-check-label" for="q34">Rarely</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q35" value="never" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q3"
                            type="radio" name="q3" id="q35" value="never">
                        <label class="form-check-label" for="q35">Never</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col questions p-mid">
                   4. I find people in general love to work with this person and he/she also reaches out to collaborate
                    with other team members:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q4" type="radio"
                            name="q4" id="q41" value="always" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q4"
                            type="radio" name="q4" id="q41" value="always">
                        <label class="form-check-label" for="q41">Always</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q4" type="radio"
                            name="q4" id="q42" value="mostly" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q4"
                            type="radio" name="q4" id="q42" value="mostly">
                        <label class="form-check-label" for="q42">Mostly</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q4" type="radio"
                            name="q4" id="q43" value="occassionally" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q4"
                            type="radio" name="q4" id="q43" value="occassionally">
                        <label class="form-check-label" for="q43">Occasionally</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q4" type="radio"
                            name="q4" id="q44" value="rarely" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q4"
                            type="radio" name="q4" id="q44" value="rarely">
                        <label class="form-check-label" for="q44">Rarely</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="q4" type="radio"
                            name="q4" id="q45" value="never" disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="q4"
                            type="radio" name="q4" id="q45" value="never">
                        <label class="form-check-label" for="q45">Never</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col questions p-mid">
                   5. Considering this person's past six month's performance expected as per his/her role in the company,
                    I feel this person could be at risk of low performance:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="row">
                        <div class="col subtext">
                            <div class="form-check form-check-inline">
                                <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance1" value="strongly agree"
                                    disabled>
                                <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance1" value="strongly agree">
                                <label class="form-check-label" for="low_performance1">Strongly Agree</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance2" value="agree" disabled>
                                <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance2" value="agree">
                                <label class="form-check-label" for="low_performance2">Agree</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance3" value="nuetral" disabled>
                                <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance3" value="nuetral">
                                <label class="form-check-label" for="low_performance3">Neutral</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance4" value="disagree" disabled>
                                <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance4" value="disagree">
                                <label class="form-check-label" for="low_performance4">Disagree</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance5" value="strongly disagree"
                                    disabled>
                                <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                                    type="radio" name="low_performance" id="low_performance5" value="strongly disagree">
                                <label class="form-check-label" for="low_performance5">Strongly Disagree</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions p-mid">
                   6. I believe this person is ready for promotion considering his/her collaborative and mentoring skills:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio3" [value]=true disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio3" [value]=true>
                        <label class="form-check-label" for="inlineRadio3">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio4" [value]=false disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio4" [value]=false>
                        <label class="form-check-label" for="inlineRadio4">No</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions p-mid">
                    <label for="increment" class="form-label">
                       7. Would you like to recommend the salary correction / revision:
                    </label>
                    <div class="row">
                        <div class="col subtext">
                            (Please provide increment in %)
                        </div>
                    </div>
                    <input *ngIf="teamViewOnly" formControlName="increment" type="range" class="form-range"
                        min="0" max="100" id="increment" [value]="form.value.increment" disabled>
                    <input *ngIf="!teamViewOnly" formControlName="increment" type="range" class="form-range"
                        min="0" max="100" id="increment" [value]="form.value.increment"><output
                        id="num5">{{midForm.value.increment}}</output>
                </div>
            </div>
            <div class="row" *ngIf="midForm.value.increment > 0">
                <div class="col questions p-mid">
                   8. If any correction recommended, please provide the basis for the same:
                </div>
            </div>
            <div class="row" *ngIf="midForm.value.increment > 0">
                <div class="col subtext">
                    Remarks for Increment<sub> [10-1000 characters allowed]</sub>
                </div>
            </div>
            <div class="row"  *ngIf="midForm.value.increment > 0">
                <div class="col">
                    <textarea *ngIf="teamViewOnly" formControlName="increment_remarks" class="answers" disabled></textarea>
                    <textarea *ngIf="!teamViewOnly" formControlName="increment_remarks" class="answers"></textarea>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="midForm.value['increment_remarks']?.length > 1000">
                        Please enter less than 1000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="midForm.value['increment_remarks']?.length > 0 && midForm.value['increment_remarks']?.length <10">
                        Please enter minimum 10 characters.
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="!midCycle" [formGroup]="form">
            <div class="row">
                <div class="col mT5 title">
                    Accomplishments | Strengths | Improvements
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    What are person's accomplishments in last 12 month?<br>
                    <font size="1.8vh" color="#8c8c8c">(Please review Achievements of the past year for the employee and
                        mark your comments)</font>
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <font color="red">*</font>Achievements<sub> [10-5000 characters allowed]</sub>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <textarea *ngIf="teamViewOnly" formControlName="accomplishments" class="answers"
                        disabled></textarea>
                    <textarea *ngIf="!teamViewOnly" formControlName="accomplishments" class="answers"></textarea>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['accomplishments']?.length > 5000">
                        Please enter less than 5000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['accomplishments']?.length > 0 && form.value['accomplishments']?.length <10">
                        Please enter minimum 10 characters.
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    According to you, what are the strengths of this employee?
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <font color="red">*</font>Strengths<sub> [10-5000 characters allowed]</sub>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <textarea *ngIf="teamViewOnly" formControlName="strength" class="answers" disabled></textarea>
                    <textarea *ngIf="!teamViewOnly" formControlName="strength" class="answers"></textarea>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['strength']?.length > 5000">
                        Please enter less than 5000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['strength']?.length > 0 && form.value['strength']?.length <10">
                        Please enter minimum 10 characters.
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    According to you, what are the areas of improvement for this employee?
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <font color="red">*</font>Improvements<sub> [10-5000 characters allowed]</sub>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <textarea *ngIf="teamViewOnly" formControlName="improvement" class="answers" disabled></textarea>
                    <textarea *ngIf="!teamViewOnly" formControlName="improvement" class="answers"></textarea>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['improvement']?.length > 5000">
                        Please enter less than 5000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['improvement']?.length > 0 && form.value['improvement']?.length <10">
                        Please enter minimum 10 characters.
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col title">
                    CLIP Framework Score
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    On the performance you have reviewed of this person, on the scale of 0-100 how would you rate this
                    person on CLIP Framework?
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <label for="customRange2" class="form-label">
                        <font color="red">*</font>Commitment: Long term commitment to the growth of the organisation,
                        helping in expanding the markets and business which is beyond functional parameters and taking
                        pride
                        in being at Amplus.
                    </label>
                    <input *ngIf="teamViewOnly" formControlName="clip_commitment" type="range" class="form-range"
                        min="0" max="100" id="customRange2" [value]="form.value.clip_commitment" disabled>
                    <input *ngIf="!teamViewOnly" formControlName="clip_commitment" type="range" class="form-range"
                        min="0" max="100" id="customRange2" [value]="form.value.clip_commitment"><output
                        id="num1">{{form.value.clip_commitment}}</output>
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <label for="customRange1" class="form-label">
                        <font color="red">*</font>Leadership: Providing mentorship and guidance in your functional area,
                        working across functions and looking at thebusiness holistically by developing cross functional
                        skills.
                    </label>
                    <input *ngIf="teamViewOnly" formControlName="clip_leadership" type="range" class="form-range"
                        min="0" max="100" id="customRange2" [value]="form.value.clip_leadership" disabled>
                    <input *ngIf="!teamViewOnly" formControlName="clip_leadership" type="range" class="form-range"
                        min="0" max="100" id="customRange1" [value]="form.value.clip_leadership"><output
                        id="num2">{{form.value.clip_leadership}}</output>
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <label for="customRange3" class="form-label">
                        <font color="red">*</font>Innovations: Bringing in efficiency and innovation in your normal
                        functions. Developing new products in conjuction with other team.
                    </label>
                    <input *ngIf="teamViewOnly" formControlName="clip_innovation" type="range" class="form-range"
                        min="0" max="100" id="customRange2" [value]="form.value.clip_innovation" disabled>
                    <input *ngIf="!teamViewOnly" formControlName="clip_innovation" type="range" class="form-range"
                        min="0" max="100" id="customRange3" [value]='form.value.clip_innovation'>
                    <output id="num3">{{form.value.clip_innovation}}</output>
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <label for="customRange4" class="form-label">
                        <font color="red">*</font>Performance: Excellent functional performance. Great team player.
                        (Helping
                        others for overall company benefit).
                    </label>
                    <input *ngIf="teamViewOnly" formControlName="clip_innovation" type="range" class="form-range"
                        min="0" max="100" id="customRange2" [value]="form.value.clip_performance" disabled>
                    <input *ngIf="!teamViewOnly" formControlName="clip_performance" type="range" class="form-range"
                        min="0" max="100" id="customRange4" [value]='form.value.clip_performance'><output
                        id="num4">{{form.value.clip_performance}}</output>
                </div>
            </div>
            <div class="row">
                <div class="col title">
                    Feedback and Other
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    Considering this person's past quarter performance, I feel this person could be at a risk of low
                    performance?
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                            type="radio" name="low_performance" id="inlineRadio1" [value]=true disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                            type="radio" name="low_performance" id="inlineRadio1" [value]=true>
                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="low_performance"
                            type="radio" name="low_performance" id="inlineRadio2" [value]=false disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="low_performance"
                            type="radio" name="low_performance" id="inlineRadio2" [value]=false>
                        <label class="form-check-label" for="inlineRadio2">No</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    Do you believe that this person is ready for promotion?
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio3" [value]=true disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio3" [value]=true>
                        <label class="form-check-label" for="inlineRadio3">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio4" [value]=false disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="ready_for_promotion"
                            type="radio" name="ready_for_promotion" id="inlineRadio4" [value]=false>
                        <label class="form-check-label" for="inlineRadio4">No</label>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="type == 'Off-Roll'">
                <div class="col questions">
                    Do you believe that this person is ready for On-Roll conversion?
                </div>
            </div>
            <div class="row" *ngIf="type == 'Off-Roll'">
                <div class="col subtext">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="offroll_to_onroll"
                            type="radio" name="offroll_to_onroll" id="inlineRadio3" [value]=true disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="offroll_to_onroll"
                            type="radio" name="offroll_to_onroll" id="inlineRadio3" [value]=true>
                        <label class="form-check-label" for="inlineRadio3">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="offroll_to_onroll"
                            type="radio" name="offroll_to_onroll" id="inlineRadio4" [value]=false disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="offroll_to_onroll"
                            type="radio" name="offroll_to_onroll" id="inlineRadio4" [value]=false>
                        <label class="form-check-label" for="inlineRadio4">No</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    Feedback for employee:
                </div>
            </div>
            <div class="row">
                <div class="col subtext">
                    <font color="red">*</font>Feedback<sub> [10-5000 characters allowed]</sub>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <textarea *ngIf="teamViewOnly" formControlName="feedback" class="answers" disabled></textarea>
                    <textarea *ngIf="!teamViewOnly" formControlName="feedback" class="answers"></textarea>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['feedback']?.length > 5000">
                        Please enter less than 5000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="form.value['feedback']?.length > 0 && form.value['feedback']?.length <10">
                        Please enter minimum 10 characters.
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col questions">
                    Have you had one-on-one discussion with the employee on their current performance?<br>
                    <font size="1.8vh" color="#8c8c8c">(Please note that one-on-one discussion with the employee is
                        mandatory to close the performance appraisal)</font>
                </div>
            </div>
            <div class="row">
                <div class="col subtext" style="padding-bottom: 10vh;">
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="one_on_one_discussion"
                            type="radio" name="one_on_one_discussion" id="inlineRadio5" [value]=true disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="one_on_one_discussion"
                            type="radio" name="one_on_one_discussion" id="inlineRadio5" [value]=true>
                        <label class="form-check-label" for="inlineRadio5">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input *ngIf="teamViewOnly" class="form-check-input" formControlName="one_on_one_discussion"
                            type="radio" name="one_on_one_discussion" id="inlineRadio6" [value]=false disabled>
                        <input *ngIf="!teamViewOnly" class="form-check-input" formControlName="one_on_one_discussion"
                            type="radio" name="one_on_one_discussion" id="inlineRadio6" [value]=false>
                        <label class="form-check-label" for="inlineRadio6">No</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">

            </div>
            <div class="col" style="padding-right: 3vh; margin-bottom: 10vh;">
                <div class="row">
                    <div class="col"></div>
                    <div class="col-4" style="padding-right: 3vh;">
                        <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="cancel()">
                            Go Back
                        </button>
                    </div>
                    <div *ngIf="!teamViewOnly" class="col-4" style="padding-right: 3vh;">
                        <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" [disabled]="!isValid" (click)="onSubmit()">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
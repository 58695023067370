import { Component, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { EmponboardingService } from "../../employee-onboarding/services/emponboarding.service";
import { LoginService } from "src/app/core/login/login.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { MatDialog } from "@angular/material/dialog";
import { CompanyDialogComponent } from "../company-dialog/company-dialog.component";
import { BusinessDialogComponent } from "../business-dialog/business-dialog.component";
@Component({
    selector: "app-business-list",
    templateUrl: "./business-list.component.html",
    styleUrls: ["./business-list.component.scss"],
})


export class BusinessListComponent implements OnInit {
    headingBusiness: string = "List of Business";
    businesslist: any;
    // compType: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private hrservice: HrserviceService,
        private EmpOnboardingService: EmponboardingService,
        private loginService: LoginService,
        private loaderService: LoaderService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.hrservice.getBusiness().subscribe((res: any) => {
            this.businesslist = res.data

            // for (var i = 0; i < this.businesslist.length; i++) {
            //     for (var j = 0; j < this.companyType.length; j++) {
            //         if (this.companylist[i].company_type == this.companyType[j].key) {
            //             this.companylist[i].company_type = this.companyType[j].value;
            //         }
            //     }
            // }
        })
    }

    addBusiness() {
        this.loaderService.setLoading(true);
        // for(var i =0; i< this.companyType.length; i++){
        //     if(company.company_type == this.companyType[i].value){
        //         company.company_type = this.companyType[i].key;
        //     }
        // }
        let dialogRef = this.dialog.open(BusinessDialogComponent, {
            data: {
                type: 'create',
            },
        });
    }

    editBusiness(business) {
        this.loaderService.setLoading(true);
        // for(var i =0; i< this.companyType.length; i++){
        //     if(company.company_type == this.companyType[i].value){
        //         company.company_type = this.companyType[i].key;
        //     }
        // }
        let dialogRef = this.dialog.open(BusinessDialogComponent, {
            data: {
                type: 'edit',
                data: business
            },
        });
    }
}
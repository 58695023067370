import { Component, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { EmponboardingService } from "../../employee-onboarding/services/emponboarding.service";
@Component({
  selector: "app-empolyee-list",
  templateUrl: "./empolyee-list.component.html",
  styleUrls: ["./empolyee-list.component.css"],
})
export class EmpolyeeListComponent2 implements OnInit {
  headingEmployee: string = "List of Employee";
  umail: string = this.EmpOnboardingService.umail;
  employeedata: any;
  totalCount: number = 0;
  totalPages: number = 0;
  currentPage: number = 1;
  email:string;
  pageSize: number = 10;
  employeeEmail: string;
  leavevalue: number;
  searchQuery:string;
  numButtons:number = 5;
  middle:number;
  start:number;
  end:number;
  private searchSubject = new Subject<string>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hrservice: HrserviceService,
    private EmpOnboardingService:EmponboardingService
  ) {
    this.searchSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((query) => {
        console.log(query);
          this.email=query;
          this.currentPage=1;
        this._EmployeeList(this.currentPage,this.email);
      });
  }

  _onSearchInput(event: Event) {
    console.log(event);
    this.searchSubject.next(this.searchQuery);
    console.log(this.searchQuery);
  }

  _EmployeeList(page: number,email:string) {
    try {
      this.hrservice.EmployeeList(page,email).subscribe((res: any) => {
        console.log(res.data);
        this.totalCount = res.meta.count;
        this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { page: this.currentPage, email:this.searchQuery},
          queryParamsHandling: "merge",
        });
        this.employeedata = res.data;
        console.log(this.employeedata);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
    console.log("all employee");
  }
  is_Disabled(isdisabled: Number) {
    console.log(isdisabled);
    if (isdisabled == 1) {
      return "Yes";
    } else {
      return "No";
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const page = +params["page"] || 1;
      const email = params["email"] ||'';
      this.currentPage = page;
      this.email=email;
      this.searchQuery = this.email;
    });
    this._EmployeeList(this.currentPage,this.email);
  }
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this._EmployeeList(this.currentPage,this.email);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this._EmployeeList(this.currentPage,this.email);
    }
  }
  firstPage() {
    this.currentPage = 1;
    this._EmployeeList(
      this.currentPage,
      this.email
    );
  }
  lastPage() {
    this.currentPage = this.totalPages;
    this._EmployeeList(
      this.currentPage,
      this.email
    );
  }
  getNumericButtons(): number[] {
      this.numButtons =10;
      this.middle = Math.ceil(this.numButtons / 2);
      this.start = Math.max(1, this.currentPage - this.middle + 1);
      this.end = Math.min(this.totalPages, this.start + this.numButtons - 1);
  
    const buttons: number[] = [];
    for (let i = this.start; i <= this.end; i++) {
      buttons.push(i);
    }
  
    return buttons;
  }
  
  gotoPage(page: number) {
    this.currentPage = page;
    this._EmployeeList(this.currentPage,this.email);
  }

  openUserProfile(user: any,) {
    // window.location.href = '/verify-user-data?umail=' + this.umail + '&email=' + user.email;
    // if(status=="approved"){ 
    // console.log(event);
    // if(event.cellIndex !=4){

      window.open('/onboarding?umail=' + this.umail + '&emp_id=' + user.emp_id + '&email=' + user.email, '_blank') 
    // }
    // }
    // else{
      // window.open('/verify-data?umail=' + this.umail + '&email=' + user.email, '_blank')
    // }
  
  }

  _addPatrnityLeaves(empId: number, leavevalue: number) {
    try {
      this.hrservice.addPatrnity(empId, leavevalue).subscribe((res: any) => {
         alert(res.message);
         this.ngOnInit();
      });
    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }

}

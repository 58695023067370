<div class='search-tab' [style.display]="noPOA ? 'flex' : 'none'">
  <input type="search" id='searchId' [(ngModel)]="searchTerm" (input)="onSearchChange()" placeholder="Search here">
</div>



<div *ngFor="let item of responseData ;let i = index">
  <div class="card" (click)="getRaisedPoa(item.id,value,item.status)">
    <div class="card-body">
      <div class='card-head'>
        <p class="card-text"><span class='card_title'>SrNo : </span> {{ i+1 }}</p>
        <p class="card-text"><span class='card_title'> , POA No : </span>{{ item.id }}</p>

      </div>
      <p class="card-text"><span class='card_title'>Description : </span>{{ item.description }}</p>

      <div class='seperate_cont'>
        <div class='text-cont'>
          <p class="card-text"><span class='card_title'>Start Date : </span>{{ item.start_date }}</p>
          <p class="card-value"><span class='card_title'>, POA Created By : </span>{{ item.created_by_name }}</p>
          <p class="card-value"><span class='card_title'>, Status : </span>{{ item.status }}</p>
          <p class="card-value"><span class='card_title'>, Company : </span>{{ item.company }}</p>


        </div>
        <div class='image-cont'>
          <p class="card-text"><span class='view_card'>View </span></p>
          <img src="assets/images/card_arrow_image.svg" alt="Image description">

        </div>
      </div>

    </div>
  </div>
</div>

<div class='IsData' [style.display]="isData ? 'flex' : 'none'">
  <h4>No POA present </h4>
</div>
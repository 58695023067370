<div class="manage-proposal-wrapper" style="width: 500px;">
    <div *ngIf="dataPresent" class="row">
        <div class="col text-heading-popup">
            <strong>{{title}}</strong> | {{modality}}
        </div>
        <div class="col-2 text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col check-col text-align-justify">
            You're about to disenroll. Please support your cancellation request with a reason below.<br/>
            You'll be receiving an email confirming your disenrollment. You're requested to seek approval from your Reporting Manager on the same email thread, stating the reason for cancellation. This must be completed no later than 12 noon on the day preceding the session day.
        </div>
    </div>
    <div [formGroup]="form">
        <fieldset>
            <div class="col-12 reason">
                <label for="name">Mention your reason of disenrolling below * :</label>
                <br />
                <textarea rows="3" class="form-control mod-css" formControlName="comment"></textarea>
            </div>
            <br />
        </fieldset>
    </div>

    <div class="row">
        <div class="col p5">
            <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="unenroll()">
                Yes, I want to Disenroll
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col p5">
            <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                (click)="cancel()">
                Go Back
            </button>
        </div>
    </div>
</div>
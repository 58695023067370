<!-- <h1 mat-dialog-title>Add New User</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="firstName">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="email">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="{firstName: firstName, email: email}" cdkFocusInitial>Submit</button>
</div> -->


    <!-- <h1 mat-dialog-title>Add New User</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="firstName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="email">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button [mat-dialog-close]="{firstName: firstName, email: email}" cdkFocusInitial>Submit</button>
    </div> -->


    <!-- <h1 mat-dialog-title>Add New User</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput [(ngModel)]="firstName">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="email">
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button [mat-dialog-close]="{firstName: firstName, email: email}" cdkFocusInitial>Submit</button>
    </div> -->
    <div class="requestdaialogcontent">
    <div class="w-[100%] flex justify-center d-flex justify-content-center">
        <h1 mat-dialog-title>Initiate onboarding request</h1>
    </div>
  
   <!--
// v0 by Vercel.
// https://v0.dev/t/clSbB44yMnj
-->

<div class="formdiv">
    <form class="flex flex-col justify-center items-center  w-full reqform">
      <div class="mb-4 row">
        <div class="col-sm-12">
          <label
          class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          for="firstName"
        >
          First Name
        </label>
        </div>
        <div class="col-sm-12">
          <input
          [(ngModel)]="firstName"
          name="firstName"
          class="flex h-10 w-[100%] rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 custominput"
          type="text"
          id="firstName"
          placeholder="Enter first name"
        />
        </div>
     
      </div>
      <div class="mb-6 row">
        <div class="col-sm-12">
          <label
          class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          for="email"
        >
          Email
        </label>
        </div>
          <div class="col-sm-12">
            <input
            [(ngModel)]="email"
            name="email"
              class="flex h-10 w-[100%] rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 custominput"
              type="email"
              id="email"
              placeholder="Enter email"
            />
          </div>
       
      </div>
       <div class="flex items-center justify-between  d-flex mt-3 justify-content-center">
        <button type="submit"  class="bg-gray-800 onboardingreqbtn  disabled:bg-gray-500 font-bold py-2 px-4 rounded" [disabled]="!firstName || !email" (click)="handleClick()">
          Submit
         </button>
       </div>
        
    </form>
  </div>
</div>
import { Component, Inject, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ToastrService } from "ngx-toastr";
@Component({
    selector: "app-business-dialog",
    templateUrl: "./business-dialog.component.html",
    styleUrls: ["./business-dialog.component.scss"],
})


export class BusinessDialogComponent implements OnInit {
    companyType: any;
    isValuearray: boolean = false;
    form: any;
    empList: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<BusinessDialogComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService,
        private hrService: HrserviceService,
        private toast: ToastrService
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            id: [null],
            name: ['', Validators.required],
            abbr: [null],
            head: [null , Validators.required],
        });
        console.log(this.data)
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        if(this.data.type == 'edit'){
            this.form.patchValue({
                id: this.data.data.id,
                name: this.data.data.name,
                abbr: this.data.data.abbr,
                head: this.data.data.head.name,
            })
        }
       this.hrService.getEmployees().subscribe((res:any)=>{
        this.empList = res.data;
       })
    }

    onSubmit() {
        console.log(this.form.value);
        let headtemp =  this.form.value.head.emp_id;
        this.form.value.head = headtemp;
        console.log(this.form.valid)
        if(this.form.valid){
            if(this.data.type == 'edit'){
                this.hrService.updateBusiness(this.form.value).subscribe((res: any)=>{
                    this.toast.success(res.message);
                    this.dialogRef.close();
                    setTimeout(()=>{
                      window.location.reload();
                  },600)
                  },
             (error: any)=>{
              this.toast.success(error.message);
             });
            }
          else if(this.data.type == 'create'){
            this.hrService.createBusiness(this.form.value).subscribe((res: any)=>{
                this.toast.success("Business Added Successfully!");
                this.dialogRef.close();
                setTimeout(()=>{
                  window.location.reload();
              },600)
              },
         (error: any)=>{
          this.toast.success(error.message);
         });
          }
        }
        else{
            this.toast.error("Form Invalid. Please Check!!!")
        }
       
    }
    cancel() {
        this.dialogRef.close();
    }

}
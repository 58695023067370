<div *ngIf="data.type =='edit'" class="manage-proposal-wrapper" style="width: 700px; max-height: fit-content;">
    <div class="main">
        <div class="content">
            <form [formGroup]="form" class="personalInfo">
                <p class="upheading">Edit Company Information</p>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Company ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="company_id" class="infofield" required
                                    placeholder="Company ID" disabled>
                                <div class="erroMessage"
                                    *ngIf="form.get('company_id')?.errors?.['required'] && form.get('company_id')?.touched">
                                    Company ID is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Company Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="name" class="infofield" required placeholder="Company Name" disabled>
                                <div class="erroMessage"
                                    *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Company name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Code</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="code" class="infofield" required placeholder="Company Code">
                                <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Code is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Address</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="address" class="infofield" placeholder="Company Address" >
                                <!-- <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Address is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company Type <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="company_type" class="infofield" required>
                                    <option value="">Select Type</option>
                                    <option *ngFor="let obj of companyType " [value]="obj.key">{{obj.value}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="form.get('company_type')?.errors?.['required'] && form.get('company_type')?.touched">
                                    Company Type is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->

                <button mat-button type="button" class="cancelbtn" (click)="cancel()">Cancel</button>
                <button mat-button type="submit" class="universalbtn" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="data.type =='create'" class="manage-proposal-wrapper" style="width: 500px; max-height: fit-content;">
    <div class="main">
        <div class="content">
            <form [formGroup]="form" class="personalInfo">
                <p class="upheading">Add Company Information</p>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Company Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="name" class="infofield" required placeholder="Company Name">
                                <div class="erroMessage"
                                    *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Company name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Code</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="code" class="infofield" required placeholder="Company Code">
                                <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Code is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Address</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="address" class="infofield" placeholder="Company Address" >
                                <!-- <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Address is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company Type <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="company_type" class="infofield" required>
                                    <option value="">Select Type</option>
                                    <option *ngFor="let obj of companyType " [value]="obj.key">{{obj.value}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="form.get('company_type')?.errors?.['required'] && form.get('company_type')?.touched">
                                    Company Type is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->

                <button mat-button type="button" class="cancelbtn" (click)="cancel()">Cancel</button>
                <button mat-button type="submit" class="universalbtn" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
    selector: 'app-root',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    isAuthenticated = false;
    constructor(private msalService: LoginService,
        private router: Router) { }

    login() {
        localStorage.clear();
        this.msalService.login();
    }

    logout() {
        this.msalService.logout();
    }

    isLoggedIn(): boolean {
        return this.msalService.isLoggedIn();
    }


    getToken(): any {
        return this.msalService.getToken();

    }
}

<ng-container *ngIf="!lndUserType && !loaderService.getLoading()">
    <div class="row">
        <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
            Oops! It seems you are logged out. <br>
            Please Login again to continue :)
        </div>
    </div>
    <div class="row">
        <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
            <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
        </div>
    </div>
</ng-container>
<span *ngIf="lndUserType">
    <app-header-global (notify)="expensecardshow($event)"
        (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>
<div class="row allcontent" *ngIf="lndUserType">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
        <div class="row">
            <div class="col header">
                Profile
            </div>
        </div>
        <div class="row profile-card" *ngIf="emp">
            <div class="col paddings">
                <div class="row">
                    <div class="col key">
                        Employee ID
                    </div>
                    <div class=" col-4 value">
                        {{emp.emp_id}}
                    </div>
                    <div class="col key">
                        Full Name
                    </div>
                    <div class=" col-4 value">
                        {{emp.name |titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Gender
                    </div>
                    <div class=" col-4 value">
                        {{emp.gender |titlecase}}
                    </div>
                    <div class="col key">
                        Designation
                    </div>
                    <div class=" col-4 value">
                        {{emp.job_title |titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Department
                    </div>
                    <div class=" col-4 value">
                        {{emp.department |titlecase}}
                    </div>
                    <div class="col key">
                        Business
                    </div>
                    <div class=" col-4 value">
                        {{emp.business |titlecase}}
                    </div>
                </div>
                <!-- <div class="row">
                    
                    <div class="col key">
                        Cluster
                    </div>
                    <div class=" col-4 value">
                        {{emp.cluster |titlecase}}
                    </div>
                </div> -->
                <div class="row">
                    <div class="col key">
                        Mobile No.
                    </div>
                    <div class=" col-4 value">
                        {{emp.mobile_no}}
                    </div>
                    <div class="col key">
                        Email
                    </div>
                    <div class=" col-4 value">
                        {{emp.email}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Date of Birth
                    </div>
                    <div class=" col-4 value">
                        {{emp.dob}}
                    </div>
                    <div class="col key">
                        Date of Joining
                    </div>
                    <div class=" col-4 value">
                        {{emp.doj}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Qualification(UG)
                    </div>
                    <div class="col-4 value">
                        {{emp.qualification_ug}}
                    </div>
                    <div class="col key">
                        Qualification(PG)
                    </div>
                    <div class="col-4 value">
                        {{emp.qualification_pg}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Reporting Manager
                    </div>
                    <div class=" col-4 value" style="margin-left: -7vw">
                        {{emp.manager}}: {{emp.manager_name |titlecase}}
                    </div>
                    <div class="col">
                    </div>
                    <div class="col"></div>
                </div>
                <div class="row">
                    <div class="col promotion" (click)="getPromotions()">
                        Promotion History
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'; 
import { environment } from 'src/environments/environment';
import { CsrftokenService } from 'src/app/core/services/csrftoken.service';


@Injectable({
  providedIn: 'root'
})
export class PoaserviceService {
  // baseUrl = 'http://127.0.0.1:8000/poa/';
  baseUrl = environment.url + "poas/";
  access_key: string = localStorage.getItem("accessToken");

  headers: any = new HttpHeaders()
    .set("Authorization", `${this.access_key}`)
  // .set('Content-Type', `multipart/form-data`)
  requestOptions: any = { headers: this.headers };
  apiUrl: any;

  responseDataEvent = new EventEmitter<any>();

  constructor(private http: HttpClient, private getcsrfservice: CsrftokenService) { }

  raisePoa(data: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };

    const url = this.baseUrl;
    return this.http.post(url, data, httpOptions);
  }

  viewPoa(status: any): Observable<any> {
    const url = `${this.baseUrl}?status=${status}`;
    return this.http.get(url, this.requestOptions).pipe(
      tap((res: any) => {
        this.responseDataEvent.emit(res);
      })
    );
  }

  getPoa(id: number): Observable<any> {
    const url = `${this.baseUrl}?id=${id}`;
    return this.http.get(url, this.requestOptions);
  }

  updatePoa(data: any, id: number, action: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };
    const url = `${this.baseUrl}?id=${id}&action=${action}`;
    return this.http.put(url, data, httpOptions);
  }

  updateStatus(id: number, action: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };
    const url = `${this.baseUrl}?id=${id}&action=${action}`;
    return this.http.put(url, null, httpOptions);

  }

  deletePoa(id: number): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };
    const url = `${this.baseUrl}?id=${id}`;
    return this.http.delete(url, httpOptions);
  }

  getEmployee(): Observable<any> {
    // const url = `https://empower.amplussolar.com/poa/getmployee/`; 
    const url = `${this.baseUrl}getemployee/`;
    return this.http.get(url, this.requestOptions);
  }

  getCompany(): Observable<any> {
    const url = `${this.baseUrl}getcompany/`;
    return this.http.get(url, this.requestOptions);

  }


}

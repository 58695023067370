import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificationServiceService } from './service/notification-service.service';
declare var $: any; 
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationDialogComponent } from 'src/app/components/expense-manager/confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-notification-module',
  templateUrl: './notification-module.component.html',
  styleUrls: ['./notification-module.component.css']
})
export class NotificationModuleComponent implements OnInit {

  showcard: boolean;
  showPOA:boolean;
  docRes: any;
  expirationDate: string = '';
  notificationDate: string = '';
  files: File[] = [];
  // saved_files: File[] = [];
  saved_files: string[][] = [];
  existing_files:any
  newEmail: string = '';
  emailsTo: string[] = [];
  emailsCc: string[] = [];
  getEmailTo: string = ''; 
  getEmailCc: string = ''; 
  subject: string = '';
  responseData: any;
  Id:any;
  uploadedFileUrl:any;
  modifiedFiles:any;
  deleteFooter: boolean = false;
  saveFooter: boolean = true;
  cardFooter: boolean = false;
  showRemoveButton=true;
  id:any;
  item_id:any;
  service_id:any;
  expiry_date:any
  task_notification_id:any;
  editBtn:boolean=false;
  saveBtn:boolean=true;
  showAmdoc:any;



  constructor(private notifyservice: NotificationServiceService,public dialog: MatDialog,private route: ActivatedRoute,private router: Router) {

   }

  ngOnInit(): void {
  //   this.notifyservice.getDocument().subscribe((res: any) => {
  //     const filteredRes = res.filter(item => item.status !== 'disabled');
  //     this.responseData = filteredRes.map(item => item[0]);
  //     console.log('res is ', this.responseData);      
  // });
  this.subject='Remainder for insurance Tracker '

  this.route.queryParams.subscribe(params => {
    this.item_id = params['id'];
    this.service_id = params['service_id']
    this.expiry_date=params['expiry_date']
    this.task_notification_id=params['task_notification_id']

    if(this.task_notification_id){
      this.editBtn=true;
      this.saveBtn=false;
      //  call the api to get the values
      this.notifyservice.getNotificationValue(this.task_notification_id).subscribe((res: any) => {

        // console.log('the value of the notification is ',res)
        console.log(res.metadata['subject'])
        this.subject=res.metadata['subject']
        this.emailsTo=res.metadata['emailsTo']
        this.emailsCc=res.metadata['emailsCc']
  
      })


    }

    

    if(this.expiry_date){
      this.expirationDate=this.expiry_date
      
    }

    this.notifyservice.getNotifConfig(this.service_id).subscribe((res: any[]) => {

      console.log('the response is ',res)

    })


  });

  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  poashow(showpoa){
    this.showPOA=showpoa;
  }

  amdocshow(showamdoc){
    this.showAmdoc=showamdoc;
}

  getDocument(docres: any) {
    this.docRes = docres;
  }

formatDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); 
    const day = ('0' + date.getDate()).slice(-2); 
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2); 

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
  }

validateForm(){
    let isValid = true; 
    const formdata = {
      expirationDate:this.expirationDate,
      // notificationDate:this.notificationDate,
      subject:this.subject
    };

    const fields = ['subject','expirationDate'];
    for (const field of fields) {
      if (!formdata[field]) {
        console.log('the field is',formdata[field])
        document.getElementById(`invalid_${field}`).style.display = 'block';
        isValid = false; 
        return isValid;
      }
  }

  if(this.emailsTo.length==0){
    console.log('yes the length is empty for email to')
    document.getElementById(`invalid_emailsTo`).style.display = 'block';
    isValid = false; 
    return isValid;
  }

  if(this.emailsCc.length==0){
    console.log('yes the length is empty for email cc')

    document.getElementById(`invalid_emailsCc`).style.display = 'block';
    isValid = false; 
    return isValid;

  }
  return isValid;


}

saveChanges(action:any) {
    let isValid = this.validateForm();
    if (!isValid) {
        return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result=>{
      if(result){

        const formData = new FormData();

      formData.append('expired_date', this.expirationDate);
      // formData.append('notify_date', this.notificationDate);
      // formData.append('subject',this.subject)
   
      const metadata = {
        emailsTo: this.emailsTo,
        emailsCc: this.emailsCc,
        subject : this.subject
      };

      formData.append('metadata', JSON.stringify(metadata));

      if(action==0){
        this.notifyservice.saveNotification(this.service_id,this.item_id,this.expiry_date).subscribe((res: any[]) => {
          console.log('response is ',res)
          alert("Notication Added Successfully")

          this.router.navigate(['template', 'view_template'], {
            queryParams: {
              service_id: this.service_id
            }
          });
          // this.router.navigate(['template', 'view_template']);

        });

        }
        else if(action==1){
          console.log('the action is ',action)
          let tag='edit';
          // this.notifyservice.updateNotification(formData,this.task_notification_id,this.service_id,tag).subscribe((res: any) => {
          //   console.log('response is ',res)
          //   alert("Notication Updated Successfully")

          //   this.router.navigate(['template', 'view_template'], {
          //     queryParams: {
          //       service_id: this.service_id
          //     }
          //   });
          //   // this.router.navigate(['template', 'view_template']);
  
          // });

        }
      
      
     

      // if(this.item_id){
      //   formData.append('id',this.item_id)
      // }

     
       

      }

    })


    // const formData = new FormData();
    // this.files.forEach((file, index) => {
    //   formData.append(`file${index}`, file);
    // });

    // check if is present than make a put request otherwise make a post request
    // if(id){
    //   formData.append('expired_date', this.expirationDate);
    //   formData.append('notify_date', this.notificationDate);
    //   formData.append('subject',this.subject)
    //   formData.append('id',this.Id);
     
    //   const savedFilesArray = [];
      
    //   if (Array.isArray(this.uploadedFileUrl)) {
    //     this.uploadedFileUrl.forEach((fileUrl) => {
    //       savedFilesArray.push(fileUrl)

          
    //     });
    //   }
    //   console.log(savedFilesArray)

    //   const metadata = {
    //     emailsTo: this.emailsTo,
    //     emailsCc: this.emailsCc,
    //     // subject : this.subject,
    //     saved_files:savedFilesArray
    //   };

    //   formData.append('metadata', JSON.stringify(metadata));
    //   // const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    //   // dialogRef.afterClosed().subscribe(result=>{
    //   //   if(result){
    //   //     // this.notifyservice.saveDocument(formData).subscribe((res: any[]) => {
    //   //     //   console.log('response is ',res)
    //   //     //   $('#exampleModal').modal('hide'); 
    //   //     //   window.location.reload();
    //   //     // });
    //   //     console.log('the api called')
         

    //   //   }

    //   // })

     
    
     
    // }

    // else{

    //   formData.append('expired_date', this.expirationDate);
    //   formData.append('notify_date', this.notificationDate);
    //   formData.append('subject',this.subject)

    //   const metadata = {
    //     emailsTo: this.emailsTo,
    //     emailsCc: this.emailsCc,
    //     // subject : this.subject
    //   };

    //   formData.append('metadata', JSON.stringify(metadata));
    //   // get the already saved files and also if any new file is uploaded
    //   this.notifyservice.saveDocument(formData).subscribe((res: any[]) => {
    //   console.log('response is ',res)
    //   $('#exampleModal').modal('hide'); 
    //   window.location.reload();
    // });

    // }

  
  }

deleteDocument() {
    const id = this.Id;
    this.notifyservice.updateDocument(id).subscribe((res: any[]) => {
        $('#exampleModal').modal('hide'); 
        window.location.reload();
    });
}

openModal(item: any, value: number) {
    $('#exampleModal').modal('show'); 
    
    if (value === 0) {
       this.showRemoveButton=true

        // If value is 0, erase all values
        this.subject = '';
        this.emailsTo = [];
        this.emailsCc = [];
        this.saved_files = [];
        this.expirationDate = '';
        this.notificationDate = '';
        this.Id=null
        this.deleteFooter=false
        this.saveFooter=true
        this.uploadedFileUrl=[]
        this.modifiedFiles=[]
        this.files=[]


    }
    
    else if(value==1){
        this.showRemoveButton=true
        this.deleteFooter=false
        this.saveFooter=true
        // Otherwise, assign values from the item
        this.subject = item.subject || '';
        this.emailsTo = item.metadata.emailsTo || [];
        this.emailsCc = item.metadata.emailsCc || [];
        this.saved_files = item.metadata.document_urls || [];
        this.existing_files=item.metadata.saved_files || []
        this.expirationDate = this.formatDate(item.expired_date) || '';
        this.notificationDate = this.formatDate(item.notify_date) || '';
        this.Id=item.id
        //append the id to the existing url 

        this.uploadedFileUrl = [...(item.metadata.document_urls || []), ...(item.metadata.saved_files || [])];
        console.log('all links are',this.uploadedFileUrl)
        this.modifiedFiles = this.uploadedFileUrl.map(link => link.replace('notification_documents/', ''));


    }
    else{
      // delete request
      this.showRemoveButton=false
      this.deleteFooter=true
      this.saveFooter=false

      this.subject = item.subject || '';
      this.emailsTo = item.metadata.emailsTo || [];
      this.emailsCc = item.metadata.emailsCc || [];
      this.saved_files = item.metadata.document_urls || [];
      this.existing_files=item.metadata.saved_files || []
      this.expirationDate = this.formatDate(item.expired_date) || '';
      this.notificationDate = this.formatDate(item.notify_date) || '';
      this.Id=item.id
      this.uploadedFileUrl = [...(item.metadata.document_urls || []), ...(item.metadata.saved_files || [])];
      this.modifiedFiles = this.uploadedFileUrl.map(link => link.replace('notification_documents/', ''));


    }
}

onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push(selectedFiles.item(i)!);
    }
  }

removeFile(file: File) {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

removeSavedFile(savedFile: any){
    const index = this.uploadedFileUrl.indexOf(savedFile);
    if (index !== -1) {
      this.uploadedFileUrl.splice(index, 1);
    }
  }

addEmailTo(elementID) {
    const emailInputValue = this.getEmailTo.trim();
    let isValid= this.isValidEmail(emailInputValue)
    if(!isValid){
      document.getElementById(elementID).style.display='block'

    }
    else if (emailInputValue !== '') {
      this.emailsTo.push(emailInputValue);
      this.getEmailTo = ''; 
      document.getElementById(elementID).style.display='none'

    }
  }
  

removeEmailTo(index: number) {
    this.emailsTo.splice(index, 1);
  }

isValidEmail(email: string): boolean {
    if (!email) {
        return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

addEmailCc(elementID) {
    const emailInputValue = this.getEmailCc.trim();
    let isValid= this.isValidEmail(emailInputValue)
    if(!isValid){
      document.getElementById(elementID).style.display='block'

    }
    else if (emailInputValue !== '') {
      this.emailsCc.push(emailInputValue);
      this.getEmailCc = '';
      document.getElementById(elementID).style.display='none'

    }
  }
  

removeEmailCc(index: number) {
    this.emailsCc.splice(index, 1);
  }

triggerFileInput(): void {
    document.getElementById('customFile').click();
  }

hideValidation(elementID:string){
    document.getElementById(elementID).style.display='none'
  }

handleEditButtonClick(event: MouseEvent, item: any) {
    event.stopPropagation(); 
    console.log('Edit button clicked', item);
    this.openModal(item,1);
  }

handleDeleteButtonClick(event: MouseEvent, item: any) {
    event.stopPropagation(); 
    this.openModal(item,2);
  }

handleCardClick(event: MouseEvent, item: any){
    event.stopPropagation(); 
    this.openModal(item,2);
    this.saveFooter=false
    this.deleteFooter=false
  }


}


  





 


 
 




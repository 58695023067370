
<div class="mb-3 custom-form-group">
  <label class="highlight">{{ heading }}</label>
  <div class="input-group">
      <input type="file" class="form-control" [accept]="accept" [disabled]="isDisabled" (change)="onFileChange($event)" [id]="id" [required]="isRequired">
      <div *ngIf="selectedFile" class="input-group-append">
          <span class="input-group-text" (click)="removeFile()">✖</span>
      </div>
  </div>
</div>

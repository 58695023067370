<div class="container">
    <h6>Enter Rejection Reason</h6>
    <textarea [(ngModel)]="rejectionReason" placeholder="Enter reason"></textarea>
    <div *ngIf="showError" class="error-message">Please enter a rejection reason.</div>
     <div class="button_div">
       <button mat-button (click)="onSubmitClick()" class="submitbtn">Submit</button>
        <button mat-button (click)="onCancelClick()" class="cancelbtn">Cancel</button>
     </div>
   
</div> 
   


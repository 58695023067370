import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-hr-header",
  templateUrl: "./hr-header.component.html",
  styleUrls: ["./hr-header.component.css"],
})
export class HrHeaderComponent implements OnInit {
  @Input()
  hr_heading: string = "";
  @Input()
  employeeHeading: string = "";
  @Input()
  holidayHeading: string = "";
  constructor() {}

  ngOnInit(): void {}
}

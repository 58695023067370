import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ViewSelfAppraisalComponent } from "../self-appraisal-popup/self-appraisal-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { ViewTeamAppraisalComponent } from "../team-appraisal/team-appraisal-popup/team-appraisal-popup.component";
import { BulkSubmitComponent } from "../bulk-submit/bulk-submit.component";
import { formatDate } from "@angular/common";
import { PromotionHistoryComponent } from "../promotion-history/promotion-history.component";

@Component({
    selector: 'cluster-appraisal',
    templateUrl: './cluster-appraisal.component.html',
    styleUrls: ['./cluster-appraisal.component.scss']
})

export class ClusterAppraisalComponent implements OnInit {


    count = 0;
    offset = 0;
    limit: number;
    reportees = [];
    appraisalcycle: any;
    selfAp = 0;
    teamAp = 0;
    deptAp = 0;
    today: any;
    midCycle = false;
    isDepartment: boolean;
    isBusiness: boolean;
    isCompany: boolean;
    constructor(
        private router: Router,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService,
        private loginService: LoginService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.today = formatDate(new Date(), "yyyy-MM-dd", 'en-IN');
        this.isDepartment = localStorage.getItem('department_head') == 'true' ? true : false;
        if (this.isDepartment) {
            this.appraisalService.getDeptAppraisal().subscribe((res: any) => {
                console.log(res, "<=----------=-=----------=-=------");
                this.reportees = res.data.department;
                this.appraisalcycle = res.data.appraisal_cycle;
                this.midCycle = this.appraisalcycle.is_mid_cycle;
                this.selfAp = res.data.total_self_appraisal_done;
                this.teamAp = res.data.total_team_appraisal_done;
                this.deptAp = res.data.total_department_appraisal_done;
                this.count = res.data.total_employees;

            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                })
        }
        console.log('inside team appraisal')
    }

    onActivate(event: any) {
        if (this.midCycle) {
            if (event.type == "click" && event.cellIndex != 2 && event.cellIndex != 3 && event.cellIndex != 6 && event.row.team_appraisal_done) {
                setTimeout(() => {
                    window.open('department?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }
        else if (!this.midCycle) {
            if (event.type == "click" && event.cellIndex != 3 && event.cellIndex != 4 && event.cellIndex != 7 && event.row.self_appraisal_done && event.row.team_appraisal_done) {
                setTimeout(() => {
                    window.open('department?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }

    }

    openViewSelf(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeSelfAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data.response;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewSelfAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'Self Appraisal',
                    selfData: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    openViewTeam(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeTeamAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let teamData = res.data;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewTeamAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'RM Appraisal',
                    teamData: teamData,
                    midCycle: this.midCycle
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    bulkSubmit() {
        // console.log(" inside view method, data", item.emp_id);
        this.appraisalService.getDeptAppraisalForm({}).subscribe((res: any) => {
            console.log(res);
            let teamData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef2 = this.dialog.open(BulkSubmitComponent, {
                data: {
                    // id: item.emp_id,
                    // name: item.fname + ' ' + item.lname,
                    // popup_title: 'RM Appraisal',
                    bulkData: teamData,
                    midCycle: this.midCycle
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    getPromotions(item) {
        this.appraisalService.getPromotionHistory({ employee: item.emp_id }).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    name: item.fname + ' ' + lname,
                    emp_id: item.emp_id,
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {
   showcard:any;
   showhr:boolean;
   showPOA:any;
   showAmdoc:any;
   imgpathone:any="../../../assets/images/AmplusAmpower.svg";
   imgpathtwo:any="../../../assets/images/AmplusPartner.svg";
   imgpaththree:any="../../../assets/images/astralcrm.svg";
   imgpathfour:any="../../../assets/images/HawkAI.svg";
   imgpathfive:any="../../../assets/images/Homescapepartner.svg";
   imgpathsix:any="../../../assets/images/Homescapesolar.svg";
   product:any=[
    {
      name:"Hawkai",
      description:"Hawk.AI offers centralized monitoring and analysis solution for energy assets. A reliable, secure, and efficient solution for digitizing solar assets.",
      imglink:this.imgpathfour,
      link:"https://hawkai.in/"
     },
     {
      name:"Amplus Travelex",
      description:"Amplus Energy is an internal hybrid app by Amplus Solar Energy Solutions, streamlining reimbursement and leave application management for the company.",
      imglink:this.imgpathone,
      link:"https://play.google.com/store/apps/details?id=com.amplus.solar&pcampaignid=web_share"
     },
     {
      name:"Astral CRM",
      description:"The portal serves as a comprehensive tool for managing various stages of project development, allowing users to categorize leads, monitor their advancement through different phases, and facilitate the smooth execution of tasks.",
      imglink:this.imgpaththree,
      link:"https://astral.homescape.solar/"
     },
     {
      name:"HomeScape Customer",
      description:"Homescape customer app integrates features like a Remote Monitoring System Dashboard, Profile and Property Creation, Property Selection, and Contact Customer Care for seamless monitoring, personalized service, site survey requests, and real-time support.",
      imglink:this.imgpaththree,
      link:"https://play.google.com/store/apps/details?id=org.amplus.homescape&pcampaignid=web_share"
     },
     {
      name:"HomeScape Partner",
      description:"is a one-stop portal to assist our HomeScape partners to deliver the best experience and assistance of installation of solar rooftop systems to its clients. ",
      imglink:this.imgpathfive,
      link:"https://play.google.com/store/apps/details?id=org.amplus.homescape.partner&pcampaignid=web_share"
     },
     {
      name:"HomeScape Solar",
      description:"The leading residential rooftop solar company in India, HomeScape has innovation at the core to enable homeowners to make smarter and stylish choices for their home.",
      imglink:this.imgpathsix,
      link:"https://homescape.solar/"
     },
     {
      name:"Amplus O&M",
      description:"Amplus Energy is an internal hybrid app by Amplus Solar Energy Solutions, streamlining reimbursement and leave application management for the company.",
      imglink:this.imgpathone,
      link:"https://play.google.com/store/apps/details?id=org.amplus.onm&pcampaignid=web_share"
     },
     {
      name:"Amplus Partner",
      description:"The Amplus Partner app facilitates collaboration with internal and external business users, expanding the footprint for rooftop installations by connecting to the internal CRM system to track and manage leads generated through the portal.",
      imglink:this.imgpathtwo,
      link:"https://play.google.com/store/apps/details?id=org.amplus.rooftop&pcampaignid=web_share"
     },
    {
    name:"Amplus Ampower",
    description:"Enjoy the user-friendly AmPower App by Amplus Energy Solutions for free, allowing you to effortlessly monitor real-time and historical performance of your solar photovoltaic plant, anytime and anywhere, with insightful environmental data integration.",
    imglink:this.imgpathone,
    link:"https://play.google.com/store/apps/details?id=org.amplus.ampower&pcampaignid=web_share"
   },
    
  ]
  constructor() { }

  ngOnInit(): void {
  }
  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }
  hrmanagercardshow(showhrmanagerdata){
    this.showhr=showhrmanagerdata
    
 }
  poashow(showpoa){
    this.showPOA=showpoa;
  }
  amdocshow(showamdoc){
    this.showAmdoc=showamdoc;
}
  
}

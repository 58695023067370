import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LearnerService } from 'src/app/core/services/learner.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'feedback-popup',
    templateUrl: './feedback-popup.component.html',
    styleUrls: ['./feedback-popup.component.scss']
})
export class FeedbackPopupComponent implements OnInit {

    // @Input() data: any;
    form: any;
    title: any;
    modality: any;
    location: any;
    date: any;
    startTime: any;
    endTime: any;
    message: any;
    dataPresent = false;
    submitEnable = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FeedbackPopupComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private learnerService: LearnerService,
        private loginService: LoginService,
        private loaderService: LoaderService
        // private toast: toaste
    ) { }

    ngOnInit() {
        console.log("inside register popup", this.data);
        setTimeout(()=>{
            this.loaderService.setLoading(false);
        }, 100);
        this.form = this.fb.group({
            comment: ["", Validators.required],
            rating: ["", Validators.required]
        });
        if (this.data.data != null) {
            this.dataPresent = true;
            this.title = this.data.data.name;
            this.modality = this.data.data.modality;
            this.location = this.data.data.location;
            this.date = formatDate(this.data.data.start_time, "MMM dd, yyyy", 'en-IN');
            this.startTime = formatDate(this.data.data.start_time, "HH:mm aa", 'en-IN');
            this.endTime = formatDate(this.data.data.end_time, "HH:mm aa", 'en-IN');
            this.submitEnable = !this.data.data.fb_submitted;
        }
        if(!this.submitEnable){
            this.learnerService.getMyFeedback(this.data.data.id).subscribe((res: any) => {
                console.log(res);
                this.form.patchValue({
                    rating: res.data.rating,
                    comment: res.data.comment
                },
                (error:any)=>{
                    console.log(error, error.error, error.message);
                     if(error.error == 'Signature has expired'){
            this.loginService.logout();
            }
                });
            })
        }
    }

    submit() {
        if (this.form.valid) {
            this.learnerService.myFeedback(this.data.data.id, this.form.value).subscribe((res: any) => {
                console.log("feedback", res);
                this.dialogRef.close();
                let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                    data: {
                        message: res.message,
                        data: res.data
                    },
                });
            },
            (error:any)=>{
                console.log(error, error.error, error.message);
                 if(error.error == 'Signature has expired'){
            this.loginService.logout();
            }
            });
        }
        else {
            console.log("provide feedback");
            alert("Please fill all the mandatoy fields!!!");
        }
    }

    cancel() {
        this.dialogRef.close();
    }
}
<div *ngIf="questions.length >0">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let question of questions" class="form-row">
      <app-question [question]="question" [form]="form "></app-question>
    </div>

    <div class="row">
      <div class="col-9">

      </div>
      <div class="col" style="width: max-content;  margin: 4vh 2vh;">
        <button class="btn btn-primary" type="submit" [disabled]="!isValid && valid" style="min-width: 15.8vw !important;">Submit Response</button>
      </div>
    </div>
  </form>
</div>
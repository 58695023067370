<!-- <div class="dropdown" id="dropdownTemplate"> -->
<!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
        aria-expanded="false">
        Select Template
    </button> -->
<!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let templateName of templateNames">
            <a class="dropdown-item" (click)="makeTemplate(templateName)">{{ templateName }}</a>
        <li>
        </li>
    </ul>
</div> -->

<div class="templateCont">

    <div class="templateHeading">
        <h4>{{ service_name }}</h4>
        <div *ngIf="item_id">
            <h6>ID :{{item_id}}</h6>
        </div>
    </div>

    <div>
        <form>


            <div *ngIf="filteredTextInputs">
                <ng-container *ngFor="let item of filteredTextInputs; let i = index">
                    <app-input-text [placeholder]="item.description" [heading]="item.title"
                        [minWords]="item.validations.min" [maxWords]="item.validations.max" [hint]="item.hint"
                        [isDisabled]="item.is_disabled" [name]="item.title" [id]="item.id" [isRequired]="item.required">

                    </app-input-text>
                </ng-container>

            </div>

            <div *ngIf="filteredTextDropdown">
                <ng-container *ngFor="let item of filteredTextDropdown; let i = index">
                    <app-input-dropdown [placeholder]="item.description" [heading]="item.title"
                        [minWords]="item.validations.min" [maxWords]="item.validations.max" [hint]="item.hint"
                        [isDisabled]="item.is_disabled" [name]="item.title" [id]="item.id" [isRequired]="item.required"
                        [options]="item.options">
                    </app-input-dropdown>
                </ng-container>
            </div>


            <div *ngIf="filteredTextDate">
                <ng-container *ngFor="let item of filteredTextDate">
                    <app-input-date [placeholder]="item.description" [heading]="item.title"
                        [minWords]="item.validations.min" [maxWords]="item.validations.max" [hint]="item.hint"
                        [isDisabled]="item.is_disabled" [(ngModel)]="item.value" name="{{item.title}}" [id]="item.id"
                        [isRequired]="item.required">

                    </app-input-date>
                </ng-container>
            </div>


            <div *ngIf="filteredTextNumber">
                <ng-container *ngFor="let item of filteredTextNumber; let i = index">
                    <app-input-number [placeholder]="item.description" [heading]="item.title"
                        [minWords]="item.validations.min" [maxWords]="item.validations.max" [hint]="item.hint"
                        [isDisabled]="item.is_disabled" [name]="item.title" [id]="item.id" [isRequired]="item.required">
                    </app-input-number>
                </ng-container>

            </div>


            <div *ngIf="filteredTextFile">
                <ng-container *ngFor="let item of filteredTextFile">
                    <app-input-file [heading]="item.title" [hint]="item.hint" [accept]=""
                        [isDisabled]="item.is_disabled" [(ngModel)]="item.value" [name]="item.title"
                        (change)="onFileSelected($event, item.id)" [id]="'file'+item.id" [isRequired]="item.required">
                    </app-input-file>
                    <a [id]="item.id" (click)="getTheFileUrl(item.id)" class="fileUrl"></a>
                </ng-container>
            </div>

            <div *ngIf="filteredTextMultipleFile">
                <ng-container *ngFor="let item of filteredTextMultipleFile">
                    <!-- Assuming 'item.title', 'item.hint', 'item.is_disabled', 'item.id', and 'item.required' are defined in your component -->
                    <app-input-multiple-file [heading]="item.title" [hint]="item.hint" [accept]=""
                        [isDisabled]="item.is_disabled" [name]="item.title" [id]="item.id" [isRequired]="item.required">
                    </app-input-multiple-file>

                    <div [id]="'multiFile' + item.id" class="fileUrl">
                    </div>
                </ng-container>
            </div>





            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <button class="btn" id="notificationBtn" style="display: none;">Add
                            Notification</button>

                    </div>
                    <div class="col-auto">
                        <!-- in submit button i will have to put a ng if comdition -->
                        <div *ngIf="submitBtn">
                            <button type="submit" class="btn" id="submitBtn"
                                (click)="submitForm(0,'submit')">Submit</button>
                        </div>

                        <div class="editButtons">

                            <div *ngIf="editBtn">
                                <button type="submit" class="btn" id="renewBtn"
                                    (click)="submitForm(1,'renew')">Renew</button>
                            </div>

                            <div *ngIf="editBtn">
                                <button type="submit" class="btn" id="updateBtn"
                                    (click)="submitForm(1,'edit')">Save</button>
                            </div>
                            <div *ngIf="editBtn">
                                <button type="submit" class="btn" id="deleteBtn"
                                    (click)="submitForm(2,'delete')">Delete</button>
                            </div>


                        </div>





                    </div>
                </div>
            </div>




        </form>
    </div>

</div>
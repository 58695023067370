import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { ActionPopupComponent } from '../action/action.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as $ from 'jquery';
const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'view-session',
    templateUrl: './view-session.component.html',
    styleUrls: ['./view-session.component.scss']
})
export class ViewSessionComponent implements OnInit {

    // @Input() data: any;
    form: FormGroup;
    f_value: any;
    formData: FormData;
    publishEnable: boolean = false;
    now: any;
    isAdmin: boolean = false;
    stars = [1, 2, 3, 4, 5];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ViewSessionComponent>,
        private dialog: MatDialog,
        private adminService: AdminService,
        private fb: FormBuilder,
        private loginService: LoginService,
        private learnerService: LearnerService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside view session page", this.data);
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        this.now = formatDate(new Date, "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2')
        this.f_value = this.data.sessionData;
        this.f_value.avg_rating = Math.round(this.f_value.avg_rating);
        // if (this.data.past && this.f_value.status != 'cancelled') {
        //     this.form.patchValue({
        //         rating: this.f_value.avg_rating
        //     })
        // };
        if (this.f_value.name != null &&
            this.f_value.modality != null &&
            this.f_value.location != null &&
            this.f_value.start_time != null &&
            this.f_value.end_time != null &&
            this.f_value.capacity != null &&
            this.f_value.open_to != null) {
            this.publishEnable = true
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    editDraft(id) {
        this.dialogRef.close();
        this.adminService.getSession(id).subscribe((res: any) => {
            console.log(res);
            let sessionData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(SessionModalComponent, {
                data: {
                    id: id,
                    create: false,
                    view: false,
                    edit: true,
                    sessionData: sessionData,
                    buttonSecondary: 'Publish',
                    buttonPrimary: 'Save Draft'
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    deleteDraft() {
        let dialogRef = this.dialog.open(ActionPopupComponent, {
            width: "300px",
            data: {
                buttontext: "Yes, Delete Draft",
                desc: "Are you sure to delete this draft?",
            },
        });
        const sub = dialogRef.componentInstance.option.subscribe((data) => {
            if (data) {
                sub.unsubscribe();
                if (data == "success") {
                    this.adminService.deleteSession(this.data.id).subscribe((res: any) => {
                        console.log(res);
                        if (res.message == 'Draft deleted') {
                            this.dialogRef.close();
                            let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                                data: {
                                    message: res.message,
                                    data: res.data
                                },
                            });
                        }
                    },
                        (error: any) => {
                            console.log(error, error.error, error.message);
                            if (error.error == 'Signature has expired') {
                                this.loginService.logout();
                            }
                        });
                }
            }
        });
    }

    publish() {
        this.cancel();
        let f_value = this.data.sessionData;
        var formData = new FormData();
        formData.append("id", f_value.id);
        formData.append("status", "published");
        console.log("form value ---- draft published--->", formData);
        // console.log(this.formData.valid);
        this.adminService.modifyNewSession(f_value.id, formData).subscribe((res: any) => {
            console.log("create session (published)", res);
            if (res.message == 'Published successfully') {
                this.dialogRef.close();
                let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                    data: {
                        message: res.message,
                        data: res.data
                    },
                });
            }
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
                else {
                    alert(error.error.error);
                }
            });

    }

    cancelSession(data) {
        this.cancel();
        let dialogRef = this.dialog.open(ActionPopupComponent, {
            width: "300px",
            data: {
                data: data,
                buttontext: "Yes, Cancel Session",
                desc: "Are you sure to cancel this session?",
            },
        });
        const sub = dialogRef.componentInstance.option.subscribe((data) => {
            if (data) {
                sub.unsubscribe();
                if (data == "success") {
                    let f_value = this.data.sessionData;
                    f_value.start_time = formatDate(f_value.start_time, "yyyy-MM-dd HH:mm:ss", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
                    f_value.end_time = formatDate(f_value.end_time, "yyyy-MM-dd HH:mm:ss", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
                    var formData = new FormData();
                    formData.append("id", f_value.id);
                    formData.append("status", "cancelled");
                    console.log("form value ---- draft --->", f_value);
                    this.adminService.modifySession(f_value.id, formData).subscribe((res: any) => {
                        console.log("create session (cancelled)", res);
                        if (res.message == 'Session cancelled') {
                            let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                                data: {
                                    message: res.message,
                                    data: res.data
                                },
                            });
                        }
                    },
                        (error: any) => {
                            console.log(error, error.error, error.message);
                            if (error.error == 'Signature has expired') {
                                this.loginService.logout();
                            }
                        });
                }
            }
        });
    }

    openFeedbacks(f_value) {
        this.dialogRef.close();
        let dialogRef = this.dialog.open(FeedbackComponent, {
            data: {
                data: f_value,
            },
        });
    }

    openAgendaFile(url) {
        let params = {
            file_url: url
        }
        this.learnerService.getAgenda(params).subscribe(res => {
            window.open(res['presigned_url'], "_blank");
        })
    }
}
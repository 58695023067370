import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ViewSelfAppraisalComponent } from "../self-appraisal-popup/self-appraisal-popup.component";
import { formatDate } from "@angular/common";
import { PromotionHistoryComponent } from "../promotion-history/promotion-history.component";
import { LearnerService } from "src/app/core/services/learner.service";

@Component({
    selector: 'team-appraisal',
    templateUrl: './team-appraisal.component.html',
    styleUrls: ['./team-appraisal.component.scss']
})

export class TeamAppraisalComponent implements OnInit {

    count = 0;
    offset = 0;
    limit: number;
    reportees: any;
    selfAp: any;
    teamAp: any;
    appraisalcycle: any;
    midCycle: boolean = false;
    today: any;
    isManager: boolean;
    constructor(
        private router: Router,
        private loaderService: LoaderService,
        private dialog: MatDialog,
        private loginService: LoginService,
        private learnerService: LearnerService,
        private appraisalService: AppraisalService
    ) { }

    ngOnInit() {
        // $(document).ready(function(){
        //     $(this).scrollTop(0);
        // });
        this.today = formatDate(new Date(), "yyyy-MM-dd", 'en-IN');
        // this.isSelf = localStorage.getItem('self') == 'true' ? true : false;
        this.isManager = localStorage.getItem('manager') == 'true' ? true : false;
        console.log(this.today, "<------------------date")
        if (this.isManager) {
            this.appraisalService.getTeamAppraisal().subscribe((res: any) => {
                this.reportees = res.data.reportees;
                this.appraisalcycle = res.data.appraisal_cycle;
                this.midCycle = this.appraisalcycle.is_mid_cycle;
                this.selfAp = res.data.total_self_appraisal_done;
                this.teamAp = res.data.total_team_appraisal_done;
                this.count = this.reportees.length;

            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                })
        }
        console.log(this.appraisalcycle, ",-------------------appraisal in teams");
    }

    onActivate(event: any) {
        if(this.midCycle){
            if (event.type == "click" && event.cellIndex != 2 && event.cellIndex != 5 ) {
                setTimeout(() => {
                    window.open('teams?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }
        else if(!this.midCycle){
            if (event.type == "click" && event.cellIndex != 3 && event.cellIndex != 6 && event.row.self_appraisal_done) {
                setTimeout(() => {
                    window.open('teams?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }
    }

    openViewSelf(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeSelfAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data.response;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewSelfAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'Self Appraisal',
                    selfData: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    getPromotions(item) {
        this.appraisalService.getPromotionHistory({ employee: item.emp_id }).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            this.loaderService.setLoading(true);
            let lname = item.lname ? item.lname : '';
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    name: item.fname + ' ' + lname,
                    emp_id: item.emp_id,
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
}
<!-- 
<div *ngIf="lndUserType" class="row"    >

    <div class="col header">
      List of Claims  |  {{user}} Portal
    </div>
    <div class="col header" style="text-align: right;">
     <span style="font-size:30px;cursor:pointer;margin-left: 61px;" (click)="allLink.openNav()">&#9776; </span>

    </div>


</div> -->
<span *ngIf="lndUserType" class="row">
<app-header-global></app-header-global>
</span>

<!-- <app-side-overlay-menu></app-side-overlay-menu> -->

<div class="row main_claim">
<div class="row detail_claim">
  <div class="claim_div">
<app-header [claimDetailsHeading]="headingTwo"></app-header>
<div class="table-responsive">
<table class="table" id="emp_expense">
    <thead>
    <tr>
      <th>Claim ID</th>
      <th>Submitter</th>
      <th>Submitter ID</th>
      <th>Project</th>
      <th>Legal Entity</th>
      <th>Date Submitted</th>
      <th>Expense Type</th>
      <th>Amount(Total vs Approved)</th>
      <!-- <th>Amount(Approved)</th> -->
      <th>Category</th>
      <th>Reviewer</th>
      
    </tr>
  </thead>
  <tbody>
    <tr  *ngFor="let claim of claim">
    <td>{{claim.data.id}}</td>
    <td>{{claim.data.submitter.name}}<br>({{claim.data.submitter.job_title}})</td>
    <td>{{claim.data.submitter.emp_id}}</td>
    <td>{{claim.data.project.name}}/{{claim.data.project.amplus_id}}</td>
    <td>{{claim.data.submitter.legal_entity}}</td>
    <td>{{claim.data.submitted_at}}</td>
    <td>{{claim.data.expense_type}}</td>
    <td>{{claim.data.amount}}/--{{claim.data.approved_amount}}</td>
   <!-- <td>{{claim.data.approved_amount}}</td> -->
    <td>{{claim.data.category.name}}</td>
    <td>{{claim.data.reviewer.name}}</td>
    
  
    </tr>
  </tbody>
  </table>
</div>
</div>
 <div class="row pre_div">
 <app-pre-aproved-claim [preapprovals]="preapproval" [isShow] ="isShow"></app-pre-aproved-claim>
</div>
 <br>
 <div class="row exp_div">
<app-expense-details [expense]="expenses"  [claims]="claim" [is_actions_global]="is_actions_globals"  [expensetype]="expenseTypes" [expensetypekeys]="expenseTypeKeys" (testevent)="getDetailClaimData()"></app-expense-details>
</div>
</div>
</div>
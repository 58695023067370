<app-hr-header [employeeHeading]="headingBusiness"></app-hr-header>
<div class="row">
  <div class="col">
    <button type="button" class="approvebtn" (click)="addBusiness()">Add Business</button>
  </div>
</div>
<div class="table-responsive">
  <table class="table" id="emp_expense">
    <thead>
      <tr>
        <th>ID</th>
        <th>Business Name</th>
        <th>Head</th>
        <th>Abbr</th>
        <th></th>
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cmp of businesslist; let i = index">
        <td>{{ cmp.id }}</td>
        <td>{{ cmp.name | titlecase }}</td>
        <td>{{ cmp.head.name }}</td>
        <td>{{ cmp.abbr | titlecase }}</td>
        <td style="color: #42478b; text-decoration: underline; cursor: pointer;" (click)="editBusiness(cmp)">
            edit<img class="arrow-image" src="../../../assets/images/arrows.svg" alt="">
          </td>
      </tr>
    </tbody>
  </table>
</div>

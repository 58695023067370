<div class="row">
    <div class="col pt10">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h4 class="accordion-header" id="headingOne">
                    <button class="accordion-button font18" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Enrolment Procedures
                    </button>
                </h4>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <ul class="list-unstyled procedure">
                            <li>
                                <ul>
                                    <li>
                                        Disenrollment closes 2 days before the session day at 12 noon. As an example, if
                                        a session is scheduled for Thursday, then the disenrollment closes Tuesday 12
                                        noon.<br />
                                    </li>
                                    <li>
                                        Enrolment closes 1 day before the session day at 12 noon. As an example, if a
                                        session is scheduled for Thursday, then the enrolment closes Wednesday 12
                                        noon.<br />
                                    </li>
                                    <li>
                                        If you choose to disenroll from a session, your request will need to be
                                        supported with a reason. You'll also be receiving an email confirming your
                                        disenrollment.<br /> You're requested to seek approval from your Reporting
                                        Manager on the same email thread, stating the reason for cancellation.<br />
                                        This must be completed no later than 12 noon on the day preceding the session
                                        day.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col session-header p1rem">
        Upcoming Sessions
    </div>
</div>
<div *ngIf="sessionList.length >0">
    <div *ngFor="let item of sessionList">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div *ngIf="item.status == 'cancelled'" class="row">
                                    <div class="col text-heading">
                                        <strong>{{item.name}}</strong> | {{item.modality}}
                                        <span class="badge rounded-pill text-bg-secondary tooltipInput"
                                            style="background-color: #6c757d54 !important;">{{item.status}}
                                            <span class="tooltiptext">This session was
                                                cancelled by the Admin/HR.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="item.status != 'cancelled'" class="row">
                                    <div class="col text-heading">
                                        <strong>{{item.name}}</strong> | {{item.modality}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-decor">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        {{item.location}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-decor">
                                        <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                        {{item.start_time | date:'MMM dd, yyyy'}} |
                                        {{item.start_time | date:'HH:mm aa'}} -
                                        {{item.end_time | date:'HH:mm aa'}}
                                    </div>
                                </div>
                                <div class="row" *ngIf="item.enrollment_status != null">
                                    <div class="col-4 text-decor"
                                        style="width:max-content !important; border-right: 1px solid #000;">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        {{item.no_of_enrollment}}/{{item.capacity}} Spots
                                        filled
                                    </div>
                                    <div *ngIf="item.enrollment_status == 'enrolled' && item.status !='cancelled' && item.disenroll_close_time > now"
                                        class="col-3 list-link" (click)="unenroll(item)">
                                        Disenroll
                                    </div>
                                    <div *ngIf="item.enrollment_status == 'enrolled' && item.status !='cancelled' && item.disenroll_close_time <= now"
                                        class="col-3 list-link-disabled">
                                        Disenroll
                                    </div>
                                    <div *ngIf="item.enrollment_status != 'enrolled' || item.status =='cancelled'"
                                        class="col-3 list-link">
                                        <span
                                            class="badge rounded-pill text-bg-secondary tooltipInput">{{item.enrollment_status}}
                                            <span *ngIf="item.enrollment_status == 'disenrolled'"
                                                class="tooltiptext">You disenrolled from
                                                this session.</span>
                                            <span *ngIf="item.enrollment_status == 'absent'" class="tooltiptext">You
                                                were marked absent from
                                                this session.</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row" *ngIf="item.enrollment_status == null">
                                    <div class="col-4 text-decor">
                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        {{item.no_of_enrollment}}/{{item.capacity}} Spots
                                        filled
                                    </div>
                                </div>
                                <div *ngIf="isAdmin" class="row">
                                    <div class="col list-link" (click)="openParticipantList(item.id)">
                                        <i class="fa fa-cloud" aria-hidden="true"></i> Click here to view
                                        enrollment/participant list
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div *ngIf="item.status != 'cancelled'" class="row">
                                    <div class="col p5 text-align-end">
                                        <button type="button" class="btn btn-primary"
                                            *ngIf="item.enroll_close_time >= now && (item.no_of_enrollment < item.capacity) && item.enrollment_status == null"
                                            (click)="openRegistration(item)">Register</button>
                                        <button
                                            *ngIf="item.enroll_close_time < now && item.enrollment_status != null && item.no_of_enrollment == item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">You already
                                                enrolled/disenrolled.</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time < now && item.enrollment_status != null && item.no_of_enrollment != item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">You already
                                                enrolled/disenrolled.</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time >= now && item.enrollment_status != null && item.no_of_enrollment == item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">You already
                                                enrolled/disenrolled.</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time < now && item.enrollment_status == null && item.no_of_enrollment == item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">The session enrollment has now closed.<br/> You may walk-in to the session and you might be accommodated based on availability.</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time < now && item.enrollment_status == null && item.no_of_enrollment != item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">The session enrollment has now closed.<br/> You may walk-in to the session and you might be accommodated based on availability..</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time >= now && item.enrollment_status != null && item.no_of_enrollment != item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">You already
                                                enrolled/disenrolled.</span></button>
                                        <button
                                            *ngIf="item.enroll_close_time >= now && item.enrollment_status == null && item.no_of_enrollment == item.capacity"
                                            type="button" class="btn btn-outline-secondary tooltipInput">Register
                                            <span class="tooltiptext">Spots are full right
                                                now.</span></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col p5 view-btn">
                                        <button type="button" class="btn btn-outline-primary"
                                            (click)="openView(item.id)">View</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="sessionList.length <=0" class="row no-data">
    <div class="col">
        no sessions available :(
    </div>
</div>
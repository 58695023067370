import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ActionPopupComponent } from "src/app/components/action/action.component";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import * as $ from 'jquery';
import { LoaderService } from "src/app/core/services/loader.service";
declare var bootstrap: any;
@Component({
    selector: 'team-appraisal-form',
    templateUrl: './team-appraisal-form.component.html',
    styleUrls: ['./team-appraisal-form.component.scss']
})

export class TeamAppraisalFormComponent implements OnInit {
    emp_name: any;
    cycle: any = '2023-24'
    name: any;
    user: any;
    lndUserType: boolean;
    isAdmin: any;
    showcard: any;
    showhr: boolean;
    form: any;
    midForm: any;
    emp_id: any;
    appraisalId: any;
    teamViewOnly = false;
    cycle_name: any;
    midCycle: boolean = false;
    type: any;

    // @ViewChild('myTabContentRef') myTabContent: ElementRef;

    constructor(
        private learnerService: LearnerService,
        private loginService: LoginService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        public loaderService: LoaderService,
        private router: Router,
        private dialog: MatDialog,
        private toast: ToastrService,

        private appraisalService: AppraisalService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.emp_id = params['id'];
            this.emp_name = params['name'];
            this.appraisalId = params['cycle'];
            this.type = params['type'];
            console.log(this.emp_id, this.appraisalId, "=======================emp, appraisal=======================")
            this.getEmployeeAppraisal();
        });
        this.form = this.fb.group({
            employee: [this.emp_id],
            appraisal_cycle: [this.appraisalId],
            employment_type: [""],
            accomplishments: ["", Validators.required],
            strength: ["", Validators.required],
            improvement: ["", Validators.required],
            clip_commitment: [0, Validators.required],
            clip_leadership: [0, Validators.required],
            clip_innovation: [0, Validators.required],
            clip_performance: [0, Validators.required],
            low_performance: [false, Validators.required],
            ready_for_promotion: [false, Validators.required],
            offroll_to_onroll: [false, Validators.required],
            one_on_one_discussion: [false, Validators.required],
            feedback: ["", Validators.required],
        });
        this.midForm = this.fb.group({
            employee: [this.emp_id],
            appraisal_cycle: [this.appraisalId],
            // employment_type: [""],
            q1: ["", Validators.required],
            q2: ["", Validators.required],
            q3: ["", Validators.required],
            low_performance: ["", Validators.required],
            ready_for_promotion: [false, Validators.required],
            q4: ["", Validators.required],
            increment: [0, Validators.required],
            increment_remarks: [""],
        });
        console.log('in appraisal')
        this.learnerService.getUserType().subscribe(res => {
            this.user = res['lnd_user_type'];
            this.lndUserType = true;
            localStorage.setItem('user_type', res['lnd_user_type']);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        // this.user_type = localStorage.getItem('user_type');
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        // this.user = localStorage.getItem('user_type');
        this.name = localStorage.getItem('name');
    }
    expensecardshow(showclaimdata) {
        this.showcard = showclaimdata;
    }

    hrmanagercardshow(showhrmanagerdata) {
        this.showhr = showhrmanagerdata

    }

    getEmployeeAppraisal() {
        this.appraisalService.getTeamAppraisalForm({ 'employee': this.emp_id }).subscribe((res: any) => {
            console.log(res, "--------team appraisal form--------");
            let appraisal = res.data.team_appraisal;
            this.cycle_name = res.data.appraisal_cycle.name;
            this.midCycle = res.data.appraisal_cycle.is_mid_cycle;
            if (JSON.stringify(appraisal) != '{}') {
                if (!this.midCycle) {
                    this.form.patchValue({
                        employee: this.emp_id,
                        employment_type: appraisal.employment_type,
                        appraisal_cycle: this.appraisalId,
                        accomplishments: appraisal.accomplishments,
                        strength: appraisal.strength,
                        improvement: appraisal.improvement,
                        clip_commitment: appraisal.clip_commitment,
                        clip_leadership: appraisal.clip_leadership,
                        clip_innovation: appraisal.clip_innovation,
                        clip_performance: appraisal.clip_performance,
                        low_performance: appraisal.low_performance,
                        ready_for_promotion: appraisal.ready_for_promotion,
                        offroll_to_onroll: appraisal.offroll_to_onroll,
                        one_on_one_discussion: appraisal.one_on_one_discussion,
                        feedback: appraisal.feedback,
                    })
                }
                else if (this.midCycle) {
                    this.midForm.patchValue({
                        employee: this.emp_id,
                        // employment_type: appraisal.employment_type,
                        appraisal_cycle: this.appraisalId,
                        q1: appraisal.q1,
                        q2: appraisal.q2,
                        q3: appraisal.q3,
                        low_performance: appraisal.low_performance,
                        ready_for_promotion: appraisal.ready_for_promotion,
                        q4: appraisal.q4,
                        increment: appraisal.increment,
                        increment_remarks: appraisal.increment_remarks,
                    })
                }

                this.teamViewOnly = true;
            }
            else {
                this.teamViewOnly = false;
            }
        })
    }

    get isValid() {
        if (!this.midCycle) {
            if (this.form.controls['accomplishments'].value.length < 10 || this.form.controls['accomplishments'].value.length > 5000) {
                return false;
            }
            else if (this.form.controls['strength'].value.length < 10 || this.form.controls['strength'].value.length > 5000) {
                return false;
            }
            else if (this.form.controls['improvement'].value.length < 10 || this.form.controls['improvement'].value.length > 5000) {
                return false;
            }
            else if (this.form.controls['feedback'].value.length < 10 || this.form.controls['feedback'].value.length > 5000) {
                return false;
            }
            else {
                return this.form.valid;
            }
        }
        else if (this.midCycle) {
            if (this.midForm.controls['increment'].value != 0) {
                if ((this.midForm.controls['increment_remarks'].value.length < 10 || this.midForm.controls['increment_remarks'].value.length > 1000)) {
                    return false;
                }
                else{
                    return true;
                }
            }
            else {
                return this.midForm.valid;
            }
        }

        console.log(this.form, "_____form___validation");
    }
    cancel() {
        localStorage.setItem('tab', 'team');
        this.router.navigate(['/appraisals']);

    }

    onSubmit() {
        console.log("submit team appraisal, gdsdcb========")
        console.log(this.form.value);
        let dialogRef = this.dialog.open(ActionPopupComponent, {
            width: "300px",
            data: {
                buttontext: "Submit",
                desc: "You will not be able to make any changes once submitted, do you want to Submit Appraisal?",
            },
        });
        const sub = dialogRef.componentInstance.option.subscribe((data) => {
            if (data) {
                sub.unsubscribe();
                if (data == "success") {
                    if (!this.midCycle) {
                        this.appraisalService.createTeamAppraisal(this.emp_id, this.form.value).subscribe((res: any) => {
                            this.toast.success('Hurrah!! Appraisal Submitted!!!');
                            setTimeout(() => {
                                window.location.reload();
                            }, 700)
                        },
                            (error: any) => {
                                this.toast.error('Oops!! Please re-check form and fill all mandatory fields.');
                            })
                    }
                    else {
                        this.appraisalService.createTeamAppraisal(this.emp_id, this.midForm.value).subscribe((res: any) => {
                            this.toast.success('Hurrah!! Appraisal Submitted!!!');
                            setTimeout(() => {
                                window.location.reload();
                            }, 700)
                        },
                            (error: any) => {
                                this.toast.error('Oops!! Please re-check form and fill all mandatory fields.');
                            })
                    }
                }
               
            }
        });

    }
}
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator'
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoginService } from 'src/app/core/login/login.service';
import { MatListModule } from '@angular/material/list';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
import { AsyncPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Dialog } from '@angular/cdk/dialog';
import { ActionPopupComponent } from '../../action/action.component';
import { Router } from '@angular/router';


@Component({
    selector: 'table-selection-example',
    styleUrls: ['./bulk-submit.component.scss'],
    templateUrl: './bulk-submit.component.html',
})
export class BulkSubmitComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: any;
    name: any;
    user: any;
    cycle: any = '2023-24'
    lndUserType: boolean;
    isAdmin: any;
    showcard: any;
    showhr: boolean;
    form: any;
    midform: any;
    masterSelected: boolean;
    checkedList: any = [];
    emp_data = [];
    noData = false;
    departmentList = [];
    selectedOption: any;
    bulkData: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef2: MatDialogRef<BulkSubmitComponent>,
        private loginService: LoginService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private learnerService: LearnerService,
        private toast: ToastrService,
        private loaderService: LoaderService,
        private appraisalService: AppraisalService,
        private router: Router
    ) { }
    ngOnInit() {
        console.log(this.data, "<----------------bulk submit data");
        this.getDepartments();
        this.form = this.fb.group({
            emp_id: ['', Validators.required],
            name: ['', Validators.required],
            commitment: ['', Validators.required],
            leadership: ['', Validators.required],
            innovation: ['', Validators.required],
            performance: ['', Validators.required],
            low_performer: ['', Validators.required],
            promotion: ['', Validators.required],
        });
        this.midform = this.fb.group({
            emp_id: ['', Validators.required],
            name: ['', Validators.required],
            increment: ['', Validators.required],
            increment_remarks: ['', Validators.required],
            low_performer: ['', Validators.required],
            promotion: ['', Validators.required],
        });
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        // this.emp_data = this.data.department_appraisals;
        // this.emp_data.forEach(obj => {
        //     obj.isSelected = false
        // })
        console.log(this.emp_data);
    }

    checkUncheckAll() {
        for (var i = 0; i < this.emp_data.length; i++) {
            if (this.emp_data[i].status == 'saved' || this.emp_data[i].status == 'department pending')
                this.emp_data[i].isSelected = this.masterSelected;
        }
        this.getCheckedItemList();
    }
    isAllSelected() {
        this.masterSelected = this.emp_data.every(function (item: any) {
            if (item.status == 'saved' || item.status == 'department pending') {
                return item.isSelected == true;
            }
        })
        this.getCheckedItemList();
    }

    getDepartments() {
        this.appraisalService.getDepartments({ 'employee_type': 'department_head' }).subscribe((res: any) => {
            this.departmentList = res.data;
            // this.selectedOption = this.departmentList[0].id;
            // this.emp_data = [];
            // this.getEmployeeList(this.selectedOption);
        })
    }

    getEmployeeList(event) {
        if (event.isUserInput) {    // ignore on deselection of the previous option
            this.emp_data = [];
            this.selectedOption = event.source.value;
            this.appraisalService.getDeptAppraisalForm({ 'department': event.source.value }).subscribe((res: any) => {
                this.emp_data = res.data.department_appraisals;
                if (res.data.department_appraisals.length == 0) {
                    this.noData = true;
                }
                else {
                    this.noData = false;
                }
                this.emp_data.forEach(obj => {
                    obj.isSelected = false
                })
                console.log(this.emp_data, ",-------------,,,")
            })
        }
    }
    getCheckedItemList() {
        this.checkedList = [];
        for (var i = 0; i < this.emp_data.length; i++) {
            if (this.emp_data[i].isSelected)
                this.checkedList.push(this.emp_data[i]);
        }
        // this.checkedList = JSON.stringify(this.checkedList);
        console.log("checked list-----------------------", this.checkedList)
    }

    save() {
        this.getCheckedItemList();
        let params = {
            'action': 'save',
            'department': this.selectedOption
        }
        let saveCheck = false;
        for (var i = 0; i < this.checkedList.length; i++) {
            if (this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks == '') {
                this.toast.error("Please enter increment remarks for - " + this.checkedList[i].employee_fullname);
                saveCheck = true;
            }
            else if ((this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks.length < 10) || this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks.length > 1000) {
                this.toast.error("Please enter increment remarks between 10-1000 characters.");
                saveCheck = true;
            }
        }
        if (!saveCheck) {
            let dialogRef = this.dialog.open(ActionPopupComponent, {
                width: "300px",
                data: {
                    buttontext: "Save",
                    desc: "Do you want to save selected Appraisals?",
                },
            });
            const sub = dialogRef.componentInstance.option.subscribe((data) => {
                if (data) {
                    sub.unsubscribe();
                    if (data == "success") {
                        this.appraisalService.createDepartmentBulkAppraisal(params, this.checkedList).subscribe((res: any) => {
                            this.toast.success("Hurray!! Bulk Appraisal Saved");
                            setTimeout(() => {
                                window.location.reload();
                                localStorage.setItem('tab', 'department');
                            }, 500)
                        })

                    }
                }
            });
        }
        else {
            return;
        }

    }
    freeze() {
        this.getCheckedItemList();
        let params = {
            'action': 'freeze',
            'department': this.selectedOption
        }
        let saveCheck = false;
        for (var i = 0; i < this.checkedList.length; i++) {
            if (this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks == '') {
                this.toast.error("Please enter increment remarks for - " + this.checkedList[i].employee_fullname);
                saveCheck = true;
            }
            else if ((this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks.length < 10) || this.checkedList[i].increment > 0 && this.checkedList[i].increment_remarks.length > 1000) {
                this.toast.error("Please enter increment remarks between 10-1000 characters.");
                saveCheck = true;
            }
        }
        if (!saveCheck) {
            let dialogRef = this.dialog.open(ActionPopupComponent, {
                width: "300px",
                data: {
                    buttontext: "Freeze",
                    desc: "You will not be able to make any changes once freezed, do you want to freeze selected Appraisals?",
                },
            });
            const sub = dialogRef.componentInstance.option.subscribe((data) => {
                if (data) {
                    sub.unsubscribe();
                    if (data == "success") {
                        this.appraisalService.createDepartmentBulkAppraisal(params, this.checkedList).subscribe((res: any) => {
                            this.toast.success("Hurray!! Selected Appraisals Submitted");
                            setTimeout(() => {
                                window.location.reload();
                                localStorage.setItem('tab', 'department');
                            }, 500)
                        });

                    }
                }
            });
        }
        else {
            return;
        }
    }

    handleChange(input, id) {
        console.log(typeof input);
        if (input % 1 !== 0) {
            input = Math.round(input);
            this.toast.warning("Decimal values are not allowed");
        }
        console.log(input, "<------floor");
        if (input < 0) {
            input = Math.abs(input);
            this.toast.warning("Please enter value between 0-100");
        };
        if (input > 100) {
            input = 100;
            this.toast.warning("Please enter value between 0-100");
        };
        $('#' + id).val(input);
    }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsrftokenService {

  constructor() { }

  getCsrfToken(): string | null {
    // const cookies = document.cookie.split(';');
    // for (let i = 0; i < cookies.length; i++) {
    //     const cookie = cookies[i].trim();
    //     // Check if the cookie starts with 'csrftoken='
    //     if (cookie.startsWith('csrftoken=')) {
    //         // Extract and return the CSRF token value
    //         console.log("csrf token ================================>", cookie.substring('csrftoken='.length))
    //         return cookie.substring('csrftoken='.length);
    //     }
    // }
    // return ''; // CSRF token not found
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('csrftoken=')) {
            console.log(cookie.split('=')[1])
            return cookie.split('=')[1];
        }
    }
    return '';
}

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css']
})
export class InputDropdownComponent implements OnInit {
  @Input() heading: string = 'Select an Option';
  @Input() options: string[] = [];
  @Input() placeholder: string = 'Please select';
  @Input() isDisabled: boolean = false;
  @Input() id: string = 'customDropdown';
  @Input() isRequired: boolean = false;
  @Input() hint: string = '';
  @Input() minWords: number;
  @Input() maxWords: number;

  parsedOptions: { value: string, label: string }[] = [];
  selectedValue: string;
  isValid: boolean = true;

  ngOnInit() {
    if (this.options.length > 0) {
      this.parsedOptions = this.options.map(option => {
        const cleanOption = option.slice(1, -1);
        const [value, label] = cleanOption.split(':');
        return { value: value.trim(), label: label.trim() }; 
      });
    }
    
  }

  onSelectionChange(value: string, id: string) {
    this.selectedValue = value;
    this.isValid = this.validateSelection(value);
    
    let span=(document.getElementById(`${id}_span`) as HTMLInputElement)
      span.style.display='none';
  }

  validateSelection(value: string): boolean {
    return !!value;
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-rejection-reason-dialog-component',
  templateUrl: './rejection-reason-dialog.component.html',
  styleUrls: ['./rejection-reason-dialog.component.css']
})
export class RejectionReasonDialogComponent implements OnInit {

  
  rejectionReason: string = '';
  showError: boolean = false;
  constructor(public dialogRef: MatDialogRef<RejectionReasonDialogComponent>) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    if (this.rejectionReason.trim() === '') {
      this.showError = true;
    } else {
      this.dialogRef.close(this.rejectionReason);
    }
  }
  ngOnInit(): void {
  }

}

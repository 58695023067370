import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { access } from "fs";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    baseUrl = environment.url;
    authUrl = 'auth_token/';
   

    constructor(
        private http: HttpClient
    ) {
        console.log("obj created");
    }

    getAccessToken(data) {
        const headers = new HttpHeaders().set('Authorization', data);
        return this.http.post(this.baseUrl + this.authUrl, '', { headers });
    }
}
<div class="manage-proposal-wrapper" style="width: 500px;">
    <div *ngIf="dataPresent" class="row">
        <div class="col  text-heading-popup">
            <strong>{{title}}</strong> | {{modality}}
        </div>
        <div class="col-2 text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col check-col text-align-justify">
            This session is scheduled {{modality}} at {{location}} and is Open To {{data.data.open_to}}. By registering,
            you confirm your availability and eligibility for the same.
        </div>
    </div>
    <div *ngIf="enrollCloseTime > now && disenrollCloseTime < now" class="row">
        <div class="col text-align-center pb10">
            <strong>Disenrolment for this session is closed.</strong>
        </div>
    </div>
    <div class="row">
        <div class="col pt10">
            <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="register()">
                Register
            </button>
        </div>
    </div>
</div>
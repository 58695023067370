import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/core/login/login.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { FeedbackPopupComponent } from '../feedback-popup/feedback-popup.component';
import { UnenrollPopupComponent } from '../unenroll-popup/unenroll-popup.component';
import { formatDate } from '@angular/common';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
    selector: 'your-sessions',
    templateUrl: './your-sessions.component.html',
    styleUrls: ['./your-sessions.component.scss']
})
export class YourSessionsComponent implements OnInit {

    form: any;
    upcomingList: any = [];
    pastList: any = [];
    interval: any;
    now: any;
    constructor(
        private fb: FormBuilder,
        private learnerService: LearnerService,
        private loginService: LoginService,
        private dialog: MatDialog,
        private loaderService: LoaderService
    ) {
    }

    ngOnInit() {

        this.now = formatDate(new Date(), "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        console.log("inside your sessions tab");
        this.mySessions();
    }

    mySessions() {
        this.learnerService.getMySessions().subscribe((res: any) => {
            console.log("result of my session api", res);
            localStorage.setItem('user_type', res.lnd_user_type);
            this.upcomingList = res.upcoming_sessions;
            this.pastList = res.past_sessions;

            // window.location.reload();
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        this.interval = setInterval(() => {
            this.learnerService.getMySessions().subscribe((res: any) => {
                console.log("result of my session api", res);
                localStorage.setItem('user_type', res.lnd_user_type);
                this.upcomingList = res.upcoming_sessions;
                this.pastList = res.past_sessions;
                // window.location.reload();
            },
                (err: any) => {
                    console.log(err);
                    this.loginService.logout();
                });
        }, 1000 * 60 * 30);
    }

    unenroll(data) {
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(UnenrollPopupComponent, {
            data: {
                data: data
            },
        });
    }

    rating(data) {
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(FeedbackPopupComponent, {
            data: {
                data: data
            },
        });
    }

    joinSessionByUrl(url: string) {
        this.loaderService.setLoading(true);
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 200);
        console.log("join session url------------------------->", url);
        window.open(url, "_blank");
    }
}
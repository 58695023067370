import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { QuestionBase } from './question-base';
import { CheckboxQuestion } from './checkbox';
import { RadioQuestion } from './radio';
import { TextboxQuestion } from './textbox';
import { AppraisalService } from 'src/app/core/services/appraisal.service';

@Component({
  standalone: true,
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form.scss'],
  imports: [CommonModule, ReactiveFormsModule],
})
export class DynamicFormQuestionComponent {
  @Input() question!: QuestionBase<string>;
  // question: QuestionBase<string>[] = [];
  @Input() form!: FormGroup;
  arrayValue = [];
  other = [];
  newArray = [];
  showTextField = false;
  otherLength = 0;
  buffer = 0;
  constructor(
    private appraisalService: AppraisalService
  ) {

  }

  ngOnInit() {
    this.newArray = [];
    // this.appraisalService.getSelfAppraisalTemplate().subscribe((res: any) => {
    //   // this.dataCheck = res.data;
    //   // console.log("data check===================>", this.dataCheck)

    //   let filterData = res.data;
    //   for (let i = 0; i < filterData.length; i++) {
    //     let arrmeta = [];
    //     let arrvalid = [];
    //     arrvalid.push(filterData[i].validations);
    //     filterData[i]['validation'] = arrvalid;
    //     if (filterData[i].meta.json != null) {
    //       Object.keys(filterData[i].meta.json).forEach(function (key) {
    //         console.log('Key : ' + key + ', Value : ' + filterData[i].meta.json[key])
    //         let obj = {};
    //         obj['id'] = key;
    //         obj['name'] = filterData[i].meta.json[key];
    //         arrmeta.push(obj);
    //       })
    //       filterData[i].meta.json = arrmeta;
    //       filterData[i]['newMeta'] = arrmeta;
    //     }
    //   }
    //   // this.filteredData = filterData;
    //   // this.filterData();
    //   let ques = filterData;
    //   console.log(ques, "=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")

    //   for (var i = 0; i < ques.length; i++) {
    //     if (ques[i].type == "textarea") {
    //       this.question.push(
    //         new TextboxQuestion(ques[i])
    //       )
    //       console.log(this.question, "=================this.qs====")
    //     }
    //     else if (ques[i].type == "checkbox") {
    //       this.question.push(
    //         new CheckboxQuestion(ques[i])
    //       )
    //       console.log(this.question, "=================this.qs====")
    //     }
    //     else if (ques[i].type == "radio") {
    //       this.question.push(
    //         new RadioQuestion(ques[i])
    //       )
    //       console.log(this.question, "=================this.qs====")
    //     }
    //   }
    //   console.log(this.question.sort((a, b) => a.order - b.order), "in question form [][][][][][][][][][][][][][][][][][][]");
    //   // return of(questions.sort((a, b) => a.order - b.order));

    // })
  }
  get isValid() {
    console.log(this.form.controls[this.question.title].value, "________validation");
    if (typeof (this.form.controls[this.question.title].value) == 'string') {
      if (this.form.controls[this.question.title].value.length < 100 || this.form.controls[this.question.title].value.length > 5000) {
        return false;
      }
    }
    else {
      return this.form.controls[this.question.title].valid;
    }
    console.log(this.form, "_____form___validation");

  }

  updateChkbxArray(id, isChecked, key, q_id) {
    const chkArray = <FormArray>this.form.controls[key];
    // console.log("1---", chkArray);
    if (isChecked) {
      // console.log("2", chkArray);
      chkArray.value.controls.push(new FormControl(id));
      // console.log("2", chkArray);
    }
    else {
      let idx = chkArray.value.controls.findIndex(x => x.value == id);
      chkArray.value.removeAt(idx);
      if (this.arrayValue.includes(id)) {
        const index = this.arrayValue.indexOf(id);
        this.arrayValue.splice(index, 1);
      }
    }
    for (let item of chkArray.value.controls) {
      // console.log("item-------chkArray---------------------->", item)
      if (!this.arrayValue.includes(item.value)) {
        this.arrayValue.push(item.value);
      }
    }
    if (this.arrayValue.includes('Others (select and fill in below box)')) {
      this.showTextField = true;
    }
    else {
      this.showTextField = false;
      this.otherLength = 0;
    }
  }

  valueAdded(event, key, q_id) {
    // console.log('newArray--', this.newArray);
    let rArray = this.appraisalService.getData();
    console.log(rArray);
    let inputs = event.target.value;
    let arr = [];
    arr = inputs.split(',');
    // console.log('inputs-------------->', inputs);
    // this.arrayValue = this.arrayValue.concat(arr);
    // this.arrayValue = this.removeDuplicates(this.arrayValue);
    arr = this.removeDuplicates(arr);
    this.other = arr;
    this.otherLength = this.other.length - 1;
    this.buffer = this.otherLength;
    // console.log("array values", arr);
    let newObject = { question: key, answer: arr }
    // console.log('newArray', this.newArray);
    if (rArray == undefined) {
      rArray = [];
      rArray.push(newObject);
      // console.log('newArray1', rArray);
    }
    else {
      for (let i = 0; i < rArray.length; i++) {
        if (newObject.question == rArray[i].question) {
          rArray[i].answer = arr;
          // console.log('newArray2', rArray);
        }
        else {
          rArray.push(newObject);
          // console.log('newArray3', rArray);
        }
      }
    }
    const arr1 = this.getUniqueListBy(rArray, 'question');
    // console.log(arr1, "<---- unique");
    this.appraisalService.setData(arr1);
  }

  // sendData()

  removeDuplicates(arr) {
    //   return arr.filter((item,
    //     index) => arr.indexOf(item) === index);
    // }
    arr = arr.map(item => item.trim().toLowerCase());
    let arr2 = arr.filter(function (el) {
      return (el != '' );
    });
    // Filter out duplicates
    let uniqueArr = arr2.filter((item, index) => arr2.indexOf(item) === index);
    console.log(uniqueArr);

    return uniqueArr;
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
}


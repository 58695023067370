<app-header [preApprovalHeading]="headingThree"></app-header>
<div class="table-responsive">
  <table class="table" id="emp_expense">
    <thead>
    <tr *ngIf="isShow">
      <th>Pre Approval ID</th>
      <th>Submitter</th>
      <th>Submitter ID</th>
      <th>Category</th>
      <th>City</th>
      <th>Date From</th>
      <th>Date To</th>
      <th>Purpose</th>
      <th>Reviewer</th>
    </tr>
  </thead>
     <tbody>
    <tr *ngFor="let pre of preapprovals">
      <td>{{ pre.id }}</td>
      <td>{{ pre.submitter.name }}</td>
      <td>{{pre.submitter.emp_id}}</td>
      <td>{{pre.project.company_name}}<br>
        /{{pre.project.amplus_id}}</td>
      <td>{{ pre.cities_string }}</td>
      <td>{{ pre.date_from }}</td>
      <td>{{ pre.date_to }}</td>
      <td>{{ pre.purpose }}</td>
      <td>{{ pre.reviewer.name }}</td>
    </tr>
  </tbody>
  </table>
</div>

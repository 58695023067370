import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { EmponboardingService } from '../services/emponboarding.service';
@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls:['./user-dialog.component.css']
})
export class UserDialogComponent {
  firstName = '';
  email = '';

  constructor(public dialogRef: MatDialogRef<UserDialogComponent>, private http: HttpClient,private EmponboardingService:EmponboardingService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  handleClick(): void {
    const umail = this.EmponboardingService.umail; 
    const fname = this.firstName;
    const email = this.email;

    this.EmponboardingService.createUser(umail, fname, email).subscribe(
      (res: any) => {
        console.log(res ? JSON.stringify(res) : '');
        alert(`User Added! ${this.firstName} ${this.email}`);
        this.dialogRef.close("Added!");
        window.location.reload();
      },
      (err: any) => {
        let errorMessage = "An error occurred.";
        if (err.error) {
          errorMessage = err.error.error || errorMessage;
        }
        alert(errorMessage);
      }
    );
  }
}
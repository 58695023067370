<ng-container *ngIf="!lndUserType && !loaderService.getLoading()">
    <div class="row">
        <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
            Oops! It seems you are logged out. <br>
            Please Login again to continue :)
        </div>
    </div>
    <div class="row">
        <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
            <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
        </div>
    </div>
</ng-container>
<span *ngIf="lndUserType">
    <app-header-global (notify)="expensecardshow($event)"
        (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>
<div class="row allcontent" *ngIf="lndUserType">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
        <div class="row">
            <div class="col header">
                Amplus Appraisal and Increment Process<br>
                <font size="1.8vh">Performance Evaluation Process for Appraisal Cycle: {{cycle}}</font>
            </div>
        </div>
        <div class="wrapper" style="margin-top: 10vh;">
            <div *ngIf="lndUserType" class="row">
                <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist" #myTabRef>
                        <li *ngIf="isSelf" class="nav-item" role="presentation">
                            <button class="nav-link" id="self-tab" data-bs-toggle="tab" data-bs-target="#self"
                                style="min-width: 10vw;" type="button" role="tab" aria-controls="self"
                                aria-selected="false" [ngClass]="{'active': activeTab === 'self'}">Self</button>
                        </li>
                        <!-- *ngIf="isManager" -->
                        <li *ngIf="isManager" class="nav-item" role="presentation">
                            <button class="nav-link" id="team-tab" data-bs-toggle="tab" data-bs-target="#team"
                                style="min-width: 10vw;" type="button" role="tab" aria-controls="team"
                                aria-selected="true" [ngClass]="{'active': activeTab === 'team'}">Team</button>
                        </li>
                        <li *ngIf="isDepartment" class="nav-item" role="presentation">
                            <button class="nav-link" id="cluster-tab" data-bs-toggle="tab" data-bs-target="#cluster"
                                style="min-width: 10vw;" type="button" role="tab" aria-controls="cluster"
                                aria-selected="false"
                                [ngClass]="{'active': activeTab === 'department'}">Department</button>
                        </li>
                        <li *ngIf="isBusiness" class="nav-item" role="presentation">
                            <button class="nav-link" id="business-tab" data-bs-toggle="tab" data-bs-target="#business"
                                style="min-width: 10vw;" type="button" role="tab" aria-controls="business"
                                aria-selected="false" [ngClass]="{'active': activeTab === 'business'}">Business
                                Unit</button>
                        </li>
                        <li *ngIf="isCompany" class="nav-item" role="presentation">
                            <button class="nav-link" id="company-tab" data-bs-toggle="tab" data-bs-target="#company"
                                style="min-width: 10vw;" type="button" role="tab" aria-controls="company"
                                aria-selected="false">Company</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent" #myTabContentRef>
                        <div class="tab-pane fade" id="self" role="tabpanel" aria-labelledby="self-tab"
                            [ngClass]="{'active show': activeTab === 'self'}">
                            <self-appraisal></self-appraisal>
                        </div>
                        <div class="tab-pane fade" id="team" role="tabpanel" aria-labelledby="team-tab"
                            [ngClass]="{'active show': activeTab === 'team'}">
                            <team-appraisal></team-appraisal>
                        </div>
                        <div class="tab-pane fade" id="cluster" role="tabpanel" aria-labelledby="cluster-tab"
                            [ngClass]="{'active show': activeTab === 'department'}">
                            <cluster-appraisal></cluster-appraisal>
                        </div>
                        <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab"
                            [ngClass]="{'active show': activeTab === 'business'}">
                            <business-appraisal></business-appraisal>
                        </div>
                        <div class="tab-pane fade" id="company" role="tabpanel" aria-labelledby="company-tab">
                            <company-appraisal></company-appraisal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import {prefix} from '../../environments/utils';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmponboardingService } from '../../../employee-onboarding/services/emponboarding.service';
import { ConfirmationDialogComponent } from 'src/app/components/expense-manager/confirmation-dialog/confirmation-dialog.component';
import { LearnerService } from 'src/app/core/services/learner.service';
interface FileOption {
  name: string;
  format: string;
  size: string;
  icon: string;
  selected: boolean;
  acceptedTypes: string;
  selectedFile?: { name: string; type: string; size: number };
  error?: string;
  
}

@Component({
  selector: 'app-verify-dialog',
  templateUrl: './verify-dialog.component.html',
 
  
})
export class VerifyDialogComponent2 {
  dialogForm: FormGroup;
  @Output() profileReopened = new EventEmitter<void>();
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<VerifyDialogComponent2>,
    private http: HttpClient,
    private EmpOnboardingService:EmponboardingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogForm = this.fb.group({
      // Define your form controls here
      remarks: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.dialogForm.valid) {
      const { umail, email } = this.data;
      const { remarks } = this.dialogForm.value;

      this.EmpOnboardingService.reopenProfile(umail, email, remarks).subscribe((response: any) => {
        alert(response.message);
        this.profileReopened.emit();
      });

      this.dialogRef.close(this.dialogForm.value);
    }
  }
  
  reopenRequest(){}
  closeDialog() {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-verif',
  templateUrl: './verif.component.html',
  styleUrls: ['./verif.component.css']
})
export class VerifyComponent2 implements OnInit {
  files: FileOption[] = [
    { name: 'photograph', format: 'JPG & PNG', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.jpg,.png' },
    { name: 'aadhar_card', format: 'JPG & PNG', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.jpg,.png' },
    { name: 'pan_card', format: 'JPG & PNG', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.jpg,.png' },
    { name: 'marksheet', format: 'PDF', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.pdf' },
    { name: 'cv', format: 'PDF', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.pdf' },
    { name: 'relieving_letter', format: 'PDF', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.pdf' },
    { name: 'cancelled_cheque', format: 'PDF', size: '500 KB', icon: 'assets/blackicon.svg', selected: false, acceptedTypes: '.pdf' }
  ];
  fb = new FormBuilder();
  umail: any;
  email: any;
  status:boolean=false;
  reopenstatus:boolean=false;
  personalInfoForm: FormGroup;
  uploadedDocsFromDb: FormGroup;
  initials = ['Mr', 'Ms', 'Mrs'];
  dialogRef: any;
  showcard: any;
  showhr: any;
  showPOA: any;
  showAmdoc: any;
  documents_uploaded:boolean;


  
  constructor(private http: HttpClient, private route: ActivatedRoute, private dialog: MatDialog,private EmpOnboardingService:EmponboardingService,  private LearnerService:LearnerService) { 
    this.uploadedDocsFromDb = this.fb.group({
      photograph: [''],
      aadhar_card: [''],
      pan_card: [''],
      marksheet: [''],
      cv: [''],
      relieving_letter: [''],
      cancelled_cheque: ['']

    })

    this.personalInfoForm = this.fb.group({
      initials: ['', [Validators.required, Validators.minLength(2)]],
      fname: ['', Validators.required],
      mname: [''],
      lname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      father_name: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      spouse_name: ['', [Validators.pattern(/^[a-zA-Z\s]*$/)]],
      blood_group: [''],
      uan_number: [''],
      permanent_address: [''],

      emergency_contact : ['',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      prev_org: ['', [Validators.pattern(/^[a-zA-Z0-9\s]*$/)]],
      // add marital status
      aadhar_no: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern('^[0-9]*$')]],
      pan_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      marital_status: ['', Validators.required],
      city: ['', Validators.required],
      postal_code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      address: ['', Validators.required],
      personal_email: ['', [Validators.required, Validators.email]],
      mobile_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      alternate_mobile_no: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      qualification_ug: ['', Validators.required],
      qualification_pg: [''],
      total_experience: ['', Validators.required],
      solar_experience: ['', Validators.required],
      account_holder_name:['',Validators.required],
      account_number:['',Validators.required],
      bank_name:['',Validators.required],
      ifsc_code:['',Validators.required],
    })  
    this.personalInfoForm.disable();
   
  }

  ngOnInit() {
    // this.umail = this.route.snapshot.queryParamMap.get('umail');
    this.umail=this.EmpOnboardingService.umail;
    this.email = this.route.snapshot.queryParamMap.get('email');
    if (this.umail && this.email) {
      this.EmpOnboardingService.getProfile(this.umail, this.email).subscribe((response: any) => {
        this.personalInfoForm.patchValue(response);
        this.uploadedDocsFromDb.patchValue(response);
        this.documents_uploaded = response.documents_uploaded ? response.documents_uploaded :false;
        this.status = response.status === 'approved' ? true : false;
        this.reopenstatus = response.status === 'user_pending' ? true : false;
      });
    }
  }
     
  openApprove(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.approveAll();
      }
    });
  }

  
  approveAll(): void {
    if (this.umail && this.email) {
      this.EmpOnboardingService.approveProfile(this.umail, this.email).subscribe(
        (response: any) => {
          //console.log(response);
          alert(response.message);
          this.ngOnInit();
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  reopenForUser(){
    this.dialogRef = this.dialog.open(VerifyDialogComponent2, {
      // width: '400px',
      // height: '300px',
       panelClass: 'verifcustomclass',
      data: { umail: this.umail, email: this.email }
    });

    this.dialogRef.componentInstance.profileReopened.subscribe(() => {
      this.ngOnInit(); 
    });

  }

  onClickDocLink(docName: string) {
    this.LearnerService.getViewDownloadPdf(docName)
      .subscribe({
        next: (response: any) => {
          window.open(response.presigned_url, '_blank'); 
        },
        error: (error) => {
          console.log('could not fetch document');
        }
      });
  }

  getName(name: string): string {
    switch (name) {
      case 'photograph': {
        return 'Photograph';
      }
      case 'marksheet': {
        return 'Marksheet';
      }
      case 'aadhar_card': {
        return 'Aadhar Card'
      }
      case 'pan_card': {
        return 'Pan Card'
      }
      case 'cv': {
        return 'CV'
      }
      case 'relieving_letter': {
        return 'Relieving Letter'
      }
      case 'cancelled_cheque': {
        return 'Cancelled Cheque'
      }
      default: {
        return '';
      }
    }
  }
}


import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DynamicFormQuestionComponent } from './dynamic-form-question.component';

import { QuestionBase } from './question-base';
import { QuestionControlService } from './question-control.service';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { CheckboxQuestion } from './checkbox';
import { RadioQuestion } from './radio';
import { TextboxQuestion } from './textbox';
import { MatDialog } from '@angular/material/dialog';
import { ActionPopupComponent } from '../action/action.component';
import { first } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService],
  imports: [CommonModule, DynamicFormQuestionComponent, ReactiveFormsModule, AsyncPipe],
})
export class DynamicFormComponent {
  questions: QuestionBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = [];
  valid = true;

  constructor(
    private appraisalService: AppraisalService,
    private toast: ToastrService,
    private qs: QuestionControlService,
    private dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.questions, "=================this.qs====")
    this.questions = [];
    this.appraisalService.getSelfAppraisalTemplate().subscribe((res: any) => {
      // this.dataCheck = res.data;
      // console.log("data check===================>", this.dataCheck)

      let filterData = res.data.self_appraisal_form;
      for (let i = 0; i < filterData.length; i++) {
        let arrmeta = [];
        let arrvalid = [];
        arrvalid.push(filterData[i].validations);
        filterData[i]['validation'] = arrvalid;
        if (filterData[i].meta.json != null) {
          Object.keys(filterData[i].meta.json).forEach(function (key) {
            // console.log('Key : ' + key + ', Value : ' + filterData[i].meta.json[key])
            let obj = {};
            obj['id'] = key;
            obj['name'] = filterData[i].meta.json[key];
            arrmeta.push(obj);
          })
          filterData[i].meta.json = arrmeta;
          filterData[i]['newMeta'] = arrmeta;
        }
      }
      // this.filteredData = filterData;
      // this.filterData();
      let ques = filterData;
      console.log(ques, "=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")

      for (var i = 0; i < ques.length; i++) {
        if (ques[i].type == "textarea") {
          this.questions.push(
            new TextboxQuestion(ques[i])
          )
          // console.log(this.questions, "=================this.qs====")
        }
        else if (ques[i].type == "checkbox") {
          this.questions.push(
            new CheckboxQuestion(ques[i])
          )
          // console.log(this.questions, "=================this.qs====")
        }
        else if (ques[i].type == "radio") {
          this.questions.push(
            new RadioQuestion(ques[i])
          )
          // console.log(this.questions, "=================this.qs====")
        }
      }
      // console.log(this.questions.sort((a, b) => a.order - b.order), "[][][][][][][][][][][][][][][][][][][]");
      // return of(questions.sort((a, b) => a.order - b.order));
      this.form = this.qs.toFormGroup(this.questions as QuestionBase<string>[]);
      console.log("form------", this.form)
    })
  }

  get isValid() {
    for (let j = 0; j < this.questions.length; j++) {
      if (typeof (this.form.controls[this.questions[j].title].value) == 'string') {
        if (this.form.controls[this.questions[j].title].value.length < 100 || this.form.controls[this.questions[j].title].value.length > 5000) {
          return false;
        }
      }
      else {
        return this.form.valid;
      }
    }
  }

  onSubmit() {
    console.log(this.form);
    console.log(this.appraisalService.getData());
    this.payLoad = [];
    console.log(this.questions, "<1234567890-087654234567890987654323456789")
    for (let j = 0; j < this.questions.length; j++) {
      if (this.questions[j].type == 'checkbox') {
        let skillsForm = this.form.controls[this.questions[j].title].value.controls;
        let skillsArray = [];
        for (let item of skillsForm) {
          console.log("item----------------------------->", item.value)
          skillsArray.push(item.value);
        }
        let arrData = this.appraisalService.getData();
        console.log("skillsArray----includes------------------------->", skillsArray.includes('Others (select and fill in below box)'))
        if (skillsArray.includes('Others (select and fill in below box)') && arrData) {
          console.log(skillsArray, "inside for loop");
          skillsArray.splice(skillsArray.indexOf('Others (select and fill in below box)'), 1);
          console.log(skillsArray, "inside for loop2-----");
          for (let i = 0; i < arrData.length; i++) {
            if (arrData[i].question == this.questions[j].title) {
              this.form.value[this.questions[j].title] = skillsArray.concat(arrData[i].answer);
              // this.form.value[this.questions[j].title] = arrData;
            }
          }
        }
        else {
          if (skillsArray.includes('Others (select and fill in below box)')){
            skillsArray.splice(skillsArray.indexOf('Others (select and fill in below box)'), 1);
          }
          this.form.value[this.questions[j].title] = skillsArray;
        }

        // if (skillsArray.length < this.questions[j].validation[0].min && skillsArray.length > this.questions[j].validation[0].max) {
        //   this.toast.warning('please select required checkboxes!!!');
        // }
      }
      else if (this.questions[j].type == 'radio') {
        let itemVal = this.form.controls[this.questions[j].title].value;
        let skillsArray = [];
        skillsArray.push(itemVal);
        this.form.value[this.questions[j].title] = skillsArray;
        if (skillsArray.length < this.questions[j].validation[0].min && skillsArray.length > this.questions[j].validation[0].max) {
          this.toast.warning('please select required radio!!!');
        }

      }
      else if (this.questions[j].type == 'textarea') {
        let itemVal = this.form.controls[this.questions[j].title].value
        if (itemVal.length >= this.questions[j].validation[0].min && itemVal.length <= this.questions[j].validation[0].max) {
          this.form.value[this.questions[j].title] = itemVal;
        }
        else if (itemVal.length < this.questions[j].validation[0].min) {
          this.toast.warning('please enter minimum ' + this.questions[j].validation[0].min + ' characters!!!');
        }
        else if (itemVal.length < this.questions[j].validation[0].max) {
          this.toast.warning('please enter maximum ' + this.questions[j].validation[0].max + ' characters!!!');
        }
        else {
          this.toast.warning('please enter characters between ' + this.questions[j].validation[0].min + ' - ' + this.questions[j].validation[0].max);
        }
      }
    }
    console.log(this.form.value, "<-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-")
    const keys = Object.keys(this.form.value);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      for (let j = 0; j < this.questions.length; j++) {
        if (this.questions[j].title == key) {
          let obj = {};
          obj['id'] = this.questions[j].id;
          obj['title'] = this.questions[j].title;
          obj['value'] = this.form.value[key];
          this.payLoad.push(obj);
        }
      }
    }
    console.log("form payload------", this.payLoad);
    let dialogRef = this.dialog.open(ActionPopupComponent, {
      width: "300px",
      data: {
        buttontext: "Submit",
        desc: "You will not be able to make any changes once submitted, Do you want to Submit Self-Appraisal?",
      },
    });
    const sub = dialogRef.componentInstance.option.subscribe((data) => {
      if (data) {
        sub.unsubscribe();
        if (data == "success") {
          this.appraisalService.createSelfappraisal(this.payLoad).subscribe((res: any) => {
            console.log(res);
            this.toast.success('Hurray!!! Your Self-Appraisal is submitted Successfully!!!')
            setTimeout(() => {
              window.location.reload();
            }, 1000)

          },
            (error: any) => {
              console.log(error);
              // this.toast.error(JSON.stringify(error.error.errors));
              var myObj = JSON.parse(JSON.stringify(error.error.errors));
              var firstKey = Object.keys(myObj)[0];
              console.log(firstKey);
              for (let i = 0; i < Object.keys(myObj).length; i++) {
                var firstKey = Object.keys(myObj)[i];
                console.log(firstKey + ' : ' + myObj[firstKey]);
                this.toast.error(firstKey + ' : ' + myObj[firstKey]);
              }
            })
        }
      }
    });

  }

}

import { Component } from '@angular/core';
import { environment } from './../environments/environment';

@Component({
  selector: "app-root",
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
  ) {
    console.log(environment.production); // Logs false for default environment
    this.disableConsole();
  }
  
  disableConsole() {
    if (environment.production) {
      window.console.log = function () { }; // disable any console.log debugging statements in production mode
      // window.console.error = function () { };
    }
  }
  title = 'learning-portal';

}
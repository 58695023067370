<div class="manage-proposal-wrapper" style="width: 1100px;">
  <div class="row">
    <div class="col text-heading-bold">
      Enrollment List | {{session.name}}
    </div>
    <div class="col text-align-end">
      <i class="fa fa-times cross" (click)="cancel()"></i>
    </div>
  </div>
  <div class="row">
    <div class="col text-decor">
      <i class="fa fa-map-marker" aria-hidden="true"></i> {{session.location}}
    </div>
  </div>
  <div class="row">
    <div class="col text-decor">
      <i class="fa fa-calendar-o" aria-hidden="true"></i> {{session.start_time | date: 'MMM dd, yyyy'}} |
      {{session.start_time | date: 'HH:mm aa'}} - {{session.end_time | date: 'HH:mm aa'}}
    </div>
  </div>
  <div class="row">
    <div class="col-4 text-decor">
      <i class="fa fa-file-text-o" aria-hidden="true"></i>
      {{session.no_of_enrollment}}/{{session.capacity}} Spots filled
    </div>
    <div class="col list-link text-align-end" (click)="exportAsXLSX(session.id, session.name, session.start_time)">
      <i class="fa fa-cloud-download" aria-hidden="true"></i> Click here to download
      enrollment/participant list
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="enrolled-tab" data-bs-toggle="tab" data-bs-target="#enrolled"
            type="button" role="tab" aria-controls="enrolled" aria-selected="true">Enrolled Participants</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="cancelled-tab" data-bs-toggle="tab" data-bs-target="#cancelled" type="button"
            role="tab" aria-controls="cancelled" aria-selected="false">Cancelled Enrollments</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="enrolled" role="tabpanel" aria-labelledby="enrolled-tab">
          <div *ngIf="enrollList.length > 0" class="col">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Employee Code</th>
                  <th scope="col">Role</th>
                  <th scope="col">Department</th>
                  <th scope="col">Email ID</th>
                  <th scope="col" *ngIf="session.status!='cancelled' && session.start_time < now">Attendance</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of enrollList">
                  <td>{{item.employee_info.name}}</td>
                  <td>{{item.employee_info.emp_id}}</td>
                  <td>{{item.employee_info.job_title}}</td>
                  <td>{{item.employee_info.department}}</td>
                  <td>{{item.employee_info.email}}</td>
                  <td *ngIf="item.status == 'absent' && session.status !='cancelled' && session.start_time < now" class="absent"
                    (click)="markPresent(item.employee_info.emp_id)"><i class="fa fa-window-close-o"
                      aria-hidden="true"></i></td>
                  <td *ngIf="item.status == 'enrolled' && session.status !='cancelled' && session.start_time < now" class="enrolled"
                    (click)="markAbsent(item.employee_info.emp_id)">
                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="enrollList.length <=0" class="col no-data">
            No Data Available :(
          </div>
        </div>
        <div class="tab-pane fade" id="cancelled" role="tabpanel" aria-labelledby="cancelled-tab">
          <div *ngIf="disenrollList.length >0" class="col">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Employee Code</th>
                  <th scope="col">Role</th>
                  <th scope="col">Department</th>
                  <th scope="col">Email ID</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of disenrollList">
                  <td>{{item.employee_info.name}}</td>
                  <td>{{item.employee_info.emp_id}}</td>
                  <td>{{item.employee_info.job_title}}</td>
                  <td>{{item.employee_info.department}}</td>
                  <td>{{item.employee_info.email}}</td>
                  <!-- <td class="absent"><i class="fa fa-window-close-o" aria-hidden="true"></i></td> -->
                  <!-- <td class="present"><i class="fa fa-check-square-o" aria-hidden="true"></i></td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="disenrollList.length <=0" class="col no-data">
            No Data Available :(
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
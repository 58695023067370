<div [formGroup]="form">
  <div class="row">
    <div class="col q-label" [attr.for]="question.id">{{question.title}}<br>
      <font size="1.8vh" color="#8c8c8c">{{question.description}}</font>
    </div>
  </div>
  <div *ngIf="question.hint" class="row">
    <div class="col subtext">
      <font color="red">*</font>{{question.hint}}<sub> </sub>
    </div>
  </div>
  <!-- <label class="q-label" [attr.for]="question.id">{{question.title}}</label> -->

  <div [ngSwitch]="question.type">

    <div *ngSwitchCase="'textbox'">
      <textarea class="answers" [formControlName]="question.title" [id]="question.id"></textarea>
      <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
        *ngIf="form.value[question.title]?.length < question.validation[0].min && form.value[question.title]?.length > 0">
        Please enter more than {{question.validation[0].min}} characters.
      </span>
      <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
        *ngIf="form.value[question.title]?.length > question.validation[0].max">
        Please enter less than {{question.validation[0].max}} characters.
      </span>
    </div>

    <div *ngSwitchCase="'radio'" style="padding: 1vh 3vh;">
      <p *ngFor='let item of question.newMeta; let i = index' class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="{{question.id}}_inlineRadio_{{i}}"
          [formControlName]="question.title" value={{item.name}}>
        <label class="form-check-label" for="{{question.id}}_inlineRadio_{{i}}">{{item.name}}</label>
      </p>
    </div>

    <div *ngSwitchCase="'checkbox'" style="padding: 1vh 3vh;">
      <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
        *ngIf=" (arrayValue.length + otherLength) < question.validation[0].min && arrayValue.length >0">
        Please select minimum {{question.validation[0].min}} items. 
      </span>
      <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
        *ngIf=" (arrayValue.length + otherLength) > question.validation[0].max">
        Please select only {{question.validation[0].max}} items.
      </span>
      <section class="checkbox-section" *ngIf="question.newMeta  && question.newMeta.length > 0">
        <p *ngFor="let item of question.newMeta ; let i = index" [formArrayName]="question.title" [id]="question.id"
          class="form-check">
          <input class="form-check-input" type="checkbox" id="{{question.id}}_inlineCheckbox_{{i}}"
            (change)="updateChkbxArray(item.name, $event.target.checked, question.title, question)" [value]="item.name">
          <!-- <input *ngIf="arrayValue.length == 4" class="form-check-input" type="checkbox" id="{{question.id}}_inlineCheckbox_{{i}}"
            (change)="updateChkbxArray(item.id, $event.target.checked, question.title, question.id)" [value]="item.id"> -->
          <label class="form-check-label" for="{{question.id}}_inlineCheckbox_{{i}}">{{item.name}}</label>
        </p>
        <span  *ngIf="showTextField"  style="color:#555; padding: 0.5vh 3vh; font-size: 1.5vh;">
        Please enter comma seperated items. (i.e. skill 1, skill 2, skill 3)
      </span>
      <span  *ngIf="showTextField && other.length == 0"  style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;">
        Please enter skills!!!
      </span>
        <textarea *ngIf="showTextField" class="answers" [formArrayName]="question.title" id="{{question.id}}_text"
          (change)="valueAdded($event, question.title, question)" [value]="other"></textarea>
      </section>
    </div>
    <!-- <input *ngSwitchCase="'dropdown'" [formControlName]="question.title" [id]="question.id" [type]="question.type"
            value=""> -->
    <!-- <select [id]="question.id" *ngSwitchCase="'checkbox'" [formControlName]="question.title">
              <option *ngFor="let opt of question.meta" [value]="opt.id">{{opt.name}}</option>
            </select> -->
  </div>



  <!-- <div class="errorMessage" *ngIf="!isValid">{{question.title}} is required</div> -->
</div>
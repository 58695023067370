import { Component,Input,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimserviceService } from '../service/claimservice.service';
@Component({
  selector: 'app-pre-aproved-claim',
  templateUrl: './pre-aproved-claim.component.html',
  styleUrls: ['./pre-aproved-claim.component.scss']
})
export class PreAprovedClaimComponent implements OnInit {
   headingThree:string="Pre Approval Details"
   
   @Input()
   preapprovals:any;
   @Input()
   isShow :any;
  constructor(private route:ActivatedRoute,private claimservice:ClaimserviceService){}

  ngOnInit(){
  
  }

}

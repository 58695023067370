import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input()
   allClaimHeading:string='';
   @Input()
  claimDetailsHeading:string='';
  @Input()
   preApprovalHeading:string='';
  @Input()
  expenseDetailsHeading:string='';
}

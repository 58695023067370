<div class="manage-proposal-wrapper" style="width: 450px;">
    <div *ngIf="dataPresent" class="row">
        <div class="col text-heading-popup">
            <strong>{{title}}</strong> | {{modality}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col check-col text-align-center pb10">
           {{desc}}
        </div>
    </div>
    <div class="row">
        <div class="col pb10 pt10">
            <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="option_yes()">
                {{buttontext}}
            </button>
        </div>
        <div class="col pb10 pt10">
            <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="cancel()">
                Go Back
            </button>
        </div>
    </div>
    
</div>

<span *ngIf="lndUserType">
    <app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>
<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard"  [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>
     </div>
    <div class="col sm-10  contentsection">
        <div *ngIf="lndUserType" class="row p1rem">
            <p>Circular / Forms</p>
            <!-- <div class="col user-header">
                Hello {{name}}!
            </div>
         -->
        </div>
        <div class="wrapper">
            <div *ngFor="let document of documents">
                <div class="row">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col text-heading">
                                                <strong>{{document.title}} </strong>  
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col text-decor">
                                                {{document.description}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <div class="col p5 view-btn">
                                                <button type="button" class="btn btn-outline-primary" (click)="viewDownloadPdf(document.file)">View / Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            </div>
        
    </div>
</div>
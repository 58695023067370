<div class="top_row">
    <div class="menu">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <p>{{allClaimHeading}}</p>
    <p>{{claimDetailsHeading}}</p>
    <p>{{preApprovalHeading}}</p>
    <p>{{expenseDetailsHeading}}</p>
    
    
   
  </div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { TemplateServiceService } from './service/template-service.service'
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-template-module',
  templateUrl: './template-module.component.html',
  styleUrls: ['./template-module.component.css']
})


export class TemplateModuleComponent implements OnInit {
  showcard: boolean;
  showPOA: boolean;
  showAmdoc: boolean
  docRes: any;
  service_id: any;
  filter: any;
  count: any;
  private subscription: Subscription;


  constructor(private router: Router, private route: ActivatedRoute, private templateservice: TemplateServiceService) {
    this.subscription = this.templateservice.currentCount.subscribe(count => {
      this.count = count;
      this.route.queryParams.subscribe(params => {
        this.filter = params['filter'];
        this.updateLinkText(this.filter, this.count)

      });

    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.service_id = params['service_id'];
      this.filter = params['filter'];
      // this.count=params['count']
    });


  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  poashow(showpoa) {
    this.showPOA = showpoa;
  }

  getDocument(docres: any) {
    this.docRes = docres;
  }
  amdocshow(showamdoc) {
    this.showAmdoc = showamdoc;
  }


  logTemplateName(filter: any) {
    this.router.navigate(['/template/view_template'], {
      queryParams: {
        service_id: this.service_id,
        filter: filter
      }
    }).then(() => {
      // Reload the page after navigation
      window.location.reload();
    });
  }

  navigate() {
    const url = `/template/save_template?service_id=${this.service_id}&action=new&filter=addNew`;
    window.location.href = url;
  }

  updateLinkText(filter: string, count: number) {
    if (count > 0) {
      if (filter == 'total') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `Total (${count})`


      } else if (filter == 'pending') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `About to expire (${count})`

      }
      else if (filter == 'expired') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `Expired (${count})`

      }
    }



  }



}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { TemplateServiceService } from '../service/template-service.service'


@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.css']
})

export class ViewTemplateComponent implements OnInit {

  templateValues: any[] = [];
  service_id: any;
  filter: any;
  visible_to: any[] = [];
  searchTerm: string = '';
  responseData: any[] = [];
  isValue: boolean = true;
  noData: boolean = false;
  insuranceBtn: boolean = false;
  count: any;

  constructor(private router: Router, private templateservice: TemplateServiceService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.service_id = params['service_id'];
      this.filter = params['filter']

     

      const filterElement = document.getElementById(this.filter);
      if (filterElement) {
        filterElement.style.color = '#07527D';
        filterElement.style.fontWeight = '600';
      }

      this.templateservice.getTemplate(this.service_id).subscribe((response: any) => {

        let res = response.data
        // for insurance tracker 
        const fields = res.fields;

        if (res.name == 'Insurance Tracker') {
          this.insuranceBtn = true;
        }
        else {
          this.insuranceBtn = false;
        }

        if (Array.isArray(fields)) {
          fields.forEach(field => {
            const visibleTo = field.visible_to;
            const isRequired = field.required
            if (visibleTo == 1) {
              this.visible_to.push({ id: field.id, title: field.title });

            }

          });
        }
        // Nested API call
        this.templateservice.getTemplateValue(null, this.service_id, 'false', this.filter).subscribe((response: any) => {
          let status = response.status
          let data = response.data
          if (status == 200 && data.length > 0) {
            let res = response.data
            this.templateValues = res;
            this.count = response.count
            this.templateservice.updateCount(this.count);
            this.filterData();

          }
          else {
            this.isValue = false;
            this.noData = true;


          }


        });




      });



    });

  }

  handleCardClick(event: MouseEvent, id: any) {
    const queryParams = { service_id: this.service_id, id: id, action: 'view', filter: this.filter, count: this.count };

    const urlTree = this.router.createUrlTree(['template', 'save_template'], { queryParams: queryParams });
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');

  }

  handleEditButtonClick(event: MouseEvent, id: any) {

    event.stopPropagation();
    // get the name of the template also
    // const name = "Insurance_Tracker"
    const queryParams = { service_id: this.service_id, id: id, action: 'edit', filter: this.filter, count: this.count };

    const urlTree = this.router.createUrlTree(['template', 'save_template'], { queryParams: queryParams });
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');

  }

  handleDeleteButtonClick(event: MouseEvent, id: any) {
    event.stopPropagation();
    const queryParams = { service_id: this.service_id, id: id, action: 'delete' };

    const urlTree = this.router.createUrlTree(['template', 'save_template'], { queryParams: queryParams });
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');

  }

  navigate() {
    const url = `/template/save_template?service_id=${this.service_id}&action=new&filter=addNew`;
    // window.open(url, '_blank');
    window.open(url);

  }

  filterData() {
    if (!this.searchTerm.trim()) {
      this.responseData = this.templateValues;
      // let addBtn = document.getElementById('addValueBtn') as HTMLInputElement

      // if (this.responseData.length > 6) {
      //   addBtn.style.bottom = '50px'
      // }
      // else {
      //   addBtn.style.bottom = '5px'

      // }

    } else {
      const searchTermLowerCase = this.searchTerm.toLowerCase();

      this.responseData = this.templateValues.filter(item => {
        return (
          String(item.id).toLowerCase().includes(searchTermLowerCase) ||
          item.values['6'].toLowerCase().includes(searchTermLowerCase) ||
          item.values['5'].toLowerCase().includes(searchTermLowerCase)
        );
      });
    }
    this.responseData.sort((a, b) => b.id - a.id);
  }


  onSearchChange() {
    this.filterData();
  }

  // downloadFile(event: MouseEvent, id: any) {

  //   event.stopPropagation();
  //   this.templateservice.getTemplateValue(id, this.service_id,'true').subscribe((response: any) => {
  //     console.log('the response is ',response.urls)

  //   })

  //   }

  downloadFile(event: MouseEvent, id: any) {
    event.stopPropagation();
    this.templateservice.getTemplateValue(id, this.service_id, 'true', null).subscribe((response: any) => {

      let urls = response.urls;
      this.downloadAll(urls)
    });
  }

  downloadAll(urls) {
    urls.forEach((url) => {
      let iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);


      iframe.onload = function () {
        // setTimeout(() => {x
        document.body.removeChild(iframe);

        // }, 1000);
      };
    });
  }











}

















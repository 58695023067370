import { Component, Input, Output, EventEmitter ,OnInit} from '@angular/core';
import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {TemplateServiceService} from 'src/app/components/template-module/service/template-service.service';


export interface FileInfo {
  id: any;
  file: File;
}

@Component({
  selector: 'app-input-multiple-file',
  templateUrl: './input-multiple-file.component.html',
  styleUrls: ['./input-multiple-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultipleFileComponent),
      multi: true
    }
  ]
})
export class InputMultipleFileComponent implements OnInit {

  showRemoveButton=true;
  files: FileInfo[] = [];
  uploadedFileUrl:any;
  @Input() heading: string;
  @Input() hint: string;
  @Input() accept: string; 
  @Input() isDisabled: boolean;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  @Input() id: string='id'
  @Input() isRequired: boolean;



  constructor(private templateservice: TemplateServiceService) { }

  ngOnInit(): void {
  }

  triggerFileInput(): void {
    const filebtn=document.getElementById(this.id) as HTMLInputElement
    filebtn.click()
  }

  // removeFile(file: File) {
  //   const index = this.files.indexOf(file);
  //   if (index !== -1) {
  //     this.files.splice(index, 1);
  //   }
  // }

  removeFile(fileInfo: FileInfo): void {
    const index = this.files.findIndex(f => f.file === fileInfo.file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
    this.templateservice.setFiles(this.files); 
  }


  removeSavedFile(savedFile: any){
    const index = this.uploadedFileUrl.indexOf(savedFile);
    if (index !== -1) {
      this.uploadedFileUrl.splice(index, 1);
    }
  }

  onFileSelected(event: any, id: any): void {
    console.log('the id is', id);
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.files.push({
        id: id,
        file: selectedFiles.item(i)!
      });
    }
    this.templateservice.setFiles(this.files);
    console.log(this.files);
  }

}

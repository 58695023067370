<div class="manage-proposal-wrapper" style="width: 1000px;">
    <div class="row">
        <div class="col feedback-heading">
            Feedbacks
        </div>
        <div class="col export text-align-end" (click)="exportAsXLSX(id, title, date)">
            <i class="fa fa-cloud-upload" aria-hidden="true"></i> Export Feedback List
        </div>
    </div>
    <div *ngIf="dataPresent" class="card">
        <div class="card-body">
            <div class="row text-heading">
                <div class="col">
                    <strong>{{title}}</strong> | {{modality}}
                </div>
            </div>
            <div class="row text-decor">
                <div class="col">
                    <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
                </div>
            </div>
            <div class="row text-decor">
                <div class="col">
                    <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let item of feedbackList; let i = index" class="row" style="padding: 0px 10px;">
        <div class="col">
            <div class="row name">
                <div class="col fit">
                    {{item.employee.name}}
                </div>
                <div class="col">
                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
                        <li [ngClass]="{'selected': (star <= item.rating)}">
                            <i class="fa fa-star"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row email">
                <div class="col">
                    {{item.created_at}} | {{item.employee.email}}
                </div>
            </div>
            <div class="row comment">
                <div class="col">
                    {{item.comment}}
                </div>
            </div>
        </div>
        <!-- <div *ngIf="i%2==1" class="col-md-6 offset-md-6" style="margin-top: -80px;">
            <div class="row name">
                <div class="col">
                    {{item.employee.name}}
                </div>
                <div class="col">
                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
                        <li [ngClass]="{'selected': (star <= item.rating)}">
                            <i class="fa fa-star"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row email">
                <div class="col">
                    {{item.created_at}} | {{item.employee.email}}
                </div>
            </div>
            <div class="row comment">
                <div class="col">
                    {{item.comment}}
                </div>
            </div>
        </div> -->
    </div>
    <div *ngIf="feedbackList.length <=0" class="col no-data">
        No Data Available :(
      </div>
    <br />
</div>
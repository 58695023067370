import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { access } from "fs";

@Injectable({
    providedIn: "root"
})
export class AdminService {
    baseUrl = environment.url;
    draftSessionUrl = 'lnd/draft-sessions/';
    sessionUrl = 'lnd/sessions/';
    accessToken = localStorage.getItem('accessToken');

    constructor(
        private http: HttpClient
    ) {
        console.log("obj created");
    }

    getDraftSessions() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.draftSessionUrl, { headers });
    }

    createNewSession(data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.post(this.baseUrl + this.sessionUrl, data, { headers });
    }

    modifyNewSession(id, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.put(this.baseUrl + this.sessionUrl + id + '/', data, { headers });
    }
    getSession(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.sessionUrl + id + '/', { headers });
    }

    modifySession(id, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.put(this.baseUrl + this.sessionUrl + id + '/', data, { headers });
    }

    deleteSession(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.delete(this.baseUrl + this.sessionUrl + id + '/', { headers });
    }

    getEnrollmentList(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.sessionUrl + id + '/enrollments/', { headers });
    }

    markAttendance(id, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.put(this.baseUrl + this.sessionUrl + id + '/enrollments/', data, { headers });
    }

    allfeedbacks(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.sessionUrl + id + '/feedback/', { headers });
    }
}
import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { PoaserviceService } from "../service/poaservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { RouterModule, Routes } from '@angular/router';
import { LearnerService } from 'src/app/core/services/learner.service';




@Component({
  selector: 'app-main-poa',
  templateUrl: './main-poa.component.html',
  styleUrls: ['./main-poa.component.css']
})


export class MainPoaComponent implements OnInit {
  showcard: boolean;
  docRes: any;
  formdata: any;
  poaPermission: any;
  employeepermissions: any
  showPOA: boolean;
  showAmdoc: any;

  constructor(private poaservice: PoaserviceService, private router: Router,
    private route: ActivatedRoute, private learnerService: LearnerService,
  ) { }

  ngOnInit(): void {
    this.learnerService.getEmployeeDetails().subscribe(
      (res: any) => {
        this.employeepermissions = res['employee_permissions'];
        this.poaPermission = this.employeepermissions?.poa_permission || '';

        if (this.poaPermission === 'hod' || this.poaPermission.includes('legal') || this.poaPermission.includes('ceo')) {
          // You should ideally avoid direct DOM manipulation
          const raiseElement = document.getElementById('raise');
          if (raiseElement) {
            raiseElement.style.display = 'none';
          }
        } else if (this.poaPermission.includes('view_all')) {
          const viewAllPoaElement = document.getElementById('viewallpoa');
          if (viewAllPoaElement) {
            viewAllPoaElement.style.display = 'block';
          }
        } else {
          const raiseElement = document.getElementById('raise');
          if (raiseElement) {
            raiseElement.style.display = 'block';
          }
        }
      },
      (error: any) => {
        // Handle error here
        console.error('Error fetching employee details:', error);
      }
    );
  }


  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  poashow(showpoa) {
    this.showPOA = showpoa;
  }
  amdocshow(showamdoc) {
    this.showAmdoc = showamdoc;
  }

  getDocument(docres: any) {
    this.docRes = docres;
  }

  _viewPoa(value: any, elementID: string) {

    try {

      this.router.navigate(['poa', 'view_poa'], {
        queryParams: {
          value: value,
        }
      });

    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }

  _raisePoa(elementID: string) {
    try {

      this.router.navigate(['poa', 'raise_poa'], {
        queryParams: {
          value: elementID
        }
      });

    } catch (error) {
      console.error("An error occurred:", error);
      throw new Error("Something went wrong. Please try again later.");
    }
  }
}


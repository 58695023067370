<div class="title">
  <h1 mat-dialog-title>Please input your remarks for reopening</h1>
</div>

<div class="reopendialog">
  <div mat-dialog-content>
    <form [formGroup]="dialogForm" (ngSubmit)="onSubmit()">
      <div class="full-width row">
        <div class="col-sm-12">
          <mat-label>Remarks</mat-label>
        </div>
        <div class="col-sm-12">
          <textarea  formControlName="remarks" rows="5" class="textremarks"></textarea>
        </div>
      </div>
      <div class="row btn-divs">
       
        <div class="col-sm-6"></div>
        <div class="col-sm-3">
          <button mat-button (click)="onNoClick()" class="cancelbtn">Cancel</button>
      </div>
        <div class="col-sm-3">
          <button mat-button type="submit" [disabled]="!dialogForm.valid" class="submitbtn">Submit</button>
        </div>
       
      </div>
    
      
    </form>
  </div>
</div>

<style>

  .title{
    text-align: center;
  }
   /* .reopendialog{
    width: 32vw;
   
  } */
  .textremarks{
    width:100%;
  }
  .btn-divs{
    margin-top: 20px;
  }
  .submitbtn{
    background-color: #023654;
    color: #FFFFFF;
  }
  .cancelbtn{
    background-color: #eee;
  }


</style>
<!-- 
<div *ngIf="lndUserType" class="row">
  <div class="col header">
    <span
      (click)="allLink.home()"
      style="max-width: fit-content; cursor: pointer"
    >
      <i class="fa fa-chevron-left" aria-hidden="true"></i> </span
    >&nbsp;&nbsp;
    <span> List of Claims | {{ user }} Portal </span>
  </div>
  <div class="col header" style="text-align: right">
    <button
      type="button"
      class="btn btn-filter"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      Filter
    </button>
    &nbsp;&nbsp;
    <span *ngIf="filter_value">
      <button type="button" class="btn btn-filter" (click)="clearFilter()">
        Clear
      </button>
    </span>
    <span
      style="font-size: 30px; cursor: pointer; margin-left: 61px"
      (click)="allLink.openNav()"
      >&#9776;
    </span>
  </div>
</div> -->
 <span *ngIf="lndUserType">
<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"></app-header-global>
</span>


<!-- <app-header [allClaimHeading]="headingOne"></app-header> -->

<div class="row allcontent">
  <!-- <div class="col-sm-2">
    <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr"></app-side-overlay-menu>
  </div> -->
  <div class="col sm-12">
    <div class="main_claim">
      <div class="detail_claim">
        <div class="radio_btn_section d-flex">
          <div class="radio-btn-section">
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input radioinput"
                name="status"
                [value]="''"
                [(ngModel)]="status"
                (change)="onRadioChange('')"
                checked
              />
              All
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input radioinput"
                name="status"
                [value]="'pending'"
                [(ngModel)]="status"
                (change)="onRadioChange('pending')"
              />
              Pending
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input radioinput"
                name="status"
                [value]="'rejected'"
                [(ngModel)]="status"
                (change)="onRadioChange('rejected')"
              />
              Rejected By HOD
            </label>
          </div>
        </div>
        <div class="filter-btn-section">
          <button
          type="button"
          class="btn btn-filter"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Filter
        </button>
        &nbsp;&nbsp;
        <span *ngIf="filter_value">
          <button type="button" class="btn btn-filter" (click)="clearFilter()">
            Clear
          </button>
        </span>
      
      <!-- ------------------- -->
        </div>
        
        </div>
        <div class="claim_div">
          <div class="table-responsive">
            <table class="table" id="emp_expense">
              <thead>
                <tr>
                  <th>Claim ID</th>
                  <th>Submitter</th>
                  <th>Submitter ID</th>
                   <th>CRIS ID </th>
                  <th>Project</th>
                  <th>Legal Entity</th>
                  <th>Date Submitted</th>
                  <th>Expense Type</th>
                  <th>Amount(Total vs Approved)</th>
                  <!-- <th>Amount(Approved)</th> -->
                  <th>Category</th>
                  <th>Reviewer</th>
                  <th>Status</th>
                  <th>Payment Status</th>
                  <th>Manager Approved Date</th>
                  <th>Last Approved Date</th>
                </tr>
              </thead>
              <tbody *ngFor="let data of tableData.length ? tableData : items">
                <!-- [routerLink]="['/detailclaim', data.id]" -->
                <tr (click)="onTableIdClick(data.id)">
                  <td>{{ data.id }}</td>
                  <td>{{ data.submitter.name }}<br>({{data.submitter.job_title}})</td>
                  <td>{{ data.submitter.emp_id }}</td>
                  <td>{{ data.submitter.sap_id }}</td>
                  <td>{{ data.project.name }}/{{ data.project.amplus_id }}</td>
                  <td>{{ data.submitter.legal_entity }}</td>
                  <td>{{ data.submitted_at }}</td>
                  <td>{{ data.expense_type }}</td>
                  <td>{{ data.amount }}/--{{data.approved_amount}}</td>
                  <td>{{ data.category.name }}</td>
                  <td>{{ data.reviewer.name }}</td>
                  <td>{{ data.status }}</td>
                  <td>{{ data.payment_status }}</td>
                  <td>{{ data.manager_approved_date }}</td>
                  <td>{{ data.last_approved_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
    
          <div class="page_div">
            <div class="row" id="pagination-container">
              <div class="col-sm-5">
                Current Page {{ this.currentPage }} Total Number of Pages
                {{ this.totalPages }}
              </div>
              <div class="col-sm-7">
    
                <button
                  (click)="previousPage()"
                  class="btn btn-primary"
                  [disabled]="this.currentPage === 1"
                >
                  <<
                </button>
                <button
                (click)="firstPage()"
                class="btn btn-primary"
                [disabled]="this.currentPage === 1"
              >
                First
              </button>
                <ng-container *ngIf="totalPages > 0">
                  <button
                    *ngFor="let page of getNumericButtons()"
                    (click)="gotoPage(page)"
                    class="btn btn-primary"
                    [class.active]="page === currentPage"
                  >
                    {{ page }}
                  </button>
                </ng-container>
                <button
            (click)="lastPage()"
            class="btn btn-primary"
            [disabled]="this.currentPage === this.totalPages"
          >
            Last
          </button>
                <button
                  (click)="nextPage()"
                  class="btn btn-primary"
                  [disabled]="this.currentPage === this.totalPages"
                >
                  >>
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="pagination_div"></div> -->
    
    <!-- <pagination-controls (pageChange)="p=$event"></pagination-controls> -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
            <button
              type="button"
              class="close allclaimPopup close_btn cross_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <label for="name">Submitter's Name</label><br />
              <input
                type="text"
                [(ngModel)]="uname"
                name="uname"
                placeholder="Enter Name"
              /><br />
              <label for="Email">Submitter's Email</label><br />
              <input
                type="text"
                [(ngModel)]="uemail"
                name="uemail"
                placeholder="Enter Email"
              /><br />
              <label for="Email">Project Code</label><br />
              <input
                type="text"
                [(ngModel)]="pcode"
                name="pcode"
                placeholder="Enter Project Code"
              /><br />
              <label for="Email">Claim Id</label><br />
              <input
                type="text"
                [(ngModel)]="claimid"
                name="claimid"
                placeholder="Enter Claim Id"
              /><br />
              <label for="">Legal Entity</label><br />
              <select
                name="legalentity"
                [(ngModel)]="legalentity"
                name="legalentity"
                id="legal_entity"
              >
                <option>Select Legal Entity</option>
                <option *ngFor="let legalentitydata of ledata">
                  {{ legalentitydata }}
                </option>
              </select>
              <br />
              <!-- <div class="row">
                <div class="col-sm-6">
                  <label for="datafrom">Date From</label><br>
                  <input type="date" [(ngModel)]="fromdate" name="fromdate" placeholder='From(dd-mm-yy)'>
                </div>
                <div class="col-sm-6">
                  <label for="dataTo">Date To</label><br>
                 <input type="date"  [(ngModel)]="todate" name="todate"  placeholder='To(dd-mm-yy)'>
                </div>
             
              </div> -->
              <div class="outeremp">
                <input
                  type="checkbox"
                  id="outer-emp"
                  #externalCheckbox
                  (change)="externalValue(externalCheckbox.checked)"
                />
                <p>Third Party Claims</p>
              </div>
            </form>
          </div>
    
          <div class="modal-footer">
            <button
              type="button"
              (click)="_clearData()"
              class="btn btn-primary clear_btn"
            >
              Clear
            </button>
    
            <br />
            <button
              type="button"
              (click)="_filterData()"
              class="btn btn-primary apply_btn"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit } from "@angular/core";
import { AllLinksComponent } from "src/app/core/all-links/all-links.component";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoginService } from "src/app/core/login/login.service";
@Component({
  selector: "app-hrmanager",
  templateUrl: "./hrmanager.component.html",
  styleUrls: ["./hrmanager.component.css"],
})
export class HrmanagerComponent2 implements OnInit {
  lndUserType: boolean = false;
  user: any;
  name: any;
  isAdmin: boolean = false;
  constructor(
    public allLink: AllLinksComponent,
    private learnerService: LearnerService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.learnerService.getUserType().subscribe(
      (res) => {
        this.user = res["lnd_user_type"];
        this.lndUserType = true;
        localStorage.setItem("user_type", res["lnd_user_type"]);
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
    // this.user_type = localStorage.getItem('user_type');
    this.isAdmin = localStorage.getItem("user_type") == "admin" ? true : false;
    // this.user = localStorage.getItem('user_type');
    this.name = localStorage.getItem("name");
  }
}

<div class="top_row">
  <!-- <div class="menu">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div> -->

  <p style="padding-left: 1vw !important;">{{ employeeHeading }}</p>
  <p style="padding-left: 1vw !important;">{{ holidayHeading }}</p>
</div>

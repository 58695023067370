import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {

    // @Input() data: any;
    form: any;
    title: any;
    modality: any;
    location: any;
    date: any;
    startTime: any;
    endTime: any;
    message: any;
    dataPresent = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
        private matDialog: MatDialog,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        console.log("inside conformation popup", this.data);
        this.message = this.data.message;
        if (this.data.data != null) {
            this.dataPresent = true;
            this.title = this.data.data.name;
            this.modality = this.data.data.modality;
            this.location = this.data.data.location;
            this.date = formatDate(this.data.data.start_time, "MMM dd, yyyy", 'en-IN');
            this.startTime = formatDate(this.data.data.start_time, "HH:mm aa", 'en-IN');
            this.endTime = formatDate(this.data.data.end_time, "HH:mm aa", 'en-IN');
        }
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }

    cancel() {
        this.dialogRef.close();
    }
}
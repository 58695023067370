<form #poaform="ngForm">
    <div class='poaNumber'>
        <div>
            <h5>POA Request</h5>
        </div>
        <div [style.display]="isId ? 'block' : 'none'">
            <h5 class='poaId'>ed No-{{poaId}}</h5>
        </div>
    </div>


    <div class='first-container'>

        <div class='cont-1'>
            <label for="name">Name of Person</label>

            <div>

                <input class='input-class' id='inputName' type='text' placeholder='Enter name' name='name'
                    [ngModel]='poaForm.get("name").value' (click)='showOptions()' required #getname="ngModel"
                    (input)='filterOptions()' [readonly]="isDisabled">

                <div class='options' id='option'>
                    <div *ngFor="let employee of filteredEmployee" class='optionDiv'
                        (click)='getValueOfEmployee(employee)'>
                        <a class='option_value'>{{ employee }} </a>
                    </div>
                </div>

            </div>

        </div>

        <div class='cont-2'>
            <label for="full_name">Full Name</label>

            <div>
                <input class='input-class' type='text' placeholder='Enter full name' name='full_name'
                    [ngModel]='poaForm.get("full_name").value' (click)="hideValidation('invalid_full_name')" required
                    #getfullname="ngModel" [readonly]="isDisabled">
                <span class='validation' id='invalid_full_name' style='display:none'>Enter full name</span>

            </div>
        </div>

    </div>

    <div class='first-container'>

        <div class='cont-1'>
            <label for="father_name">Father's Name</label>
            <div>
                <input class='input-class' type='text' placeholder='Enter father name' name='father_name'
                    [ngModel]='poaForm.get("father_name").value' (click)="hideValidation('invalid_father_name')"
                    required #getfathername="ngModel" [readonly]="isDisabled">
                <span class='validation' id='invalid_father_name' style='display:none'>Enter father's name</span>

            </div>
        </div>

        <div class='cont-2'>
            <label for="email">Email</label>
            <div>
                <input class='input-class' type='text' placeholder='Enter email' name='email'
                    [ngModel]='poaForm.get("email").value' (click)="hideValidation('invalid_email')" required
                    #getemail="ngModel" [readonly]="isDisabled">
                <span class='validation' id='invalid_email' style='display:none'>Enter valid email</span>

            </div>

        </div>

    </div>

    <div class='first-container'>

        <div class='second-container'>

            <div class='cont-1'>
                <label for="pan_no">PAN No.</label>

                <div>
                    <input class='input-class' type='text' placeholder='Enter Pan no.' name='pan_no'
                        [ngModel]='poaForm.get("pan_no").value' (click)="hideValidation('invalid_pan_no')" required
                        #getpanNo="ngModel" [readonly]="isDisabled">
                    <span class='validation' id='invalid_pan_no' style='display:none'>Enter valid PAN number</span>

                </div>

            </div>

            <div class='cont-2'>
                <label for="mobile_no">Phone No.</label>

                <div>
                    <input class='input-class' type='number' placeholder='Enter phone no.' name='mobile_no'
                        [ngModel]='poaForm.get("mobile_no").value' (click)="hideValidation('invalid_mobile_no')"
                        required #getphonenumber="ngModel" [readonly]="isDisabled">
                    <span class='validation' id='invalid_mobile_no' style='display:none'>Enter valid mobile
                        number</span>

                </div>

            </div>

        </div>

        <div class='cont-2'>
            <label for="execution_place">Place of Execution</label>

            <div>
                <input class='input-class' type='text' placeholder='Enter place of execution' name='execution_place'
                    [ngModel]='poaForm.get("execution_place").value' (click)="hideValidation('invalid_execution_place')"
                    required #getplace="ngModel" [readonly]="isDisabled">
                <span class='validation' id='invalid_execution_place' style='display:none'>Enter place of
                    execution</span>

            </div>
        </div>

    </div>

    <div class='first-container'>

        <div class='cont-1'>
            <label for="address">Address Details</label>

            <div>
                <textarea class="textarea-class " rows="2" cols="" name='address'
                    [ngModel]='poaForm.get("address").value' (click)="hideValidation('invalid_address')" required
                    #getaddress="ngModel" [readonly]="isDisabled"></textarea>
                <span class='validation' id='invalid_address' style='display:none'>Enter address</span>


            </div>
        </div>

        <div class='cont-2'>
            <label for="Description">Description</label>

            <div>
                <textarea class="textarea-class" rows="2" cols="" name='description'
                    [ngModel]='poaForm.get("description").value' (click)="hideValidation('invalid_description')"
                    required #getdescription="ngModel" [readonly]="isDisabled"></textarea>
                <span class='validation' id='invalid_description' style='display:none'>Enter description</span>

            </div>
        </div>

    </div>

    <div class='first-container'>

        <div class='second-container'>

            <div class='cont-1'>
                <label for="start_date">POA Start Date</label>

                <div>
                    <input class='input-class' type='date' name='start_date' [ngModel]='poaForm.get("start_date").value'
                        (click)="hideValidation('invalid_start_date')" #getstartdate="ngModel" [readonly]="isDisabled">
                    <span class='validation' id='invalid_start_date' style='display:none'>Enter start date</span>

                </div>

            </div>

            <div class='cont-2'>
                <label for="valid_upto">Valid Upto</label>

                <div>
                    <input class='input-class' type='date' name='valid_upto' [ngModel]='poaForm.get("valid_upto").value'
                        (click)="hideValidation('invalid_valid_upto')" #getvaliddate='ngModel' [readonly]="isDisabled">
                    <span class='validation' id='invalid_valid_upto' style='display:none'>Enter valid upto</span>

                </div>

            </div>

        </div>

        <div class='cont-2'>
            <label for="remark">Remark</label>

            <div>
                <input class='input-class disable' type='text' placeholder='Enter remark' name='remark'
                    [ngModel]='poaForm.get("remark").value' (click)="hideValidation('invalid_remark')"
                    #getremark='ngModel' [readonly]="isDisabled">
                <span class='validation' id='invalid_remark' style='display:none'>Enter remark</span>

            </div>
        </div>
    </div>

    <div class='first-container'>


        <div class='cont-1'>
            <label for="company">Company Name</label>
            <div>
                <select class='input-class disable' name='company' [ngModel]='poaForm.get("company").value'
                    (click)="hideValidation('invalid_company')" #getcompany='ngModel'>
                    <option *ngFor="let company of companyNames" [value]="company">
                        <p>{{company}}</p>
                    </option>
                </select>
                <span class='validation' id='invalid_company' style='display:none'>Enter Company</span>
            </div>
        </div>


        <div class='cont-2'>
            <label for="poa_file">Upload File</label>
            <div class='fileDiv' id='fileDiv'>
                <div class='fileDivOne'>
                    <input type='text' class='fileInput' placeholder='' id='poafileInput' readonly>
                    <span class="removeFileBtn" (click)="removeFile()" id='removeBtn' style='display:none'>✖</span>
                    <!-- Cross symbol for removing the file -->
                </div>
                <div class='fileDivTwo'>
                    <input type="file" id="fileUpload" style='display:none;' name='poa_file'
                        [ngModel]='poaForm.get("poa_file").value' #getpoafile='ngModel'
                        (change)="onFileSelected($event)">
                    <button type="button" class="fileBtn" (click)="onBrowseBtnClick()">Browse</button>
                </div>
            </div>
            <div id='imageLinkDiv'>
                <a id='urlValue' (click)='getTheFileUrl()'>{{ poaFileUrl }}</a>
            </div>
        </div>


    </div>


    <div id='raisePoaBtn' [style.display]="raiseBtnVisible ? 'flex' : 'none'">
        <button type="submit" class="btn" id="draftBtn"
            (click)="_raisePoa(poaform, 'save_as_draft','post')">Draft</button>
        <button type="submit" class="btn" id="submitBtn"
            (click)="_raisePoa(poaform, 'send_to_hod','post')">Submit</button>
    </div>

    <div id='draftactionBtn' [style.display]="draftactionBtnVisible ? 'flex' : 'none'">
        <button type="submit" class="btn" id="deleteBtn" (click)="_deletePoa()">Delete</button>
        <button type="submit" class="btn" id="submitBtn" (click)="_raisePoa(poaform,'save_draft','put')">Submit</button>
    </div>

    <div id='PendingBtn' [style.display]="pendingBtnVisible ? 'flex' : 'none'">
        <button type="submit" class="btn" id="draftBtn" (click)="_updateStatus('reject')">Reject</button>
        <button type="submit" class="btn" id="approveBtn"
            (click)="_raisePoa(poaform,'approve_save_file','put')">Approve</button>
    </div>

</form>
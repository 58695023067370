<span>
    <app-header-global></app-header-global>
</span>
<div class="row allcontent">
    <!-- <div class="col-sm-2">
        <app-side-overlay-menu ></app-side-overlay-menu>
    </div> -->
    <div class="col-sm-12  contentsection">
        <div class="main">
            <div class="content">

                <form [formGroup]="personalInfoForm" class="personalInfo">
                    <!-- Personal Information Section -->
                    <div class="border border-black p-4">
                        <div class="row subheading">
                            <div class="col-sm-3">
                                <p class="upheading">Personal Information</p>
                                <span class="sp1">Person’s Details</span>
                            </div>
                        </div>

                        <div class="row">
                            <!-- Initials -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Initials</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input formControlName="initials" class="infofield" required>
                                        <!-- <select formControlName="initials" class="infofield" required>
                                            <option value="">Select Initials</option>
                                            <option *ngFor="let initial of initials" [value]="initial">{{ initial }}
                                            </option>
                                        </select> -->
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('initials')?.errors?.['required'] && personalInfoForm.get('initials')?.touched">
                                            Initials is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- First Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="fname" class="infofield"
                                            placeholder="First Name" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('fname')?.errors?.['required'] && personalInfoForm.get('fname')?.touched">
                                            First Name is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- Middle Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Middle Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="mname" class="infofield"
                                            placeholder="Middle Name">
                                    </div>
                                </div>
                            </div>
                            <!-- Last Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="lname" class="infofield"
                                            placeholder="Last Name" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('lname')?.errors?.['required'] && personalInfoForm.get('lname')?.touched">
                                            Last Name is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Date of Birth -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Date of Birth</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">
                                            <input matInput [matDatepicker]="pickerfrom" name="dob" required
                                                class="infofield" placeholder="Date of Birth" formControlName="dob"
                                                (click)="pickerfrom.open()" />
                                            <!-- <mat-datepicker-toggle matSuffix [for]="pickerfrom"
                                                class="datepicker-toggle"></mat-datepicker-toggle> -->
                                            <mat-datepicker #pickerfrom></mat-datepicker>
                                        </div>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('dob')?.errors?.['required'] && personalInfoForm.get('dob')?.touched">
                                            Date of Birth is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- Gender -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Gender</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="gender" class="infofield" required>
                                        <!-- <select formControlName="gender" class="infofield" required>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select> -->
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('gender')?.errors?.['required'] && personalInfoForm.get('gender')?.touched">
                                            Gender is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Previous Organisation<span class="man-field"></span></label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="prev_org" class="infofield"
                                                placeholder="Previous Organisation" maxlength="100">

                                            <div class="erroMessage"
                                                *ngIf="personalInfoForm.get('prev_org')?.errors?.['pattern'] && personalInfoForm.get('prev_org')?.touched">
                                                Entern valid value
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Qualification Details Section -->
                        <div class="border border-black p-4 mt-4">
                            <div class="row subheading">
                                <div class="col-sm-3">
                                    <span>Qualification Details</span>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Qualification (up to UG) -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Qualification (up to UG)</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="qualification_ug"
                                                name="qualification_ug" placeholder="Qualification (up to UG)"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('qualification_ug')?.errors?.['required'] && personalInfoForm.get('qualification_ug')?.touched">
                                            Qualification is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Qualification (PG & above) -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Qualification (PG & above)</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="qualification_pg"
                                                name="qualification_pg" placeholder="Qualification (PG & above)"
                                                class="infofield">
                                            <!-- <div class="erroMessage"
                                                    *ngIf="personalInfoForm.get('qualification_pg')?.errors?.['required'] && personalInfoForm.get('qualification_pg')?.touched">
                                                    Qualification is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Professional Experience -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Professional Experience</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="total_experience"
                                                name="total_experience" placeholder="Professional Experience"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('total_experience')?.errors?.['required'] && personalInfoForm.get('total_experience')?.touched">
                                            Professional Experience is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Solar Experience -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Solar Experience</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="solar_experience"
                                                name="solar_experience" placeholder="Solar Experience"
                                                class="infofield">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- bank details -->

                    </div>
                </form>
                <!-- ------------------------------ -->

                <div class="text-right">
                    <button mat-button (click)="reopenForUser()" class="universalbtn reopenbtn"
                        [disabled]="status||reopenstatus" [ngClass]="{'disabled':status||reopenstatus}">Reopen</button>

                    <button mat-button (click)="openApprove()" class="universalbtn approvebtn"
                        [disabled]="status ||reopenstatus"
                        [ngClass]="{'disabled':status ||reopenstatus}">Approve</button>
                </div>

            </div>
        </div>
    </div>
</div>
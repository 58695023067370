<span>
    <app-header-global></app-header-global>
</span>
<div class="row allcontent">
    <!-- <div class="col-sm-2">
        <app-side-overlay-menu ></app-side-overlay-menu>
    </div> -->
    <div class="col-sm-12  contentsection">
        <div class="main">
            <div class="content">

                <form [formGroup]="personalInfoForm" class="personalInfo">
                    <!-- Personal Information Section -->
                    <div class="border border-black p-4">
                        <div class="row subheading">
                            <div class="col-sm-3">
                                <p class="upheading">Personal Information</p>
                                <span class="sp1">Person’s Details</span>
                            </div>
                        </div>

                        <div class="row">
                            <!-- Initials -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Initials</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input formControlName="initials" class="infofield" required>
                                        <!-- <select formControlName="initials" class="infofield" required>
                                            <option value="">Select Initials</option>
                                            <option *ngFor="let initial of initials" [value]="initial">{{ initial }}
                                            </option>
                                        </select> -->
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('initials')?.errors?.['required'] && personalInfoForm.get('initials')?.touched">
                                            Initials is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- First Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>First Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="fname" class="infofield"
                                            placeholder="First Name" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('fname')?.errors?.['required'] && personalInfoForm.get('fname')?.touched">
                                            First Name is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- Middle Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Middle Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="mname" class="infofield"
                                            placeholder="Middle Name">
                                    </div>
                                </div>
                            </div>
                            <!-- Last Name -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Last Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="lname" class="infofield"
                                            placeholder="Last Name" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('lname')?.errors?.['required'] && personalInfoForm.get('lname')?.touched">
                                            Last Name is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Date of Birth -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Date of Birth</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">
                                            <input matInput [matDatepicker]="pickerfrom" name="dob" required
                                                class="infofield" placeholder="Date of Birth" formControlName="dob"
                                                (click)="pickerfrom.open()" />
                                            <!-- <mat-datepicker-toggle matSuffix [for]="pickerfrom"
                                                class="datepicker-toggle"></mat-datepicker-toggle> -->
                                            <mat-datepicker #pickerfrom></mat-datepicker>
                                        </div>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('dob')?.errors?.['required'] && personalInfoForm.get('dob')?.touched">
                                            Date of Birth is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- Gender -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Gender</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="gender" class="infofield" required>
                                        <!-- <select formControlName="gender" class="infofield" required>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select> -->
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('gender')?.errors?.['required'] && personalInfoForm.get('gender')?.touched">
                                            Gender is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- Marital Status -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Marital Status</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input formControlName="marital_status" placeholder="Marital Status"
                                            name="marital_status" class="infofield" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('marital_status')?.errors?.['required'] && personalInfoForm.get('marital_status')?.touched">
                                            Marital Status is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Father Name</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input formControlName="father_name" placeholder="Father Name"
                                            name="father_name" class="infofield" required>
                                        <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('marital_status')?.errors?.['required'] && personalInfoForm.get('marital_status')?.touched">
                                            Marital Status is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- adhar details -->
                        <div class="row">
                            <!-- Aadhar -->
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Aadhar Number</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="aadhar_no" class="infofield"
                                                placeholder="Aadhar Number" minlength="12" maxlength="12" required>


                                        </div>
                                        <!-- <div class="erroMessage"
                                *ngIf="personalInfoForm.get('aadhar_no')?.errors?.['required'] && personalInfoForm.get('aadhar_no')?.touched">
                                Aadhar Number is required.
                            </div>
                            <div class="erroMessage"
                                *ngIf="personalInfoForm.get('aadhar_no')?.errors?.['minlength'] && personalInfoForm.get('aadhar_no')?.touched">
                                Aadhar Number must be 12 characters long.
                            </div>
                            <div class="erroMessage"
                                *ngIf="personalInfoForm.get('aadhar_no')?.errors?.['maxlength'] && personalInfoForm.get('aadhar_no')?.touched">
                                Aadhar Number must be 12 characters long.
                            </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">PAN Number</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="pan_no" class="infofield"
                                                placeholder="PAN Number" minlength="10" maxlength="10" required>


                                        </div>
                                        <!-- <div class="erroMessage"
                                *ngIf="personalInfoForm.get('pan_no')?.errors?.['required'] && personalInfoForm.get('pan_no')?.touched">
                                PAN Number is required.
                            </div>
                            <div class="erroMessage"
                                *ngIf="personalInfoForm.get('pan_no')?.errors?.['minlength'] && personalInfoForm.get('pan_no')?.touched">
                                PAN Number must be 10 characters long.
                            </div>
                            <div class="erroMessage"
                                *ngIf="personalInfoForm.get('pan_no')?.errors?.['maxlength'] && personalInfoForm.get('pan_no')?.touched">
                                PAN Number must be 10 characters long.
                            </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Spouse Name<span class="man-field"></span></label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="spouse_name" class="infofield"
                                                placeholder="Spouse Name" maxlength="100">

                                            <div class="erroMessage"
                                                *ngIf="personalInfoForm.get('spouse_name')?.errors?.['pattern'] && personalInfoForm.get('spouse_name')?.touched">
                                                Entern valid value
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Blood Group<span class="man-field"></span></label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="blood_group" class="infofield"
                                                placeholder="Blood Group" maxlength="4">

                                            <div class="erroMessage"
                                                *ngIf="personalInfoForm.get('blood_group')?.errors?.['pattern'] && personalInfoForm.get('blood_group')?.touched">
                                                Entern valid value
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-sm-3">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="l1">Previous Organisation<span class="man-field"></span></label>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="d-flex input-wrapper">

                                            <input type="text" formControlName="prev_org" class="infofield"
                                                placeholder="Previous Organisation" maxlength="100">

                                            <div class="erroMessage"
                                                *ngIf="personalInfoForm.get('prev_org')?.errors?.['pattern'] && personalInfoForm.get('prev_org')?.touched">
                                                Entern valid value
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Address Details Section -->
                        <div class="border border-black p-4 mt-4">
                            <div class="row subheading">
                                <div class="col-sm-3">
                                    <span>Address Details</span>
                                </div>
                            </div>
                            <div class="row">
                                <!-- City -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>City</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="city" name="city" placeholder="City"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('city')?.errors?.['required'] && personalInfoForm.get('city')?.touched">
                                            City is required.
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Postal Code -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Postal Code</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="postal_code" name="postal_code"
                                                placeholder="Postal Code" class="infofield" minlength="6" maxlength="6"
                                                pattern="\d*" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('postal_code')?.errors?.['required'] && personalInfoForm.get('postal_code')?.touched">
                                            Postal Code is required.
                                        </div> -->
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('postal_code')?.errors?.['minlength'] && personalInfoForm.get('postal_code')?.touched">
                                            Postal Code must be 6 characters long.
                                        </div> -->
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('postal_code')?.errors?.['maxlength'] && personalInfoForm.get('postal_code')?.touched">
                                            Postal Code must be 6 characters long.
                                        </div> -->
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('postal_code')?.errors?.['pattern'] && personalInfoForm.get('postal_code')?.touched">
                                            Postal Code must contain only numbers.
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Address -->
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Correspondance Address</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="address" name="address"
                                                placeholder="Address" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('address')?.errors?.['required'] && personalInfoForm.get('address')?.touched">
                                            Address is required.
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Permanent Address</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="permanent_address" name="address"
                                                placeholder="Address" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                                *ngIf="personalInfoForm.get('permanent_address')?.errors?.['required'] && personalInfoForm.get('permanent_address')?.touched">
                                                Address is required.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Contact Details Section -->
                            <div class="row subheading">
                                <div class="col-sm-3">
                                    <span>Contact Details</span>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Personal E-Mail Id -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Personal E-Mail Id</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="email" formControlName="personal_email" name="personal_email"
                                                placeholder="Personal E-Mail Id" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('personal_email')?.errors?.['required'] && personalInfoForm.get('personal_email')?.touched">
                                            Personal Email is required.
                                        </div> -->
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('personal_email')?.errors?.['email'] && personalInfoForm.get('personal_email')?.touched">
                                            Please enter a valid email.
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Personal Contact No. -->
                                <div class="col-sm-3">
                                    <div class="col-sm-12">
                                        <label>Personal Contact No.</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="mobile_no" name="mobile_no"
                                            placeholder="Personal Contact No." class="infofield" minlength="10"
                                            maxlength="10" pattern="\d*" required>
                                        <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('mobile_no')?.errors?.['required'] && personalInfoForm.get('mobile_no')?.touched">
                                            Personal Contact No. is required.</div>
                                        <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('mobile_no')?.errors?.['minlength'] && personalInfoForm.get('mobile_no')?.touched">
                                            Personal Contact No. must be 10 characters long.</div>
                                        <!-- <div class="erroMessage"
                                        *ngIf="personalInfoForm.get('mobile_no')?.errors?.['pattern'] && personalInfoForm.get('mobile_no')?.touched">
                                        mobile number must contain only numbers.
                                    </div> -->
                                    </div>
                                </div>
                                <!-- Alternate Contact No. -->
                                <div class="col-sm-3">
                                    <div class="col-sm-12">
                                        <label>Alternate Contact No.</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="alternate_mobile_no"
                                            name="alternate_mobile_no" placeholder="Alternate Contact No."
                                            class="infofield" minlength="10" maxlength="10" pattern="\d*">
                                        <!-- <div class="erroMessage"
                                        *ngIf="personalInfoForm.get('alternate_mobile_no')?.errors?.['minlength'] && personalInfoForm.get('alternate_mobile_no')?.touched">
                                        Alternate Contact No. must be 10 characters long.</div> -->
                                        <!-- <div class="erroMessage"
                                        *ngIf="personalInfoForm.get('alternate_mobile_no')?.errors?.['pattern'] && personalInfoForm.get('alternate_mobile_no')?.touched">
                                        mobile number must contain only numbers.
                                    </div> -->
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="col-sm-12">
                                        <label>Emergency Contact Number</label>
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" formControlName="emergency_contact" name="emergency_contact"
                                            placeholder="Emergency Contact No." class="infofield" minlength="10"
                                            maxlength="10" pattern="\d*">

                                        <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('emergency_contact')?.errors?.['pattern'] || personalInfoForm.get('emergency_contact')?.errors?.['minlength']">
                                            Mobile number is 10 digits long and must contain only numbers.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Qualification Details Section -->
                        <div class="border border-black p-4 mt-4">
                            <div class="row subheading">
                                <div class="col-sm-3">
                                    <span>Qualification Details</span>
                                </div>
                            </div>
                            <div class="row">
                                <!-- Qualification (up to UG) -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Qualification (up to UG)</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="qualification_ug"
                                                name="qualification_ug" placeholder="Qualification (up to UG)"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('qualification_ug')?.errors?.['required'] && personalInfoForm.get('qualification_ug')?.touched">
                                            Qualification is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Qualification (PG & above) -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Qualification (PG & above)</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="qualification_pg"
                                                name="qualification_pg" placeholder="Qualification (PG & above)"
                                                class="infofield">
                                            <!-- <div class="erroMessage"
                                                    *ngIf="personalInfoForm.get('qualification_pg')?.errors?.['required'] && personalInfoForm.get('qualification_pg')?.touched">
                                                    Qualification is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Professional Experience -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Professional Experience</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="total_experience"
                                                name="total_experience" placeholder="Professional Experience"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('total_experience')?.errors?.['required'] && personalInfoForm.get('total_experience')?.touched">
                                            Professional Experience is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Solar Experience -->
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Solar Experience</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="solar_experience"
                                                name="solar_experience" placeholder="Solar Experience"
                                                class="infofield">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- bank details -->
                        <div class="border border-black p-4 mt-4">
                            <div class="row subheading">
                                <div class="col-sm-3">
                                    <span>Bank Details</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Account Holder Name</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="account_holder_name"
                                                name="account_holder_name" placeholder="Account Holder Name"
                                                class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('account_number')?.errors?.['required'] && personalInfoForm.get('account_number')?.touched">
                                            Account Number is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Account Number</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="account_number" name="account_number"
                                                placeholder="Account Nmumber" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('account_number')?.errors?.['required'] && personalInfoForm.get('account_number')?.touched">
                                            Account Number is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Bank Name</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="bank_name" name="bank_name"
                                                placeholder="Bank Name" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('bank_name')?.errors?.['required'] && personalInfoForm.get('bank_name')?.touched">
                                            bank_name is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>IFSC Code</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" formControlName="ifsc_code" name="ifsc_code"
                                                placeholder="IFSC Code" class="infofield" required>
                                            <!-- <div class="erroMessage"
                                            *ngIf="personalInfoForm.get('ifsc_code')?.errors?.['required'] && personalInfoForm.get('ifsc_code')?.touched">
                                            IFSC is required.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <label class="l1">UAN Number <span class="man-field"></span></label>
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="text" formControlName="uan_number" name="uan_number"
                                                    placeholder="UAN Number" class="infofield" required>
                                                <div class="erroMessage"
                                                    *ngIf="personalInfoForm.get('uan_number')?.errors?.['required'] && personalInfoForm.get('uan_number')?.touched">
                                                    UAN is required.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <!-- ------------------------------------------ file details -->
                <div class="border border-black p-4 mt-4">
                    <div class="row subheading">
                        <div class="col-sm-3">
                            <span>Attachments</span>
                        </div>
                    </div>

                    <div class="file-picker-container row">
                        <ng-container *ngIf="documents_uploaded; else noFiles">
                            <div class="row" style="justify-content: center;">
                                <div class="file-pick-status-view col-sm-2 col-5" *ngFor="let file of files.slice(0, 5); let i = index">
                                    <div class="file-picker" *ngIf="uploadedDocsFromDb.get(file.name)?.value"
                                        (click)="onClickDocLink(uploadedDocsFromDb.get(file.name)?.value)">
                                        <div class="file-icon">
                                            <img src="../../../../../assets/images/googledocs.png" alt="Document Icon" />
                                        </div>
                                        <div class="file-info">
                                            <div class="file-name-og">{{ getName(file.name) }}</div>
                                            <div class="file-view">View {{ getName(file.name) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" style="justify-content: center;">
                                <div class="file-pick-status-view col-sm-2 col-5" *ngFor="let file of files.slice(5, 7); let i = index">
                                    <div class="file-picker" *ngIf="uploadedDocsFromDb.get(file.name)?.value"
                                        (click)="onClickDocLink(uploadedDocsFromDb.get(file.name)?.value)">
                                        <div class="file-icon">
                                            <img src="../../../../../assets/images/googledocs.png" alt="Document Icon" />
                                        </div>
                                        <div class="file-info">
                                            <div class="file-name-og">{{ getName(file.name) }}</div>
                                            <div class="file-view">View {{ getName(file.name) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    
                    <ng-template #noFiles>
                        <div class="no-files-message">
                            <p class="nofile">No File Uploaded ....</p>
                        </div>
                    </ng-template>
                    

                </div>
                <!-- ------------------------------ -->

                <div class="text-right">
                    <button mat-button (click)="reopenForUser()" class="universalbtn reopenbtn"
                        [disabled]="status||reopenstatus" [ngClass]="{'disabled':status||reopenstatus}">Reopen</button>

                    <button mat-button (click)="openApprove()" class="universalbtn approvebtn"
                        [disabled]="status ||reopenstatus"
                        [ngClass]="{'disabled':status ||reopenstatus}">Approve</button>
                </div>

            </div>
        </div>
    </div>
</div>
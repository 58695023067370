import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ViewSelfAppraisalComponent } from "../self-appraisal-popup/self-appraisal-popup.component";
import { ViewDepartmentAppraisalComponent } from "../cluster-appraisal/department-appraisal-popup/department-appraisal-popup.component";
import { ViewTeamAppraisalComponent } from "../team-appraisal/team-appraisal-popup/team-appraisal-popup.component";
import { BUBulkSubmitComponent } from "../bu-bulk-submit/bu-bulk-submit.component";
import { formatDate } from "@angular/common";
import { PromotionHistoryComponent } from "../promotion-history/promotion-history.component";

@Component({
    selector: 'business-appraisal',
    templateUrl: './business-appraisal.component.html',
    styleUrls: ['./business-appraisal.component.scss']
})

export class BusinessAppraisalComponent implements OnInit {


    count = 0;
    offset = 0;
    limit: number;
    reportees = [];
    appraisalcycle: any;
    selfAp = 0;
    teamAp = 0;
    deptAp = 0;
    buAp = 0;
    today: any;
    isBusiness: boolean;
    midCycle = false;
    constructor(
        private router: Router,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService,
        private loginService: LoginService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.today = formatDate(new Date(), "yyyy-MM-dd", 'en-IN');
        this.isBusiness = localStorage.getItem('business_head') == 'true' ? true : false;
        if (this.isBusiness) {
            this.appraisalService.getBUAppraisal().subscribe((res: any) => {
                console.log(res, "<=----------=-=----------=-=------");
                this.reportees = res.data.departments;
                this.appraisalcycle = res.data.appraisal_cycle;
                this.midCycle = this.appraisalcycle.is_mid_cycle;
                this.selfAp = res.data.total_self_appraisal_done;
                this.teamAp = res.data.total_team_appraisal_done;
                this.deptAp = res.data.total_department_appraisal_done;
                this.buAp = res.data.total_business_appraisal_done;
                this.count = res.data.total_employees;
                console.log(this.reportees, "<=----------=-=----------=-=------");
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                })
        }
        console.log('inside bu appraisal')
    }

    onActivate(event: any) {
        if (!this.midCycle) {
            if (event.type == "click" && event.cellIndex != 3 && event.cellIndex != 4 && event.cellIndex != 5 && event.cellIndex != 8 && event.row.dept_appraisal_done) {
                setTimeout(() => {
                    window.open('business?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }
        else if (this.midCycle) {
            if (event.type == "click" && event.cellIndex != 3 && event.cellIndex != 4 && event.cellIndex != 7 && event.row.dept_appraisal_done) {
                setTimeout(() => {
                    window.open('business?id=' + event.row.emp_id + '&name=' + event.row.fname + '&type=' + event.row.employment_type + '&cycle=' + this.appraisalcycle.id, "_self");
                }, 300);
            }
        }

    }

    openViewTeam(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeTeamAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewTeamAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'RM Appraisal',
                    teamData: selfData,
                    midCycle: this.midCycle
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    openViewDepartment(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeDepartmentAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewDepartmentAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'Department Appraisal',
                    teamData: selfData,
                    midCycle: this.midCycle
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    openViewSelf(item) {
        console.log(" inside view method, data", item.emp_id);
        this.appraisalService.viewEmployeeSelfAppraisal(item.emp_id).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data.response;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewSelfAppraisalComponent, {
                data: {
                    id: item.emp_id,
                    name: item.fname + ' ' + lname,
                    popup_title: 'Self Appraisal',
                    selfData: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    bulkSubmit() {
        this.appraisalService.getBUAppraisalForm({}).subscribe((res: any) => {
            console.log(res);
            let teamData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef2 = this.dialog.open(BUBulkSubmitComponent, {
                data: {
                    // id: item.emp_id,
                    // name: item.fname + ' ' + item.lname,
                    // popup_title: 'RM Appraisal',
                    bulkData: teamData,
                    midCycle: this.midCycle
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    getPromotions(item) {
        this.appraisalService.getPromotionHistory({ employee: item.emp_id }).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            let lname = item.lname ? item.lname : '';
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    name: item.fname + ' ' + lname,
                    emp_id: item.emp_id,
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
}
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CsrftokenService } from 'src/app/core/services/csrftoken.service'
import { FileInfo } from 'src/app/components/input-multiple-file/input-multiple-file.component'
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {

  baseUrl= environment.url +'template_module/'
  access_key: string = localStorage.getItem("accessToken");

  headers: any = new HttpHeaders().set("Authorization", `${this.access_key}`)
  // .set('Content-Type', `multipart/form-data`)
  requestOptions: any = { headers: this.headers };
  apiUrl: any;
  files: FileInfo[] = [];


  setFiles(files: FileInfo[]): void {
    this.files = files;
  }

  getFiles(): FileInfo[] {
    return this.files;
  }

  responseDataEvent = new EventEmitter<any>();

  constructor(private http: HttpClient, private getcsrfservice: CsrftokenService) { }

  private countSource = new BehaviorSubject<number>(0);
  currentCount = this.countSource.asObservable();

  updateCount(count: number) {
    this.countSource.next(count);
  }


  saveValue(data: any, service_id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };
    const url = `${this.baseUrl}template_value/?service_id=${service_id}`;
    return this.http.post(url, data, httpOptions);
  }


  getTemplate(id: any): Observable<any> {
    let params = new URLSearchParams();
    if (id !== undefined && id !== null) {
      params.append('id', id);
    }

    const url = `${this.baseUrl}get_template/?${params.toString()}`;
    return this.http.get(url, this.requestOptions);
  }


  getDataType(id: any): Observable<any> {

    let params = new URLSearchParams();
    if (id !== undefined && id !== null) {
      params.append('id', id);
    }
    const url = `${this.baseUrl}get_type/?${params.toString()}`;
    return this.http.get(url, this.requestOptions);

  }

  getTemplateValue(id: any, service_id: any, download: any, filter: any): Observable<any> {
    let params = new URLSearchParams();

    if (id !== undefined && id !== null) {
      params.append('id', id);
    }

    if (service_id !== undefined && service_id !== null) {
      params.append('service_id', service_id);
    }

    if (download == 'true') {
      params.append('download', download);
    }
    if (filter !== undefined && filter !== null) {
      params.append('filter', filter);
    }

    const url = `${this.baseUrl}template_value/?${params.toString()}`;
    return this.http.get(url, this.requestOptions);
  }

  updateValue(data: any, id: any, tag: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };

    const url = `${this.baseUrl}template_value/?id=${id}&&tag=${tag}`;
    return this.http.put(url, data, httpOptions);
  }

  deleteValue(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
        'Authorization': this.access_key
      }),
    };

    const url = `${this.baseUrl}template_value/?id=${id}`;
    return this.http.delete(url, httpOptions);
  }

}





















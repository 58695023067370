<div class="row">
    <div class="col">
        <div class="row" style="margin-bottom: 4vh;">
            <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                <div class="row">
                    <div class="col c-keys">
                        Total Employees
                    </div>
                </div>
                <div class="row">
                    <div class="col c-values">
                        {{count}}
                    </div>
                </div>
            </div>
            <div *ngIf="!midCycle" class="col a-card" style="margin: 3vh 0vh 2vh;">
                <div class="row">
                    <div class="col c-keys">
                        Self Appraisal Status
                    </div>
                </div>
                <div class="row">
                    <div class="col c-values">
                        {{selfAp}}/{{count}}
                    </div>
                    <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_ic">
                        Due: {{appraisalcycle.due_date_for_ic}}
                    </div>
                    <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_ic">
                        Due: {{appraisalcycle.due_date_for_ic}}
                    </div>
                    <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_ic">
                        Due: {{appraisalcycle.due_date_for_ic}}
                    </div>
                </div>
            </div>
            <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                <div class="row">
                    <div class="col c-keys">
                        RM Appraisal Status
                    </div>
                </div>
                <div class="row">
                    <div class="col c-values">
                        {{teamAp}}/{{count}}
                    </div>
                    <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_rm">
                        Due: {{appraisalcycle.due_date_for_rm}}
                    </div>
                    <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_rm">
                        Due: {{appraisalcycle.due_date_for_rm}}
                    </div>
                    <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_rm">
                        Due: {{appraisalcycle.due_date_for_rm}}
                    </div>
                </div>
            </div>
        </div>
        <ngx-datatable [rows]="reportees" [columns]="[
{ name: 'Employee ID' },
{ name: 'Name' },
{ name: 'Designation' },
{ name: 'Self Appraisal Status' },
{ name: 'RM Appraisal Status'},
{ name: 'Qualifications (UG)' },
{ name: 'Qualifications (PG)' },
{ name: 'Date of Joining' },
{ name: 'Promotion History' }
]" [columnMode]="'flex'" [headerHeight]="'auto'" [footerHeight]="0" [rowHeight]="'auto'" [externalPaging]="true"
            [count]="count" [offset]="offset" [limit]="limit" [scrollbarH]="true" (activate)="onActivate($event)"
            style="cursor: pointer;">



            <ngx-datatable-column name="Employee ID" [minWidth]="120" [canAutoResize]="false" [draggable]="false"
                [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                   <div *ngIf="!row.self_appraisal_done && !midCycle">
                    {{row.emp_id }}
                   </div> 
                   <div *ngIf="midCycle" class="view">
                    {{row.emp_id }}
                   </div> 
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Name" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false" [draggable]="false"
                [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ row.fname | titlecase }}&nbsp;{{row.lname | titlecase}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Designation" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false"
                [draggable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="row.functional_designation != null">{{ row.job_title 
                        }}({{row.functional_designation }})</div>
                         <div *ngIf="row.functional_designation == null">{{ row.job_title 
                        }}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="!midCycle" name="Self Appraisal" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false"
                [draggable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="!row.self_appraisal_done" class="row">
                        <div class="col-5">
                            <span class="badge text-bg-danger">Pending</span>
                        </div>
                    </div>
                    <div *ngIf="row.self_appraisal_done" class="row">
                        <div class="col-5">
                            <span class="badge text-bg-success">Done</span>
                        </div>
                        <div class="col view" (click)="openViewSelf(row)">
                            View
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="RM Appraisal" [canAutoResize]="false" [minWidth]='180' [flexGrow]="0"
                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="!row.team_appraisal_done" class="row">
                        <div class="col-5">
                            <span class="badge text-bg-danger">Pending</span>
                        </div>
                    </div>
                    <div *ngIf="row.team_appraisal_done" class="row">
                        <div class="col-5">
                            <span class="badge text-bg-success">Done</span>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date of Joining" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false"
                [draggable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ row.doj }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Promotion History" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false"
                [draggable]="false" [resizeable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div class="promotion" (click)="getPromotions(row)">
                        Click
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
<!-- <div class="form-group custom-form-group">
    <label *ngIf="heading" class="highlight">{{ heading }}</label>
    <input type="text" class="form-control custom-input" [ngClass]="{'is-invalid': !isValid}" 
           [(ngModel)]="inputValue" [placeholder]="placeholder" [disabled]="isDisabled" 
           [id]="id" (input)="onInputChange($event.target.value)" [required]="isRequired" >
    <p *ngIf="hint" class="form-text text-muted">{{ hint }}</p>
    <div *ngIf="!isValid" class="invalid-feedback">Input must contain between {{ minWords }} and {{ maxWords }} words</div>
</div> -->


<div class="form-group custom-form-group">
    <label *ngIf="heading" class="highlight">{{ heading }}</label>
    <input
      type="text"
      class="form-control custom-input"
      [ngClass]="{'is-invalid': !isValid && inputValue}"
      [(ngModel)]="inputValue"
      [placeholder]="placeholder"
      [disabled]="isDisabled"
      [id]="id"
      (input)="onInputChange($event.target.value, $event.target.id)"
      [required]="isRequired"
    />
    <p *ngIf="hint" class="form-text text-muted">{{ hint }}</p>
      <span [id]="id + '_span'" class="required" style="display: none;">* This field is required</span>
    <div *ngIf="!isValid && inputValue" class="invalid-feedback">
      Input must contain between {{ minWords }} and {{ maxWords }} words
    </div>
  </div>
  
  
<div class="row">
    <div class="col session-header p1rem">
        Past Sessions
    </div>
</div>
<div *ngFor="let item of sessionList">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <div *ngIf="item.status == 'cancelled'" class="row">
                                <div class="col text-heading">
                                    <strong>{{item.name}}</strong> | {{item.modality}}
                                    <span class="badge rounded-pill text-bg-secondary tooltipInput"
                                        style="background-color: #6c757d54 !important;">{{item.status}}
                                        <span class="tooltiptext">This session was
                                            cancelled by the Admin/HR.
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="item.status != 'cancelled'" class="row">
                                <div class="col text-heading">
                                    <strong>{{item.name}}</strong> | {{item.modality}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-decor">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    {{item.location}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-decor">
                                    <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                    {{item.start_time | date:'MMM dd, yyyy'}} |
                                    {{item.start_time | date:'HH:mm aa'}} -
                                    {{item.end_time | date:'HH:mm aa'}}
                                </div>
                            </div>
                            <div *ngIf="isAdmin" class="row">
                                <div class="col list-link" (click)="openParticipantList(item.id)">
                                    <i class="fa fa-cloud" aria-hidden="true"></i> Click here to view
                                    enrollment/participant list
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col p5 view-btn">
                                    <button type="button" class="btn btn-outline-primary" (click)="viewPastSession(item.id)">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="sessionList.length <=0" class="row no-data" >
    <div class="col">
        no sessions available :(
    </div>
</div>
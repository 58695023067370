import { Component, OnInit, ViewEncapsulation, Renderer2, Injectable, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActionPopupComponent } from 'src/app/components/action/action.component';
import { Router, RouterLink } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'all-links',
  templateUrl: './all-links.component.html',
  styleUrls: ['./all-links.component.scss'],
  encapsulation: ViewEncapsulation.None,



})
export class AllLinksComponent implements OnInit {
  isAdmin: boolean = false;
  user: any;
  lndUserType: any;
  dates: any;
  documents: any;
  name: any;
  setAttribute: any;
  htmlToAdd: string;
  checked = false;
  startDate = new Date(2018, 2, 12);
  ogDate = new Date(1990, 0, 1);
  specialDates = [];
  showcard: Boolean;
  showhr:Boolean
  showPOA:boolean;
  showAmdoc:boolean;
  docRes:any;
  holidayList: any;
  upcomingHolidays: any;
  Holidays: any
  optionalHolidays: any;
  currentDate: Date;
  event: any;
  currentMonthLastDate: Date;
  holidayDate: any;
  currentDates: Date = new Date();
  currentYear: any;
  isPickerOpen = false;
  holidaydates: any;
  currentDateWithoutTime: any;
  holidayDateWithoutTime: any;
  holidaysForCurrentYear: any;
  threeMonthsLater: Date;
  currentMonth: any;
  cards = [
    {
      id: 1,
      title: 'L&D Manager',
      description: 'Learning & Development (L&D) delivers to the changing needs of organizations & level-up investment in professional development. Explore new sessions, register for upcoming sessions, provide feedback & experience a smooth L&D experience.',
      accessibility: 'Open to all on-roll & off-roll employees.',
      onClick: () => this.openLnd()
    },
    {
      id: 2,
      title: 'Appraisal Engine',
      description: 'Appraisals empower all-encompassing assessment & feedback to develop an engaging high-performance org culture. Experience a streamlined, digital appraisal workflow.',
      accessibility: 'Open to all on-roll & off-roll employees.',
      onClick: () => this.openAppraisal()
    },
    {
      id: 3,
      title: 'Payroll & Compensation',
      description: 'Through our Taxation & Payroll partner, manage all things taxes & salary.',
      accessibility: 'Open only to on-roll employees.',
      onClick: () => this.opengreytHR()
    },
    {
      id: 4,
      title: 'Rewards & Recognition',
      description: 'Enabled by Vantage Circle, recognize your peers for their exemplary work & foster meaningful connections through innovative recognition & wellness solutions.',
      accessibility: 'Open to all on-roll & off-roll employees.',
      onClick: () => this.openVantageCircle()
    },
    {
      id: 5,
      title: 'Emotional Well-being Assistance',
      description: 'Enabled by 1to1Help, Amplus brings to you and your family a 24X7 available counseling platform to address the increased issues of emotional well-being. Explore assessments, podcasts, articles, videos, and webinars focusing on psychological safety and emotional well-being.',
      accessibility: 'Open to all on-roll & off-roll employees.',
      onClick: () => this.openEWA()
    },

    {
      id: 6,
      title: 'Expense Manager',
      description: 'Through this Hr or authorised personnel can manage all claims and Expenses.',
      accessibility: 'Open only to amplus employees.',
      onClick: () => this.openExpenseManager(),
      hasAccess: () => this.showcard
    },
    {
      id: 7,
      title: 'HR Manager',
      description: 'Through this Hr or authorised personnel can manage all Holiday,Leaves and Events.',
      accessibility: 'Open only to amplus employees.',
      onClick: () => this.openHrManager(),
      hasAccess: () => this.showhr
    },


  ];

  constructor(
    private learnerService: LearnerService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private msalService: LoginService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/arrow.svg')
    );
  }

  ngOnInit() {
    this.getToken();
    this.isLoggedIn();
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }
      this.learnerService.getUserType().subscribe(res => {
        this.user = res['lnd_user_type'];
        this.lndUserType = true;
        localStorage.setItem('user_type', res['lnd_user_type']);
      },
        (error: any) => {
          console.log(error, error.error, error.message);
          if (error.error == 'Signature has expired') {
            this.msalService.logout();
          }
        });
      // this.user_type = localStorage.getItem('user_type');
      this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
      // this.user = localStorage.getItem('user_type');
      this.name = localStorage.getItem('name');
      this.learnerService.getHolidayList().subscribe(res => {
        // console.log(res);
        this.dates = res['data'];
        this.holidayList = this.dates;
        this.filterUpcomingHolidays();
  
      },
        (error: any) => {
          console.log(error, error.error, error.message);
  
        });
   
  }

  isLoggedIn(): boolean {
    return this.msalService.isLoggedIn();
  }

  logout() {
    this.closeNav();
    let dialogRef = this.dialog.open(ActionPopupComponent, {
      width: "300px",
      data: {
        buttontext: "Log Out",
        desc: "Do you want to Log Out?",
      },
    });
    const sub = dialogRef.componentInstance.option.subscribe((data) => {
      if (data) {
        sub.unsubscribe();
        if (data == "success") {
           this.isLoggedIn();
          this.msalService.logout();
        }
      }
    });

  }

  getToken(): any {
    return this.msalService.getToken();

  }
  openLnd() {
    // window.open('https://empower.amplussolar.com/learning-and-development/', "_blank");
    // window.open('http://localhost:4200/learning-and-development/', "_blank");
    this.router.navigate(['learning-and-development']);
  }

  openExpenseManager() {
    this.router.navigate(['expense-manager/all-claim']);
  }
  openHrManager() {

    this.router.navigate(['hr-manager']);
  }
  amplusPoa() {
    this.router.navigate(['/poa/view_poa'], { 
      queryParams: { 
        value: 'action'
      }
    });
  }

  

  openAppraisal() {
    // window.open('https://empower.amplussolar.com/appraisal/', "_self");
    this.router.navigate(['appraisals']);
  }
  opengreytHR() {
    window.open('https://amplus-brooks.greythr.com/', "_blank");
  }

  openVantageCircle() {
    window.open('http://amplussolar.vantagecircle.com/', "_blank");
  }

  openEWA() {
    window.open('https://eapsupport.1to1help.net/amplus/', "_blank");
  }

  employeeDocuments() {
    this.router.navigate(['employee-documents']);

  }



  openNav() {
    document.getElementById("mySidenav").style.width = "300px";

    document.getElementById("fullPageOverLay").style.display = "block";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("fullPageOverLay").style.display = "none";
  }



  // dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
  //   const index = this.dates.findIndex(x => new Date(x.date).toLocaleDateString() === cellDate.toLocaleDateString());
  //   if (index > -1) {
  //     if (this.dates[index].type == 0) {

  //       return "example-custom-date-class";

  //     } else if (this.dates[index].type == 1) {

  //       return "example-custom-date-class date-grey";
  //     }

  //     else if (this.dates[index].type == 2) {

  //       return "example-custom-date-class date-AmplusBlueDark";
  //     }
  //   }
  //   return '';
  // };

  // displayMonth() {

  //   let elements = document.querySelectorAll('.endDate');
  //   let x = elements[0].querySelectorAll('.mat-calendar-body-cell');
  //   x.forEach((y) => {
  //     const dateSearch = this.dateToString(
  //       new Date(y.getAttribute('aria-label'))
  //     );
  //     const data = this.dates.find((f) => f.date == dateSearch);

  //     if (data) y.setAttribute('aria-label', data.name);
  //   });
  // }
  // streamOpened(event) {
  //   setTimeout(() => {
  //     let buttons = document.querySelectorAll('mat-calendar .mat-icon-button');

  //     buttons.forEach((btn) =>
  //       this.renderer.listen(btn, 'click', () => {
  //         setTimeout(() => {
  //           //debugger
  //           this.displayMonth();
  //         });
  //       })
  //     );
  //     this.displayMonth();
  //   });
  // }
  // dateToString(date: any) {
  //   return (
  //     date.getFullYear() +
  //     '-' +
  //     ('0' + (date.getMonth() + 1)).slice(-2) +
  //     '-' +
  //     ('0' + date.getDate()).slice(-2)
  //   );
  // }




  openSubMenu: string | null = null; // Stores the identifier of the currently open submenu

  toggleSubMenu(menuId: string): void {
    if (this.openSubMenu === menuId) {
      // Clicked on the currently open submenu, so close it
      this.openSubMenu = null;
    } else {
      // Clicked on a different submenu, so open it
      this.openSubMenu = menuId;
    }
  }

  isSubMenuOpen(menuId: string): boolean {
    return this.openSubMenu === menuId;
  }

  home() {
    this.router.navigate(['home']);

  }
  amplusProduct() {
    this.router.navigate(['products']);

  }

  amplusMemories() {
    this.router.navigate(['memories']);

  }
  amplusCircular() {
    this.router.navigate(['circular']);
  }
  
  employeeOnboarding() {
    this.router.navigate(['hr-home']);

  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata){
     this.showhr=showhrmanagerdata
  } 
  poashow(showpoa){
    this.showPOA=showpoa;
    console.log('the showpoa given is ',this.showPOA)
  }
  amdocshow(showamdoc){
    this.showAmdoc=showamdoc;
}
  getDocument(docres: any) {
    this.docRes = docres
  }

  filterUpcomingHolidays(): void {
    this.currentDate = new Date();
    this.currentMonth = this.currentDate.getMonth();
    this.currentYear = this.currentDate.getFullYear();
    this.currentDateWithoutTime = this.getDateWithoutTime(this.currentDate);
    this.currentMonthLastDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    this.threeMonthsLater = new Date(this.currentYear, this.currentMonth + 3, 0);

    this.upcomingHolidays = this.holidayList
      .filter(holiday => {
        this.holidayDateWithoutTime = this.getDateWithoutTime(new Date(holiday.date));
        return this.holidayDateWithoutTime >= this.currentDateWithoutTime && holiday.type == 0;
      })
      .slice(0, 5);

    this.holidaysForCurrentYear = this.holidayList.filter(holiday => {
      const holidayDate = new Date(holiday.date);
      return holidayDate.getFullYear() === this.currentYear;
    });

    // console.log(this.holidaysForCurrentYear);
    this.Holidays = this.holidaysForCurrentYear
      .filter(holiday => {
        return holiday.type == 0;
      });

    this.optionalHolidays = this.holidaysForCurrentYear
      .filter(holiday => {
        return holiday.type == 2;
      });

    this.event = this.holidayList
      .filter(holiday => {
        this.holidayDate = new Date(holiday.date);
        this.holidayDateWithoutTime = this.getDateWithoutTime(this.holidayDate);
        return (this.holidayDateWithoutTime >= this.currentDateWithoutTime &&
          this.holidayDateWithoutTime <= this.getDateWithoutTime(this.threeMonthsLater)) &&
          holiday.type == 3;
      });

    this.holidaydates = this.holidayList
      .filter(holiday => holiday.type == 3);
  }

  getDateWithoutTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }


  getCurrentMonth(): string {
    return this.currentDates.toLocaleDateString('en-US', { month: 'short' });
  }


  extractUrls(description: string): string[] {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return description.match(urlRegex) || [];
  }

  processDescription(description: string): string {
    return description.replace(/(https?:\/\/[^\s]+)/g, '');
  }

  copyImage(imageUrl: string) {
    navigator.clipboard.writeText(imageUrl)
      .then(() => {
        this.toastr.success(imageUrl, 'linked copied successfully');
      })
      .catch((err) => {
        this.toastr.success(imageUrl, 'failed to copied ');
      });
  }



  getAccessibleCards() {
    return this.cards.filter(card => {
      if (card.hasAccess) {
        // console.log(card.hasAccess())
        return card.hasAccess();
      }
      return true;
    });
  }


}   
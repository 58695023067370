import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClaimserviceService } from "../service/claimservice.service";
import { AllLinksComponent } from "src/app/core/all-links/all-links.component";
import { LoginService } from "src/app/core/login/login.service";
import { LearnerService } from "src/app/core/services/learner.service";

@Component({
  selector: "app-claim-details",
  templateUrl: "./claim-details.component.html",
  styleUrls: ["./claim-details.component.scss"],
})
export class ClaimDetailsComponent implements OnInit {
  headingTwo: string = "Claim Details";
  expenses: any;
  preres: any;
  preapproval: any;
  claim: any;
  booleanvalue: any;
  is_actions_globals: any;
  AllLinksComponent: any;
  user_type: any;
  lndUserType: boolean = false;
  user: any;
  name: any;
  isAdmin: boolean = false;
  documents: any;
  isShow: boolean = false;
  expenseTypes: { [key: string]: string } = {};
  expenseTypeKeys: string[] = [];
  typeid: any;
  value1: any;
  value2: any;
  type:string='';
  constructor(
    private learnerService: LearnerService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private claimservice: ClaimserviceService,
    public allLink: AllLinksComponent
  ) {}
  ngOnInit() {
    this.learnerService.getUserType().subscribe(
      (res) => {
        this.user = res["lnd_user_type"];
        this.lndUserType = true;
        localStorage.setItem("user_type", res["lnd_user_type"]);
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
    // this.user_type = localStorage.getItem('user_type');
    this.isAdmin = localStorage.getItem("user_type") == "admin" ? true : false;
    // this.user = localStorage.getItem('user_type');
    this.name = localStorage.getItem("name");

    // this.learnerService.getDocumentsList(this.type).subscribe(
    //   (res) => {
    //     // console.log(res);
    //     this.documents = res;
    //   },
    //   (error: any) => {
    //     console.log(error, error.error, error.message);
    //   }
    // );
        
    this.getDetailClaimData()
  }

  getDetailClaimData(){
    let id = this.route.snapshot.params["id"];
    this.claimservice.detailClaim(id).subscribe((res: any) => {
      this.expenses = res.data.expenses;
      this.is_actions_globals = res.data.is_action;

      //preapproval code
      this.preres = [res.data.preApproval];
      this.preapproval = this.preres.filter((prevalue: any) => {
        return prevalue ? prevalue.preApproval !== null : "";
      });
      if (this.preapproval == "") {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
      this.claim = [res];

      this.expenseTypes=res.data.expense_types
       this.expenseTypeKeys = Object.keys(this.expenseTypes)
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { access } from "fs";
// import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: "root"
})
export class AppraisalService {
    baseUrl = environment.url;

    teamAppraisalurl = 'new_appraisal/team_appraisal/';
    teamAppraisalFormUrl = 'new_appraisal/team_appraisal_form/';
    sessionUrl = 'lnd/sessions/';
    selfAppraisalUrl = 'new_appraisal/self/';
    selfAppraisalFormUrl = 'new_appraisal/self_appraisal_form/';
    viewSelfAppraisalUrl = 'new_appraisal/view_self_appraisal_form/';
    viewTeamAppraisalUrl = 'new_appraisal/view_team_appraisal_form/';
    viewDepartmentAppraisalUrl = 'new_appraisal/view_department_appraisal_form/';
    viewBusinessAppraisalUrl = 'new_appraisal/view_business_appraisal_form/';
    deptAppraisalUrl = 'new_appraisal/department_appraisal/';
    deptAppraisalFormUrl = 'new_appraisal/department_appraisal_form/';
    buAppraisalUrl = 'new_appraisal/business_appraisal/';
    buAppraisalFormUrl = 'new_appraisal/business_appraisal_form/';
    departmentUrl = 'new_appraisal/get_departments/';
    promotionUrl = 'new_appraisal/promotion/';
    companyUrl = 'new_appraisal/company_appraisal/';
    accessToken = localStorage.getItem('accessToken');
    csrfToken = 'B3GTpk3bMbXFTjCPqQrNTiSS7Z8OWwOTmWEAQ9IlM74hZ67jzK6wzrHCOPpu8dtx';
    filteredData: any;

    constructor(
        private http: HttpClient,
        // private _cookieService: CookieService
    ) {
        console.log("obj created");
    }

    getCsrfToken(): string | null {
        // const cookies = document.cookie.split(';');
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i].trim();
        //     // Check if the cookie starts with 'csrftoken='
        //     if (cookie.startsWith('csrftoken=')) {
        //         // Extract and return the CSRF token value
        //         console.log("csrf token ================================>", cookie.substring('csrftoken='.length))
        //         return cookie.substring('csrftoken='.length);
        //     }
        // }
        // return ''; // CSRF token not found
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('csrftoken=')) {
                console.log(cookie.split('=')[1])
                return cookie.split('=')[1];
            }
        }
        return '';
    }
    getSelfAppraisalTemplate() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.selfAppraisalFormUrl, { headers });
    }

    viewSelfAppraisal(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.selfAppraisalUrl + id + '/', { headers });
    }

    createSelfappraisal(data) {
        // let csrf = this._cookieService.get("csrftoken");
        // if (typeof (csrf) === 'undefined') {
        //     csrf = '';
        // }
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
        };
        // const headers = new HttpHeaders().set('Authorization', this.accessToken)
        // .set('Authorization', this.accessToken);
        return this.http.post(this.baseUrl + this.selfAppraisalUrl, data, httpOptions);
    }

    getSelfApprasail() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.selfAppraisalUrl, { headers });
    }

    setData(data) {
        this.filteredData = data;
    }

    getData() {
        return this.filteredData;
    }

    getTeamAppraisal() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.teamAppraisalurl, { headers });
    }

    getTeamAppraisalForm(params) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
        };
        return this.http.get(this.baseUrl + this.teamAppraisalFormUrl, httpOptions);
    }

    createTeamAppraisal(id, data) {
        // const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
        };
        return this.http.post(this.baseUrl + this.teamAppraisalFormUrl + id + '/', data, httpOptions);
    }

    getDeptAppraisal() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.deptAppraisalUrl, { headers });
    }

    getDeptAppraisalForm(params) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
        };
        return this.http.get(this.baseUrl + this.deptAppraisalFormUrl, httpOptions);
    }

    createDeptAppraisal(params, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
            params: params
        };
        return this.http.post(this.baseUrl + this.deptAppraisalFormUrl, data, httpOptions);
    }

    getBUAppraisal() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.buAppraisalUrl, { headers });
    }

    getBUAppraisalForm(params) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
        };
        return this.http.get(this.baseUrl + this.buAppraisalFormUrl, httpOptions);
    }

    createBUAppraisal(params, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
            params: params
        };
        return this.http.post(this.baseUrl + this.buAppraisalFormUrl, data, httpOptions);
    }

    getDepartments(params) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
        };
        return this.http.get(this.baseUrl + this.departmentUrl, httpOptions);
    }

    viewEmployeeSelfAppraisal(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.viewSelfAppraisalUrl + id + '/', { headers });
    }

    viewEmployeeTeamAppraisal(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.viewTeamAppraisalUrl + id + '/', { headers });
    }

    viewEmployeeDepartmentAppraisal(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.viewDepartmentAppraisalUrl + id + '/', { headers });
    }

    viewEmployeeBusinessAppraisal(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.viewBusinessAppraisalUrl + id + '/', { headers });
    }

    createDepartmentBulkAppraisal(params, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
            params: params
        };
        return this.http.post(this.baseUrl + this.deptAppraisalFormUrl, data, httpOptions);
    }

    createBUBulkAppraisal(params, data) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRFToken': this.getCsrfToken(),
                'Authorization': this.accessToken
            }),
            params: params
        };
        return this.http.post(this.baseUrl + this.buAppraisalFormUrl, data, httpOptions);
    }

    getPromotionHistory(params) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
        };
        return this.http.get(this.baseUrl + this.promotionUrl, httpOptions);
    }

    getCompanyAppraisals() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.companyUrl, { headers });
    }
}
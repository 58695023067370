import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import {prefix} from '../../environments/utils';
import { Observable } from 'rxjs';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmponboardingService } from '../services/emponboarding.service';

interface Employee {
  name: string;
  contactNo: string;
  personalEmail: string;
  status: string;
  details: string;

  // Get from MS login
  
}
@Component({
  selector: 'app-hr-home',
  templateUrl: './hr-home.component.html',
  styleUrls: ['./hr-home.component.css']
})
export class HrHomeComponent implements OnInit {
  umail: string = this.EmpOnboardingService.umail;
  token: any;
  data: any;
  fb = new FormBuilder();
   users$: Observable<any> | undefined; 
  showcard: any;
  showhr: any;
  showPOA: any;
  showAmdoc: any;
  // users$:any;

  constructor( private http: HttpClient, public dialog : MatDialog, private EmpOnboardingService:EmponboardingService) { 
   
  }

  ngOnInit() {
    this.getUsers();
  }
  getUsers() {
    // this.users$ = this.http.get(prefix+'/get_all_profiles/?umail=lm@gmail.com');
    // this.EmpOnboardingService.getUser().subscribe((res)=>{
    //   this.users$=res;
    // })
    this.users$=this.EmpOnboardingService.getUser()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      // width: '450px',
      panelClass: 'addreqcustomclass',
      // height: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'status approved';
      case 'user_pending':
        return 'status user_pending';
      case 'hr_pending':
        return 'status HR_pending';
      case 'reopen':
        return 'status reopen';
      default:
        return '';
    }
  }
  getStatusName(status: string): string {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'Approved';
      case 'user_pending':
        return 'Pending by user';
      case 'hr_pending':
        return 'pending by HR';
      case 'reopen':
        return 'Re-opened';
      default:
        return 'NA';
    }
  }

  openUserProfile(user: any,status:string) {
    // window.location.href = '/verify-user-data?umail=' + this.umail + '&email=' + user.email;
    if(status=="approved"){
      window.open('/add-to-emp?umail=' + this.umail + '&email=' + user.email, '_blank') 
    }
    else{
      window.open('/verify-user-data?umail=' + this.umail + '&email=' + user.email, '_blank')
    }
  
  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata){
    this.showhr=showhrmanagerdata
    
 }
  poashow(showpoa){
    this.showPOA=showpoa;
  }
  amdocshow(showamdoc){
    this.showAmdoc=showamdoc;
}
}

import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/core/login/login.component';
import { LoginService } from 'src/app/core/login/login.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { ExcelService } from 'src/app/core/services/excel.service';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    providers: [ExcelService]
})
export class FeedbackComponent implements OnInit {

    // @Input() data: any;
    form: any;
    title: any;
    id: any;
    modality: any;
    location: any;
    date: any;
    startTime: any;
    endTime: any;
    message: any;
    dataPresent = false;
    submitEnable = true;
    feedbackList: any = [];
    stars = [1, 2, 3, 4, 5];
    combinedList: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FeedbackComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private adminService: AdminService,
        private loginService: LoginService,
        private excelService: ExcelService
        // private toast: toaste
    ) { }

    ngOnInit() {
        console.log("inside feedback component", this.data);
        if (this.data.data != null) {
            this.dataPresent = true;
            this.id = this.data.data.id;
            this.title = this.data.data.name;
            this.modality = this.data.data.modality;
            this.location = this.data.data.location;
            this.date = formatDate(this.data.data.start_time, "MMM dd, yyyy", 'en-IN');
            this.startTime = formatDate(this.data.data.start_time, "HH:mm aa", 'en-IN');
            this.endTime = formatDate(this.data.data.end_time, "HH:mm aa", 'en-IN');
        }
        this.getFeedbacks(this.data.data.id);
    }

    getFeedbacks(id) {
        this.adminService.allfeedbacks(id).subscribe((res: any) => {
            console.log(res.data.feedback, "<--------feedbacks");
            this.feedbackList = res.data.feedback;
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }


    cancel() {
        this.dialogRef.close();
    }

    exportAsXLSX(s_id, s_name, date): void {
        this.combinedList = [...this.feedbackList];
        if (this.combinedList[0]['employee']) {
            for (let i = 0; i < this.combinedList.length; i++) {
                this.combinedList[i]['employee_name'] = this.combinedList[i].employee.name;
                this.combinedList[i]['job_title'] = this.combinedList[i].employee.job_title;
                this.combinedList[i]['department'] = this.combinedList[i].employee.department;
                this.combinedList[i]['email'] = this.combinedList[i].employee.email;
                this.combinedList[i]['cluster'] = this.combinedList[i].employee.cluster;
                this.combinedList[i]['manager_name'] = this.combinedList[i].employee.manager_name;
                this.combinedList[i]['manager_email'] = this.combinedList[i].employee.manager_email;
                delete this.combinedList[i]['employee'];
                delete this.combinedList[i]['session'];
                delete this.combinedList[i]['id'];
            }
        }
        // console.log(JSON.stringify(excelJsonData));
        this.excelService.exportAsExcelFile(this.combinedList, s_id + '__' + s_name + '__' + date + '__feedbacks');
    }

}
<div class="row">
    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
        <div class="row">
            <div class="col c-keys">
                Total Employees
            </div>
        </div>
        <div class="row">
            <div class="col c-values">
                {{count}}
            </div>
        </div>
    </div>
    <div *ngIf="!midCycle" class="col a-card" style="margin: 3vh 0vh 2vh;">
        <div class="row">
            <div class="col c-keys">
                Self Appraisal Status
            </div>
        </div>
        <div class="row">
            <div class="col c-values">
                {{selfAp}}/{{count}}
            </div>
            <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_ic">
                Due: {{appraisalcycle.due_date_for_ic}}
            </div>
            <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_ic">
                Due: {{appraisalcycle.due_date_for_ic}}
            </div>
            <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_ic">
                Due: {{appraisalcycle.due_date_for_ic}}
            </div>
        </div>
    </div>
    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
        <div class="row">
            <div class="col c-keys">
                RM Appraisal Status
            </div>
        </div>
        <div class="row">
            <div class="col c-values">
                {{teamAp}}/{{count}}
            </div>
            <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_rm">
                Due: {{appraisalcycle.due_date_for_rm}}
            </div>
            <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_rm">
                Due: {{appraisalcycle.due_date_for_rm}}
            </div>
            <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_rm">
                Due: {{appraisalcycle.due_date_for_rm}}
            </div>
        </div>
    </div>
    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
        <div class="row">
            <div class="col c-keys">
                Dept. Appraisal Status
            </div>
        </div>
        <div class="row">
            <div class="col c-values">
                {{deptAp}}/{{count}}
            </div>
            <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_dept">
                Due: {{appraisalcycle.due_date_for_dept}}
            </div>
            <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_dept">
                Due: {{appraisalcycle.due_date_for_dept}}
            </div>
            <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_dept">
                Due: {{appraisalcycle.due_date_for_dept}}
            </div>
        </div>
    </div>
    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
        <div class="row">
            <div class="col c-keys">
                BU Appraisal Status
            </div>
        </div>
        <div class="row">
            <div class="col c-values">
                {{buAp}}/{{count}}
            </div>
            <div class="col danger" *ngIf="appraisalcycle!=undefined && today > appraisalcycle.due_date_for_bu">
                Due: {{appraisalcycle.due_date_for_bu}}
            </div>
            <div class="col warn" *ngIf="appraisalcycle!=undefined && today == appraisalcycle.due_date_for_bu">
                Due: {{appraisalcycle.due_date_for_bu}}
            </div>
            <div class="col green" *ngIf="appraisalcycle!=undefined && today < appraisalcycle.due_date_for_bu">
                Due: {{appraisalcycle.due_date_for_bu}}
            </div>
        </div>
    </div>
</div>

<div class="row" style="margin: 3vh -3vh;">
    <div class="col"></div>
    <div class="col-2">
        <button class="btn btn-primary" (click)="bulkSubmit()">Bulk Submit</button>
    </div>
</div>

<div class="accordion" id="accordionPanelsStayOpenExample" style="padding-bottom: 20vh;">
    <div class="accordion-item" *ngFor="let item of reportees; let i = index">
        <div class="accordion-header" id="panelsStayOpen-headingOne">
            <button class="accordion-button" [ngClass]="{'collapsed': i > 0}" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse'+ i" aria-expanded="true" [attr.aria-controls]="'#collapse'+ i">
                <div class="row">
                    <div class="col">
                        {{item.name}}
                    </div>
                </div>
                <div class="col" style="text-align: end;">
                    <span *ngIf="!midCycle" class="badge rounded-pill text-bg-secondary">Self:&nbsp;
                        {{item.total_self_appraisal_done}}/{{item.members.length}}</span>
                    &nbsp; <span class="badge rounded-pill text-bg-secondary">RM:&nbsp;
                        {{item.total_team_appraisal_done}}/{{item.members.length}}</span>
                    &nbsp; <span class="badge rounded-pill text-bg-secondary">Dept.:&nbsp;
                        {{item.total_department_appraisal_done}}/{{item.members.length}}</span>
                    &nbsp; <span class="badge rounded-pill text-bg-secondary">BU:&nbsp;
                        {{item.total_business_appraisal_done}}/{{item.members.length}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                </div> 
            </button>
        </div>
        <div class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" id="collapse{{i}}"
            [ngClass]="{'show': i === 0}">
            <div class="accordion-body">
                <!-- <div class="row">
                    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                        <div class="row">
                            <div class="col c-keys">
                                Total
                            </div>
                        </div>
                        <div class="row">
                            <div class="col c-values">
                                {{item.members.length}}
                            </div>
                        </div>
                    </div>
                    <div class="col a-card" style="margin: 3vh 0vh 2vh;">
                        <div class="row">
                            <div class="col c-keys">
                                Self Status
                            </div>
                        </div>
                        <div class="row">
                            <div class="col c-values">
                                {{item.total_self_appraisal_done}}/{{item.members.length}}
                            </div>
                        </div>
                    </div>
                    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                        <div class="row">
                            <div class="col c-keys">
                                RM Status
                            </div>
                        </div>
                        <div class="row">
                            <div class="col c-values">
                                {{item.total_team_appraisal_done}}/{{item.members.length}}
                            </div>
                        </div>
                    </div>
                    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                        <div class="row">
                            <div class="col c-keys">
                                Dept. Status
                            </div>
                        </div>
                        <div class="row">
                            <div class="col c-values">
                                {{item.total_department_appraisal_done}}/{{item.members.length}}
                            </div>
                        </div>
                    </div>
                    <div class="col a-card" style="margin: 3vh 2vh 2vh;">
                        <div class="row">
                            <div class="col c-keys">
                                BU Status
                            </div>
                        </div>
                        <div class="row">
                            <div class="col c-values">
                                {{item.total_business_appraisal_done}}/{{item.members.length}}
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col">
                        <ngx-datatable [rows]="item.members" [columns]="[
                        { name: 'Employee ID' },
                        { name: 'Name' },
                        { name: 'Designation' },
                        { name: 'Self Appraisal Status' },
                        { name: 'RM Appraisal Status'},
                        { name: 'Dept. Appraisal' },
                        { name: 'BU Appraisal' },
                        { name: 'Date of Joining' },
                        { name: 'Promotion History' }
                        ]" [columnMode]="'flex'" [headerHeight]="'auto'" [footerHeight]="0" [rowHeight]="'auto'"
                            [externalPaging]="true" [count]="item.members.length" [offset]="offset" [limit]="limit"
                            [scrollbarH]="true" (activate)="onActivate($event)" style="cursor: pointer;">

                            <ngx-datatable-column name="Employee ID" [minWidth]="120" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.dept_appraisal_done">
                                        {{row.emp_id }}
                                    </div>
                                    <div *ngIf="row.dept_appraisal_done" class="view">
                                        {{row.emp_id }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Name" [flexGrow]="0" [minWidth]='180' [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{ row.fname | titlecase }}&nbsp;{{row.lname | titlecase}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Designation" [flexGrow]="0" [minWidth]='180'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="row.functional_designation != null">{{ row.job_title 
                                        }}({{row.functional_designation }})</div>
                                    <div *ngIf="row.functional_designation == null">{{ row.job_title 
                                        }}</div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column *ngIf="!midCycle" name="Self Appraisal" [flexGrow]="0" [minWidth]='180'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.self_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-danger">Pending</span>
                                        </div>
                                    </div>
                                    <div *ngIf="row.self_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-success">Done</span>
                                        </div>
                                        <div class="col view" (click)="openViewSelf(row)">
                                            View
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="RM Appraisal" [canAutoResize]="false" [minWidth]='180'
                                [flexGrow]="0" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.team_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-danger">Pending</span>
                                        </div>
                                    </div>
                                    <div *ngIf="row.team_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-success">Done</span>
                                        </div>
                                        <div class="col view" (click)="openViewTeam(row)">
                                            View
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Dept. Appraisal" [canAutoResize]="false" [minWidth]='180'
                                [flexGrow]="0" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.dept_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-danger">Pending</span>
                                        </div>
                                    </div>
                                    <div *ngIf="row.dept_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-success">Done</span>
                                        </div>
                                        <div class="col view" (click)="openViewDepartment(row)">
                                            View
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="BU Appraisal" [canAutoResize]="false" [minWidth]='180'
                                [flexGrow]="0" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div *ngIf="!row.business_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-danger">Pending</span>
                                        </div>
                                    </div>
                                    <div *ngIf="row.business_appraisal_done" class="row">
                                        <div class="col-5">
                                            <span class="badge text-bg-success">Done</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <!-- <ngx-datatable-column name="Qualifications(UG)" [flexGrow]="0" [minWidth]='180'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{ row.qualification_ug }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Qualifications(PG)" [width]='180' [flexGrow]='0'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.qualification_pg}}
                                </ng-template>
                            </ngx-datatable-column> -->
                            <ngx-datatable-column name="Date of Joining" [flexGrow]="0" [minWidth]='180'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{ row.doj }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Promotion History" [flexGrow]="0" [minWidth]='180'
                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="promotion" (click)="getPromotions(row)">
                                        Click
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
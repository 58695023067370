import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from 'src/app/components/expense-manager/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from "@angular/router";
import { TemplateServiceService } from '../service/template-service.service'
import { LearnerService } from 'src/app/core/services/learner.service';
import { NotificationServiceService } from 'src/app/components/notification-module/service/notification-service.service';
import { FileInfo } from 'src/app/components/input-multiple-file/input-multiple-file.component'

interface FileWithTitle {
  id: any;
  file: File;
}

@Component({
  selector: 'app-save-template',
  templateUrl: './save-template.component.html',
  styleUrls: ['./save-template.component.css']
})

export class SaveTemplateComponent implements OnInit {
  templateNames: string[] = [];
  response: any;
  documents: any = [];
  inputText: boolean = false;
  dateType: boolean = false;
  form: FormGroup;
  formData: any[];
  selectedFile: File;
  fileName: string = '';
  inputHeading: string = '';
  filteredTextInputs: any[] = [];  // Initialize it here
  filteredTextMultipleFile: any[] = []
  filteredTextDate: any[] = []
  filteredTextFile: any[] = []
  filteredTextNumber: any[] = []
  filteredTextDropdown: any[] = []
  service_name: any;
  files: FileWithTitle[] = [];
  templateValues: any[] = [];
  action: string
  service: string
  item_id: any
  id: any;
  service_id: any;
  submitBtn: boolean;
  editBtn: boolean;
  deleteBtn: boolean;
  expiry_date: any;
  task_notification_id: any;
  fields: any;
  required: any[] = [];
  multipleFiles: File[] = [];
  filter: any;
  count: any;


  constructor(private templateservice: TemplateServiceService, private notifyservice: NotificationServiceService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private learnerService: LearnerService,) {

  }


  ngOnInit(): void {

    // this.templateservice.getTemplate(null).subscribe((response: any) => {
    //   // console.log('the res is ', res['data'])
    //   // console.log('the res is ', res['status'])


    //   this.formData = response.data;
    //   this.response = response.data;

    //   response.forEach(template => {
    //     this.fields = template.fields
    //     this.templateNames.push(template.name);

    //   });

    //   if (this.fields) {
    //     if (Array.isArray(this.fields)) {
    //       this.fields.forEach(field => {
    //         const isRequired = field.required
    //         if (isRequired == true) {
    //           this.required.push({ id: field.id });
    //         }
    //       });
    //     }
    //     console.log('required fileds are', this.required)


    //   }

    //   // if i get the id from the url so make a put get request
    //   this.route.queryParams.subscribe(params => {
    //     this.item_id = params['id'];
    //     this.service_id = params['service_id'];
    //     this.action = params['action']


    //     if (this.item_id && this.service_id && this.action) {

    //       document.getElementById('dropdownTemplate').style.display = 'none';
    //       this.makeTemplate(this.service_id)
    //       console.log('service is ', this.service)

    // this.templateservice.getTemplateValue(this.item_id, this.service_id).subscribe((res: any) => {
    //   const data = res[0].values
    //   const baseURL = 'https://dev-empower-docs.s3.amazonaws.com/';

    //   for (const key in data) {

    //     if (Array.isArray(data[key])) {

    //       console.log('File key is', key)
    //       const inputElement = document.getElementById(key) as HTMLInputElement;

    //       if (inputElement && data[key] != "") {
    //         // https://dev-empower-docs.s3.amazonaws.com/
    //         let fileUrl = baseURL + data[key]
    //         inputElement.innerHTML = fileUrl;
    //       }


    //     } else {
    //       const inputElement = document.getElementById(key) as HTMLInputElement;
    //       if (inputElement) {
    //         console.log('the data key is ', data[key])
    //         inputElement.value = data[key];
    //       }

    //     }

    //   }

    // });

    //       if (this.action == 'delete') {
    //         this.deleteBtn = true
    //         // document.getElementById('deleteBtn').style.display = 'block'
    //         // document.getElementById('submitBtn').style.display = 'none'


    //       }
    //       else if (this.action == 'edit') {
    //         this.editBtn = true
    //         // console.log('edit button clicked')
    //         // document.getElementById('updateBtn').style.display = 'block'
    //         // document.getElementById('submitBtn').style.display = 'none'



    //       }
    //       else if (this.action == 'view') {
    //         console.log('the action is view bhai')
    //         // document.getElementById('submitBtn').style.display = 'none'

    //       }

    //     }
    //     else if (this.service_id) {
    //       document.getElementById('dropdownTemplate').style.display = 'none';
    //       this.makeTemplate(this.service_id)
    //       this.submitBtn = true;

    //       // do something 
    //     }
    //     else {

    //     }


    //   })

    // });

    //   this.templateservice.getTemplate(null).subscribe((res: any) => {
    //     console.log('the response is ', res);

    // });

    this.route.queryParams.subscribe(params => {
      this.service_id = params['service_id'];
      this.action = params['action'];
      this.item_id = params['id'];
      this.filter = params['filter']
      this.count = params['count']

      if (this.count > 0) {
        this.updateLinkText(this.filter, this.count)

      }


      const filterElement = document.getElementById(this.filter);
      if (filterElement) {
        filterElement.style.color = '#07527D';
        filterElement.style.fontWeight = '600';
      }


      this.templateservice.getTemplate(this.service_id).subscribe((response: any) => {
        if (response.status == 200) {
          this.formData = response.data;
          this.response = response.data.fields;
          this.service_name = response.data.name;
          this.makeTemplate();
        }

        if (this.item_id && this.service_id && this.action) {
          this.templateservice.getTemplateValue(this.item_id, this.service_id, 'false', null).subscribe((response: any) => {
            // Add your code here for handling the response of getTemplateValue

            if (response.status == 200) {
              const data = response.data[0].values;
              // const baseURL = 'https://dev-empower-docs.s3.amazonaws.com/';
              const baseURL = 'https://apazciempowerstrg.blob.core.windows.net/dev-empower-documents/';


              for (const key in data) {

                if (Array.isArray(data[key])) {
                  // create a div below the input tag with ids as 
                  this.templateservice.getDataType(this.item_id).subscribe((response: any) => {
                    if (response.status == 200) {

                      console.log(response.data)
                      if (response.data == key) {

                        let displayDiv = document.getElementById(`multiFile${key}`) as HTMLInputElement
                        // get the value of mulitfile
                        displayDiv.style.display = 'flex';
                        displayDiv.style.flexDirection = 'column';

                        data[key].forEach((fileUrl: string, index) => {

                          let file = baseURL + fileUrl;
                          let displayText = fileUrl.replace('documents/', '');
                          let aTag = document.createElement('a');
                          aTag.innerHTML = displayText;
                          aTag.className = 'viewBtn';
                          aTag.id = `multi${index}`;
                          aTag.style.textDecoration = 'none';

                          aTag.href = fileUrl

                          //   // Attach click event listener
                          aTag.addEventListener('click', (event) => {
                            event.preventDefault();
                            this.viewDownloadPdf(file);
                          });

                          //   // Append the button to the displayDiv
                          displayDiv.appendChild(aTag);
                        });



                      }
                      else {
                        const aTag = document.getElementById(key) as HTMLAnchorElement;

                        if (aTag && data[key] != "") {
                          // https://dev-empower-docs.s3.amazonaws.com/

                          data[key].forEach((fileUrl, index) => {
                            let file = baseURL + fileUrl
                            let displayText = fileUrl.replace('documents/', '');
                            aTag.innerHTML = displayText;
                            aTag.href = fileUrl

                            aTag.addEventListener('click', (event) => {
                              event.preventDefault();
                              // console.log('The id of atag clicked is ',aTag.id)
                              // console.log('Clicked file:', fileUrl);
                              // console.log('the file is',file)
                              this.viewDownloadPdf(file);
                            });



                          })
                        }
                      }
                    }
                  })

                  if (this.action == 'view') {

                    let fileInput = document.getElementById(`file${key}`) as HTMLInputElement;
                    fileInput.disabled = true;
                  
                    const notifyButtons = document.querySelectorAll('.btn-notify') as NodeListOf<HTMLButtonElement>;
                    notifyButtons.forEach(button => {
                      button.style.display = 'none';
                    });
                  }
                  


                }


                else {
                  const inputElement = document.getElementById(key) as HTMLInputElement;
                  if (inputElement) {
                    inputElement.value = data[key];
                  }
                  if (this.action == 'view') {
                    inputElement.readOnly = true;
                  }

                }

              }

              if (this.action == 'edit') {
                this.editBtn = true;

              }

            }



          });
        }
      });
    });

  }

  makeTemplate() {
    if (this.action == 'new') {
      this.submitBtn = true
    }
    console.log('this.fields is ', this.response)
    this.response.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      return 0;
    });
    this.response.forEach(item => {
      if (item.type === 'text') {
        this.filteredTextInputs.push(item);
      }
      else if (item.type === 'date') {
        this.filteredTextDate.push(item);
      } else if (item.type === 'file') {
        this.filteredTextFile.push(item);
      } else if (item.type === 'number') {
        this.filteredTextNumber.push(item);
      }
      else if (item.type === 'multiple_file') {
        this.filteredTextMultipleFile.push(item)
      }
      else if (item.type == 'dropdown') {
        this.filteredTextDropdown.push(item)
      }

    })




    // this.response.forEach(template => {
    //   console.log('template is ',template)

    //   if (template.id = service_id) {
    //     this.service_name = template.name
    //     this.formData = template.fields;
    //     console.log(this.formData)

    //     this.formData.sort((a, b) => {
    //       if (a.order && b.order) {
    //         return a.order - b.order;
    //       }
    //       return 0;
    //     });

    //     console.log("Sorted formData:", this.formData);

    //     const filteredFields = this.formData.reduce((acc, item) => {
    //       if (item.type === 'text') {
    //         acc.filteredTextInputs.push(item);
    //       } else if (item.type === 'date') {
    //         acc.filteredTextDate.push(item);
    //       } else if (item.type === 'file') {
    //         acc.filteredTextFile.push(item);
    //       } else if (item.type === 'number') {
    //         acc.filteredTextNumber.push(item);
    //       }
    //       return acc;
    //     }, { filteredTextInputs: [], filteredTextDate: [], filteredTextFile: [], filteredTextNumber: [] });

    //     console.log("Filtered fields:", filteredFields);

    //     this.filteredTextInputs = filteredFields.filteredTextInputs;
    //     this.filteredTextDate = filteredFields.filteredTextDate;
    //     this.filteredTextFile = filteredFields.filteredTextFile;
    //     this.filteredTextNumber = filteredFields.filteredTextNumber;
    //   }
    // });
  }

  onFileSelected(event: any, id: any) {
    const selectedFiles: FileList = event.target.files;
    const existingFileIndex = this.files.findIndex(file => file.id === id);

    if (existingFileIndex !== -1) {
      this.files.splice(existingFileIndex, 1);
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      const fileWithTitle: FileWithTitle = {
        id: id,
        file: selectedFiles.item(i)!
      };
      this.files.push(fileWithTitle);
      console.log('File with title:', fileWithTitle);
    }
  }

  viewDownloadPdf(file) {
    console.log('the file is', file)
    this.learnerService.getViewDownloadPdf(file).subscribe(res => {

      this.documents = res;
      console.log('document is ', this.documents)
      // alert(this.documents.presigned_url);
      window.open(this.documents.presigned_url, "_blank");
      //window.location.reload();
    },
      (error: any) => {
        console.log(error, error.error, error.message);

      });
  }

  // viewDownloadPdf(file) {
  //   console.log(`the file is `, file)
  //   this.learnerService.getViewDownloadPdf(file).subscribe(
  //     res => {
  //       console.log();

  //       this.documents = res;
  //       console.log('document is ', this.documents);
  //       // Create an anchor element
  //       const a = document.createElement('a');
  //       a.href = this.documents.presigned_url;
  //       a.target = '_blank'; // Open in a new window
  //       a.download = ''; // Suggests the browser to download the file

  //       // Append the anchor to the body
  //       document.body.appendChild(a);

  //       // Trigger a click event on the anchor
  //       a.click();

  //       // Remove the anchor from the document
  //       document.body.removeChild(a);
  //     },
  //     (error: any) => {
  //       console.log(error, error.error, error.message);
  //     }
  //   );
  // }


  getTheFileUrl(id: any) {
    const fileUrlElement = document.getElementById(id);
    const imageUrl = fileUrlElement.innerHTML;
    this.viewDownloadPdf(imageUrl)

  }

  // navigate() {
  //   if (this.task_notification_id) {
  //     this.router.navigate(['/notify'], { queryParams: { id: this.id, service_id: this.service_id, expiry_date: this.expiry_date, task_notification_id: this.task_notification_id } });

  //   }
  //   else {
  //     this.router.navigate(['/notify'], { queryParams: { id: this.id, service_id: this.service_id, expiry_date: this.expiry_date } });

  //   }
  // }


  addOrRemoveErrorMessage(inputElement: HTMLInputElement) {
    const id = inputElement.id
    let isEmpty = true;
    console.log('the id inside is ', id)

    if (inputElement.required && inputElement.value === '') {
      isEmpty = false;
      let span = (document.getElementById(`${id}_span`) as HTMLInputElement)
      span.style.display = 'block';
    }
    return isEmpty;
  }

  //   submitForm(act: any, tag: any) {
  //     let isValid = true;
  //     let isEmpty = true;
  //     let isEmptyOverall = true;


  //     let values = {};
  //     let formData = new FormData();
  //     formData.append('service_name', this.service_name)

  //     const multipleFiles: FileInfo[] = this.templateservice.getFiles();
  //     console.log('multiple files are', multipleFiles); 

  //     multipleFiles.forEach((fileInfo, index) => {
  //       console.log('the multiple file is', fileInfo);
  //       formData.append(`file_${fileInfo.id}_${index}_`, fileInfo.file);
  //       // formData.append(`file_id_${index}`, fileInfo.id);

  //     });

  //     // now iterate this multipleFiles and append in the formdata as files


  //     for (let i = 0; i < this.filteredTextInputs.length; i++) {
  //       const item = this.filteredTextInputs[i];
  //     this.filteredTextInputs.forEach((item, index) => {
  //       const id = item.id;
  //       let inputElement = (document.getElementById(id) as HTMLInputElement);
  //       isEmpty = this.addOrRemoveErrorMessage(inputElement);

  //       if (!isEmpty) {
  //         isEmptyOverall = false;
  //         console.log('Element with id', id, 'is empty. Stopping form submission.');
  //         return;
  //       }

  //       if (inputElement.classList.contains('is-invalid')) {
  //         isValid = false;
  //         console.log('Element with id', id, 'has class is-invalid');
  //       }

  //       let inputValue = inputElement.value
  //       values[item.id] = inputValue

  //     });

  //     this.filteredTextDate.forEach((item, index) => {
  //       // for (let i = 0; i < this.filteredTextDate.length; i++) {
  //       //   const item = this.filteredTextInputs[i];
  //       const id = item.id;
  //       let inputElement = (document.getElementById(id) as HTMLInputElement);
  //       isEmpty = this.addOrRemoveErrorMessage(inputElement);

  //       if (!isEmpty) {
  //         isEmptyOverall = false;
  //         console.log('Element with id', id, 'is empty. Stopping form submission.');
  //         return;
  //       }

  //       if (inputElement.classList.contains('is-invalid')) {
  //         isValid = false;
  //         console.log('Element with id', id, 'has class is-invalid');
  //       }

  //       let inputValue = inputElement.value
  //       values[item.id] = inputValue

  //     });

  //     this.filteredTextNumber.forEach((item, index) => {
  //       // for (let i = 0; i < this.filteredTextNumber.length; i++) {
  //       // const item = this.filteredTextInputs[i];
  //       const id = item.id;
  //       let inputElement = (document.getElementById(id) as HTMLInputElement);
  //       console.log('inside the number ')
  //       isEmpty = this.addOrRemoveErrorMessage(inputElement);

  //       if (!isEmpty) {
  //         isEmptyOverall = false;
  //         console.log('Element with id', id, 'is empty. Stopping form submission.');
  //         return;
  //       }

  //       if (inputElement.classList.contains('is-invalid')) {
  //         isValid = false;
  //         console.log('Element with id', id, 'has class is-invalid');
  //       }

  //       let inputValue = inputElement.value
  //       values[item.id] = inputValue

  //     });


  //     this.filteredTextFile.forEach((item, index) => {
  //       // for (let i = 0; i < this.filteredTextFile.length; i++) {
  //       // const item = this.filteredTextInputs[i];
  //       const id = `file${item.id}`;
  //       console.log('inside the file loop')
  //       let inputElement = (document.getElementById(id) as HTMLInputElement)
  //       let value = inputElement.value

  //       console.log('value hai ', value)

  //       if (value) {
  //         console.log('it means file is uploaded do nothing')
  //       }
  //       else {
  //         console.log('no the file is not uploaded')
  //         // if file is not uploaded than take the file from the id 
  //         let existing_file = (document.getElementById(item.id) as HTMLInputElement);
  //         if (existing_file && existing_file.innerHTML) {

  //           let innerHTML = existing_file.innerHTML;
  //           console.log('Original innerHTML:', innerHTML);

  //           // Remove the specific part
  //           let updatedInnerHTML = innerHTML.replace('https://dev-empower-docs.s3.amazonaws.com/', '');
  //           console.log('Updated innerHTML:', updatedInnerHTML);

  //           // Set the updated innerHTML back to the element

  //           existing_file.innerHTML = innerHTML;
  //           values[item.id] = [updatedInnerHTML]


  //         }
  //         else {
  //           // no file is uploaded 
  //           if (inputElement.required) {
  //             alert('Upload the file')
  //             isEmpty = false;
  //             return;

  //           }


  //         }

  //       }


  //     });


  //     if (!isEmptyOverall || !isValid) {
  //       if (!isValid) {
  //         alert('Invalid value in the field !');
  //         console.log('Form submission aborted due to invalid input.');
  //       }
  //       return;
  //     }

  //     this.files.forEach((fileWithTitle, index) => {
  //       formData.append(`file_${fileWithTitle.id}`, fileWithTitle.file);
  //     });

  //     let jsonString = JSON.stringify(values);

  //     formData.append('values', jsonString);

  //     const dialogRef = this.dialog.open(ConfirmationDialogComponent);

  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result == 1) {
  //         console.log('the values are ', values)

  //         if (this.item_id) {

  //           if (this.action == 'edit') {
  //             //  now identify that it is edit or delete

  //             if (act == 1 && tag) {
  //               // update so 2 case renwe and edit 
  //               this.templateservice.updateValue(formData, this.item_id, tag).subscribe((response: any) => {

  //                 if(response.status==200){

  //                   this.id = response.data.id
  //                   this.expiry_date = response.data['values']['5']
  //                   this.task_notification_id = response.data.task_notification_id

  //                   // document.getElementById('notificationBtn').style.display = 'block';

  //                   this.notifyservice.updateNotification(this.task_notification_id, this.service_id, tag, this.expiry_date).subscribe((res: any) => {
  //                     console.log('response is ', res)
  //                     alert("Updated Successfully")

  //                     this.router.navigate(['template', 'view_template'], {
  //                       queryParams: {
  //                         service_id: this.service_id
  //                       }
  //                     });
  //                     // this.router.navigate(['template', 'view_template']);

  //                   });

  //                 }



  //                 // this.router.navigate(['template', 'view_template'],{
  //                 //   queryParams:{
  //                 //     service_id:this.service_id
  //                 //   }
  //                 // });

  //                 // if (res.status === 200) {
  //                 //   alert('Successfully Edited')
  //                 //   this.router.navigate(['template', 'view_template'], { 
  //                 //     queryParams: { 
  //                 //       value: 'requested'
  //                 //     } 
  //                 //   });
  //                 //   // document.getElementById('notificationBtn').style.display = 'block';
  //                 //   // this.id = res.id

  //                 // }
  //                 // else {
  //                 //   alert('Some Error occurred bro')
  //                 // }



  //               })


  //             }
  //             else if (act == 2) {
  //               // delete
  //               this.templateservice.deleteValue(this.item_id).subscribe((response: any) => {
  //                 if(response.status==200){

  //                   this.task_notification_id = response.data.task_notification_id

  //                   // alert('Successfully Deleted')
  //                   // this.router.navigate(['template', 'view_template'], { queryParams: { service_id: this.service_id } });

  //                   this.notifyservice.deleteNotification(this.task_notification_id, this.service_id).subscribe((response: any) => {
  //                     console.log('response is ', response)
  //                     alert("Deleted Successfully")

  //                     this.router.navigate(['template', 'view_template'], {
  //                       queryParams: {
  //                         service_id: this.service_id
  //                       }
  //                     });
  //                     // this.router.navigate(['template', 'view_template']);

  //                   });


  //                 }


  //                 // if (res.status = 200) {
  //                 // alert('Successfully Deleted')


  //                 //   // document.getElementById('notificationBtn').style.display = 'block';
  //                 //   // this.id = res.id

  //                 // }
  //                 // else {
  //                 //   alert('Some Error occurred')

  //                 // }



  //               })

  //             }

  //           }


  //         }
  //         else {
  //           // make a post request
  //           this.templateservice.saveValue(formData, this.service_id).subscribe((response: any) => {
  //             console.log(response)
  //             // alert('Saved Successfully , add the notification')
  //             this.id = response.data.id

  //             this.expiry_date = response.data['values']['5']
  //             console.log('expiry date is ',this.expiry_date)

  //             // set the notification from here call the notification api
  //             if(response.status==201){

  //               this.notifyservice.saveNotification(this.service_id, this.id, this.expiry_date).subscribe((res: any) => {
  //                 console.log('response is ', res)
  //                 alert("Saved Successfully")
  //                 this.router.navigate(['template', 'view_template'], { queryParams: { service_id: this.service_id } });


  //               });

  //             }
  //             else{
  //               console.log('error occured')
  //             }

  //             // this.submitBtn=false;



  //             // get the expired date from the res and store it in a variable


  //             // this.router.navigate(['template', 'view_template']);

  //             // if (res.status == 201) {
  //             //   alert('Saved Successfully')
  //             //   this.router.navigate(['template', 'view_template'], { 
  //             //     queryParams: { 
  //             //       value: 'requested'
  //             //     } 
  //             //   });


  //             // }
  //             // else {
  //             //   alert('Some Error occurred')

  //             // }



  //           })



  //         }

  //       }
  //     })

  //   }



  // }

  submitForm(act: any, tag: any) {
    let isValid = true;
    let isEmpty = true;
    let isEmptyOverall = true;

    let values = {};
    let formData = new FormData();
    formData.append('service_name', this.service_name);

    // now iterate this multipleFiles and append in the formdata as files

    this.filteredTextInputs.forEach((item, index) => {
      const id = item.id;
      let inputElement = (document.getElementById(id) as HTMLInputElement);
      isEmpty = this.addOrRemoveErrorMessage(inputElement);

      if (!isEmpty) {
        isEmptyOverall = false;
        return;
      }

      if (inputElement.classList.contains('is-invalid')) {
        isValid = false;
      }

      let inputValue = inputElement.value;
      values[item.id] = inputValue;
    });

    this.filteredTextDropdown.forEach((item, index) => {
      const id = item.id;
      let inputElement = (document.getElementById(id) as HTMLInputElement);
      isEmpty = this.addOrRemoveErrorMessage(inputElement);

      if (!isEmpty) {
        isEmptyOverall = false;
        return;
      }

      if (inputElement.classList.contains('is-invalid')) {
        isValid = false;
      }

      let inputValue = inputElement.value;
      values[item.id] = inputValue;
    });

    this.filteredTextDate.forEach((item, index) => {
      const id = item.id;
      let inputElement = (document.getElementById(id) as HTMLInputElement);
      isEmpty = this.addOrRemoveErrorMessage(inputElement);

      if (!isEmpty) {
        isEmptyOverall = false;
        return;
      }

      if (inputElement.classList.contains('is-invalid')) {
        isValid = false;
      }

      let inputValue = inputElement.value;
      values[item.id] = inputValue;
    });

    this.filteredTextNumber.forEach((item, index) => {
      const id = item.id;
      let inputElement = (document.getElementById(id) as HTMLInputElement);
      isEmpty = this.addOrRemoveErrorMessage(inputElement);

      if (!isEmpty) {
        isEmptyOverall = false;
        return;
      }

      if (inputElement.classList.contains('is-invalid')) {
        isValid = false;
      }

      let inputValue = inputElement.value;
      values[item.id] = inputValue;
    });

    this.filteredTextFile.forEach((item, index) => {
      const id = `file${item.id}`;
      let inputElement = (document.getElementById(id) as HTMLInputElement);
      let value = inputElement.value;

      if (value) {
        console.log('it means file is uploaded do nothing');
      } else {
        console.log('no the file is not uploaded');
        let aTag = (document.getElementById(item.id) as HTMLAnchorElement);
        if (aTag && aTag.href) {

          // let innerHTML = existing_file.innerHTML;
          // console.log('Original innerHTML:', innerHTML);

          // let updatedInnerHTML = innerHTML.replace('https://dev-empower-docs.s3.amazonaws.com/', '');
          // console.log('Updated innerHTML:', updatedInnerHTML);

          // existing_file.innerHTML = innerHTML;
          let relativeUrl = new URL(aTag.href).pathname;
          let hrefArray = [];

          if (relativeUrl.startsWith('/')) {
            relativeUrl = relativeUrl.substring(1);
            hrefArray.push(relativeUrl)
          }

          values[item.id] = hrefArray
        } else {
          if (inputElement.required) {
            alert('Upload the file');
            isEmptyOverall = false;
            return;
          }
        }
      }
    });


    this.filteredTextMultipleFile.forEach((item, index) => {
      console.log('The id for the multiple file upload field is ', item.id);
      const id = item.id;
      let inputElement = document.getElementById(id) as HTMLInputElement;
      let value = inputElement.value;

      if (!value) {
        const multiFileDivId = `multiFile${id}`;
        let multiFileDiv = document.getElementById(multiFileDivId);
        console.log(multiFileDiv);

        if (multiFileDiv) {
          let aTags = multiFileDiv.querySelectorAll('a'); // Use querySelectorAll to get a NodeList of all <a> tags
          if (aTags.length > 0) { // Check if there are any <a> tags

            console.log('Yes, the file is uploaded');
            // Get the value of href from all the <a> tags and push it to an array, then append it into the FormData
            let hrefArray = [];
            aTags.forEach(aTag => {
              let relativeUrl = new URL(aTag.href).pathname; // Extract the pathname
              if (relativeUrl.startsWith('/')) {
                relativeUrl = relativeUrl.substring(1); // Remove leading slash if needed
              }
              console.log(`Href value of <a> tag: ${relativeUrl}`);
              hrefArray.push(relativeUrl);
            });


            // Assuming you have a FormData object named formData
            values[item.id] = hrefArray

          } else if (inputElement.required) {
            alert('Upload the file');
            isEmptyOverall = false;
            return;
          }
        }
      }
    });


    if (!isEmptyOverall || !isValid) {
      if (!isValid) {
        alert('Invalid value in the field !');
        console.log('Form submission aborted due to invalid input.');
      }
      return;
    }

    this.files.forEach((fileWithTitle, index) => {
      formData.append(`file_${fileWithTitle.id}`, fileWithTitle.file);
    });

    const multipleFiles: FileInfo[] = this.templateservice.getFiles();
    console.log('multiple files are', multipleFiles);

    multipleFiles.forEach((fileInfo, index) => {
      console.log('the multiple file is', fileInfo);
      formData.append(`file_${fileInfo.id}_${index}_`, fileInfo.file);
      // formData.append(`file_id_${index}`, fileInfo.id);
    });


    let jsonString = JSON.stringify(values);
    formData.append('values', jsonString);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        console.log('the values are ', values);

        if (this.item_id) {
          if (this.action == 'edit') {
            if (act == 1 && tag) {
              this.templateservice.updateValue(formData, this.item_id, tag).subscribe((response: any) => {
                if (response.status == 200) {
                  this.id = response.data.id;
                  this.expiry_date = response.data['values']['6'];
                  this.task_notification_id = response.data.task_notification_id;

                  this.notifyservice.updateNotification(this.task_notification_id, this.service_id, tag, this.expiry_date).subscribe((res: any) => {
                    console.log('response is ', res);
                    alert("Updated Successfully");
                    this.router.navigate(['template', 'view_template'], {
                      queryParams: {
                        service_id: this.service_id,
                        filter: 'total'
                      }
                    }).then(() => {
                      window.location.reload();
                    });
                  });

                }
              });
            } else if (act == 2) {
              this.templateservice.deleteValue(this.item_id).subscribe((response: any) => {
                if (response.status === 200) {
                  this.task_notification_id = response.data.task_notification_id;
                  this.notifyservice.deleteNotification(this.task_notification_id, this.service_id).subscribe((deleteResponse: any) => {
                    console.log('Delete notification response:', deleteResponse);
                    alert("Deleted Successfully");
                    this.router.navigate(['template', 'view_template'], {
                      queryParams: {
                        service_id: this.service_id,
                        filter: 'total'
                      }
                    }).then(() => {
                      window.location.reload();
                    });
                  });
                }
              });
            }
          }
        } else {
          this.templateservice.saveValue(formData, this.service_id).subscribe((response: any) => {
            console.log(response);
            this.id = response.data.id;
            this.expiry_date = response.data['values']['6'];
            console.log('expiry date is ', this.expiry_date);

            if (response.status === 201) {
              this.notifyservice.saveNotification(this.service_id, this.id, this.expiry_date).subscribe((res: any) => {
                console.log('response is ', res);
                alert("Saved Successfully");
                this.router.navigate(['template', 'view_template'], {
                  queryParams: { service_id: this.service_id, filter: 'total' }
                }).then(() => {
                  window.location.reload();
                });
              });
            } else {
              console.log('error occurred');
            }
          });

        }
      }
    });

  }

  updateLinkText(filter: string, count: number) {
    if (count > 0) {
      if (filter == 'total') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `Total (${count})`


      } else if (filter == 'pending') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `About to expire (${count})`

      }
      else if (filter == 'expired') {
        let tag = document.getElementById(filter) as HTMLAnchorElement
        tag.innerHTML = `Expired (${count})`

      }
    }



  }




}

<div class="manage-proposal-wrapper" style="width: 400px; max-height: fit-content;">
    <div class="row" *ngIf="data.create">
        <div class="col">
            <h4 id="sessionLabel">Create New Session</h4>
        </div>
        <div class="col-2 text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div class="row" *ngIf="data.edit">
        <div class="col">
            <h4 id="sessionLabel">Edit Draft</h4>
        </div>
        <div class="col-1 text-align-end p0">
            <i class="fa fa-trash del" aria-hidden="true" (click)="deleteDraft()"></i>
        </div>
        <div class="col-1 text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div class="row">
        <div class="">
            <div [formGroup]="form">
                <fieldset>
                    <div class="row">
                        <div class="col-12">
                            <label for="name">Name</label>
                            <span class="red">*</span>
                            <input type="text" class="form-control mod-css" formControlName="name" />
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Modality</label>
                            <select class="form-control" formControlName="modality">
                                <option *ngFor="let option of modality_dropdown" value="{{ option.value }}">
                                    {{ option.key }}
                                </option>
                            </select>
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Location</label>
                            <input type="text" class="form-control mod-css" formControlName="location" />
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Capacity</label>
                            <input type="number" class="form-control mod-css" formControlName="capacity" />
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Start Time</label>
                            <!-- <span class="red">*</span> -->
                            <input type="datetime-local" class="form-control mod-css" formControlName="start_time" style="cursor: text;"/>
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">End Time</label>
                            <!-- <span class="red">*</span> -->
                            <input type="datetime-local" class="form-control mod-css" formControlName="end_time" style="cursor: text;"/>
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Open To</label>
                            <input type="text" class="form-control mod-css" formControlName="open_to" />
                        </div>
                        <br /><br />
                        <div class="col-12">
                            <label for="name">Agenda</label>
                            <span *ngIf="addedFile" class="uploaded-file">(Uploaded File:&nbsp;
                            <span *ngIf="addedFile" class="added-file" (click)="openAgendaFile('https://amplus-documents.s3.amazonaws.com/lnd_session/'+addedFile)">{{addedFile}}</span>)</span>
                            <input type="file" class="form-control mod-css" formControlName="agenda" (change)="fileChange($event)"/>
                        </div>
                        <br /><br /><br />
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                            (click)="publish()" [disabled]="!form.valid">
                                Publish
                            </button>
                        </div>
                        <br />
                        <div class="col-12" style="padding-top: 10px;">
                            <button type="submit" class="btn btn-outline-primary mr-3"
                                style="width: -webkit-fill-available;
width: -moz-available;" (click)="saveDraft()">
                                Save Draft
                            </button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</div>
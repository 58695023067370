import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { PoaserviceService } from "../service/poaservice.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-view-poa',
  templateUrl: './view-poa.component.html',
  styleUrls: ['./view-poa.component.css']
})

export class ViewPoaComponent implements OnInit {
  data: any
  responseData: any;
  value: string;
  searchTerm: string = '';
  originalResponseData: any;
  isData: boolean = false;
  noPOA: boolean = true;



  constructor(private poaservice: PoaserviceService, private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.value = params['value'];

      this.poaservice.viewPoa(this.value).subscribe((res: any) => {
        this.originalResponseData = res;

        if (Array.isArray(res) && res.length === 0) {
          console.log('empty ')
          this.noPOA = false
          // hide the search taskbar
          this.isData = true;
        }
        else {

          this.noPOA = true
          this.isData = false;

        }
        this.filterData();

        (document.querySelectorAll('.allLinks') as NodeListOf<HTMLElement>).forEach(link => {
          link.style.color = '#656565';
          link.style.fontWeight = '400';
        });

        document.getElementById(this.value).style.color = '#07527D';
        document.getElementById(this.value).style.fontWeight = '600';
      });
    });

  }

  getRaisedPoa(id: any, value: string, status: string) {
    const queryParams = { id: id, value: value, status: status };
    const urlTree = this.router.createUrlTree(['poa', 'raise_poa'], { queryParams: queryParams });
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  filterData() {
    if (!this.searchTerm.trim()) {
      this.responseData = this.originalResponseData;
    }
    else {
      const searchTermLowerCase = this.searchTerm.toLowerCase();
      this.responseData = this.originalResponseData.filter(item =>
        item.description.toLowerCase().includes(searchTermLowerCase) ||
        item.status.toLowerCase().includes(searchTermLowerCase) ||
        item.created_by_name.toLowerCase().includes(searchTermLowerCase) ||
        item.start_date.toLowerCase().includes(searchTermLowerCase) ||
        String(item.id).includes(searchTermLowerCase)

      );
    }
    this.responseData.sort((a, b) => b.id - a.id);
  }

  onSearchChange() {
    this.filterData();
  }
}

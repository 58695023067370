import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { ViewSessionComponent } from '../view-session/view-session.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ParticipantListComponent } from '../participant-list/participant-list.component';

@Component({
    selector: 'past-sessions',
    templateUrl: './past-sessions.component.html',
    styleUrls: ['./past-sessions.component.scss']
})
export class PastSessionsComponent implements OnInit {

    form: any;
    sessionList: any = [];
    isAdmin: boolean = false;
    constructor(
        private fb: FormBuilder,
        private learnerService: LearnerService,
        private adminService: AdminService,
        private dialog: MatDialog,
        private loginService: LoginService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside past sessions tab");
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        this.pastSessions();
    }

    pastSessions() {
        this.learnerService.getPastSessions().subscribe((res: any) => {
            console.log("past sessions data--->", res);
            this.sessionList = res.data;
        },
        (error:any)=>{
            console.log(error, error.error, error.message);
             if(error.error == 'Signature has expired'){
            this.loginService.logout();
            }
        });
    }

    viewPastSession(id) {
        this.adminService.getSession(id).subscribe((res: any) => {
            console.log(res);
            let sessionData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(ViewSessionComponent, {
                data: {
                    id: id,
                    past: true,
                    draft: false,
                    upcoming: false,
                    sessionData: sessionData,
                },
            });
        },
        (error:any)=>{
            console.log(error, error.error, error.message);
             if(error.error == 'Signature has expired'){
            this.loginService.logout();
            }
        });
    }

    openParticipantList(id) {
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(ParticipantListComponent, {
            disableClose: true,
            data: {
                id: id,
            },
        });
    }
}
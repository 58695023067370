import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { ViewSessionComponent } from '../view-session/view-session.component';
import { LoginService } from 'src/app/core/login/login.service';

@Component({
    selector: 'drafts',
    templateUrl: './drafts.component.html',
    styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {

    form: any;
    draftList: any = [];
    isAdmin: boolean = false;
    constructor(
        private fb: FormBuilder,
        private adminService: AdminService,
        private dialog: MatDialog,
        private loginService: LoginService
    ) { }

    ngOnInit() {
        console.log("inside drafts tab");
        if (localStorage.getItem('user_type') == 'admin') {
            this.isAdmin = true;
            this.drafts();
        }
    }

    drafts() {
        this.adminService.getDraftSessions().subscribe((res: any) => {
            console.log("drafts---->", res);
            this.draftList = res.data;
        },
        (error:any)=>{
            console.log(error, error.error, error.message);
            if(error.error == 'Signature has expired'){
            this.loginService.logout();
            }
        });
    }

    viewDraft(id) {
        this.adminService.getSession(id).subscribe((res: any) => {
            console.log(res);
            let sessionData = res.data;
            let dialogRef = this.dialog.open(ViewSessionComponent, {
                data: {
                    id: id,
                    past: false,
                    upcoming: false,
                    draft: true,
                    sessionData: sessionData,
                },
            });
        });
    }
}
import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { LoginService } from 'src/app/core/login/login.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as $ from 'jquery';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'self-appraisal-popup',
    templateUrl: './self-appraisal-popup.component.html',
    styleUrls: ['./self-appraisal-popup.component.scss']
})
export class ViewSelfAppraisalComponent implements OnInit {

    selfData: any;
    isValuearray: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ViewSelfAppraisalComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside view session page", this.data);
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        this.selfData = this.data.selfData;
        this.filterData(this.selfData);
    }

    cancel() {
        this.dialogRef.close();
    }

    filterData(data) {
        let filterData = data;
        console.log(filterData)
        for (let i = 0; i < filterData.length; i++) {
          let arrmeta = [];
          console.log(typeof filterData[i].value != 'string', "typeof filterData[i].value != 'string'");
          if (typeof filterData[i].value != 'string') {
            console.log(filterData[i]);
            Object.keys(filterData[i].value).forEach(function (key) {
              console.log('Key : ' + key + ', Value : ' + filterData[i].value[key])
              let obj = {};
              obj['id'] = key;
              obj['name'] = filterData[i].value[key];
              arrmeta.push(obj);
            })
            this.isValuearray = true;
            filterData[i]['ValArray'] = this.isValuearray;
            filterData[i].value = arrmeta;
          }
          else {
            this.isValuearray = false;
            filterData[i]['ValArray'] = this.isValuearray;
          }
        }
        this.selfData = filterData;
        console.log(this.selfData, '============================')
    //   console.log(this.selfData, '============================')
    }
}
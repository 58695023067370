<span>

  <app-header-global (notify)="expensecardshow($event)" (notifyPOA)="poashow($event)"
    (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>

<div class="row allcontent">
  <div class="col-sm-2">
    <app-side-overlay-menu [showClaim]="showcard" [showpoa]="showPOA" [showamdoc]="showAmdoc"
      (notify)="getDocument($event)"></app-side-overlay-menu>
  </div>

  <div class="col-sm-10">
    <div class='allinonelink'>
      <div style='margin-bottom:4px'>
        <a class='allLinks' id='action' (click)="_viewPoa('action','action')">My Pending Actions</a>
      </div>
      <span class="separator">|</span>
      <div id='requestedIt'>

        <a class='allLinks' id='requested' (click)="_viewPoa('requested','requested')">My Processed POA's</a>
      </div>
      <span class="separator">|</span>
      <div>
        <a class="allLinks" id='raise' (click)="_raisePoa('raise')">Request New POA</a>
      </div>

      <div id='viewallpoa' style='display:none'>
        <span class="separator">|</span>
        <a class="allLinks" id='get_all' (click)="_viewPoa('get_all', 'get_all')">View All</a>
      </div>

    </div>

    <router-outlet></router-outlet>


  </div>
</div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ClaimserviceService {
  baseUrl = environment.url + "travelex_app/api/v2/admin";

  access_key: string = localStorage.getItem("accessToken");
  headers: any = new HttpHeaders()
    .set("Authorization", `${this.access_key}`)
    .set("Content-Type", "application/json");

  requestOptions: any = { headers: this.headers };
  apiUrl: any;
  
  constructor(private http: HttpClient) {}
  allClaim(
    page: number,
    uname: string,
    uemail: string,
    claimid: any,
    pcode: any,
    legal_entity: string,
    status: string,
    external:boolean

  ) {
    this.apiUrl = `${this.baseUrl}/claims?page=${page}&status=${status}&submitter_name=${uname}&submitter_email=${uemail}&claim_id=${claimid}&project_code=${pcode}&legal_entity=${legal_entity}&external=${external}`;
    const url = this.apiUrl; // + page;
    return this.http.get(url, this.requestOptions);
  }

  detailClaim(id: number) {
    console.log(id);
    return this.http.get(
      `${this.baseUrl}/claims/${id}`,
      this.requestOptions
    );
  }

  filterClaim(
    page: number,
    uname: string,
    uemail: string,
    claimid: number,
    pcode: number,
    legal_entity: string,
    status: string,
    external:boolean
  ) {
    this.apiUrl = `${this.baseUrl}/claims?page=${page}&status=${status}&submitter_name=${uname}&submitter_email=${uemail}&claim_id=${claimid}&project_code=${pcode}&legal_entity=${legal_entity}&external=${external}`;
    const url = this.apiUrl;
    return this.http.get(url, this.requestOptions);
  }

  //approve api call...
  approveClaim(expense_id: number, claim_id: any) {
    const body = { claim_id: claim_id };
    return this.http.post(
      `${this.baseUrl}/expenses/approve/${expense_id}`,
      body,
      this.requestOptions
    );
  }

  //reject api call ...
  rejectClaim(expense_id: number, claim_id: any,reason:any) {
    const body = { claim_id: claim_id,reason:reason };
    return this.http.post(
      `${this.baseUrl}/expenses/reject/${expense_id}`,
      body,
      this.requestOptions
    );
  }

  //approve allclaim
  approveAllClaim(claim_id: number) {
    const body = { claim_id: claim_id };
    return this.http.post(
      `${this.baseUrl}/expenses/approve`,
      body,
      this.requestOptions
    );
  }
  // reject claim all

  rejectAllClaim(claim_id: number,reason:any) {
    
    const body = { claim_id: claim_id,reason:reason };
    return this.http.post(
      `${this.baseUrl}/expenses/reject`,
      body,
      this.requestOptions
    );
  }

  updateExpenseType(type_id:number,exp_id:number){
    const body =""
    return this.http.post(
      `${this.baseUrl}/update-expense/${exp_id}?type_id=${type_id}`,
    body,    
    this.requestOptions
    );

  }


  updateExpenseDate(type_id:number,exp_id:number){
    const body =""
    return this.http.post(
      `${this.baseUrl}/update-expense/${exp_id}?expense_date=${type_id}`,
    body,    
    this.requestOptions
    );

  }


  
  //getter and setter function
  private filter: any;
  setFilter(filter: any) {
    this.filter = filter;
  }
  getFilter(): any {
    return this.filter;
  }
}
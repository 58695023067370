import { Component,OnInit,Input,Output,EventEmitter} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClaimserviceService } from '../service/claimservice.service';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RejectionReasonDialogComponent } from '../rejection-reason-dialog/rejection-reason-dialog.component';
@Component({
  selector: 'app-expense-details',
  templateUrl: './expense-details.component.html',
  styleUrls: ['./expense-details.component.css']
})
export class ExpenseDetailsComponent implements OnInit {
  headingFour:string='Expense Details';
  data:any;
  userdata:any=[];
  dates :any;
  defaultDate :any;
  inputDateValue: string = '';
  selectExpense:any;
  previousValue:any;
  confirmChange:boolean;
  @Input()
  expense :any;
  @Input()
  is_actions_global:any;
  @Input()
  claims:any;
  @Input()
  expensetype:any;
  @Input()
  expensetypekeys:any;
  @Output()
  testevent=new EventEmitter();
  constructor(private route:ActivatedRoute,private claimservice:ClaimserviceService ,public dialog: MatDialog){}
  

  _approveClaim(expense_id:number,claim_id:number){
    this.claimservice.approveClaim(expense_id,claim_id).subscribe((res:any)=>{
      this.testevent.emit();
      console.log(res);        
})
}
  openApproveConfirmation(expenseId:number,claimId:number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._approveClaim(expenseId,claimId); 
      }
    });
  }
  
 _rejectClaim(expense_id:number,claim_id:number,rejectionReason:any){
  console.log(rejectionReason);
      this.claimservice.rejectClaim(expense_id,claim_id,rejectionReason).subscribe((res:any)=>{
        this.testevent.emit();
        console.log(res);      
      })
}
openRejectionReasonDialog(expenseId: number, claimId: number): void {
  const rejectionDialogRef = this.dialog.open(RejectionReasonDialogComponent);

  rejectionDialogRef.afterClosed().subscribe((rejectionReason: string) => {
    if (rejectionReason) {
      this._rejectClaim(expenseId, claimId,rejectionReason);
     
    }
  });
}
openRejectConfirmation(expenseId: number, claimId: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      this.openRejectionReasonDialog(expenseId, claimId);
    }
  });
}
_approveAllClaim(claim_id:number){
  this.claimservice.approveAllClaim(claim_id).subscribe((res:any)=>{
   this.testevent.emit();
   console.log(res);       
})
}
openApproveAllConfirmation(claimId:number): void {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this._approveAllClaim(claimId);
    }
  });
}


_rejectAllClaim(claim_id: number,rejectionReason:string) {
     console.log(rejectionReason);
  this.claimservice.rejectAllClaim(claim_id,rejectionReason).subscribe((res: any) => {
    this.testevent.emit();
    console.log(res);
  });
}
openAllRejectionReasonDialog( claimId: number): void {
  const rejectionDialogRef = this.dialog.open(RejectionReasonDialogComponent);

  rejectionDialogRef.afterClosed().subscribe((rejectionReason: string) => {
    if (rejectionReason) {
      this._rejectAllClaim(claimId,rejectionReason);
      
    }
  });
}
openRejectAllConfirmation(claimId: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent);
  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      this.openAllRejectionReasonDialog(claimId);
    }
  });
}

onDropdownChange(
  newValue: any,
  exp_id: number,
  expkey: number,
  expname: string,
  index:number
): void {
   this.confirmChange = window.confirm(
    `Are you sure you want to change the expenses types?`
  );
  if (!this.confirmChange) {
    this.resetDropdownValue(expkey,index);
  } else {
    this.updateDropdownValue(newValue, exp_id);
  }
}

resetDropdownValue(previousValue: any,index:any): void {
  this.selectExpense = document.getElementById(
    `expense_type${index}`
  ) as HTMLSelectElement;
  console.log(this.selectExpense);
  this.selectExpense.value = previousValue;
  console.log(this.selectExpense.value);
}

updateDropdownValue(newValue: any, exp_id: number): void {
  this.previousValue = newValue;
  this.claimservice.updateExpenseType(newValue, exp_id).subscribe((res:any) => {
    if (res.status){
      alert("expense updated successfully");
    }
  });
}


filterExpenseTypeKeys(keys, id) {
  return keys.filter(key => key != id);
}

 updateExpenseDate(newDate:any,exp_id:number): void {
  const date = new Date(newDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-indexed, so we add 1
  const year = date.getFullYear();

  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;

newDate = formattedDate;
  
 this.claimservice.updateExpenseDate(newDate,exp_id).subscribe((res:any)=>{
  console.log(res);
  if(res.status){
    alert("date updated successfully.")
  }
 })
 }

 ngOnInit(){
  
 }
}

import { Component, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { EmponboardingService } from "../../employee-onboarding/services/emponboarding.service";
import { LoginService } from "src/app/core/login/login.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { MatDialog } from "@angular/material/dialog";
import { CompanyDialogComponent } from "../company-dialog/company-dialog.component";
import { BusinessDialogComponent } from "../business-dialog/business-dialog.component";
import { DepartmentDialogComponent } from "../department-dialog/department-dialog.component";
@Component({
    selector: "app-department-list",
    templateUrl: "./department-list.component.html",
    styleUrls: ["./department-list.component.scss"],
})


export class DepartmentListComponent implements OnInit {
    headingDepartment: string = "List of Departments";
    departmentlist: any;
    business: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private hrservice: HrserviceService,
        private EmpOnboardingService: EmponboardingService,
        private loginService: LoginService,
        private loaderService: LoaderService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.hrservice.getBusiness().subscribe((res: any)=>{
            this.business = res.data;
            this.hrservice.getDept().subscribe((res: any) => {
                this.departmentlist = res.data
    
                for (var i = 0; i < this.departmentlist.length; i++) {
                    for (var j = 0; j < this.business.length; j++) {
                        if (this.departmentlist[i].business == this.business[j].id) {
                            this.departmentlist[i].business = this.business[j].name;
                        }
                    }
                }
            })
        });
       
    }
    addDepartment(){
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(DepartmentDialogComponent, {
            data: {
                type: 'create',
            },
        });
    }
    editDept(dept) {
        this.loaderService.setLoading(true);
        for(var i =0; i< this.business.length; i++){
            if(dept.business == this.business[i].name){
                dept.business = this.business[i].id;
            }
        }
        let dialogRef = this.dialog.open(DepartmentDialogComponent, {
            data: {
                type: 'edit',
                data: dept
            },
        });
    }
}
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { access } from "fs";

@Injectable({
    providedIn: "root"
})
export class LearnerService {

    baseUrl = environment.url;
    mySessionsUrl = "lnd/my-sessions/";
    upcomingSessionsUrl = "lnd/upcoming-sessions/";
    pastSessionsUrl = "lnd/past-sessions/";
    getSessionUrl = "lnd/sessions/";
    userTypeUrl = "lnd/user-type/";
    agendaUrl = "lnd/presigned-url/";
    getHolidayListUrl ="appraisal/all_holidays/";  
    getDocumentsListUrl:string ='';  
    accessToken = localStorage.getItem('accessToken');
    getViewDownloadURL ='lnd/presigned-url/';
    getEmployeeDetailsURL='appraisal/employee/';
    localUrl='http://127.0.0.1:8000/'
    // lnd/sessions/3/enrollment/
    constructor(
        private http: HttpClient
    ) {
        console.log("obj created");
    }

    getAgenda(params){
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        const httpOptions = {
            headers: headers,
            params: params
          };
        return this.http.get(this.baseUrl + this.agendaUrl, httpOptions);
    }
    getUserType() {
        console.log('acess token-------------->', this.accessToken);
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.userTypeUrl, { headers });
    }

    getHolidayList() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getHolidayListUrl, { headers });
    }
    
    getMySessions() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.mySessionsUrl, { headers });
    }

    getUpcomingSessions() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.upcomingSessionsUrl, { headers });
    }

    getPastSessions() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.pastSessionsUrl, { headers });
    }

    getSession() {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getSessionUrl, { headers });
    }

    enroll(id, body) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.post(this.baseUrl + this.getSessionUrl + id + '/enrollment/', body, { headers });
    }

    unenroll(id, body) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.put(this.baseUrl + this.getSessionUrl + id + '/enrollment/', body, { headers });
    }

    myFeedback(id, body) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.post(this.baseUrl + this.getSessionUrl + id + '/my-feedback/', body, { headers });
    }

    getMyFeedback(id) {
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getSessionUrl + id + '/my-feedback/', { headers });
    }

    getDocumentsList(type:any) {
        this.getDocumentsListUrl =`appraisal/api/documents/?type=${type}`
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getDocumentsListUrl, { headers });
    }

    getViewDownloadPdf(file:any){
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getViewDownloadURL+'?file_url='+file, { headers });

    // getViewDownloadPdf(file){
    //     const headers = new HttpHeaders().set('Authorization', this.accessToken);
    //     return this.http.get(this.baseUrl + this.getViewDownloadURL+'?file_url='+file, { headers });

     }

    getEmployeeDetails(){
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this.http.get(this.baseUrl + this.getEmployeeDetailsURL, { headers });
    }

    // getEmployeeDetails(){
    //     const headers = new HttpHeaders().set('Authorization', this.accessToken);
    //     return this.http.get(this.localUrl + this.getEmployeeDetailsURL, { headers });
    // }

    // getViewDownloadPdf(file){
    //     const headers = new HttpHeaders().set('Authorization', this.accessToken);
    //     return this.http.get(this.localUrl + this.getViewDownloadURL+'?file_url='+file, { headers });

    // }
    
}


<div class="row">
    <div class="col">
        <img class="image" src="../../../assets/images/office.jpeg">
    </div>
    <div class="col p15_2 " style="overflow: hidden">
        <div class="row">
            <div class="col text-align-center p5">
                <img class="logo" src="../../../assets/images/amplusicon.svg">
            </div>
        </div>
        <div class="row">
            <div class="col heading">
                Amplus Empower
            </div>
        </div>
        <div class="row">
            <div class="col tag-line">
                Your Employee Experience Platform
            </div>
        </div>
        <div class="row">
            <div class="col text">
                <br/><br/>                
                <!-- <strong>Available and upcoming modules are:</strong> <br />
                L&D Manager, Appraisal Engine, GreytHR, Rewards & Recognition. -->
            </div>
        </div>
        <div class="row">
            <div class="col p07">
                <button class="btn login"  (click)="login()"> <i
                        class="fa fa-windows" aria-hidden="true"></i> Log In with Microsoft</button>
            </div>
        </div>
    </div>
</div>
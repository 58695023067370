<div *ngIf="selfStatus && !errorFlag" style="margin: 3vh 2vh">
  <div class="row">
    <div class="col head">
      Yay!!! Your response is saved.
    </div>
  </div>
  <div class="row" *ngFor="let item of selfData; let i =index">
    <div class="col" style=" border-bottom: 0.2vh solid #ccc; padding: 2vh;">
      <div class="row">
        <div class="col questions">
          {{item.title}}
        </div>
      </div>
      <div *ngIf="!item.ValArray" class="row">
        <div class="col ans">
           <p *ngFor="let line of item.value.split('\n')"> <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 1vh;"></i> {{line}}</p>
        </div>
      </div>
      <ng-container *ngIf="item.ValArray">
        <div *ngFor="let val of item.value; let j =index">
          <div class="row">
            <div class="col answers">
              <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 1vh;"></i> {{val.name}}
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </div>

</div>
<div class="row" *ngIf="!selfStatus && !errorFlag">
  <div class="col">

  </div>
  <div class="col danger" *ngIf="dueDate!=undefined && today > dueDate">
    Due: {{dueDate}}
</div>
<div class="col warn" *ngIf="dueDate!=undefined && today == dueDate">
    Due: {{dueDate}}
</div>
<div class="col green" *ngIf="dueDate!=undefined && today < dueDate">
    Due: {{dueDate}}
</div>
</div>
<div *ngIf="!selfStatus && !errorFlag">
  <app-dynamic-form [attr.questions]="questions"></app-dynamic-form>
</div>

<div *ngIf="errorFlag" class="row" style="margin: 10vh 2vh;font-size: 3vh;
color: #666666;">
  <div class="col">
    {{errorMessage}}
  </div>
</div>
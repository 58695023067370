import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CsrftokenService} from 'src/app/core/services/csrftoken.service'


@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  baseUrl='https://staging-empower.amplussolar.com/notification_app/'
  access_key: string | null = localStorage.getItem("accessToken");
  headers: HttpHeaders;
  requestOptions: any;
  apiUrl: any;

  responseDataEvent = new EventEmitter<any>();

  constructor(private http: HttpClient,private getcsrfservice:CsrftokenService) {
    // Check if the access key is present and set headers accordingly
    if (this.access_key) {
      this.headers = new HttpHeaders().set("Authorization", `${this.access_key}`);
    } else {
      console.error("Access token is missing");
      this.headers = new HttpHeaders();
    }
    this.requestOptions = { headers: this.headers };
  }

  saveNotification(service_id: any, itemId: any, expiry_date: any): Observable<any> {
    if (!this.access_key) {
      throw new Error("Authorization token is missing or invalid");
    }
    const httpOptions = {
      headers: new HttpHeaders({
          'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
          'Authorization': this.access_key
      }),
  };

    const url = `${this.baseUrl}notifier/?service_id=${service_id}&&id=${itemId}&&expired_date=${expiry_date}`;

    return this.http.post(url, null, httpOptions);
  }

  updateNotification(id:any,service_id:any,tag:any,expired_date:any): Observable<any>{

    if (!this.access_key) {
      throw new Error("Authorization token is missing or invalid");
    }

    const httpOptions = {
      headers: new HttpHeaders({
          'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
          'Authorization': this.access_key
      }),
  };


    const url = `${this.baseUrl}notifier/?id=${id}&service_id=${service_id}&tag=${tag}&&expired_date=${expired_date}`;
    return this.http.put(url,null,httpOptions);
  }
  
  deleteNotification(id:any,service_id:any): Observable<any>{

    if (!this.access_key) {
      throw new Error("Authorization token is missing or invalid");
    }
    const httpOptions = {
      headers: new HttpHeaders({
          'X-CSRFToken': this.getcsrfservice.getCsrfToken(),
          'Authorization': this.access_key
      }),
  };

    const url = `${this.baseUrl}notifier/?id=${id}&service_id=${service_id}`;
    return this.http.delete(url,httpOptions);

  }

  getDocument():Observable<any>{
    const url = `${this.baseUrl}notifier/`;
    return this.http.get(url, this.requestOptions);
  }

  updateDocument(data:any):Observable<any>{
    const url = `${this.baseUrl}notifier/`;
    return this.http.put(url,data, this.requestOptions);
  }

  getNotifConfig(id:any):Observable<any>{
    const url = `${this.baseUrl}notif_config/?id=${id}`;
    return this.http.get(url, this.requestOptions);
  }

  getNotificationValue(id:any):Observable<any>{
    const url = `${this.baseUrl}notifier/?id=${id}`;
    return this.http.get(url, this.requestOptions);

  }
 


  
 
  
}
















 



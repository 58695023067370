import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemoriesService } from '../service/memories.service';
@Component({
  selector: 'app-memories',
  templateUrl: './memories.component.html',
  styleUrls: ['./memories.component.css']
})
export class MemoriesComponent implements OnInit {
  showcard:any;
  showhr:boolean;
  showPOA:boolean;
  memoriesdata:any;
  showAmdoc:any;
  imagepath:any='../../../assets/images/party.jfif'
  memories_data=[
    {
      title:'Offsites',
      routename:'offsite',
      thumbnail_image:this.imagepath
    },
    { 
      title:'Townhalls',
      routename:'townhall',
      thumbnail_image:this.imagepath
    },
    {
      title:'Awards & Conferences',
      routename:'anc',
      thumbnail_image:this.imagepath
    },
    {
      title:'Life at Amplus',
      routename:'life_at_amplus',
      thumbnail_image:this.imagepath
    }
    
  ]
  constructor(private router: Router,private memories:MemoriesService) { }

  ngOnInit(): void {
      this._getMemoriesCategories();
  }
    

  _getMemoriesCategories(){
    this.memoriesdata=this.memories_data;
  }

  navigateToSubroute(category:string) {
    this.router.navigate(['/memories', category]);
}
  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata){
    this.showhr=showhrmanagerdata
    
 }
 poashow(showpoa){
  this.showPOA=showpoa;
}
amdocshow(showamdoc){
  this.showAmdoc=showamdoc;
}
}

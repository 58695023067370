<div class="manage-proposal-wrapper" style="width: 500px;">
    <div *ngIf="dataPresent" class="row text-heading-popup">
        <div class="col ">
            <strong>{{title}}</strong> | {{modality}}
        </div>
        <div class="col text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
        </div>
    </div>
    <br />
    <div *ngIf="!submitEnable" class="check-col" [formGroup]="form">
        <fieldset>
            <div class="row">
                <div class="col-8 reason text-align-start">
                    <label for="comment"><strong>Your Feedback:</strong></label>
                </div>
                <div class="col-4 reason text-align-end">
                    <div class="stars">
                        <ngx-star-rating formControlName="rating" [id]="'rating'" [disabled]="true"></ngx-star-rating>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 reason">
                    <textarea rows="10" class="form-control mod-css" formControlName="comment" disabled></textarea>
                </div>
            </div>

        </fieldset>
    </div>
    <div *ngIf="submitEnable" class="check-col" [formGroup]="form">
        <fieldset>
            <!-- <div class="col-12 reason">
                <label for="name">How much would you like to rate today's session?* :</label>
                <div class="stars">
                    <ngx-star-rating formControlName="rating" [id]="'rating'"></ngx-star-rating>
                </div>
            </div>
            <div class="col-12 reason">
                <label for="name">Write your feedback below * :</label>
                <textarea rows="3" class="form-control mod-css" formControlName="comment"></textarea>
            </div> -->
            <div class="row">
                <div class="col-8 reason text-align-start">
                    <label for="rating">How would you like to rate this session?*</label>
                </div>
                <div class="col-4 reason text-align-end">
                    <div class="stars">
                        <ngx-star-rating formControlName="rating" [id]="'rating'"></ngx-star-rating>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 reason text-align-start">
                    <label for="rating">Write your feedback below*</label>
                </div>
                <div class="col-12 reason">
                    <textarea rows="3" class="form-control mod-css" formControlName="comment"></textarea>
                </div>
            </div>
            <br />
        </fieldset>
    </div>

    <div class="row" *ngIf="submitEnable">
        <div class="col p030">
            <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="submit()">
                Submit Feedback
            </button>
        </div>
        <br />
    </div>
    <div class="row">
        <div class="col p1030">
            <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                (click)="cancel()">
                Go Back
            </button>
        </div>
    </div>
</div>
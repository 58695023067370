<div class="manage-proposal-wrapper" style="width: 400px;">
    <div *ngIf="dataPresent" class="row">
        <div class="col text-heading-popup">
            <strong>{{title}}</strong> | {{modality}}
        </div>
        <div class="col-2 text-align-end">
            <i class="fa fa-times cross" (click)="cancel()"></i>
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-map-marker" aria-hidden="true"></i> {{location}}
        </div>
    </div>
    <div *ngIf="dataPresent" class="row text-decor">
        <div class="col">
            <i class="fa fa-calendar-o" aria-hidden="true"></i> {{date}} | {{startTime}}- {{endTime}}
        </div>
    </div>
    <div class="row">
        <div class="col check-col">
            <i class="fa fa-check-square-o check" aria-hidden="true"></i>
        </div>
    </div>
    <div class="row">
        <div class="col text-align-center">
            {{message}}
        </div>
    </div>
</div>
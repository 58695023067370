<div class="form-group custom-form-group">
  <label *ngIf="heading" class="highlight">{{ heading }}</label>
  <div class="custom-select-wrapper">
    <select class="form-control custom-input custom-select" [ngClass]="{'is-invalid': !isValid && selectedValue}"
      [(ngModel)]="selectedValue" [disabled]="isDisabled" [id]="id" [required]="isRequired"
      (change)="onSelectionChange($event.target.value, $event.target.id)">
      <option *ngFor="let option of parsedOptions" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
  <p *ngIf="hint" class="form-text text-muted">{{ hint }}</p>
  <span [id]="id + '_span'" class="required" style="display: none;">* This field is required</span>
  <div *ngIf="!isValid && selectedValue" class="invalid-feedback">
    Please select a valid option
  </div>
</div>
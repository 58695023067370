<div class="manage-proposal-wrapper" style="width: 600px; max-height: fit-content; overflow-x: hidden !important;">
    <div class="row">
        <div class="col heading">
            Promotion History
        </div>
        <div class="col heading" style="text-align: end;">
            <font color="#555">{{id}}:&nbsp;{{name}}</font>
        </div>
    </div>

    <ng-container *ngIf="promotionData.length != 0" style="overflow-x: hidden;">
        <div *ngFor="let item of promotionData">
            <div class="row">
                <div class="col-3 cycle">
                    {{ item.appraisal_cycle.name}}
                </div>
                <div class="col-1">
                    <i class="fa fa-dot-circle-o dot" aria-hidden="true"></i>
                </div>
                <div class="col history">
                    <font color="#999999">{{item.old_designation}}</font> &nbsp;<i class="fa fa-angle-double-right arrow" aria-hidden="true"></i>
                    &nbsp;{{item.new_designation}}
                </div>
            </div>
            <div class="vertical"></div>
            <!-- <div class="row">
                <div class="col history">
                    {{item.old_designation}} &nbsp;<i class="fa fa-caret-right dot" aria-hidden="true"></i>
                    &nbsp;{{item.new_designation}}
                </div>
            </div> -->
        </div>
    </ng-container>
    <div class="row" *ngIf="promotionData.length == 0">
        <div class="col no-promotion">
            No Promotions Yet :\
        </div>
    </div>
</div>
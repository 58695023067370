import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base';

@Injectable()
export class QuestionControlService {
    toFormGroup(questions: QuestionBase<string>[]) {
        const group: any = {};

        questions.forEach(question => {
         
         if(question.type == 'checkbox'){
            console.log(question.type, "-----question.type-----------")
            group[question.title] = new FormControl(new FormArray([]));
         }
         else if(question.type != 'checkbox'){
            group[question.title] = new FormControl('', Validators.required);
         }
        });
        console.log(new FormGroup(group));
        return new FormGroup(group);
    }
}


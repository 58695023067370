<app-hr-header [employeeHeading]="headingCompanies"></app-hr-header>
<div class="row">
  <div class="col">
    <button type="button" class="approvebtn" (click)="addCompany()">Add Company</button>
  </div>
</div>
<div class="table-responsive">
  <table class="table" id="emp_expense">
    <thead>
      <tr>
        <th>ID</th>
        <th>Company Name</th>
        <th>Code</th>
        <th>Address</th>
        <th>Type</th>
        <th></th>
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cmp of companylist; let i = index">
        <td>{{ cmp.company_id }}</td>
        <td>{{ cmp.name | titlecase }}</td>
        <td>{{ cmp.code }}</td>
        <td>{{ cmp.address | titlecase }}</td>
        <td>{{ cmp.company_type}}</td>
        <td style="color: #42478b; text-decoration: underline; cursor: pointer;" (click)="editCompany(cmp)">
            edit<img class="arrow-image" src="../../../assets/images/arrows.svg" alt="">
          </td>
      </tr>
    </tbody>
  </table>
</div>

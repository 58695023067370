import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { provideRoutes } from '@angular/router';
import { LoginService } from 'src/app/core/login/login.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { LoaderService } from 'src/app/core/services/loader.service';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'participant-list',
    templateUrl: './participant-list.component.html',
    styleUrls: ['./participant-list.component.scss'],
    providers: [ExcelService]
})
export class ParticipantListComponent implements OnInit {

    // @Input() data: any;
    form: any;
    enrollList: any = [];
    disenrollList: any = [];
    combinedList: any = [];
    session: any;
    now: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ParticipantListComponent>,
        private matDialog: MatDialog,
        private adminService: AdminService,
        private fb: FormBuilder,
        private loginService: LoginService,
        private excelService: ExcelService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside participant list", this.data);
        this.now = formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        this.enrollmentlist();
        // setTimeout(()=>{
        //     this.loaderService.setLoading(false);
        // }, 100);
    }

    enrollmentlist() {
        // this.loaderService.setLoading(true);
        this.adminService.getEnrollmentList(this.data.id).subscribe((res: any) => {
            console.log("participant list", res);
            this.session = res.data.session;
            this.enrollList = res.data.enrolled;
            this.disenrollList = res.data.disenrolled;
                this.loaderService.setLoading(false);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    cancel() {
        this.dialogRef.close();
        window.location.reload();
    }

    markPresent(emp_id) {
        this.adminService.markAttendance(this.data.id, [{ 'emp_id': emp_id, 'status': 'enrolled' }]).subscribe((res: any) => {
            console.log("attendance present");
            this.enrollmentlist();
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    markAbsent(emp_id) {
        this.adminService.markAttendance(this.data.id, [{ 'emp_id': emp_id, 'status': 'absent' }]).subscribe((res: any) => {
            console.log("attendance absent");
            this.enrollmentlist();
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }


    exportAsXLSX(s_id, s_name, s_date): void {
        this.combinedList = [...this.enrollList, ...this.disenrollList];
        s_date = formatDate(s_date, 'MMM dd, yyyy', 'en-IN');
        if(this.combinedList[0]['employee_info']){
            for (let i = 0; i < this.combinedList.length; i++) {
                this.combinedList[i]['employee_name'] = this.combinedList[i].employee_info.name;
                this.combinedList[i]['job_title'] = this.combinedList[i].employee_info.job_title;
                this.combinedList[i]['department'] = this.combinedList[i].employee_info.department;
                this.combinedList[i]['email'] = this.combinedList[i].employee_info.email;
                this.combinedList[i]['cluster'] = this.combinedList[i].employee_info.cluster;
                this.combinedList[i]['manager_name'] = this.combinedList[i].employee_info.manager_name;
                this.combinedList[i]['manager_email'] = this.combinedList[i].employee_info.manager_email;
                delete this.combinedList[i]['employee_info'];
                delete this.combinedList[i]['session'];
                delete this.combinedList[i]['id'];
            }
        }
        // console.log(JSON.stringify(excelJsonData));
        this.excelService.exportAsExcelFile(this.combinedList,s_id +'__'+ s_name + '__' + s_date +  '__participants');
    }

}
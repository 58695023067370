import { Component, Input, Output, EventEmitter ,OnInit} from '@angular/core';
import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }
  ]
})
export class InputFileComponent implements OnInit {

  @Input() heading: string;
  @Input() hint: string;
  @Input() accept: string; 
  @Input() isDisabled: boolean;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  @Input() id: string='id'
  @Input() isRequired: boolean;



  value: string;
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor() { }

  ngOnInit(): void {
  }

  selectedFile: File | null = null;

  onFileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      this.selectedFile = fileList[0];
      this.fileSelected.emit(this.selectedFile);
    }
  }

  removeFile() {
    this.selectedFile = null;
    const inputElement: HTMLInputElement = document.querySelector('input[type="file"]');
    if (inputElement) {
      inputElement.value = ''; // Clear file input value
    }
  }

}

import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { ParticipantListComponent } from '../participant-list/participant-list.component';
import { RegisterPopupComponent } from '../register/register.component';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { ViewSessionComponent } from '../view-session/view-session.component';
import { UnenrollPopupComponent } from '../unenroll-popup/unenroll-popup.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as $ from "jquery";
@Component({
    selector: 'upcoming-sessions',
    templateUrl: './upcoming-sessions.component.html',
    styleUrls: ['./upcoming-sessions.component.scss']
})
export class UpcomingSessionsComponent implements OnInit {

    form: any;
    sessionList: any = [];
    isAdmin: boolean = false;
    now: any;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private learnerService: LearnerService,
        private adminService: AdminService,
        private loginService: LoginService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside upcoming sessions tab");
        this.now = formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        this.upcomingSessions();
    }

    upcomingSessions() {
        this.learnerService.getUpcomingSessions().subscribe((res: any) => {
            console.log("upcoming session data-->", res);
            this.sessionList = res.data;
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    unenroll(data) {
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(UnenrollPopupComponent, {
            data: {
                data: data
            },
        });
    }

    openParticipantList(id) {
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(ParticipantListComponent, {
            // disableClose: true,
            data: {
                id: id,
            },
        });
    }

    openRegistration(data) {
        console.log(" inside registraion method, data", data);
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(RegisterPopupComponent, {
            data: {
                data: data
            },
        });
    }

    openView(id) {
        console.log(" inside view method, data", id);
        this.adminService.getSession(id).subscribe((res: any) => {
            console.log(res);
            let sessionData = res.data;
            if (sessionData.status == 'cancelled') {
                this.loaderService.setLoading(true);
                let dialogRef = this.dialog.open(ViewSessionComponent, {
                    data: {
                        id: id,
                        upcoming: false,
                        draft: false,
                        past: true,
                        sessionData: sessionData,
                    },
                });
            }
            else {
                this.loaderService.setLoading(true);
                let dialogRef = this.dialog.open(ViewSessionComponent, {
                    data: {
                        id: id,
                        upcoming: true,
                        draft: false,
                        past: false,
                        sessionData: sessionData,
                    },
                });
            }
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
}
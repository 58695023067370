import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { PromotionHistoryComponent } from "../appraisal/promotion-history/promotion-history.component";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    user: any;
    lndUserType: any;
    name: any;
    showcard: Boolean;
    showhr: Boolean;
    emp: any;
    showPOA:boolean;
    id: any;
    showAmdoc:boolean;
    constructor(
        private learnerService: LearnerService,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        public loaderService: LoaderService,
        private dialog: MatDialog
    ) { }
    ngOnInit() {
        this.learnerService.getUserType().subscribe(res => {
            this.user = res['lnd_user_type'];
            this.lndUserType = true;
            localStorage.setItem('user_type', res['lnd_user_type']);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        this.learnerService.getEmployeeDetails().subscribe((res: any) => {

            this.emp = res.data;
            this.name = res.data.name;
            this.id = res.data.emp_id;
            console.log(this.emp, "----------emp data")
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            })
        // this.user_type = localStorage.getItem('user_type');
        // this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        // this.user = localStorage.getItem('user_type');
        this.name = localStorage.getItem('name');

    }

    getPromotions() {
        this.appraisalService.getPromotionHistory({}).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    name: this.name,
                    emp_id: this.id,
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    expensecardshow(showclaimdata) {
        this.showcard = showclaimdata;
    }

    hrmanagercardshow(showhrmanagerdata) {
        this.showhr = showhrmanagerdata

    }
    poashow(showpoa) {
        this.showPOA = showpoa;
    }
    amdocshow(showamdoc){
        this.showAmdoc=showamdoc;
    }
}
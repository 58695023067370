import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LearnerService } from 'src/app/core/services/learner.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'register-popup',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterPopupComponent implements OnInit {

    // @Input() data: any;
    form: any;
    title: any;
    modality: any;
    location: any;
    date: any;
    startTime: any;
    endTime: any;
    message: any;
    dataPresent = false;
    disenrollCloseTime: any;
    enrollCloseTime: any;
    now: any;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RegisterPopupComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private learnerService: LearnerService,
        private loginService: LoginService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside register popup", this.data);
        this.now = formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        console.log(this.now);
        setTimeout(()=>{
            this.loaderService.setLoading(false);
        }, 100);
        if (this.data.data != null) {
            this.dataPresent = true;
            this.title = this.data.data.name;
            this.modality = this.data.data.modality;
            this.location = this.data.data.location;
            this.date = formatDate(this.data.data.start_time, "MMM dd, yyyy", 'en-IN');
            this.startTime = formatDate(this.data.data.start_time, "HH:mm aa", 'en-IN');
            this.endTime = formatDate(this.data.data.end_time, "HH:mm aa", 'en-IN');
            this.disenrollCloseTime = this.data.data.disenroll_close_time;
            this.enrollCloseTime = this.data.data.enroll_close_time;
        }
    }

    register() {
        this.learnerService.enroll(this.data.data.id, {}).subscribe((res: any) => {
            console.log("enroll", res);
            this.dialogRef.close();
            let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                data: {
                    message: res.message,
                    data: res.data
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    cancel() {
        this.dialogRef.close();
    }
}
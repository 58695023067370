<span>
  <app-header-global (notify)="expensecardshow($event)" (notifyPOA)="poashow($event)"
    (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>

<div class="row allcontent">
  <div class="col-sm-2">
    <app-side-overlay-menu [showClaim]="showcard" [showpoa]="showPOA" [showamdoc]="showAmdoc"
      (notify)="getDocument($event)"></app-side-overlay-menu>
  </div>

  <div class="col-sm-10">
    <!-- <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Select Template
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li *ngFor="let templateName of templateNames">
                <a class="dropdown-item" (click)="makeTemplate(templateName)">{{ templateName }}</a>
                <li>
              </li>
            </ul>
        </div> -->

    <!-- <div *ngFor="let item of templateValues ;let i = index" >
          <div class="card" (click)="handleCardClick($event, item.id)"  >
            <div class="card-body">
              <div class='card-head'>
                <p class="card-text"><span class='card_title'>ID  : </span>{{item.id}}</p>
        
              </div>
              
              <div class='seperateDiv'>
                <div class='subjectDiv'>
                  <p class="card-text"><span class='card_title'>Disabled : </span>{{item.disabled}}</p>
                </div>
    
                <div class='buttonsDiv'>
  
                  <button type="button" class="btn  space">
                    <i class="fa fa-pencil-square-o" aria-hidden="true" ></i> 
                  </button>
  
                  <button type="button" class="btn space"  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                
                </div>
              </div>
            
            </div>
          </div>
        </div> -->

    <!-- <div class="templateCont">
          
          <div class="templateHeading" *ngIf="service_name">
            <h4>{{ service_name }}</h4>
          </div>

          <div>
            <form (ngSubmit)="submitForm()">


              <div *ngIf="filteredTextInputs">
                <ng-container *ngFor="let item of filteredTextInputs; let i = index">
                  <app-input-text [placeholder]="item.description" 
                                  [heading]="item.title" 
                                  [minWords]="item.validations.min" 
                                  [maxWords]="item.validations.max"
                                  [hint]="item.hint" 
                                  [isDisabled]="item.is_disabled"
                                  [name]="item.title"
                                  [id]="item.id + '_text_'">
                    </app-input-text>
                </ng-container>
                
              </div>
            
              <div *ngIf="filteredTextDate">
                <ng-container *ngFor="let item of filteredTextDate">
                  <app-input-date [placeholder]="item.description" 
                                  [heading]="item.title" 
                                  [minWords]="item.validations.min" 
                                  [maxWords]="item.validations.max"
                                  [hint]="item.hint" 
                                  [isDisabled]="item.is_disabled"
                                  [(ngModel)]="item.value"
                                  name="{{item.title}}"
                                  [id]="item.id + '_date_'">
    
                  </app-input-date>
                </ng-container>
              </div>
    
    
              <div *ngIf="filteredTextNumber">
                <ng-container *ngFor="let item of filteredTextNumber; let i = index">
                  <app-input-number [placeholder]="item.description" 
                                  [heading]="item.title" 
                                  [minWords]="item.validations.min" 
                                  [maxWords]="item.validations.max"
                                  [hint]="item.hint" 
                                  [isDisabled]="item.is_disabled"
                                  [name]="item.title"
                                  [id]="item.id + '_number_'">
                    </app-input-number>
                </ng-container>
                
              </div>
              
            
              <div *ngIf="filteredTextFile">
                <ng-container *ngFor="let item of filteredTextFile">
                  <app-input-file [heading]="item.title" 
                                  [hint]="item.hint"
                                  [accept]=""
                                  [isDisabled]="item.is_disabled"
                                  [(ngModel)]="item.value"
                                  name="{{item.title}}"
                                  [id]="item.id + '_file_'"
                                  (change)="onFileSelected($event,item.title)">
    
                  </app-input-file>
                </ng-container>
              </div>
    
    
              <div class="container">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <button class="btn" id="notificationBtn" style="display: none;" (click)="navigate()">Add Notification</button>
    
                  </div>
                  <div class="col-auto">
                    <button type="submit" class="btn" id="approveBtn" *ngIf="filteredTextInputs && filteredTextInputs.length > 0 || filteredTextDate && filteredTextDate.length > 0 || filteredTextFile && filteredTextFile.length > 0">Submit</button>
    
                  </div>
                </div>
              </div>
              
    
    
            
            </form>
          </div>
           
        </div> -->


    <div class='allinonelink'>
      <div style='margin-bottom:4px'>
        <a class='allLinks' id='total' (click)="logTemplateName('total')">Total</a>
      </div>
      <span class="separator">|</span>
      <div id='requestedIt'>
        <a class='allLinks' id='pending' (click)="logTemplateName('pending')">About to expire</a>
      </div>
      <span class="separator">|</span>
      <div id='requestedIt'>
        <a class='allLinks' id='expired' (click)="logTemplateName('expired')">Expired</a>
      </div>
      <span class="separator">|</span>

      <div id='requestedIt'>
        <a class='allLinks' id='addNew' (click)="navigate()">Add New</a>
      </div>

    </div>

    <router-outlet></router-outlet>

  </div>
import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { LoginService } from 'src/app/core/login/login.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as $ from 'jquery';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'department-appraisal-popup',
    templateUrl: './department-appraisal-popup.component.html',
    styleUrls: ['./department-appraisal-popup.component.scss']
})
export class ViewDepartmentAppraisalComponent implements OnInit {

    teamData: any;
    midCycle: any;
    isValuearray: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ViewDepartmentAppraisalComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        console.log("data-------------")
        this.teamData = this.data.teamData;
        this.midCycle = this.data.midCycle;
        console.log(this.data);

    }

    cancel() {
        this.dialogRef.close();
    }


}
<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"></app-header-global>
<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 card_div">
      <span class="title">Memories</span>
      <ng-container *ngIf="slides.length > 0; else noImagesFound">
        <div class="wrapper_main">
            <ngx-slick-carousel class="carousel slick-main" #slickMain="slick-carousel" [config]="slideConfigMain">
              <div ngxSlickItem *ngFor="let slide of slides" class="slide">
               
                  <img [src]="slide.file" alt="No Image Found" class="img-style">
                  <div class="extra_div" style="visibility:hidden;" *ngIf="slides.length===1">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</div>
              </div>
            </ngx-slick-carousel>
            </div>
            <div class="wrapper_thumb">
            <ngx-slick-carousel class=" carousel slick-thumbs" #slickThumbs="slick-carousel" [config]="slideConfigThumbs">
              <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img [src]="slide.file" alt="No Image Found" class="thumb-style" (click)="goToSlide(slide.index)">
              </div>
            </ngx-slick-carousel>
            </div>
          </ng-container>

          <ng-template #noImagesFound>
            <div class="noimagediv">
              <p>No Memories Image found in this category......</p>
            </div>
           
        </ng-template>
    </div>
</div>

    
    
                            
           

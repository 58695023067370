<!-- <div *ngIf="lndUserType" class="row">
    <div class="col-1 header" (click)="home()" style="max-width: fit-content; cursor: pointer;">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
    <div class="col header">
        L&D Manager | {{user}} Portal
    </div>
    <div class="col-1 header menuIcon">
        <i class="fa fa-power-off" aria-hidden="true"></i>

        <span style="font-size:30px;cursor:pointer;float: right;" (click)="allLink.openNav()">&#9776; </span>

    </div>

    
</div> -->
<span *ngIf="lndUserType">
<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)" ></app-header-global>
</span>
<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
        <div *ngIf="lndUserType" class="row p1rem">
            <div class="col user-header">
                Hello {{name}}!
            </div>
            <div class="col text-align-end">
                <button *ngIf="user == 'admin'" type="button" class="btn btn-primary" (click)="openSessionModal()">
                    <strong>+</strong> Create
                    New Session</button>
            </div>
        </div>
        <div class="wrapper">
        <div *ngIf="lndUserType" class="row">
            <div class="col">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="your-tab" data-bs-toggle="tab" data-bs-target="#your" type="button"
                            role="tab" aria-controls="your" aria-selected="false">My Sessions</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button"
                            role="tab" aria-controls="upcoming" aria-selected="true">Upcoming
                            Sessions</button>
                    </li>
                    <li *ngIf="user == 'admin'" class="nav-item" role="presentation">
                        <button class="nav-link" id="drafts-tab" data-bs-toggle="tab" data-bs-target="#drafts" type="button"
                            role="tab" aria-controls="drafts" aria-selected="false">Drafts</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="past-tab" data-bs-toggle="tab" data-bs-target="#past" type="button"
                            role="tab" aria-controls="past" aria-selected="false">Past Sessions</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="your" role="tabpanel" aria-labelledby="your-tab">
                        <your-sessions></your-sessions>
                    </div>
                    <div class="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <upcoming-sessions></upcoming-sessions>
                    </div>
                    <div class="tab-pane fade" id="drafts" role="tabpanel" aria-labelledby="drafts-tab">
                        <drafts></drafts>
                    </div>
                    <div class="tab-pane fade" id="past" role="tabpanel" aria-labelledby="past-tab">
                        <past-sessions></past-sessions>
                    </div>
                </div>
            </div>
        </div>
        </div>
</div>
</div>




<!-- <div class="modal fade" id="createNewSession" tabindex="-1" aria-labelledby="createNewSessionLabel" aria-hidden="true">
        <session-modal [data]="data"></session-modal>
    </div> -->
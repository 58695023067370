import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { PoaserviceService } from "../service/poaservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LearnerService } from 'src/app/core/services/learner.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/expense-manager/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-raise-poa',
  templateUrl: './raise-poa.component.html',
  styleUrls: ['./raise-poa.component.css']
})

export class RaisePoaComponent implements OnInit {
  showcard: boolean;
  docRes: any;
  formdata: any;
  poaForm: FormGroup;
  id: number;
  action: string;
  employeeNames: string[];
  employeeDetails: string[];
  email: string;
  employeepermissions: any;
  poaPermission: any;
  showAll: boolean = false;
  selectedEmployee: string = '';
  filteredEmployee: string[];
  fileName: string = '';
  selectedFile: File;
  documents: any = [];
  poaFileUrl: string = '';
  raiseBtnVisible: boolean = false;
  pendingBtnVisible: boolean = false;
  draftactionBtnVisible: boolean = false;
  isDisabled: boolean = false;
  isId: boolean = false;
  poaId: any;
  companyNames: string[] = [];


  constructor(
    private poaservice: PoaserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private learnerService: LearnerService,
    public dialog: MatDialog
  ) { }

  showOptions() {
    document.getElementById('option').style.display = 'block';
  }

  filterOptions() {
    const inputNameValue = (document.getElementById('inputName') as HTMLInputElement).value;
    this.filteredEmployee = this.employeeNames.filter(employee =>
      employee.toLowerCase().includes(inputNameValue.toLowerCase())
    );
  }

  getValueOfEmployee(employeeValue: string) {
    const employee = employeeValue.split("-")[0].trim();
    const selectedEmployee = this.employeeDetails.find(emp => emp['name'] === employee);
    if (selectedEmployee) {
      this.poaForm.get("name").setValue(selectedEmployee['name']);
      this.poaForm.get("full_name").setValue(selectedEmployee['name']);
      this.poaForm.get("email").setValue(selectedEmployee['email']);
      this.poaForm.get("mobile_no").setValue(selectedEmployee['mobile_no']);
    }
    document.getElementById('option').style.display = 'none';
  }

  _getEmployee(): void {
    this.poaservice.getEmployee().subscribe((res: any[]) => {
      this.employeeDetails = res;
      this.employeeNames = res.map(employee => `${employee.name}-(${employee.email})`);
    });

  }
  _getCompany(): void {
    this.poaservice.getCompany().subscribe(
      (res: any[]) => {
        this.companyNames = res.map(company => company.name);
        console.log(this.companyNames)
      },
      error => {
        console.error('Error fetching companies:', error);
      }
    );
  }

  ngOnInit(): void {
    this._getCompany()
    this._getEmployee()

    this.poaForm = this.fb.group({
      name: [''],
      full_name: [''],
      father_name: [''],
      email: [''],
      pan_no: [''],
      mobile_no: [''],
      execution_place: [''],
      address: [''],
      description: [''],
      start_date: [''],
      valid_upto: [''],
      remark: [''],
      status: [''],
      company: [''],
      poa_file: [''],
      hod: [''],
      legal: [''],
      created_by: [''],
      created_at: ['']
    });


    this.route.queryParams.subscribe(params => {
      const status_value = params['status'];
      const elementID = params['value'];
      const id = params['id'];

      this.learnerService.getEmployeeDetails().subscribe(
        (res: any) => {
          this.employeepermissions = res['employee_permissions'];
          this.poaPermission = this.employeepermissions?.poa_permission || '';

          if (this.poaPermission === 'hod' || this.poaPermission.includes('legal') || this.poaPermission.includes('ceo')) {
            if (elementID == 'action') {
              // for ceo,legal,hod in where value=action approve and reject button should be shown
              this.pendingBtnVisible = true

            }
          }
          else {
            //view only permission and no permission
            if (status_value == 'draft') {
              this.draftactionBtnVisible = true;

            }
            else if (elementID == 'requested' || elementID == 'get_all') {
              //document.getElementById('raisePoaBtn').style.display='none';

            }
            else {
              // when raising a poa
              this.raiseBtnVisible = true;
            }

          }
        },
        (error: any) => {
          // Handle error here
          console.error('Error fetching employee details:', error);
        }
      );


      (document.querySelectorAll('.allLinks') as NodeListOf<HTMLElement>).forEach(link => {
        link.style.color = '#656565';
        link.style.fontWeight = '400';
      });

      document.getElementById(elementID).style.color = '#07527D';
      document.getElementById(elementID).style.fontWeight = '600';



      if (id) {
        this.isId = true;
        this.poaId = id;
        this.poaservice.getPoa(id).subscribe((res: any) => {
          if (res.poa_file) {
            this.poaFileUrl = res.poa_file;
            //document.getElementById('fileDiv').style.display='none';
            //document.getElementById('imageLinkDiv').style.display='block'
          }
          else {
            this.poaFileUrl = ''
          }

          this.poaForm.patchValue({
            name: res.name,
            full_name: res.full_name,
            pan_no: res.pan_no,
            father_name: res.father_name,
            email: res.email,
            address: res.address,
            description: res.description,
            execution_place: res.execution_place,
            mobile_no: res.mobile_no,
            start_date: res.start_date,
            valid_upto: res.valid_upto,
            remark: res.remark,
            company: res.company
            // Assign other form values as needed
          });

          if (status_value == 'draft') {

          }
          else {
            this.isDisabled = true;
            this.raiseBtnVisible = false;

          }

        });
      }
      else {
        // Reset form and enable inputs if id is not present
        this.isId = false;
        this.poaForm.reset();
        this.poaFileUrl = '';
        //document.getElementById('imageLinkDiv').style.display='none';
        //document.getElementById('fileDiv').style.display='flex';
        this.isDisabled = false
        this.raiseBtnVisible = true;
        this.pendingBtnVisible = false;
        this.draftactionBtnVisible = false;

      }

    });
  }


  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  getDocument(docres: any) {
    this.docRes = docres;
  }

  validateForm(form: NgForm): boolean {
    const formdata = {
      name: form.value.name,
      full_name: form.value.full_name,
      father_name: form.value.father_name,
      email: form.value.email,
      pan_no: form.value.pan_no,
      mobile_no: form.value.mobile_no,
      execution_place: form.value.execution_place,
      address: form.value.address,
      description: form.value.description,
      start_date: form.value.start_date,
      valid_upto: form.value.valid_upto,
      remark: form.value.remark,
      company: form.value.company

    };


    let isValid = true;
    const fields = ['name', 'full_name', 'father_name', 'email', 'pan_no', 'mobile_no', 'execution_place', 'address', 'description', 'start_date', 'valid_upto', 'remark'];

    if (this.poaPermission == 'view' || this.poaPermission == 'poa') {
      fields.push('company');

    }


    for (const field of fields) {
      if (!formdata[field]) {
        document.getElementById(`invalid_${field}`).style.display = 'block';
        isValid = false;
        return isValid;
      }

      if (field === 'pan_no' && formdata[field].length !== 10) {
        document.getElementById(`invalid_${field}`).style.display = 'block';
        isValid = false;
        return isValid;

      }

      if (field === 'mobile_no' && formdata[field].toString().length < 10) {
        document.getElementById(`invalid_${field}`).style.display = 'block';
        isValid = false;
        return isValid;

      }

      if (field === 'email' && !this.validateEmail(formdata[field])) {
        document.getElementById(`invalid_${field}`).style.display = 'block';
        isValid = false;
        return isValid;

      }
    }

    return isValid;
  }

  _raisePoa(form: NgForm, action: string, request: string) {


    const isValid = this.validateForm(form);
    if (isValid) {
      let formData = new FormData();
      formData.append('name', form.value.name)
      formData.append('full_name', form.value.full_name)
      formData.append('father_name', form.value.father_name)
      formData.append('email', form.value.email)
      formData.append('pan_no', form.value.pan_no)
      formData.append('mobile_no', form.value.mobile_no)
      formData.append('execution_place', form.value.execution_place)
      formData.append('address', form.value.address)
      formData.append('description', form.value.description)
      formData.append('start_date', form.value.start_date)
      formData.append('valid_upto', form.value.valid_upto)
      formData.append('company', form.value.company)
      formData.append('remark', form.value.remark)
      formData.append('action', action);
      formData.append('company', form.value.company)



      if (this.selectedFile) {
        formData.append('poa_file', this.selectedFile);

      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          try {
            if (request === 'post') {
              console.log(formData)
              this.poaservice.raisePoa(formData).subscribe((res: any) => {
                alert('POA Saved Successfully');
                this.router.navigate(['poa', 'view_poa'], {
                  queryParams: {
                    value: 'requested'
                  }
                });
              });
            } else if (request === 'put') {
              this.route.queryParams.subscribe(params => {
                const id = params['id'];
                this.poaservice.updatePoa(formData, id, action).subscribe((res: any) => {
                  alert('POA Saved Successfully');

                  // if (action == 'approve_save_file' && this.selectedFile) {
                  //   //remain in the same page
                  //   window.location.reload()
                  // } else {
                  this.router.navigate(['poa', 'view_poa'], {
                    queryParams: {
                      value: 'requested'
                    }
                  });
                  // }

                });
              });
            }
          } catch (error) {
            console.error("An error occurred:", error);
            throw new Error("Something went wrong. Please try again later.");
          }
        }
      });
    }
    else {
      // form is not valid

    }
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  _updateStatus(action: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.route.queryParams.subscribe(params => {
          const elementID = params['value'];
          const id = params['id'];

          this.poaservice.updateStatus(id, action).subscribe((res: any) => {
            alert('POA updated successfully')
            //route them to requested page
            this.router.navigate(['poa', 'view_poa'], {
              queryParams: {
                value: 'requested'
              }
            });
          });
        });
      }
    });

  }

  _deletePoa() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.route.queryParams.subscribe(params => {
          try {
            const id = params['id'];

            this.poaservice.deletePoa(id).subscribe((res: any) => {
              alert('POA deleted successfully')
              this.router.navigate(['poa', 'view_poa'], {
                queryParams: {
                  value: 'requested'
                }
              });


            });
          } catch (error) {
            console.error('An error occurred:', error);
          }
        });

      }
    });

  }


  onNameSelected(name: string) {
    const selectedEmployee = this.employeeDetails.find((employee: any) => employee.name === name);
    if (selectedEmployee) {
      this.poaForm.get('email').setValue(selectedEmployee['email']);
      this.poaForm.get('mobile_no').setValue(selectedEmployee['mobile_no']);
      this.poaForm.get('full_name').setValue(selectedEmployee['name']);

    }
  }

  hideValidation(elementID: string) {
    document.getElementById(elementID).style.display = 'none'
  }


  onBrowseBtnClick(): void {
    document.getElementById('fileUpload').click();
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      const inputElement = document.getElementById('poafileInput') as HTMLInputElement;
      inputElement.value = this.selectedFile.name;
      document.getElementById('removeBtn').style.display = 'block';

    } else {
      this.fileName = '';
    }
  }

  removeFile() {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput.value = ''; // Clear the value of the file input
    const inputElement = document.getElementById('poafileInput') as HTMLInputElement;
    inputElement.value = ''; // Set the value of the input field
    document.getElementById('removeBtn').style.display = 'none';
  }


  viewDownloadPdf(file) {
    this.learnerService.getViewDownloadPdf(file).subscribe(res => {

      this.documents = res;
      // alert(this.documents.presigned_url);
      window.open(this.documents.presigned_url, "_blank");
      //window.location.reload();
    },
      (error: any) => {
        console.log(error, error.error, error.message);

      });
  }

  getTheFileUrl() {
    const fileUrlElement = document.getElementById('urlValue');
    const imageUrl = fileUrlElement.innerHTML;
    this.viewDownloadPdf(imageUrl)

  }



}


























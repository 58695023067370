<div class="row">
    <div class="col pt10">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Upcoming Sessions
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div *ngIf="upcomingList.length >0" class="accordion-body">
                        <div *ngFor="let item of upcomingList">
                            <div class="row">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div *ngIf="item.status == 'cancelled'" class="row">
                                                        <div class="col text-heading">
                                                            <strong>{{item.name}}</strong> | {{item.modality}}
                                                            <span
                                                                class="badge rounded-pill text-bg-secondary tooltipInput"
                                                                style="background-color: #6c757d54 !important;">{{item.status}}
                                                                <span class="tooltiptext">This session was
                                                                    cancelled by the Admin/HR.
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.status != 'cancelled'" class="row">
                                                        <div class="col text-heading">
                                                            <strong>{{item.name}}</strong> | {{item.modality}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-decor">
                                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                            {{item.location}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-decor">
                                                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                                            {{item.start_time | date:'MMM dd, yyyy'}} |
                                                            {{item.start_time | date:'HH:mm aa'}} -
                                                            {{item.end_time | date:'HH:mm aa'}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-decor"
                                                            style="width:max-content !important; border-right: 1px solid #000;">
                                                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                                            {{item.no_of_enrollment}}/{{item.capacity}} Spots
                                                            filled
                                                        </div>
                                                        <div *ngIf="item.enrollment_status == 'enrolled' && item.status !='cancelled' && item.disenroll_close_time > now"
                                                            class="col-3 list-link" (click)="unenroll(item)">
                                                            Disenroll
                                                        </div>
                                                        <div *ngIf="item.enrollment_status == 'enrolled' && item.status !='cancelled' && item.disenroll_close_time <= now"
                                                            class="col-3 list-link-disabled">
                                                            Disenroll
                                                        </div>
                                                        <div *ngIf="item.enrollment_status != 'enrolled' || item.status =='cancelled'"
                                                            class="col-3 list-link">
                                                            <span
                                                                class="badge rounded-pill text-bg-secondary tooltipInput">{{item.enrollment_status}}
                                                                <span *ngIf="item.enrollment_status == 'disenrolled'"
                                                                    class="tooltiptext">You disenrolled from
                                                                    this session.</span>
                                                                <span *ngIf="item.enrollment_status == 'absent'"
                                                                    class="tooltiptext">You were marked absent
                                                                    from
                                                                    this session.</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.modality == 'online' && item.status !='cancelled'" class="col">
                                                    <div class="row">
                                                        <div class="col p5 text-align-end">
                                                            <button
                                                                *ngIf="item.enrollment_status == 'enrolled' && item.meeting_link != null"
                                                                type="button" class="btn btn-primary"
                                                                (click)="joinSessionByUrl(item.meeting_link)">Join
                                                                Session</button>
                                                            <button
                                                                *ngIf="item.enrollment_status == 'enrolled' && item.meeting_link == null"
                                                                type="button" class="btn btn-outline-secondary"
                                                                [disabled]="true">Join
                                                                Session</button>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                    <div class="col p5 view-btn">
                                                        <button type="button" class="btn btn-outline-primary">View</button>
                                                    </div>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="upcomingList.length <= 0" class="accordion-body no-data">
                        No Sessions :(
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Past Sessions
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div *ngIf="pastList.length > 0" class="accordion-body">
                        <div *ngFor="let item of pastList">
                            <div class="row">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div *ngIf="item.status == 'cancelled'" class="row">
                                                        <div class="col text-heading">
                                                            <strong>{{item.name}}</strong> | {{item.modality}}
                                                            <span class="badge rounded-pill text-bg-secondary tooltipInput"
                                                                style="background-color: #6c757d54 !important;">{{item.status}}
                                                                <span class="tooltiptext">This session was
                                                                    cancelled by the Admin/HR.
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.status != 'cancelled'" class="row">
                                                        <div class="col text-heading">
                                                            <strong>{{item.name}}</strong> | {{item.modality}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-decor">
                                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                            {{item.location}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-decor">
                                                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                                            {{item.start_time | date:'MMM dd, yyyy'}} |
                                                            {{item.start_time | date:'HH:mm aa'}} -
                                                            {{item.end_time | date:'HH:mm aa'}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-decor"
                                                            style="width:max-content !important; border-right: 1px solid #000;">
                                                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                                            {{item.no_of_enrollment}}/{{item.capacity}} Spots
                                                            filled
                                                        </div>
                                                        <div class="col-3 list-link">
                                                            <span
                                                                class="badge rounded-pill text-bg-secondary tooltipInput">{{item.enrollment_status}}
                                                                <span *ngIf="item.enrollment_status == 'disenrolled'"
                                                                    class="tooltiptext">You disenrolled from
                                                                    this session.</span>
                                                                <span *ngIf="item.enrollment_status == 'absent'"
                                                                    class="tooltiptext">You were marked absent
                                                                    from
                                                                    this session.</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.status != 'cancelled' && now >= item.feedback_open_time && item.enrollment_status =='enrolled'"
                                                        class="row">
                                                        <div class="col list-link" (click)="rating(item)">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i> Click here
                                                            to write/view a feedback
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.status != 'cancelled' && now < item.feedback_open_time && item.enrollment_status =='enrolled'"
                                                        class="row">
                                                        <div class="col list-link-disabled">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i> Click here
                                                            to write/view a feedback
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col">
                                                    <div class="row">
                                                        <div class="col p5 text-align-end">
                                                            <button type="button" class="btn btn-primary">Join
                                                                Session</button>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pastList.length <= 0" class="accordion-body no-data">
                        No Sessions :(
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
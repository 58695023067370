<div class='search-tab' [style.display]="isValue ? 'flex' : 'none'">
  <input type="search" id='searchId' [(ngModel)]="searchTerm" placeholder="Search here" (input)="onSearchChange()">
</div>

<div class="imgDiv" *ngIf="noData">
  <img src="../../../../assets/images/template_img.svg" alt="" id="noDataImg">
</div>

<div *ngFor="let item of responseData; let i = index">
  <div class="card" (click)="handleCardClick($event, item.id)">
    <div class="card-body">
      <div class='card-head'>
        <p class="card-text"><span class='card_title'>SrNo : </span>{{i+1}}</p>
        <p class="card-text"><span class='card_title'>ID : </span>{{item.id}}</p>
      </div>

      <div class='seperateDiv'>
        <div class='subjectDiv'>
          <div class="itemInfo">
            <ng-container *ngFor="let value of visible_to">
              <p class="card-text"><span class="card_title">{{value.title}} : </span>{{item.values[value.id]}}</p>
            </ng-container>
          </div>
        </div>

        <div class='buttonsDiv'>
          <button type="button" class="btn space" (click)="handleEditButtonClick($event, item.id)">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn space" (click)="downloadFile($event, item.id)">
            <i class="fa fa-solid fa-download"></i>
          </button>


        </div>
      </div>
    </div>
  </div>
</div>

<!-- Move the button outside of the *ngFor loop -->
<!-- <div class="position-fixed  end-0 p-3 " *ngIf="insuranceBtn" id="addValueBtn">
    <button id="addBtn" class="btn" (click)="navigate()">Add Insurance</button>
</div> -->




<!-- <button type="button" class="btn space">
              <i class="fa fa-trash" aria-hidden="true" (click)="handleDeleteButtonClick($event, item.id)"></i>
      </button> -->
<div class="manage-proposal-wrapper" style="width: 400px; max-height: fit-content;">
    <!-- <div class="modal-dialog">
    <div class="modal-content"> -->
    <!-- <div class="modal-header"> -->
    <div class="row pb10" *ngIf="data.draft">
        <div class="col">
            <h4 id="sessionLabel">View Draft</h4>
        </div>
        <div class="col-1 text-align-end p0">
            <i class="fa fa-pencil-square-o edit" aria-hidden="true" (click)="editDraft(data.id)"></i>
        </div>
        <div class="col-1 text-align-end">
            <i class="fa fa-trash del" aria-hidden="true" (click)="deleteDraft()"></i>
        </div>
    </div>
    <div class="row pb10" *ngIf="data.upcoming">
        <div class="col">
            <h4 id="sessionLabel">View Upcoming Session</h4>
        </div>
    </div>
    <div class="row pb10" *ngIf="data.past">
        <div *ngIf="f_value.status != 'cancelled'" class="col">
            <h4 id="sessionLabel">View Past Session</h4>
        </div>
        <div *ngIf="f_value.status == 'cancelled'" class="col">
            <h4 id="sessionLabel">View Upcoming Session</h4>
        </div>
    </div>
    <div *ngIf="data.upcoming" class="row">
        <div class="row">
            <div class="col pt10 key">
                Name
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.name}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Modality
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.modality}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Location
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.location}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Capacity
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.capacity}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Enrollment
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.no_of_enrollment}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Date
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'MMM dd, yyyy'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Timing
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'HH:mm aa'}} - {{f_value.end_time | date: 'HH:mm aa'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Agenda
            </div>
        </div>
        <div class="row">
            <div *ngIf="f_value.agenda != null" class="col agenda" (click)="openAgendaFile(f_value.agenda)">
                View File
            </div>
            <div *ngIf="f_value.agenda == null" class="col value">
                No File 
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Open to
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.open_to}}
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col pt10 key">
                Meeting Link
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col value">
                {{f_value.meeting_link}}
            </div>
        </div>
        <div class="row">
            <div class="col pb10 pt10">
                <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="cancel()">
                    Go Back
                </button>
            </div>
        </div>
        <div *ngIf="isAdmin" class="row">
            <div class="col">
                <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                    (click)="cancelSession(f_value)">
                    Cancel Session
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="data.past" class="row">
        <div class="row">
            <div class="col pt10 key">
                Name
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.name}}
            </div>
            <div *ngIf="(f_value.status != 'cancelled') && isAdmin" class="col value">
                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
                        <li [ngClass]="{'selected': (star <= f_value.avg_rating)}">
                            <i class="fa fa-star"></i>
                        </li>
                    </ul>
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Modality
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.modality}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Location
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.location}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Capacity
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now) && isAdmin)" class="col pt10 key">
                Absent
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.capacity}}
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now)) && isAdmin" class="col value">
                {{f_value.absent_count}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Enrollment
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now) && isAdmin)" class="col pt10 key">
                Disenrolled
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.no_of_enrollment}}
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now) && isAdmin)" class="col value">
                {{f_value.disenrolled_count}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Date
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'MMM dd, yyyy'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Timing
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'HH:mm aa'}} - {{f_value.end_time | date: 'HH:mm aa'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Agenda
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now) && isAdmin)" class="col pt10 key">
                Feedback
            </div>
        </div>
        <div class="row">
            <div *ngIf="f_value.agenda != null" class="col agenda" (click)="openAgendaFile(f_value.agenda)">
                View File
            </div>
            <div *ngIf="f_value.agenda == null" class="col value">
                No File 
            </div>
            <div *ngIf="((f_value.status != 'cancelled' || f_value.start_time < now) && isAdmin)" class="col value feedback" (click)="openFeedbacks(f_value)">
                Click to view
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Open to
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.open_to}}
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col pt10 key">
                Meeting Link
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col value">
                {{f_value.meeting_link}}
            </div>
        </div>
        <div class="row">
            <div class="col pb10 pt10">
                <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                    (click)="cancel()">
                    Go Back
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="data.draft" class="row">
        <div class="row">
            <div class="col pt10 key">
                Name
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.name}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Modality
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.modality}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Location
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.location}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Capacity
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.capacity}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Enrollment
            </div>
        </div>
        <div class="row">
            <div *ngIf="f_value.no_of_enrollment !=null" class="col value">
                {{f_value.no_of_enrollment}}
            </div>
            <div *ngIf="f_value.no_of_enrollment == null" class="col value">
                0
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Date
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'MMM dd, yyyy'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Timing
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.start_time | date: 'HH:mm aa'}} - {{f_value.end_time | date: 'HH:mm aa'}}
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Agenda
            </div>
        </div>
        <div class="row">
            <div *ngIf="f_value.agenda != null" class="col agenda" (click)="openAgendaFile(f_value.agenda)">
                View File
            </div>
            <div *ngIf="f_value.agenda == null" class="col value">
                No File 
            </div>
        </div>
        <div class="row">
            <div class="col pt10 key">
                Open to
            </div>
        </div>
        <div class="row">
            <div class="col value">
                {{f_value.open_to}}
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col pt10 key">
                Meeting Link
            </div>
        </div>
        <div *ngIf="f_value.enrollment_status == 'enrolled'" class="row">
            <div class="col value">
                {{f_value.meeting_link}}
            </div>
        </div>
        <div class="row">
            <div class="col pb10 pt10 key">
                <button type="submit" class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                        (click)="publish()" [disabled]="!publishEnable">
                        Publish
                    </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button type="submit" class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;"
                    (click)="cancel()">
                    Go Back
                </button>
            </div>
        </div>
    </div>
</div>
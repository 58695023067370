import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalPageComponent } from './components/portal-page/portal-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './core/login/login.component';
import { AllLinksComponent } from './core/all-links/all-links.component';
import{ EmployeeDocumentsComponent } from './components/employee-documents/employee-documents.component';
import { AllClaimComponent } from './components/expense-manager/all-claim/all-claim.component';
import { ClaimDetailsComponent } from './components/expense-manager/claim-details/claim-details.component';
import { HrmanagerComponent } from './components/hr-manager/hrmanager/hrmanager.component';
import { AuthGuard } from './core/gaurd/auth.guard';
import { EmpolyeeListComponent } from './components/hr-manager/empolyee-list/empolyee-list.component';
import { HolidayListComponent } from './components/hr-manager/holiday-list/holiday-list.component';
import { LeavesReportComponent } from './components/hr-manager/leaves-report/leaves-report.component';
import { ProductlistComponent } from './components/productlist/productlist.component';
import { CircularComponent } from './components/circular/circular.component';
import { ViewPoaComponent } from './components/poa/view-poa/view-poa.component'
import { RaisePoaComponent } from './components/poa/raise-poa/raise-poa.component'
import { MainPoaComponent } from './components/poa/main-poa/main-poa.component'
import { NotificationModuleComponent } from './components/notification-module/notification-module.component'
import { TemplateModuleComponent } from './components/template-module/template-module.component'
import { MemoriesComponent } from "./components/all-memories/memories/memories.component";
import { MemoriesDetailsComponent } from "./components/all-memories/memories-details/memories-details.component";
import { MemoriesGalleryComponent } from "./components/all-memories/memories-gallery/memories-gallery.component";
import { AppraisalComponent } from "./components/appraisal/appraisal.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { SelfAppraisalComponent } from "./components/appraisal/self-appraisal/self-appraisal.component";
import { TeamAppraisalFormComponent } from "./components/appraisal/team-appraisal/team-appraisal-form/team-appraisal-form.component";
import { BulkSubmitComponent } from "./components/appraisal/bulk-submit/bulk-submit.component";
import { DepartmentAppraisalFormComponent } from "./components/appraisal/cluster-appraisal/department-appraisal-form/department-appraisal-form.component";
import { BusinessAppraisalFormComponent } from "./components/appraisal/business-appraisal/business-appraisal-form/business-appraisal-form.component";
import { SaveTemplateComponent } from './components/template-module/save-template/save-template.component';
import { ViewTemplateComponent } from './components/template-module/view-template/view-template.component';
import { InputMultipleFileComponent } from './components/input-multiple-file/input-multiple-file.component';
import { HrHomeComponent } from './components/employee-onboarding/hr-home/hr-home.component';
import { VerifyComponent } from './components/employee-onboarding/verify-user-data/verif/verif.component';
import { EmpAddComponent } from './components/employee-onboarding/add-to-emp/add-to-emp.component';
import { OnboardingComponent } from './components/hr-manager2/onboarding/onboarding.component';
import { EmpolyeeListComponent2 } from './components/hr-manager2/empolyee-list/empolyee-list.component';
import { HolidayListComponent2 } from './components/hr-manager2/holiday-list/holiday-list.component';
import { VerifyComponent2 } from './components/hr-manager2/verify-user-data/verif/verif.component';
import { HrmanagerComponent2 } from './components/hr-manager2/hrmanager/hrmanager.component';
import { CompanyListComponent } from './components/hr-manager2/company-list/company-list.component';
import { BusinessListComponent } from './components/hr-manager2/business-list/business-list.component';
import { DepartmentListComponent } from './components/hr-manager2/department-list/department-list.component';


const routes: Routes = [
  {
    path: "learning-and-development",
    component: PortalPageComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "home",
    component: AllLinksComponent,
    // canActivate: [AuthGuard]
  },
  { path: "products", component: ProductlistComponent },

  { path: "memories", component: MemoriesComponent },

  { path: "memories/:category", component:MemoriesDetailsComponent },
  
  { path:"memories/:category/:id",component:MemoriesGalleryComponent},
  
  { path: "circular", component: CircularComponent },
  
  {
    path: "",
    component: LoginComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "onboarding",
    component: OnboardingComponent,
  },
  {
    path: "employee-list",
    component: EmpolyeeListComponent2,
  },
  {
    path: "poa",
    component: MainPoaComponent,
    children:[
      { path: 'raise_poa', component : RaisePoaComponent},
      { path: 'view_poa', component : ViewPoaComponent},
      
    ]
  },

  {
    path: "employee-documents",
    component: EmployeeDocumentsComponent,
    // canActivate: [AuthGuard]
  },

  {
    path: "expense-manager/all-claim",
    component: AllClaimComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "appraisals",
    component: AppraisalComponent,
    // canActivate: [AuthGuard],
    
  },
  {
    path: "teams",
    component: TeamAppraisalFormComponent,
    // canActivate: [AuthGuard],
    
  },
  {
    path: "department",
    component: DepartmentAppraisalFormComponent,
    // canActivate: [AuthGuard],
    
  },
  {
    path: "business",
    component: BusinessAppraisalFormComponent,
    // canActivate: [AuthGuard],
    
  },
  {
    path: "bulk-submit",
    component: BulkSubmitComponent,
    // canActivate: [AuthGuard],
    
  },
  {
    path: "profile",
    component: ProfileComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "detailclaim/:id",
    component: ClaimDetailsComponent,
    // canActivate: [AuthGuard]
  },
  // {
  //   path: "hr-manager-crud",
  //   component: HrmanagerComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: "", redirectTo: "employelist", pathMatch: "full" },

  //     { path: "employelist", component: EmpolyeeListComponent },
  //     { path: "holidaylist", component: HolidayListComponent },
  //     // { path: "leavesreport", component: LeavesReportComponent },
  //   ],
  // },
  {
    path: "hr-manager",
    component: HrmanagerComponent2,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "employelist", pathMatch: "full" },
      { path: "companylist", component: CompanyListComponent },
      { path: "businesslist", component: BusinessListComponent},
      { path: "departmentlist", component: DepartmentListComponent},
      { path: "employelist", component: EmpolyeeListComponent2 },
      { path: "holidaylist", component: HolidayListComponent2 },
      // { path: "leavesreport", component: LeavesReportComponent },
    ],
  },
  {
    path:"notify",
    component:NotificationModuleComponent
  },

  {
    path: "template",
    component: TemplateModuleComponent,
    children:[
      { path: 'save_template', component : SaveTemplateComponent},
      { path: 'view_template', component : ViewTemplateComponent},

      
    ]
  },
  {
    path:'multiple_file',
    component:InputMultipleFileComponent
  },
  
  {
    path:"hr-home",
    component:HrHomeComponent
  },

  {path: 'verify-user-data',
    component: VerifyComponent},
    {path: 'verify-data',
    component: VerifyComponent2},
    {path: 'add-to-emp', component: EmpAddComponent},
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin.service';
import { ActionPopupComponent } from '../action/action.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ViewSessionComponent } from '../view-session/view-session.component';
import { LoginService } from 'src/app/core/login/login.service';
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoaderService } from 'src/app/core/services/loader.service';

const defaultDialogConfig = new MatDialogConfig();
@Component({
    selector: 'session-modal',
    templateUrl: './session-modal.component.html',
    styleUrls: ['./session-modal.component.scss']
})
export class SessionModalComponent implements OnInit {

    form: FormGroup;
    modality_dropdown = [{ key: "Online", value: "online" }, { key: "Offline", value: "offline" }];
    file: any;
    addedFile: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SessionModalComponent>,
        private dialog: MatDialog,
        private adminService: AdminService,
        private fb: FormBuilder,
        private loginService: LoginService,
        private learnerService: LearnerService,
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        console.log("inside session page", this.data);
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        this.form = this.fb.group({
            id: [""],
            no_of_enrollment: [""],
            avg_rating: [""],
            name: ["", Validators.required],
            modality: ["online", Validators.required],
            location: ["", Validators.required],
            start_time: [this.data.startTime, Validators.required],
            end_time: [this.data.endTime, Validators.required],
            capacity: [""],
            open_to: ["", Validators.required],
            agenda: [""],
            meeting_link: [""],
            enroll_close_time: [""],
            disenroll_close_time: [""],
            feedback_open_time: [""],
            feedback_close_time: [""],
            calender_event_id: [""],
            enrollment_status: [""],
            status: [""]
        });
        if (this.data.sessionData) {
            this._patchDraft();
        }
    }
    cancel() {
        this.dialogRef.close();
    }

    _patchDraft() {
        let f_value = this.data.sessionData;
        f_value.start_time = formatDate(f_value.start_time, 'yyyy-MM-ddTHH:mm:ss', 'en-IN');
        f_value.end_time = formatDate(f_value.end_time, 'yyyy-MM-ddTHH:mm:ss', 'en-IN');
        if (f_value.agenda) {
            this.addedFile = f_value.agenda.replace('https://amplus-documents.s3.amazonaws.com/lnd_session/', '');
        }
        this.form.patchValue({
            id: f_value.id,
            no_of_enrollment: f_value.no_of_enrollment,
            avg_rating: f_value.avg_rating,
            name: f_value.name,
            modality: f_value.modality,
            location: f_value.location,
            start_time: f_value.start_time,
            end_time: f_value.end_time,
            capacity: f_value.capacity,
            open_to: f_value.open_to,
            meeting_link: f_value.meeting_link,
            enroll_close_time: f_value.enroll_close_time,
            disenroll_close_time: f_value.disenroll_close_time,
            feedback_open_time: f_value.feedback_open_time,
            feedback_close_time: f_value.feedback_close_time,
            calender_event_id: f_value.calender_event_id,
            enrollment_status: f_value.enrollment_status,
            status: f_value.status
        });
        console.log("form value --patch-- draft --->", this.form.value);
    }

    deleteDraft() {
        let dialogRef = this.dialog.open(ActionPopupComponent, {
            width: "300px",
            data: {
                buttontext: "Yes, Delete Draft",
                desc: "Are you sure to delete this draft?",
            },
        });
        const sub = dialogRef.componentInstance.option.subscribe((data) => {
            if (data) {
                sub.unsubscribe();
                if (data == "success") {
                    this.adminService.deleteSession(this.data.id).subscribe((res: any) => {
                        console.log(res);
                        if (res.message == 'Draft deleted') {
                            this.dialogRef.close();
                            let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                                data: {
                                    message: res.message,
                                    data: res.data
                                },
                            });
                        }
                    });
                }
            }
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    saveDraft() {
        let f_value = this.form.value;
        console.log(this.form.value.start_time, this.form.value.end_time);
        this.form.value.start_time = formatDate(this.form.value.start_time, "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        this.form.value.end_time = formatDate(this.form.value.end_time, "yyyy-MM-ddTHH:mm:ssZ", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        var formData: any = new FormData();
        if (f_value.id) {
            formData.append("id", f_value.id);
        }
        if (f_value.no_of_enrollment) {
            formData.append("no_of_enrollment", f_value.no_of_enrollment);
        }
        if (f_value.avg_rating) {
            formData.append("avg_rating", f_value.avg_rating);
        }
        if (f_value.name) {
            formData.append("name", f_value.name);
        }
        if (f_value.modality) {
            formData.append("modality", f_value.modality);
        }
        if (f_value.location) {
            formData.append("location", f_value.location);
        }
        if (f_value.start_time) {
            formData.append("start_time", f_value.start_time);
        }
        if (f_value.end_time) {
            formData.append("end_time", f_value.end_time);
        }
        if (f_value.capacity) {
            formData.append("capacity", f_value.capacity);
        }
        if (f_value.open_to) {
            formData.append("open_to", f_value.open_to);
        }
        if (this.file) {
            console.log(this.file);
            formData.append("agenda", this.file);
        }
        // formData.append("calender_event_id", f_value.calender_event_id);
        // formData.append("enrollment_status", f_value.enrollment_status);
        formData.append("status", "draft");
        console.log("form value ---- draft --->", this.form.value);

        if (this.data.create) {
            this.adminService.createNewSession(formData).subscribe((res: any) => {
                console.log("create session (draft)", res);
                if (res.message == 'Draft Saved') {
                    this.dialogRef.close();
                    let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                        data: {
                            message: res.message,
                            data: res.data
                        },
                    });
                }
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                    else {
                        alert(error.error.error);
                    }
                });
        }
        else if (this.data.edit) {
            this.adminService.modifySession(this.data.id, formData).subscribe((res: any) => {
                console.log("create session (draft)", res);
                if (res.message == 'Draft Saved') {
                    this.dialogRef.close();
                    let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                        data: {
                            message: res.message,
                            data: res.data
                        },
                    });
                }
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                    else {
                        alert(error.error.error);
                    }
                });
        }
    }

    publish() {
        let f_value = this.form.value;
        this.form.value.start_time = formatDate(this.form.value.start_time, "yyyy-MM-dd HH:mm:ss", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        this.form.value.end_time = formatDate(this.form.value.end_time, "yyyy-MM-dd HH:mm:ss", 'en-IN').replace(/([+-]\d{2})(\d{2})/, '$1:$2');
        var formData: any = new FormData();
        formData.append("id", f_value.id);
        formData.append("no_of_enrollment", f_value.no_of_enrollment);
        formData.append("avg_rating", f_value.avg_rating);
        formData.append("name", f_value.name);
        formData.append("modality", f_value.modality);
        formData.append("location", f_value.location);
        formData.append("start_time", f_value.start_time);
        formData.append("end_time", f_value.end_time);
        // if (f_value.capacity != 0) {
            formData.append("capacity", f_value.capacity);
        // }
        // else if
        formData.append("open_to", f_value.open_to);
        if (this.file) {
            console.log(this.file);
            formData.append("agenda", this.file);
        }
        formData.append("calender_event_id", f_value.calender_event_id);
        formData.append("enrollment_status", f_value.enrollment_status);
        formData.append("status", "published");
        console.log("form value ---- draft --->", this.form.value);
        if (this.data.create) {
            this.adminService.createNewSession(formData).subscribe((res: any) => {
                console.log("create session (published)", res);
                if (res.message == 'Published successfully') {
                    this.dialogRef.close();
                    let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                        data: {
                            message: res.message,
                            data: res.data
                        },
                    });
                }
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                    else {
                        alert(error.error.error);
                    }
                });
        }
        else if (this.data.edit) {
            this.adminService.modifyNewSession(f_value.id, formData).subscribe((res: any) => {
                console.log("modify session (published)", res);
                if (res.message == 'Published successfully') {
                    this.dialogRef.close();
                    let dialogRefPopup = this.dialog.open(ConfirmationPopupComponent, {
                        data: {
                            message: res.message,
                            data: res.data
                        },
                    });
                }
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                    else {
                        alert(error.error.error);
                    }
                });
        }
    }

    fileChange(event) {
        console.log("on  file change -==------------------------------->", event);
        this.file = event.target.files[0];
    }

    openAgendaFile(url) {
        let params = {
            file_url: url
        }
        this.learnerService.getAgenda(params).subscribe(res => {
            window.open(res['presigned_url'], "_blank");
        })
    }
}
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // Check if the response status is 401
                if (event.status === 401) {
                    // Redirect to login page
                    this.router.navigate(['/login']);
                }
            }
        },
            (error) => {
                // Handle error responses here
                if (error.status === 401) {
                    // Redirect to login page
                    this.router.navigate(['/login']);
                }
            }));
    }
}

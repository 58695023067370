
<div class="mb-3">
    <label for="ccEmails" class="form-label">{{ heading }}</label>
    <div class="container">
        <button type="button" class="btn btn-sm btn-notify" (click)="triggerFileInput()"
            *ngIf="showRemoveButton">Upload</button>
        <div class="custom-file" style="display: none;">
            <input type="file" class="custom-file-input" [disabled]="isDisabled" [id]="id" [required]="isRequired" multiple (change)="onFileSelected($event,id)">
            <label class="custom-file-label" for="customFile"></label>
        </div>

        <div class='mt-2'>
            <div *ngIf="files && files.length > 0">
                <div *ngFor="let fileInfo of files" class="alert alert-light d-flex justify-content-between" role="alert">
                  <span class='fileSpan'>{{ fileInfo.file.name }}</span>
                  <button type="button" id="remove" class="btn btn-sm btn-outline-danger" *ngIf="showRemoveButton"
                    (click)="removeFile(fileInfo)">Remove</button>
                </div>
              </div>
            

            <div>

                <div *ngFor="let savedFile of uploadedFileUrl" class="alert alert-light d-flex justify-content-between"
                    role="alert">
                    <span class='fileSpan'>{{ savedFile }}</span>
                    <button type="button"  class="btn btn-sm btn-outline-danger" (click)="removeSavedFile(savedFile)"
                        *ngIf="showRemoveButton">Remove</button>
                </div>

            </div>
        </div>

    </div>
</div>
<app-header [expenseDetailsHeading]="headingFour"></app-header>

<div class="btn_div" *ngFor="let claimdata of claims">
  <span *ngIf="is_actions_global">
    <button
      (click)="openApproveAllConfirmation(claimdata.data.id)"
      class="btn btn-success"
    >
      Approve All
    </button>
    <button
      (click)="openRejectAllConfirmation(claimdata.data.id)"
      class="btn btn-danger"
    >
      Reject All
    </button>
  </span>
</div>

<!-- //====================== -->
<div class="accordion" id="accordionExample">
  <div class="accordion-item" *ngFor="let exp of expense; let i = index">
    <h2 class="accordion-header" id="headingOne{{ i + 2 }}">
      <!-- {{exp | json}} -->
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#collapseOne'"
        aria-expanded="true"
        [attr.data-bs-target]="'#collapseOne' + (i + 2)"
      >
        {{ exp.id }} ({{ exp.type }})
      </button>
    </h2>
    <div
      id="collapseOne{{ i + 2 }}"
      class="accordion-collapse collapse"
      [attr.aria-labelledby]="'#headingOne' + (i + 2)"
    >
      <!-- data-bs-parent="#accordionExample" exclude prom accordion to open it always untill click -->
      <div class="accordion-body">
        <table>
          <tr>
            <th>Expense Id</th>
            <td>{{ exp.id }}</td>
          </tr>

          <tr>
            <th>Type</th>

            <td>
              <select #selectexpense [disabled]="!(is_actions_global && exp.is_action)" (change)="onDropdownChange(selectexpense.value,exp.id,exp.type_id,exp.type,i)" name="selectexpense" id="expense_type{{i}}" >
                <option [value]="exp.type_id">{{expensetype[exp.type_id]}}</option>
                <option *ngFor="let key of filterExpenseTypeKeys(expensetypekeys, exp.type_id)" [value]="key">
                  {{expensetype[key]}}
                </option>
              </select>
            </td>
          </tr>

          <tr>
            <th>Details</th>

            <td style="max-width: 500px; font-size: 12px">
              {{ exp.remark | titlecase }}
            </td>
          </tr>

          <tr>
            <th>Remark</th>

            <td>{{ exp.reason | titlecase }}</td>
          </tr>

          <tr>
            <th>Manager Approved On</th>

            <td>{{ exp.reviewed_at }}</td>
          </tr>

          <tr>
            <th>Date Of Expense</th>
            <td>
              <mat-form-field appearance="none">
                <input
                  [disabled]="!(is_actions_global && exp.is_action)"
                  matInput
                  [matDatepicker]="picker"
                  placeholder="{{ exp.expense_date }}"
                  [(ngModel)]="inputDateValue"
                  style="
                    width: 90px;
                    float: right;
                    height: 21px;
                    position: absolute;
                    right: 0;
                    top: 5px;
                  "
                />
                <br />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon
                    matDatepickerToggleIcon
                    style="width: 50px; height: 32px"
                  >
                    <img src="../../../assets/images/calendar.png" />
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker panelClass="endDate">
                  <mat-datepicker-actions>
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="updateExpenseDate(inputDateValue, exp.id)"
                      matDatepickerApply
                    >
                      Apply
                    </button>
                    <button mat-button matDatepickerCancel>Close</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <th>Supported Amount</th>

            <td>{{ exp.supported_amount }}</td>
          </tr>

          <tr>
            <th>Unsupported Amount</th>

            <td>{{ exp.unsupported_amount }}</td>
          </tr>

          <tr>
            <th>Total Amount</th>

            <td>{{ exp.unsupported_amount + exp.supported_amount }}</td>
          </tr>

          <tr>
            <th>Reviewed (By Finace Executive)</th>

            <td *ngIf="!exp.is_action; else hidedate">
              {{ exp.finance_executive_reviewed_at }}
            </td>
            <ng-template #hidedate>
              <td>Not Reviewed Yet</td>
            </ng-template>
          </tr>

          <tr>
            <th>Image</th>
            <div class="tdimg">
              <span *ngIf="exp.images.length > 0">
                <div
                  class="tdlink"
                  *ngFor="let img of exp.images; let k = index"
                >
                  <a
                    class="img{{ k }}"
                    href="{{ img.original.url }}"
                    target="_blank"
                    >view</a
                  >
                </div>
              </span>

              <p style="margin:0px;" *ngIf="exp.images.length == 0">No Images Found</p>
            </div>
          </tr>

          <tr>
            <th>Docs</th>

            <!-- <td>No Docs Attached</td> -->
            <div class="tddoc">
              <span *ngIf="exp.docs.length > 0">
                <div
                  class="tddoclink"
                  *ngFor="let doc of exp.docs; let j = index"
                >
                  <a class="doc{{ j }}" href="{{ doc.url }}" target="_blank">
                    view
                  </a>
                </div>
              </span>

              <p style="margin:0px;"*ngIf="exp.docs.length == 0">No Document Found</p>
            </div>
          </tr>

          <tr>
            <th>Status</th>

            <td>{{ exp.status | titlecase }}</td>
          </tr>
          <tr>
            <th>Duplicate</th>
            <p *ngIf="exp.duplicate!=null,else duplicate">
            <td>{{ exp.duplicate | titlecase }}</td>
            </p>
             <ng-template #duplicate>
              <td>No Duplicate Found</td>
             </ng-template>
          </tr>

          <tr>
            <th>Action</th>

            <!-- {{exp.is_action}} -->
            <td>
              <span *ngIf="exp.is_action; else hidebtn">
                <span *ngFor="let claimdata of claims">
                  <button
                    (click)="openApproveConfirmation(exp.id, claimdata.data.id)"
                    class="btn btn-success"
                  >
                    Approve
                  </button>

                  <button
                    (click)="openRejectConfirmation(exp.id, claimdata.data.id)"
                    class="btn btn-danger"
                  >
                    Reject
                  </button>
                </span>
              </span>
              <ng-template #hidebtn>
                <p>
                  This expense was reviewed.
                  {{ exp.finance_executive_reviewed_at }}
                </p>
              </ng-template>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

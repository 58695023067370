<div  class="row main-header">
    <div class="col-sm-6">
        <div class="row">
            <div class="col-sm-3">
               <a href="/home"><img src="../../../assets/images/amplusicon.svg" alt="" srcset="" class="logo"></a>
            </div>
            <div class="col-sm-9 text-part">
                <p>Amplus Empower | <span class="light">Your Employee Experience Portal</span></p>
            </div>
        </div>
        
    </div>
    <div class="col-sm-6 user-img-section">
        <div class="mainNameSection">
            <span *ngIf="showText" class="round" id="round"> {{initials}}</span>
            <span class="name">{{fullName}}</span>
            <span *ngIf="!showText" class="round" id="roundImg"> </span>
            <!-- <span class="name">{{dates.name}} <br> <span class="job_title">{{dates.job_title}}</span></span> --> 
          </div>
    </div>
</div>

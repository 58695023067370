<div class="manage-proposal-wrapper" style="width: 80vw; max-height: fit-content;">
    <div class="row">
        <div class="col-3 bulk">
            Bulk Submission for
        </div>
        <div class="col-3 department">
            <mat-select class="input" style=" border-bottom: 0.1vh solid #ccc;">
                <!-- <ng-container> -->
                <mat-option *ngFor="let option of departmentList" [value]="option.id"
                    (onSelectionChange)="getEmployeeList($event)">{{ option.name
                    }}</mat-option>
                <!-- </ng-container> -->
            </mat-select>
        </div>
    </div>
    <div *ngIf="emp_data.length == 0 && !noData" class="row">
        <div class="col select">
            Please Select Department
        </div>
    </div>
    <div *ngIf="noData" class="row">
        <div class="col select">
            No Data Available :(
        </div>
    </div>
    <div class="row" *ngIf="emp_data.length > 0">
        <div class="col select-all">
            <input class="form-check-input" type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                (change)="checkUncheckAll()" /> &nbsp; <strong>Select/Unselect All</strong>
        </div>
    </div>
    <div *ngFor="let item of emp_data; let i = index">
        <div *ngIf="item.status == 'saved' || item.status == 'department pending'" class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col emp-details">
                        <input *ngIf="item.status != 'freezed'" class="form-check-input" type="checkbox"
                            [(ngModel)]="item.isSelected" name="list_name" value="{{item.emp_id}}"
                            (change)="isAllSelected()" />&nbsp;
                        {{item.employee}}: {{item.employee_fullname}}
                    </div>
                    <div *ngIf="item.status == 'freezed'" class="col-2">
                        <span class="badge text-bg-success" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                    <div *ngIf="item.status == 'saved' || item.status == 'department pending' " class="col-2">
                        <span class="badge text-bg-warning" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                    <div *ngIf="item.status == 'team pending' || item.status == 'self pending'" class="col-2">
                        <span class="badge text-bg-danger" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                </div>
                <div *ngIf="!data.midCycle" class="row">
                    <div class="col-2 clip-score">
                        Clip Score:
                    </div>
                    <div class="col clip-h">
                        Commitment &nbsp; <input class="clip" type="number" min="0" max="100" id="commitment_{{i}}"
                            (keyup)="handleChange(item.clip_commitment, 'commitment_'+i)"
                            [(ngModel)]="item.clip_commitment">
                    </div>
                    <div class="col clip-h">
                        Leadership &nbsp; <input matInput class="clip" type="number" min="0" max="100"
                            id="leadership_{{i}}" (keyup)="handleChange(item.clip_leadership, 'leadership_'+i)"
                            [(ngModel)]="item.clip_leadership" required>
                    </div>
                    <div class="col clip-h">
                        Innovation &nbsp; <input class="clip" type="number" min="0" max="100" id="innovation_{{i}}"
                            (keyup)="handleChange(item.clip_innovation, 'innovation_'+i)"
                            [(ngModel)]="item.clip_innovation">
                    </div>
                    <div class="col clip-h">
                        Performance &nbsp; <input class="clip" type="number" min="0" max="100" id="perdormance_{{i}}"
                            (keyup)="handleChange(item.clip_performance, 'performance'+i)"
                            [(ngModel)]="item.clip_performance">
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="!data.midCycle" class="col clip-h" style="padding-left: 5vh;">
                        Is low performer ?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input class="form-check-input" type="radio" name="low_performer_{{i}}"
                                id="low_performer_{{i}}" [value]=true [checked]="item.low_performance"
                                [(ngModel)]="item.low_performance">
                            <label class="form-check-label" for="low_performer_{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input class="form-check-input" type="radio" name="low_performer_{{i}}"
                                id="low_performer_{{i}}" [value]=false [checked]="!item.low_performance"
                                [(ngModel)]="item.low_performance">
                            <label class="form-check-label" for="low_performer_{{i}}">No</label>
                        </div>
                    </div>
                    <div *ngIf="item.employment_type == 'Off-Roll' && !data.midCycle" class="col clip-h"
                        style="text-align: right; margin-right: 5vh;">
                        Off-roll to On-roll?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input class="form-check-input" type="radio" name="offroll_to_onroll{{i}}"
                                id="offroll_to_onroll{{i}}" [value]=true [checked]="item.offroll_to_onroll"
                                [(ngModel)]="item.offroll_to_onroll">
                            <label class="form-check-label" for="offroll_to_onroll{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input class="form-check-input" type="radio" name="offroll_to_onroll{{i}}"
                                id="offroll_to_onroll{{i}}" [value]=false [checked]="!item.offroll_to_onroll"
                                [(ngModel)]="item.offroll_to_onroll">
                            <label class="form-check-label" for="offroll_to_onroll{{i}}">No</label>
                        </div>
                    </div>
                    <div class="col clip-h" style="text-align: right; margin-right: 5vh;">
                        Ready for Promotion?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input class="form-check-input" type="radio" name="promotion_{{i}}" id="promotion_{{i}}"
                                [value]=true [checked]="item.ready_for_promotion" [(ngModel)]="item.ready_for_promotion">
                            <label class="form-check-label" for="promotion_{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input class="form-check-input" type="radio" name="promotion_{{i}}" id="promotion_{{i}}"
                               [value]=false [checked]="!item.ready_for_promotion" [(ngModel)]="item.ready_for_promotion">
                            <label class="form-check-label" for="promotion_{{i}}">No</label>
                        </div>
                    </div>
                    <div *ngIf="data.midCycle" class="col clip-h">
                        Increment(%) &nbsp; <input class="clip" type="number" min="0" max="100" id="increment_{{i}}"
                            (keyup)="handleChange(item.increment, 'increment_'+i)" [(ngModel)]="item.increment">
                    </div>
                </div>
                <div *ngIf="data.midCycle" class="row">
                    <div class="col-3 clip-h" style="text-align: right; padding-top: 4vh;">
                        Increment Remarks
                    </div>
                    <div class="col"  style=" padding: 2vh 0.9vw;">
                        <input class="form-check-input answers" type="text" name="remarks_{{i}}" id="remarks_{{i}}"
                            [(ngModel)]="item.increment_remarks">
                            <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="item.increment_remarks?.length > 1000">
                        Please enter less than 1000 characters.
                    </span>
                    <span style="color:red; padding: 0.5vh 3vh; font-size: 1.5vh;"
                        *ngIf="item.increment_remarks?.length > 0 && item.increment_remarks.length <10">
                        Please enter minimum 10 characters.
                    </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="item.status == 'freezed' || item.status == 'self pending' || item.status == 'team pending'"
            class="card blocked">
            <div class="card-body">
                <div class="row">
                    <div class="col emp-details">
                        {{item.employee}}: {{item.employee_fullname}}
                    </div>
                    <div *ngIf="item.status == 'freezed'" class="col-2">
                        <span class="badge text-bg-success" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                    <div *ngIf="item.status == 'saved' || item.status == 'department pending' " class="col-2">
                        <span class="badge text-bg-warning" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                    <div *ngIf="item.status == 'team pending' || item.status == 'self pending' " class="col-2">
                        <span class="badge text-bg-danger" style="font-size: 1.5vh;">{{item.status | titlecase}}</span>
                    </div>
                </div>
                <div *ngIf="!data.midCycle" class="row">
                    <div class="col-2 clip-score">
                        Clip Score:
                    </div>
                    <div class="col clip-h">
                        Commitment &nbsp; <input class="clip" type="number" min="0" max="100" id="commitment_{{i}}"
                            [(ngModel)]="item.clip_commitment" disabled>
                    </div>
                    <div class="col clip-h">
                        Leadership &nbsp; <input matInput class="clip" type="number" min="0" max="100"
                            id="leadership_{{i}}" [(ngModel)]="item.clip_leadership" disabled>
                    </div>
                    <div class="col clip-h">
                        Innovation &nbsp; <input class="clip" type="number" min="0" max="100" id="innovation_{{i}}"
                            [(ngModel)]="item.clip_innovation" disabled>
                    </div>
                    <div class="col clip-h">
                        Performance &nbsp; <input class="clip" type="number" min="0" max="100" id="perdormance_{{i}}"
                            [(ngModel)]="item.clip_performance" disabled>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="!data.midCycle" class="col clip-h" style="padding-left: 5vh;">
                        Is low performer ?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input *ngIf="item.low_performance" class="form-check-input" type="radio"
                                name="low_performer_{{i}}" id="low_performer_{{i}}" [value]=true disabled checked>
                            <input *ngIf="!item.low_performance" class="form-check-input" type="radio"
                                name="low_performer_{{i}}" id="low_performer_{{i}}" [value]=true disabled>
                            <label class="form-check-label" for="low_performer_{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input *ngIf="!item.low_performance" class="form-check-input" type="radio"
                                name="low_performer_{{i}}" id="low_performer_{{i}}" [value]=false disabled checked>
                            <input *ngIf="item.low_performance" class="form-check-input" type="radio"
                                name="low_performer_{{i}}" id="low_performer_{{i}}" [value]=false disabled>
                            <label class="form-check-label" for="low_performer_{{i}}">No</label>
                        </div>
                    </div>
                    <div *ngIf="item.employment_type == 'Off-Roll' && !data.midCycle" class="col clip-h"
                        style="text-align: right; margin-right: 5vh;">
                        Off-roll to On-roll?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input class="form-check-input" type="radio" name="offroll_to_onroll{{i}}"
                                id="offroll_to_onroll{{i}}" [value]=true [checked]="item.offroll_to_onroll"
                                [(ngModel)]="item.offroll_to_onroll" disabled>
                            <label class="form-check-label" for="offroll_to_onroll{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input class="form-check-input" type="radio" name="offroll_to_onroll{{i}}"
                                id="offroll_to_onroll{{i}}" [value]=false [checked]="!item.offroll_to_onroll"
                                [(ngModel)]="item.offroll_to_onroll" disabled>
                            <label class="form-check-label" for="offroll_to_onroll{{i}}">No</label>
                        </div>
                    </div>
                    <div class="col clip-h" style="text-align: right; margin-right: 5vh;">
                        Ready for Promotion?
                        <div class="form-check form-check-inline" style="margin: 0 1vw !important;">
                            <input *ngIf="item.ready_for_promotion" class="form-check-input" type="radio"
                                name="promotion_{{i}}" id="promotion_{{i}}" [value]=true disabled checked>
                            <input *ngIf="!item.ready_for_promotion" class="form-check-input" type="radio"
                                name="promotion_{{i}}" id="promotion_{{i}}" [value]=true disabled>
                            <label class="form-check-label" for="promotion_{{i}}">Yes</label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 0.5vw !important;">
                            <input *ngIf="!item.ready_for_promotion" class="form-check-input" type="radio"
                                name="promotion_{{i}}" id="promotion_{{i}}" [value]=false disabled checked>
                            <input *ngIf="item.ready_for_promotion" class="form-check-input" type="radio"
                                name="promotion_{{i}}" id="promotion_{{i}}" [value]=false disabled>
                            <label class="form-check-label" for="promotion_{{i}}">No</label>
                        </div>
                    </div>
                    <div *ngIf="data.midCycle" class="col clip-h">
                        Increment(%) &nbsp; <input class="clip" type="number" min="0" max="100" id="increment_{{i}}"
                            [(ngModel)]="item.increment" disabled>
                    </div>
                </div>
                <div *ngIf="data.midCycle" class="row">
                    <div class="col-3 clip-h" style="text-align: right; padding-top: 4vh;">
                        Increment Remarks
                    </div>
                    <div class="col" style=" padding: 2vh 0.9vw;">
                        <input class="form-check-input answers" type="text" name="remarks_{{i}}" id="remarks_{{i}}"
                            [(ngModel)]="item.increment_remarks" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin: 5vh 0vh;" *ngIf="emp_data.length > 0">
        <div class="col-7" style="padding-bottom: 15vh;">
        </div>
        <div class="col">
            <button class="btn btn-outline-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="save()" [disabled]="checkedList.length ==0">Save</button>
        </div>
        <div class="col">
            <button class="btn btn-primary" style="width: -webkit-fill-available;
width: -moz-available;" (click)="freeze()" [disabled]="checkedList.length ==0">Freeze</button>
        </div>
    </div>
</div>
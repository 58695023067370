// event-calender.component.ts
import { Component, Input, OnInit ,Renderer2} from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { EventPopupComponent } from '../event-popup/event-popup.component';
import { CommonModule } from '@angular/common';
import { MatCalendarCellClassFunction ,MatDatepicker} from '@angular/material/datepicker';
@Component({
  selector: 'app-event-calender',
  templateUrl: './event-calender.component.html',
  styleUrls: ['./event-calender.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
})
export class EventCalenderComponent implements OnInit {
  selected: Date;
  @Input()
  dates: any;
  @Input() 
  holidays:any;
  eventsForSelectedDate:any;
  holidaydate:Date;
  index:number;
  holidayDate:Date;
  eventsOnDate:any;
  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
   
    ) {}

  ngOnInit(): void {
    
  }
  
openEventPopup() {

      this. eventsForSelectedDate = this.holidays.filter(holiday => {
      const holidayDate = new Date(holiday.date);
      return this.selected && this.selected.toDateString() === holidayDate.toDateString();
    });

    if (this.eventsForSelectedDate.length > 0) {
      this.dialog.open(EventPopupComponent, {
        data: { selectedDate: this.selected, events: this.eventsForSelectedDate },
        panelClass: 'custom-dialog-container'
      });
    }
    this.selected = null;
  }

 
  



  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    
    this.index = this.dates.findIndex(x => new Date(x.date).toLocaleDateString() === cellDate.toLocaleDateString());
    this.eventsOnDate = this.holidays.filter(holiday => {
      this.holidayDate = new Date(holiday.date);
      return cellDate.toDateString() === this.holidayDate.toDateString();
    });
    if (this.index > -1) {
      if (this.dates[this.index].type == 3) {

       
    
        if (this.eventsOnDate.length === 1) {
          return 'single-event';
        } else if (this.eventsOnDate.length > 1) {
          return 'multiple-events';
        }
    

        // return "example-custom-date-class ";

      } else if (this.dates[this.index].type == 3) {

        return "example-custom-date-class date-grey";
      }

      else if (this.dates[this.index].type == 3) {

        return "example-custom-date-class date-AmplusBlueDark";
      }
    }
    return '';
  };

  
}


import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { PromotionHistoryComponent } from "../promotion-history/promotion-history.component";
import { LoaderService } from "src/app/core/services/loader.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'company-appraisal',
    templateUrl: './company-appraisal.component.html',
    styleUrls: ['./company-appraisal.component.scss']
})

export class CompanyAppraisalComponent implements OnInit {


    reportees = [];
    appraisalcycle: any;
    selfAp = 0;
    teamAp = 0;
    deptAp = 0;
    buAp = 0;
    today: any;
    count = 0;
    offset = 0;
    limit: number;
    isCompany: boolean;
    midCycle: boolean = false;

    constructor(
        private router: Router,
        private appraisalService: AppraisalService,
        private loginService: LoginService,
        private loaderService: LoaderService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.today = formatDate(new Date(), "yyyy-MM-dd", 'en-IN');
        this.isCompany = localStorage.getItem('company') == 'true' ? true : false;
        if (this.isCompany) {
            this.appraisalService.getCompanyAppraisals().subscribe((res: any) => {
                console.log(res, "<=----------=-=----------=-=------");
                this.reportees = res.data.departments;
                this.appraisalcycle = res.data.appraisal_cycle;
                this.midCycle =this.appraisalcycle.is_mid_cycle;
                this.selfAp = res.data.total_self_appraisal_done;
                this.teamAp = res.data.total_team_appraisal_done;
                this.deptAp = res.data.total_department_appraisal_done;
                this.buAp = res.data.total_business_appraisal_done;
                this.count = res.data.total_employees;
                console.log(this.reportees, "<=----------=-=----------=-=------");
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                })
        }
        console.log('inside company appraisal')
    }

    getPromotions(id) {
        this.appraisalService.getPromotionHistory({ employee: id }).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }
    
}
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ClaimserviceService } from "../service/claimservice.service";
import { Router } from "@angular/router";
import { AllLinksComponent } from "src/app/core/all-links/all-links.component";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoginService } from "src/app/core/login/login.service";
import * as $ from "jquery";
@Component({
  selector: "app-all-claim",
  templateUrl: "./all-claim.component.html",
  styleUrls: ["./all-claim.component.scss"],
})
export class AllClaimComponent implements OnInit {
  tableData: any[] = []; // Holds the fetched table data
  currentPage: number = 1; // Current page number
  isAdmin: boolean = false;
  documents: any;
  user: any;
  name: any;
  user_type: any;
  lndUserType: boolean = false;
  headingOne: string = "List Of Claims";
  items: any = [];
  filteredItems: any;
  date_length: any;
  p: number = 1;
  userdata: any = [];
  metadata: any;
  uname: string = "";
  uemail: string = "";
  pcode: any;
  claimid: any;
  legalentity: string = "";
  external: boolean;
  fromdate: any;
  todate: any;
  totalCount: number = 0;
  pageSize: number = 10;
  status: string = "";
  isChecked: boolean = false;
  totalPages: number = 0;
  AllLinksComponent: any;
  filter_value: boolean;
  ledata: any;
  link:any;
  numButtons:number = 5;
  middle:number;
  start:number;
  end:number;
  showcard:boolean;
  showhr:boolean;
  @ViewChild("externalCheckbox", { static: true }) externalCheckbox: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private claimservice: ClaimserviceService,
    private router: Router,
    public allLink: AllLinksComponent,
    private learnerService: LearnerService,
    private loginService: LoginService
  ) {}

  externalValue(isChecked: boolean) {
    if (isChecked) {
      this.external = isChecked;
    } else {
      this.external = isChecked;
    }
  }
  _filterData() {
    this.currentPage = 1;
    const filter = {
      page: this.currentPage,
      submitter_name: this.uname,
      submitter_email: this.uemail,
      claim_id: this.claimid,
      project_code: this.pcode,
      legal_entity: this.legalentity,
      status: this.status,
      external: this.external,
    };
    this.claimservice.setFilter(filter);
    const filter_data = this.claimservice.getFilter();
    console.log(filter_data);
    if (filter_data) {
      let Page = filter_data.page;
      this.uname = filter_data.submitter_name;
      this.uemail = filter_data.submitter_email;
      this.claimid = filter_data.claim_id;
      this.pcode = filter_data.project_code;
      this.legalentity = filter_data.legal_entity;
      this.status = filter_data.status;
      this.external = filter_data.external;
      this.claimservice
        .filterClaim(
          Page,
          this.uname,
          this.uemail,
          this.claimid,
          this.pcode,
          this.legalentity,
          this.status,
          this.external
        )

        .subscribe((value: any) => {
          this.tableData = value.data;
          this.totalCount = value.meta.count;
          this.totalPages = Math.ceil(this.totalCount / this.pageSize);
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              page: this.currentPage,
              submitter_name: this.uname,
              submitter_email: this.uemail,
              claim_id: this.claimid,
              project_code: this.pcode,
              legal_entity: this.legalentity,
              status: this.status,
              external: Boolean(this.external),
            },
            queryParamsHandling: "merge",
          });
          if (value.status) {
            $(".allclaimPopup").click();
          }
        });

      //setting clear filter button value when apply filterS
      filter_data.submitter_name !== "" ||
      filter_data.submitter_email !== "" ||
      filter_data.claim_id !== "" ||
      filter_data.project_code !== "" ||
      filter_data.legal_entity !== "" ||
      filter_data.external !== false
        ? (this.filter_value = true)
        : false;
    }
  }
  ngOnInit() {
    this.learnerService.getUserType().subscribe(
      (res) => {
        this.user = res["lnd_user_type"];
        this.lndUserType = true;
        localStorage.setItem("user_type", res["lnd_user_type"]);
      },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == "Signature has expired") {
          this.loginService.logout();
        }
      }
    );
    // this.user_type = localStorage.getItem('user_type');
    this.isAdmin = localStorage.getItem("user_type") == "admin" ? true : false;
    // this.user = localStorage.getItem('user_type');
    this.name = localStorage.getItem("name");

    this.route.queryParams.subscribe((params) => {
      const page = +params["page"] || 1;
      this.currentPage = page;
      this.uname = params["submitter_name"] || "";
      this.uemail = params["submitter_email"] || "";
      this.claimid = params["claim_id"] || "";
      this.pcode = params["project_code"] || "";
      this.legalentity = params["legal_entity"] || "";
      this.status = params["status"] || "";
      this.external = params["external"] === "true" || false;
    });

    const filter = {
      page: this.currentPage,
      submitter_name: this.uname,
      submitter_email: this.uemail,
      claim_id: this.claimid,
      project_code: this.pcode,
      legal_entity: this.legalentity,
      status: this.status,
      external: this.external,
    };
    this.claimservice.setFilter(filter);
    const filter_data_2 = this.claimservice.getFilter();
    console.log(filter_data_2);
    if (filter_data_2) {
      this.currentPage = filter_data_2.page;
      this.uname = filter_data_2.submitter_name;
      this.uemail = filter_data_2.submitter_email;
      this.claimid = filter_data_2.claim_id;
      this.pcode = filter_data_2.project_code;
      this.legalentity = filter_data_2.legal_entity;
      this.status = filter_data_2.status;
      this.external = filter_data_2.external;

      this.fetchData(
        this.currentPage,
        this.uname,
        this.uemail,
        this.claimid,
        this.pcode,
        this.legalentity,
        this.status,
        this.external
      );
      //again checking submitter_name when click on table row and back
      filter_data_2.submitter_name !== "" ||
      filter_data_2.submitter_email !== "" ||
      filter_data_2.claim_id !== "" ||
      filter_data_2.project_code !== "" ||
      filter_data_2.legal_entity !== "" ||
      filter_data_2.external !== false
        ? (this.filter_value = true)
        : false;
    }
  }
  onTableIdClick(id: any) {
    const filter = {
      page: this.currentPage,
      submitter_name: this.uname,
      submitter_email: this.uemail,
      claim_id: this.claimid,
      project_code: this.pcode,
      legal_entity: this.legalentity,
      status: this.status,
      external: this.external,
    };
    this.claimservice.setFilter(filter); // Store the filter in the service
    // this.router.navigate(["/detailclaim", id]);
     this.link=`/detailclaim/${id}`
     window.open(this.link, '_blank');
  }

  fetchData(
    page: number,
    uname: any,
    uemail: any,
    claimid: any,
    pcode: any,
    legalentity: any,
    status: string,
    external: boolean
  ) {
    this.claimservice
      .allClaim(
        page,
        uname,
        uemail,
        claimid,
        pcode,
        legalentity,
        status,
        external
      )
      .subscribe(
        (data: any) => {
          this.tableData = data.data;
          // console.log(this.tableData);
          this.ledata = data.meta.legal_entity;
          this.totalCount = data.meta.count;
          this.totalPages = Math.ceil(this.totalCount / this.pageSize);
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              page: this.currentPage,
              submitter_name: this.uname,
              submitter_email: this.uemail,
              claim_id: this.claimid,
              project_code: this.pcode,
              legal_entity: this.legalentity,
              status: this.status,
              external: this.external,
            },
            queryParamsHandling: "merge",
          });
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchData(
        this.currentPage,
        this.uname,
        this.uemail,
        this.claimid,
        this.pcode,
        this.legalentity,
        this.status,
        this.external
      );
    } else {
      // alert("no more previous pages")
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchData(
        this.currentPage,
        this.uname,
        this.uemail,
        this.claimid,
        this.pcode,
        this.legalentity,
        this.status,
        this.external
      );
    } else {
      //alert("no more next pages")
    }
  }
  firstPage() {
    this.currentPage = 1;
    this.fetchData(
      this.currentPage,
      this.uname,
      this.uemail,
      this.claimid,
      this.pcode,
      this.legalentity,
      this.status,
      this.external
    );
  }
  lastPage() {
    this.currentPage = this.totalPages;
    this.fetchData(
      this.currentPage,
      this.uname,
      this.uemail,
      this.claimid,
      this.pcode,
      this.legalentity,
      this.status,
      this.external
    );
  }
  getNumericButtons(): number[] {
      this.numButtons =10;
      this.middle = Math.ceil(this.numButtons / 2);
      this.start = Math.max(1, this.currentPage - this.middle + 1);
      this.end = Math.min(this.totalPages, this.start + this.numButtons - 1);
  
    const buttons: number[] = [];
    for (let i = this.start; i <= this.end; i++) {
      buttons.push(i);
    }
  
    return buttons;
  }
  
  gotoPage(page: number) {
    this.currentPage = page;
    this.fetchData(this.currentPage, this.uname, this.uemail, this.claimid, this.pcode, this.legalentity, this.status, this.external);
  }

  onRadioChange(selectedStatus: string) {
    this.currentPage = 1;
    this.status = selectedStatus;
    this.fetchData(
      this.currentPage,
      this.uname,
      this.uemail,
      this.claimid,
      this.pcode,
      this.legalentity,
      this.status,
      this.external
    );
  }

  clearFilter() {
    this.currentPage = 1;
    this.uname = "";
    this.uemail = "";
    this.claimid = "";
    this.pcode = "";
    this.legalentity = "";
    // this.status = "";
    this.externalCheckbox.nativeElement.checked = false;
    this.external = false;
    this.fetchData(
      this.currentPage,
      this.uname,
      this.uemail,
      this.claimid,
      this.pcode,
      this.legalentity,
      this.status,
      this.external
    );
    //make filter value false
    this.filter_value = false;
  }

  _clearData() {
    this.uname = "";
    this.uemail = "";
    this.pcode = "";
    this.claimid = "";
    this.legalentity = "";
    if (this.externalCheckbox.nativeElement.checked) {
      this.external = false;
      this.externalCheckbox.nativeElement.checked = false;
    }
  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }
  hrmanagercardshow(showhrmanagerdata){
    this.showhr=showhrmanagerdata
    
 }
}

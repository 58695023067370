<div class="row">
    <div class="col session-header p1rem">
        Drafts
    </div>
</div>
<div *ngFor="let item of draftList">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col text-heading">
                                    <strong>{{item.name}}</strong> | {{item.modality}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-decor">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    {{item.location}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-decor">
                                    <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                    {{item.start_time | date:'MMM dd, yyyy'}} |
                                    {{item.start_time | date:'HH:mm aa'}} -
                                    {{item.end_time | date:'HH:mm aa'}}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col p5 view-btn">
                                    <button type="button" class="btn btn-primary" (click)="viewDraft(item.id)">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="draftList.length <=0 && isAdmin" class="row no-data" >
    <div class="col">
        no sessions available :(
    </div>
</div>

<!-- event-popup.component.html -->
<div class="modals">
<div class="modal-header">
    <p>{{ selectedDate | date: 'mediumDate' }}</p>
    <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul>
      <li *ngFor="let event of events">
       <p>{{ event.name }}</p>
      </li>
    </ul>
    
  </div>
</div>
  

<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"></app-header-global>
<div class="row allcontent">

    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr"></app-side-overlay-menu>
    </div>

    <div class="col-sm-10 card_div">
        <p class="title">Memories</p>
        <div class="card-wrapper">

            <div class="row">
                 
                <div class="col-sm-3" *ngFor="let event of memoriesdata" (click)="navigateToSubroute(event.id)">
                    <div class="image_wrapper">
                        <div class="image_div">
                            <img [src]="event.thumbnail_image" alt="{{ event.title }}">
                         
                        </div>
                        <div class=" title_div">
                            <div class="location">
                                <p> {{ event.location }}</p>&nbsp;|&nbsp; 
                                 <p> {{ event.date |date }}</p>
                             </div>
                                <p>{{ event.title }}</p>
                        </div>
                      </div>
                   
                   
                    
                </div>
            
            
          </div>
        
        </div>
    </div>

</div>
<div class="addholiday_div">
  <h4>Add A Holiday</h4>
  <form
    #holidayform="ngForm"
    (ngSubmit)="_addHoliday(holidayform)"
    (ngSubmit)="resetForm(holidayform)"
  >
    <div class="row">
      <div class="col-sm-4 inputdiv">
       
        <select name="holidaytype" ngModel required #holidaytype="ngModel">
          <option value="" disabled selected>Select Holiday Type</option>
          <option value="0">Holiday</option>
          <option value="1">WeekEnd</option>
          <option value="2">Optional</option>
          <option value="3">Event</option>
        </select>
        <span *ngIf="holidaytype.invalid && holidaytype.touched"
          >Please Select Valid Holidaytype</span
        >
      </div>
      <div class="col-sm-4 inputdiv">
        <input
          type="text"
          placeholder="Enter The Name"
          required
          #holiday="ngModel"
          name="holidayname"
          pattern="^(?!\s*$)([\s\S]+)$"
          ngModel
        />
        <!-- pattern="[a-zA-Z0-9 -]+$" -->
        <span *ngIf="holiday.invalid && holiday.touched"
          >Please Enter Valid Holiday</span
        >
      </div>
      <div class="col-sm-4 inputdivs">
        <input
          matInput
          [matDatepicker]="pickerfrom"
          placeholder="From (dd-mm-yyyy)"
          name="holidaydate"
          required
          #holidaydate="ngModel"
          ngModel
          (click)="pickerfrom.open()"
        />
        <span *ngIf="holidaydate.invalid && holidaydate.touched"
          >Please Select Valid Date</span
        >
        <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerfrom></mat-datepicker>
      </div>
     
       <!-- <div class="col-sm-4 inputdivs"  *ngIf="holidaytype.value =='3'">
           <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
            <input matStartDate name="startdate" placeholder='From (dd-mm-yyyy)' required ngModel #rangedate="ngModel">
            <input matEndDate name="enddate"  placeholder='To(dd-mm-yyyy)' required ngModel #rangedate="ngModel">
          </mat-date-range-input>
         
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <span  *ngIf="rangedate.invalid && rangedate.touched"
           >Please select date range</span>
      </div> -->
    
    </div>
    <div class="row">
      <div class="col-sm-4 inputdiv" *ngIf="holidaytype.value == '3'">
        <textarea
          maxlength="400"
          type="text"
          required
          placeholder="Enter Event Description"
          name="eventdescription"
          #description="ngModel"
          pattern="^(?!\s*$)([\s\S]+)$"
          ngModel
        >
        </textarea>
        <span *ngIf="description.invalid && description.touched"
          >Please Enter Descriptions</span
        >
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-4 inputdiv" *ngIf="holidaytype.value == '3'">
            <select name="eventtype" [(ngModel)]="selectedEventType" required
            #eventtype="ngModel">
              <option value="offline">Offline</option>
              <option value="online">Online</option>
            </select>
            <span *ngIf="eventtype.invalid && eventtype.touched"
            >Please Select Event Type</span
          >
          </div>
          <div class="col-sm-4 inputdiv" *ngIf="holidaytype.value == '3'">
                <input
                  matInput
                  [ngxTimepicker]="timepicker"
                  [format]="24"
                  placeholder="Select Start Time"
                  name="eventstarttime"
                  required
                  #selectedtime="ngModel"
                  ngModel
                />
                <span *ngIf="selectedtime.invalid && selectedtime.touched"
                  >Please Select Event Start Time</span
                >
                <ngx-material-timepicker #timepicker></ngx-material-timepicker>
             </div>
             <div class="col-sm-4 inputdiv" *ngIf="holidaytype.value == '3'">
              <input
                  matInput
                  [ngxTimepicker]="timepickers"
                  [format]="24"
                  placeholder="Select End Time"
                  name="eventendtime"
                  required
                  #selectedtimes="ngModel"
                  ngModel
                />
                <span *ngIf="selectedtimes.invalid && selectedtimes.touched"
                  >Please Select Event End Time</span
                >
                <ngx-material-timepicker #timepickers></ngx-material-timepicker>
              
             </div>
       
      
          <div
            class="col-sm-4 inputdiv"
            *ngIf="holidaytype.value == '3'"
          >
            <input
              type="text"
              placeholder="Enter Event Location"
              name="eventlocation"
              required
              #eventLocation="ngModel"
              pattern="^(?!\s*$)([\s\S]+)$"
              ngModel
            />
            <span *ngIf="eventLocation.invalid && eventLocation.touched"
              >Please Enter valid Event Location</span
            >
          </div>
          <!-- <div
            class="col-sm-4 inputdiv"
            *ngIf="holidaytype.value == '3' && selectedEventType === 'online'"
          >
            <input
              type="text"
              placeholder="Enter Link to Join Online"
              pattern="https?://.+"
              #onlineLink="ngModel"
              required
              name="onlinelink"
              ngModel
            />
            <span *ngIf="onlineLink.invalid && onlineLink.touched"
              >Please Enter Event Link</span
            >
          </div> -->
        </div>
      </div>
    </div>

    <br />
    <button
      [disabled]="holidayform.invalid"
      type="submit"
      class="btn btn-success"
    >
      Add Holiday
    </button>
  </form>
</div>

<div class="col holiday_btn">
  <button
    class="btn btn-light"
    [ngClass]="{ 'btn-clicked': selectedButton === 'all' }"
    (click)="onButtonClick('all')"
  >
    All
  </button>
  <button
    class="btn btn-light"
    [ngClass]="{ 'btn-clicked': selectedButton === 'upcoming' }"
    (click)="onButtonClick('upcoming')"
  >
    Upcoming
  </button>
  <button
    class="btn btn-light"
    [ngClass]="{ 'btn-clicked': selectedButton === 'past' }"
    (click)="onButtonClick('past')"
  >
    Past
  </button>
</div>
<app-hr-header [holidayHeading]="headingHoliday"></app-hr-header>

<div class="table-responsive">
  <table class="table" id="emp_expense">
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Type</th>
        <th>Day</th>
        <th>Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let holiday of holidaydata
            | paginate : { itemsPerPage: 10, currentPage: p };
          let i = index
        "
      >
        <td>{{ i + 1 }}</td>
        <td>{{ holiday.title | titlecase }}</td>
        <td>{{ holiday.type | titlecase }}</td>
        <td>{{ getDayForDate(holiday.date) }}</td>
        <td>{{ holiday.date }}</td>
        <td>
          <button
            (click)=" openDeleteConfirmation(holiday.id)"
            class="btn btn-danger"
          >
            Delete<i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="page_div">
  <div class="row" id="pagination-container">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>


import { Component, OnInit ,Input,SimpleChanges} from '@angular/core';
import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'


@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements OnInit {

  @Input() placeholder: string = 'Enter value';
  @Input() heading: string = 'Enter the text here';
  @Input() hint: string = 'Enter the hint here';
  @Input() isDisabled: boolean = false;
  @Input() id: string='id'
  @Input() minWords: number
  @Input() maxWords: number
  @Input() isRequired: boolean;



  isValid: boolean = true;
  value: string;
  onChange: any = () => {};
  onTouched: any = () => {};

  inputValue: string = ''; // Assuming inputValue is initialized properly
  storedValues: { [key: string]: string } = {}; // Object to store key-value pairs

 
  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor() { }

  ngOnInit(): void {
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.inputValue) {
  //     this.validateInput();
  //   }
  // }

  onInputChange(value: string,id:any): void {
  
    let span=(document.getElementById(`${id}_span`) as HTMLInputElement)
      span.style.display='none';
      
    const wordCount = value.length;
    if(this.maxWords && this.minWords){
      
      this.isValid = wordCount >= this.minWords && wordCount <= this.maxWords;
    }

  }

}

